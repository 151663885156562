import { types } from 'redux/actions/taskDetails'
import * as loadActions from '../middleware/loadActions'

const initialState = {
  ...loadActions.objectInitialState,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.LOAD_DETAILS_START:
      return loadActions.onLoadStart(state)
    case types.LOAD_DETAILS_SUCCESS:
      return loadActions.onLoadSuccess(state, action)
    case types.LOAD_DETAILS_FAIL:
      return loadActions.onLoadFailure(state, action)
    case types.UPDATE_DETAILS_START:
      return {
        ...state,
        isUpdating: true,
        isUptated: false
      }
    case types.UPDATE_DETAILS_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isUptated: true,
        data: {
          ...state.data,
          data: action.response.data.data,
          status: action.response.data.status,
          updatedAt: action.response.data.updatedAt,
        }
      }
    case types.UPDATE_DETAILS_FAIL:
      return loadActions.onLoadFailure(state, action)
    default:
      return state
  }
}
