import React from 'react'
import PropTypes from 'prop-types'
import BpmnJS from 'bpmn-js/lib/NavigatedViewer'
import minimapModule from 'diagram-js-minimap'

import emptyBpmn from './empty.bpmn'
import 'diagram-js-minimap/assets/diagram-js-minimap.css'


class BpmnViewer extends React.Component {
  viewer = null

  componentDidMount() {
    const { diagramXML } = this.props

    this.viewer = new BpmnJS({
      container: '#bpmn-container',

      keyboard: {
        bindTo: window
      },
      additionalModules: [
        minimapModule,

      ],
      textRenderer: {
        defaultStyle: {
          fontWeight: 'bold',
          fill: 'white',
        }
      },
      bpmnRenderer: {
        defaultFillColor: '#e4e4e4',
        defaultStrokeColor: '#8893a4'
      }
    })

    this.displayDiagram(diagramXML || emptyBpmn)
    console.log(this.viewer)
  }

  componentWillUnmount() {
    this.viewer.destroy()
  }

  displayDiagram(diagramXML) {
    (async () => {
      await this.viewer.importXML(diagramXML)
    })()
  }

  render() {
    return (
      <div id="bpmn-container" />
    )
  }
}

BpmnViewer.propTypes = {
  diagramXML: PropTypes.string.isRequired,
}

export default BpmnViewer
