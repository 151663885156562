import * as React from 'react'
import Card from '@material-ui/core/Card'
import { Grid } from '@material-ui/core/'
import CardContent from '@material-ui/core/CardContent'
import { Title } from 'react-admin'
import { SimpleBarChart } from './SimpleBarChart'
import { StackedBarChart } from './StackedBarChart'
import { SimpleLineChart } from './SimpleLineChart'
import { StackedAreaChart } from './StackedAreaChart'

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR'
})

const kFormatter = num => (Math.abs(num) > 999 ? `${Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1))}k` : Math.sign(num) * Math.abs(num))

const dailyRevenue = [
    { day: 'Wed, Jul 1', revenue: 10000 },
    { day: '', revenue: 50000 },
    { day: '', revenue: 30000 },
    { day: 'Sat, Jul 4', revenue: 40000 },
    { day: '', revenue: 50000 },
    { day: '', revenue: 60000 },
    { day: 'Thu, Jul 7', revenue: 40000 },
    { day: '', revenue: 30000 },
    { day: '', revenue: 80000 },
    { day: 'Fri, Jul 10', revenue: 40000 },
]

const data = [
    { month: 'Jan', value_1: 10000, value_2: 10000, revenue: 10000 },
    { month: 'Feb', value_1: 100000, value_2: 20000, revenue: 50000 },
    { month: 'Mar', value_1: 30000, value_2: 50000, revenue: 20000 },
    { month: 'Apr', value_1: 40000, value_2: 60000, revenue: 30000 },
    { month: 'May', value_1: 50000, value_2: 20000, revenue: 60000 },
    { month: 'Jun', value_1: 50000, value_2: 30000, revenue: 40000 },
    { month: 'Jul', value_1: 90000, value_2: 50000, revenue: 120000 },
    { month: 'Aug', value_1: 50000, value_2: 80000, revenue: 130000 },
    { month: 'Sep', value_1: 50000, value_2: 90000, revenue: 11000 },
    { month: 'Oct', value_1: 50000, value_2: 120000, revenue: 90000 },
    { month: 'Nov', value_1: 50000, value_2: 150000, revenue: 100000 },
    { month: 'Dez', value_1: 50000, value_2: 120000, revenue: 150000 },
]

export default () => (
  <div>
    <Grid container spacing={5} alignItems="stretch">

      <Grid item xs={12} sm={12} lg={6}>

        <Card>
          <Title title="Finance Dashboard" />
          <CardContent>Revenue By Day</CardContent>
          <SimpleBarChart data={data} kFormatter={kFormatter} formatter={formatter} />
        </Card>

      </Grid>

      <Grid item xs={12} sm={12} lg={6}>
        <Card>
          <CardContent>Revenue By Day</CardContent>
          <SimpleLineChart data={dailyRevenue} kFormatter={kFormatter} formatter={formatter} />
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <Card>
          <CardContent>Profit-Loss</CardContent>
          <StackedAreaChart data={data} kFormatter={kFormatter} formatter={formatter} />
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <Card>
          <CardContent>Billing</CardContent>
          <StackedBarChart data={data} kFormatter={kFormatter} formatter={formatter} />
        </Card>
      </Grid>


    </Grid>

  </div>
)
