import React, { Fragment, PureComponent } from 'react'
import { connect } from 'react-redux'
import ReactRouterPropTypes from 'react-router-prop-types'
import PropTypes from 'prop-types'
import { loadToolDetails } from 'redux/actions/toolDetails'
import { loadFavoriteList } from 'redux/actions/favorites'
import DefaultLayout from 'layouts/DefaultLayout'
import BackButton from 'components/BackButton'
import ToolDetailContainer from 'components/Tools/ToolDetail'
import { Container } from 'reactstrap'
import { t } from 'utils/i18n/translate'
import { setTitle } from 'redux/actions/system'
import Image from 'components/Image'
import Spinner from 'components/Spinner'

export class ToolDetailPage extends PureComponent {
  componentDidMount() {
    const { loadTool, loadFavorites, match, setPageTitle } = this.props
    const { id } = match.params

    loadFavorites()
    loadTool(id).then((result) => {
      setPageTitle(`${result.data.name} - ${t('app.pages.titles.default')}`)
    })
  }

  render() {
    const { tool, isLoaded, status } = this.props

    return (
      <Fragment>
        <DefaultLayout status={status}>
          {!isLoaded && <Spinner align="center" top={50} />}
          {isLoaded && (
            <Fragment>
              <div className="tool-header">
                <Image
                  withBlackOverlay
                  url={tool.bannerImageUrl || tool.imageUrl}
                  alt={tool.altBannerImage}
                />
                <Container className="pt-3">
                  <BackButton className="back-btn" />
                </Container>
                <div className="tile-gradient" />
              </div>
              <ToolDetailContainer />
            </Fragment>
          )}
        </DefaultLayout>
      </Fragment>
    )
  }
}

ToolDetailPage.defaultProps = {
  isLoaded: false
}

ToolDetailPage.propTypes = {
  loadTool: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  tool: PropTypes.object.isRequired,
  loadFavorites: PropTypes.func.isRequired,
  isLoaded: PropTypes.bool,
  status: PropTypes.number.isRequired,
  setPageTitle: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  tool: state.toolDetails.data,
  isLoaded: state.toolDetails.isLoaded,
  status: state.toolDetails.status
})

const mapDispatchToProps = dispatch => ({
  loadTool: id => dispatch(loadToolDetails(id)),
  loadFavorites: () => dispatch(loadFavoriteList()),
  setPageTitle: title => (dispatch(setTitle(title)))
})

export default connect(mapStateToProps, mapDispatchToProps)(ToolDetailPage)
