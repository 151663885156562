import { isEmpty } from 'utils/objects'

const getNestedObjectsOrArray = (obj, key, titleMap) => {
  const result = []
  if (typeof obj === 'object' && !isEmpty(obj)) {
    Object.keys(obj).forEach((objKey) => {
      const value = obj[objKey]
      if (typeof value === 'object') {
        result.push(...getNestedObjectsOrArray(value, objKey, titleMap))
      } else {
        const getTitle = titleMap.find(title => title.key.toLowerCase() === objKey.toLowerCase())
        result.push({
          key: getTitle ? getTitle.value : objKey,
          value
        })
      }
    })
  } else {
    result.push({
      key,
      value: isEmpty(obj) ? '-' : obj
    })
  }
  return result
}

const getTitlesFromNestedSchema = (obj, key) => {
  const result = []
  if (typeof obj === 'object' && Object.prototype.hasOwnProperty.call(obj, 'properties')) {
    Object.keys(obj.properties).forEach((objKey) => {
      result.push(...getTitlesFromNestedSchema(obj.properties[objKey], objKey))
    })
    result.push({
      key,
      value: obj.title
    })
  } else {
    result.push({
      key,
      value: obj.title
    })
  }

  return result
}

const reduceSchemaToArray = (schema) => {
  const result = []
  const data = schema.schema.properties || []
  Object.keys(data).forEach((key) => {
    result.push(...getTitlesFromNestedSchema(data[key], key))
  })
  return result
}

export const parseFields = (data, schema) => {
  const result = []
  let getTitle
  const titleMap = schema && Object.entries(schema).length !== 0 ? reduceSchemaToArray(schema) : []
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === 'object') {
      getTitle = titleMap.find(title => title.key.toLowerCase() === key.toLowerCase())
      result.push({
        key: getTitle ? getTitle.value : key,
        value: getNestedObjectsOrArray(data[key], key, titleMap)
      })
    } else {
      getTitle = titleMap.find(title => title.key.toLowerCase() === key.toLowerCase())
      result.push({
        key: getTitle ? getTitle.value : key,
        value: data[key]
      })
    }
  })
  return result
}

export default { parseFields }
