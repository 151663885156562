import React from 'react'
import PropTypes from 'prop-types'
import { sortObjects } from 'utils/arrays'
import Folder from './Folder'

const Folders = ({ folders, filteredFolders }) => {
  const folderList = filteredFolders || folders
  const sortedFolders = folderList.sort(sortObjects('sortOrder'))

  return (
    <div className="folders-content">
      {sortedFolders.map(folder => <Folder folder={folder} key={folder.id} />)}
    </div>
  )
}

Folders.defaultProps = {
  folders: [],
  filteredFolders: null
}

Folders.propTypes = {
  folders: PropTypes.array,
  filteredFolders: PropTypes.array
}

export default Folders
