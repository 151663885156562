import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'utils/i18n/translate'
import { isMobileOnly } from 'react-device-detect'
import { Container, TabContent, TabPane } from 'reactstrap'
import DashboardNavTabs from 'components/DashboardNavTabs'
import moment from 'moment'
import BackButton from 'components/BackButton'
import { isEmpty } from 'utils/objects'
import { Link } from 'react-router-dom'
import TaskDataTable from './TaskDataTable'
import TaskHeader from './TaskHeader'
import TaskForm from './TaskForm'

class TaskDetails extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedTab: this.activeTab()
    }
  }

  activeTab = () => {
    const { task } = this.props
    if (task.status === 'with_errors') {
      return 'errors-tab'
    }
    return isEmpty(task.data_schema) ? 'data-tab' : 'form-tab'
  }

  toggleFavorite = () => {
    if (this.props.favorite) {
      this.props.handleRemoveFavorite(this.props.favorite.id)
    } else {
      this.props.handleMakeFavorite()
    }
  }

  favoriteClasses = () => {
    let favClassNames = 'favorite'

    if (this.props.favorite) {
      favClassNames += ' active'
    }

    return favClassNames
  }

  handleChange = (index) => {
    this.setState({ selectedTab: index })
  }

  isClosed = status => (
    ['closed', 'processing'].includes(status)
  )

  shouldShowParent = () => {
    const { task } = this.props
    return task.taskType === 'notification' && !isEmpty(task.parent) && task.parent.id
  }

  render() {
    const { task, updateTaskDetails, role } = this.props
    const { selectedTab } = this.state
    const adminErrors = (
      task.adminErrors.length > 0 && (
        <ul>
          { (task.adminErrors || []).map(error => <li> {error} </li>) }
        </ul>
      )
    )
    const taskError = role === 'admin' ? adminErrors : task.requestError

    return (
      <div className="task-details">
        <Fragment>
          <Container>
            {!isMobileOnly && <BackButton className="d-sm-none d-md-inline-block" /> }

            <TaskHeader
              task={task}
              updateTaskDetails={updateTaskDetails}
              favoriteClassNames={this.favoriteClasses()}
              toggleFavorite={this.toggleFavorite}
              isMobileOnly={isMobileOnly}
            />

            <DashboardNavTabs handleChange={this.handleChange} activeTab={selectedTab}>
              <DashboardNavTabs.Tab name="form-tab" className="form" href="#" title={t('app.task_detail.form')} hidden={isEmpty(task.data_schema)} />
              <DashboardNavTabs.Tab name="data-tab" className="data" href="#" title={t('app.task_detail.data_and_properties')} />
              <DashboardNavTabs.Tab name="errors-tab" className="errors" href="#" title={t('app.task_detail.errors')} hidden={task.status !== 'with_errors'} />
            </DashboardNavTabs>
          </Container>
        </Fragment>

        <hr className="full-width-break" />
        { task.status === 'with_errors' && (
          <TabContent className="errors-content" activeTab={selectedTab}>
            <TabPane tabId="errors-tab">
              <Container className="pt-5">
                <div className="alert alert-danger bg-white">
                  <p className="mb-0"> {taskError || task.requestError} </p>
                </div>
              </Container>
            </TabPane>
          </TabContent>
        )}
        <TabContent className="data-content" activeTab={selectedTab}>
          <TabPane tabId="data-tab">
            { this.shouldShowParent() && (
              <Container className="pt-5">
                <Link to={`/tasks/${task.parent.id}`}> {t('app.task_detail.open_parent')} </Link>
              </Container>
            )}
            <TaskDataTable
              title={t('app.task_detail.current_task')}
              taskName={task.name}
              taskData={task.data}
              taskType={task.taskType}
              taskMetadata={task.metadataSchema}
              taskDataSchema={task.data_schema}
            />
            <PreviousData
              previousData={task.previousData}
              dataFromParents={task.dataFromParents}
            />
          </TabPane>
        </TabContent>
        <Container>
          <TabContent className="form-content" activeTab={selectedTab}>
            <TabPane tabId="form-tab">
              { !this.isClosed(task.status)
                ? (
                  <div className="form-tab-container d-flex justify-content-between mt-md-5 mt-lg-5">
                    <TaskForm
                      taskStatus={task.status}
                      dataSchema={task.data_schema}
                      data={task.data}
                      id={task.id}
                      updateTaskDetails={updateTaskDetails}
                    />
                    <div className="form-tab-info d-sm-none d-md-none d-lg-table">
                      <p>{t('app.task_detail.form_info.paragraph_1')}</p>
                      <p>{t('app.task_detail.form_info.paragraph_2')}</p>
                      <p className="mb-0">
                        {t('app.task_detail.form_info.paragraph_3_1')}
                        {' '}
                        <span className="font-weight-bold">{t('app.task_detail.form_info.paragraph_3_2')}</span>
                        {' '}
                        {t('app.task_detail.form_info.paragraph_3_3')}
                      </p>
                    </div>
                  </div>
                )
                : (
                  <div className="mt-5">
                    { task.status === 'processing' && (
                      <div className="alert alert-warning bg-white">
                        <p className="mb-0">{t('app.task_detail.task_form.processing')}</p>
                      </div>
                    )}
                    <div className="form-submit-info">
                      <h5 className="font-weight-bold mb-4">
                        {t('app.task_detail.task_submit_info_title')}
                        <span> {moment(new Date(task.updatedAt)).format('DD.MM.YYYY')}.</span>
                      </h5>
                      <p className="mb-0">{t('app.task_detail.task_submit_info_content_1')}</p>
                      <p className="mb-0">{t('app.task_detail.task_submit_info_content_2')}</p>
                    </div>
                  </div>
                )
              }
            </TabPane>
          </TabContent>
        </Container>
      </div>
    )
  }
}

const PreviousData = ({ previousData, dataFromParents }) => {
  if (!previousData || !dataFromParents) {
    return null
  }

  return (
    <Fragment>
      { dataFromParents.map(element => (
        <TaskDataTable
          title={element.taskName}
          key={element.id}
          taskType={element.taskType}
          taskName={element.taskName}
          taskData={element.data}
          taskDataSchema={element.data_schema}
        />
      ))}
    </Fragment>
  )
}

PreviousData.propTypes = {
  previousData: PropTypes.bool,
  dataFromParents: PropTypes.array,
}

PreviousData.defaultProps = {
  dataFromParents: null,
  previousData: false,
}

TaskDetails.defaultProps = {
  favorite: null,
  updateTaskDetails: null,
  role: null
}

TaskDetails.propTypes = {
  handleRemoveFavorite: PropTypes.func.isRequired,
  handleMakeFavorite: PropTypes.func.isRequired,
  updateTaskDetails: PropTypes.func,
  favorite: PropTypes.object,
  role: PropTypes.string,
  task: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.string,
    dataSchema: PropTypes.object,
    updatedAt: PropTypes.string,
    createdAt: PropTypes.string,
    favorite: PropTypes.object,
    id: PropTypes.string,
    metadataSchema: PropTypes.object,
    user: PropTypes.shape({
      name: PropTypes.string
    }),
    workflow: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string
    }),
    data_schema: PropTypes.object,
    data: PropTypes.object,
    taskType: PropTypes.string,
    parent: PropTypes.shape({
      id: PropTypes.string
    }),
    previousData: PropTypes.object,
    dataFromParents: PropTypes.object,
    adminErrors: PropTypes.array,
    requestError: PropTypes.string
  }).isRequired
}

export default TaskDetails
