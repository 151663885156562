import React from 'react'
import PropTypes from 'prop-types'

const CountAction = ({ count }) => (
  <div className="icon-action icon-counter">
    <div className="count">{count}</div>
  </div>
)

CountAction.propTypes = {
  count: PropTypes.number.isRequired
}

export default CountAction
