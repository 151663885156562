import { connect } from 'react-redux'
import TasksMagazine from './TasksMagazine'

const mapStateToProps = state => ({
  tasks: state.magazine.tasks.data,
  isLoading: state.tasks.isLoading,
  isLoaded: state.tasks.isLoaded,
})

export default connect(mapStateToProps)(TasksMagazine)
