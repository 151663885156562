import { hotjar } from 'react-hotjar'
import TagManager from 'react-gtm-module'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const Scripts = ({ id, userEmail, firstName, lastName, createdAt }) => {
  // FIX for IE
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach
  }

  if (process.env.REACT_APP_HOTJAR_ID) {
    hotjar.initialize(process.env.REACT_APP_HOTJAR_ID)
  }

  if (process.env.REACT_APP_INTERCOM_ID) {
    let settings = {
      app_id: process.env.REACT_APP_INTERCOM_ID
    }

    if (userEmail) {
      settings = {
        ...settings,
        user_id: id,
        email: userEmail,
        name: `${firstName} ${lastName}`,
        created_at: createdAt
      }
    }

    window.intercomSettings = settings
  }

  if (process.env.REACT_APP_GTM_ID) {
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID })
  }

  return null
}

Scripts.defaultProps = {
  userEmail: null,
  firstName: null,
  lastName: null,
  createdAt: null,
  id: null,
}

Scripts.propTypes = {
  userEmail: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  createdAt: PropTypes.string,
  id: PropTypes.string
}

const mapStateToProps = state => ({
  userEmail: state.auth.data.email,
  firstName: state.auth.data.firstName,
  lastName: state.auth.data.lastName,
  createdAt: state.auth.data.createdAt,
  id: state.auth.data.id
})

export default connect(mapStateToProps)(Scripts)
