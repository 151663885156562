import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { t } from 'utils/i18n/translate'
import { setViewType } from 'redux/actions/system'
import PropTypes from 'prop-types'
import { Col, Row } from 'reactstrap'
import SidebarContainer from 'layouts/Sidebar/container'
import classnames from 'classnames'
import ListViewTabsContainer from './ListViewTabsContainer'

export class ListViewPage extends PureComponent {
  componentDidMount() {
    const { setViewType: changeView, currentLayout } = this.props

    if (currentLayout === 'dashboard') {
      changeView('gridView')
    } else {
      changeView('listView')
    }
  }

  render() {
    const { isSidebarExpanded, tasksFilterValue, currentLayout } = this.props
    const isNotification = tasksFilterValue === 'notifications'
    const containerClasses = classnames('workflows-container', { 'is-hidden': isSidebarExpanded }, currentLayout)

    return (
      <Fragment>
        <Row>
          {isNotification && (
            <SidebarContainer title={t('activerecord.models.task.other')} />
          )}
          <Col className={containerClasses}>
            <ListViewTabsContainer />
          </Col>
        </Row>
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  setViewType: viewType => dispatch(setViewType(viewType))
})

const mapStateToProps = state => ({
  dashboardTabHeight: state.system.dashboardTabHeight,
  isSidebarExpanded: state.system.sidebar.isExpanded,
  tasksFilterValue: state.system.filters.tasks.value,
})

ListViewPage.propTypes = {
  isSidebarExpanded: PropTypes.bool.isRequired,
  setViewType: PropTypes.func.isRequired,
  tasksFilterValue: PropTypes.string.isRequired,
  currentLayout: PropTypes.string.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListViewPage)
