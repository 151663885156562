import React from 'react'
import { Admin, Resource } from 'react-admin'
import DefaultLayout from 'layouts/DefaultLayout'
import { createBrowserHistory } from 'history'
import { dashboardDataProvider } from 'admin/api/dashboardDataProvider'
import { ResourceList } from 'admin/components/ResourceList'

const history = createBrowserHistory({ basename: '/resource_dashboard' })

const options = {
  actions: [
    {
      name: 'Edit',
      workflow_id: process.env.REACT_APP_WORKFLOW_ACTION_1
    },
    {
      name: 'Put Sticker',
      workflow_id: ''
    }
  ],
  attributes: ['Name', 'Color']
}

export const ResourceDashboardPage = () => (
  <DefaultLayout>
    <div className="react-admin-layout">
      <Admin
        dataProvider={dashboardDataProvider}
        history={history}
      >
        <Resource
          name="cars"
          list={ResourceList}
          options={options}
        />
      </Admin>
    </div>
  </DefaultLayout>
)

export default ResourceDashboardPage
