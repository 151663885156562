import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import ReactRouterPropTypes from 'react-router-prop-types'

const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => {
  const render = (props) => {
    if (isLoggedIn) {
      return (
        <Component {...props} />
      )
    }

    const { location } = props

    return (
      <Redirect to={{ pathname: '/login', state: { from: location } }} />
    )
  }

  return (
    <Route {...rest} render={render} />
  )
}

PrivateRoute.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  component: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool
}

PrivateRoute.defaultProps = {
  isLoggedIn: false
}

export default PrivateRoute
