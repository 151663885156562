import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'
import Trans from 'components/Trans'
import FavoriteIconActionContainer from 'components/IconActions/FavoriteIconActionContainer'
import GoToIconAction from 'components/IconActions/GoToIconAction'
import Clickable from 'components/Metrics/Clickable'

export const ToolDetail = ({ tool }) => (
  <Fragment>
    <div className="tool-top">
      <Container>
        <Row>
          <Col className="title-bar">
            <h2>{ tool.name }</h2>
            <span className="category">{ tool.workflowCategory && tool.workflowCategory.name }</span>
          </Col>
          <Col className="icons">
            <Clickable id={tool.id} resource="Tool">
              <GoToIconAction link={tool.link} />
            </Clickable>
            <FavoriteIconActionContainer type="Tool" id={tool.id} />
          </Col>
        </Row>
      </Container>
    </div>
    <Container>
      <div className="tool-content">
        <span className="label"> <Trans id="app.tool.description" /> </span>
        <p className="description">{ tool.description }</p>
      </div>
    </Container>
  </Fragment>
)

ToolDetail.propTypes = {
  tool: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  tool: state.toolDetails.data
})

export default connect(mapStateToProps)(ToolDetail)
