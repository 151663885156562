import React, { Fragment, PureComponent } from 'react'
import { Nav } from 'reactstrap'
import { isMobileOnly, isMobile } from 'react-device-detect'
import CloseIcon from 'images/cancel_outline'
import NotificationIcon from 'images/ringtone-on'
import { history } from 'redux/configureStore'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SearchBarContainer from 'components/SearchBar/container'
import { t } from 'utils/i18n/translate'
import SearchIcon from 'images/search'
import GroupIcon from 'images/GroupIcon'
import ProfileNavItem from './ProfileNavItem'
import DashboardViewNavItemContainer from './DashboardViewNavItem'
import GroupNavItem from './GroupNavItem'
import UtilityNavItem from './UtilityNavItem'
import HamburgerMenuContainer from './HamburgerMenuContainer'

class LoggedNav extends PureComponent {
  state = {
    searchFocused: false
  }


  componentDidMount() {
    const { loadNotifications } = this.props
    loadNotifications()
  }

  openNotifications = () => {
    history.push('/notifications')
  }

  toggleFocusedSearch = (focused = false) => {
    this.setState(prevState => ({
      searchFocused: focused || !prevState.searchFocused
    }))
  }

  handleSearchClick = () => {
    const { searchValue } = this.props

    history.push(`/search?query=${searchValue}`)
  }

  render() {
    const {
      firstName,
      role,
      handleLogout,
      toggleSearchBar,
      isOpen,
      hasHelpPage,
      filterValue,
      notifications
    } = this.props

    const closeClasses = classNames('navbar-icon', 'close-icon', 'nav-link')
    const bellIconClass = classNames({ active: filterValue === 'notifications' })


    const badgeNumber = notifications.length

    // eslint-disable-next-line no-constant-condition
    const closeIcon = isOpen && false ? (
      <button
        id="close"
        title={t('app.accessibility.close_search_bar')}
        aria-label={t('app.accessibility.close_search_bar')}
        type="button"
        className={closeClasses}
        tabIndex="0"
        onClick={() => toggleSearchBar(false)}
      >
        <CloseIcon />
      </button>
    ) : ''

    const notificationIcon = (
      <UtilityNavItem
        id="bell"
        altText="notification icon"
        badgeNumber={badgeNumber}
        onClick={this.openNotifications}
        classes={bellIconClass}
        titleTag={t('app.accessibility.notifications_page')}
        ariaLabel={t('app.accessibility.notifications_page')}
      >
        <NotificationIcon />
      </UtilityNavItem>
    )

    const groupIcon = (
      <GroupNavItem
        id="groups"
      >
        <GroupIcon />
      </GroupNavItem>
    )

    const searchButtonClasses = classNames('search-icon', { active: isOpen })

    const searchIcon = (
      <Fragment>
        <SearchBarContainer />
        <UtilityNavItem
          id="search"
          altText="search icon"
          onClick={() => { this.handleSearchClick(); this.toggleFocusedSearch(true) }}
          classes={searchButtonClasses}
          titleTag={t('app.accessibility.open_search_bar')}
          ariaLabel={t('app.accessibility.open_search_bar')}
        >
          <SearchIcon />
        </UtilityNavItem>
      </Fragment>
    )

    return (
      <div className="browser-container">
        <Nav navbar>
          <div className="navbar-nav-flex-right">
            { closeIcon }
            { searchIcon }

            { (!isOpen || !isMobile) && !isMobileOnly && notificationIcon }
            { !isOpen && isMobile && (
              <HamburgerMenuContainer
                firstName={firstName}
                role={role}
                hasHelpPage={hasHelpPage}
                handleLogout={handleLogout}
              />
            )}
            { !isMobile && (
              <Fragment>
                { groupIcon }
                <DashboardViewNavItemContainer />
                <ProfileNavItem
                  firstName={firstName}
                  role={role}
                  hasHelpPage={hasHelpPage}
                  handleLogout={handleLogout}
                />
              </Fragment>
            )}
          </div>
        </Nav>
      </div>
    )
  }
}

LoggedNav.defaultProps = {
  role: null,
  hasHelpPage: false,
}

LoggedNav.propTypes = {
  firstName: PropTypes.string.isRequired,
  handleLogout: PropTypes.func.isRequired,
  toggleSearchBar: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  hasHelpPage: PropTypes.bool,
  role: PropTypes.string,
  filterValue: PropTypes.string.isRequired,
  loadNotifications: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
  searchValue: PropTypes.string.isRequired
}

export default LoggedNav
