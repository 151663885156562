import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { t } from 'utils/i18n/translate'
import { errorToast, successToast } from 'components/Toast'
import Form from '@rjsf/core'
import { isEmpty } from 'utils/objects'

const TaskForm = ({ id, updateTaskDetails, dataSchema, data, taskStatus }) => {
  const [shouldSubmit, setShouldSubmit] = useState(false)

  if (isEmpty(dataSchema)) { return null }

  const onSave = ({ formData }) => {
    const taskData = { formData: { ...formData, skipTransformKeys: true } }

    updateTaskDetails(id, taskData, false).then(() => {
      if (shouldSubmit) {
        submitTask()
      }
    })
  }

  const submitTask = () => {
    updateTaskDetails(id, { status: 'processing' }).then(() => {
      successToast(t('app.task_detail.task_form.form_submit_success'))
    }).catch(() => {
      errorToast(t('app.errors.camunda.complete_task'))
    })
  }

  const handleClick = (_event, submit) => {
    setShouldSubmit(submit)
    document.getElementById('jsonschema-form-button').click()
  }

  const isClosed = () => (
    ['closed', 'processing'].includes(taskStatus)
  )

  return (
    <div className="task-form-container mx-sm-0 mr-md-0">
      <Form
        className="task-form"
        schema={dataSchema.schema}
        uiSchema={dataSchema.uiSchema}
        formData={data}
        onSubmit={onSave}
      >
        <button id="jsonschema-form-button" type="submit" className="d-none" />
      </Form>
      {
        !isClosed() && (
          <div className="form-actions-container">
            <button
              type="button"
              onClick={handleClick}
              className="save btn btn-secondary mr-4"
            >
              {t('app.task_detail.form_save_button')}
            </button>
            <button
              type="submit"
              onClick={e => handleClick(e, true)}
              disabled={data === null || data.length === 0}
              className="submit btn btn-primary"
              id="submit-task"
            >
              {t('app.task_detail.form_submit_button')}
            </button>
          </div>
        )
      }
    </div>
  )
}

TaskForm.propTypes = {
  id: PropTypes.string.isRequired,
  dataSchema: PropTypes.object,
  data: PropTypes.object,
  updateTaskDetails: PropTypes.func.isRequired,
  taskStatus: PropTypes.string.isRequired,
}

TaskForm.defaultProps = {
  dataSchema: null,
  data: null,
}

export default TaskForm
