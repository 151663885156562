import React from 'react'
import PropTypes from 'prop-types'

const AddGroupIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width="25.776" height="13.049" viewBox="0 0 25.776 13.049">
    <g id="Group_8325" data-name="Group 8325" transform="translate(-1363 -189)">
      <path id="ic_group_add_24px" d="M8,10H5V7H3v3H0v2H3v3H5V12H8Zm5,1a3,3,0,1,0-3-3A2.987,2.987,0,0,0,13,11Zm0,2c-2,0-6,1-6,3v2H19V16C19,14,15,13,13,13Z" transform="translate(1363 184)" fill="#fff" />
      <path id="ic_group_24px" d="M15.448,9.9A2.448,2.448,0,1,0,13,7.448,2.437,2.437,0,0,0,15.448,9.9Zm0,1.632c-.237,0,.286-.024,0,0,.946.685.816,1.648.816,2.856v1.264h4.9V14.383C21.159,12.482,17.349,11.527,15.448,11.527Z" transform="translate(1367.617 186.402)" fill="#fff" />
    </g>
  </svg>
)

AddGroupIcon.defaultProps = {
  className: 'add-group-icon'
}

AddGroupIcon.propTypes = {
  className: PropTypes.string
}

export default AddGroupIcon
