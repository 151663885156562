export const types = {
  SET_TITLE: 'system/SET_TITLE',
  DASHBOARD_TAB_LOADED: 'system/DASHBOARD_TAB_LOADED',
  TOGGLE_FILTER: 'system/TOGGLE_FILTER',
  DASHBOARD_VIEW: 'system/DASHBOARD_VIEW',
  TOGGLE_EXPAND: 'system/TOGGLE_EXPAND',
  SET_FILTER: 'system/SET_FILTER',
  DASHBOARD_SWITCH_LAYOUT: 'system/DASHBOARD_SWITCH_LAYOUT',
  SET_VIEW_TYPE: 'system/SET_VIEW_TYPE',
  SET_TAB: 'system/SET_TAB',
  TOGGLE_MENU: 'system/TOGGLE_MENU',
  SWITCH_LAYOUT: 'system/SWITCH_LAYOUT'
}

export const setTitle = title => ({
  type: types.SET_TITLE,
  title
})

export const dashboardLoaded = () => ({
  type: types.DASHBOARD_TAB_LOADED,
  area: 'dashboard',
  value: 'all'
})

export const toggleFilter = area => ({
  type: types.TOGGLE_FILTER,
  area
})

export const toggleDashboardView = area => ({
  type: types.DASHBOARD_VIEW,
  area
})

export const setFilter = (area, value) => ({
  type: types.SET_FILTER,
  area,
  value
})

export const setViewType = viewType => ({
  type: types.SET_VIEW_TYPE,
  viewType
})

export const toggleExpand = openFlag => ({
  type: types.TOGGLE_EXPAND,
  openFlag
})

export const setTab = value => ({
  type: types.SET_TAB,
  value
})

export const dashboardSwitchLayout = direction => ({
  type: types.DASHBOARD_SWITCH_LAYOUT,
  direction
})

export const toggleMenu = isOpen => ({
  type: types.TOGGLE_MENU,
  isOpen
})

export const switchLayout = layout => ({
  type: types.SWITCH_LAYOUT,
  layout
})
