import { connect } from 'react-redux'
import { makeFavorite, removeFavorite } from 'redux/actions/favorites'
import HorizontalTile from 'components/HorizontalTile'

const getFavorite = (favorites, objectId, type) => (
  favorites.find(favorite => (
    favorite.favoritableType === type && favorite.favoritableId === objectId
  ))
)

const mapStateToProps = (state, ownProps) => ({
  favorite: getFavorite(state.favorites.data, ownProps.id, ownProps.type),
  category: ownProps.type === 'Tool' ? ownProps.workflowCategory.name : ownProps.category,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleMakeFavorite: () => dispatch(makeFavorite(ownProps.type, ownProps.id)),
  handleRemoveFavorite: id => dispatch(removeFavorite(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HorizontalTile)
