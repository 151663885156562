import React, { Fragment } from 'react'
import Trans from 'components/Trans'
import FoldersContainer from './FoldersContainer'

const FoldersSection = () => (
  <Fragment>
    <div className="header">
      <h3> <Trans id="app.magazine.folders.title" /> </h3>
    </div>
    <FoldersContainer />
  </Fragment>
)

export default FoldersSection
