import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Container } from '@material-ui/core'
import Trans from 'components/Trans'
import { t } from 'utils/i18n/translate'
import Table from 'components/GroupDetails/GroupDetailsTable'
import { createRows } from 'utils/tables'
import GroupsHeader from './GroupsHeader'


const Groups = ({ groups, createGroup, userEmail }) => {
  const groupsLength = groups.length

  const groupsEmpty = () => (
    <p><Trans id="app.groups.no_groups" /></p>
  )


  const groupsList = () => (
    <Fragment>
      <Table
        className="group_list"
        showPagination
        tableTitle={t('app.groups.all_groups')}
        columns={[
            { field: 'avatar', headerName: ' ' },
            { field: 'name', headerName: t('app.groups.attributes.name') },
          ]}
        rows={createRows(groups)}
      />
    </Fragment>
  )
  return (
    <Fragment>
      <GroupsHeader createGroup={createGroup} userEmail={userEmail} groups={groups} />
      <Container>
        { groupsLength > 0 ? groupsList() : groupsEmpty() }
      </Container>
    </Fragment>
  )
}

Groups.propTypes = {
  groups: PropTypes.array.isRequired,
  createGroup: PropTypes.func.isRequired,
  userEmail: PropTypes.string.isRequired
}

export default Groups
