import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { isMobile, isMobileOnly } from 'react-device-detect'
import {
  Slide
} from 'pure-react-carousel'
import { Swipeable } from 'react-swipeable'
import Trans from 'components/Trans'
import Image from 'components/Image'
import VerticalTile from 'components/VerticalTile'

class Article extends PureComponent {
  state = {
    isExpanded: false
  }

  componentWillReceiveProps(nextProps) {
    const { swiped } = nextProps

    this.setState({
      isExpanded: !swiped
    })
  }

  toggleExpandText = () => {
    const { goToTop } = this.props

    this.setState((prevState) => {
      goToTop()
      return {
        isExpanded: !prevState.isExpanded
      }
    })
  }

  render() {
    const { article, index, onSwipe } = this.props
    const { isExpanded } = this.state
    const hasImage = article.imageUrl || article.tile
    const defaultLimit = hasImage ? 900 : 1200
    const tabletLimit = hasImage ? 400 : 570
    const mobileLimit = 350
    let limit = defaultLimit
    if (isMobile) {
      limit = isMobileOnly ? mobileLimit : tabletLimit
    }
    const hasBigText = article.content.length > limit
    const showImage = article.showAsMainImage === 'uploaded_image'

    let content = isExpanded ? article.content : article.content.substring(0, limit)
    if (hasBigText && !isExpanded) {
      content = `${content}...`
    }

    const showMoreText = isExpanded ? <Trans id="app.magazine.slider.show_less" />
      : <Trans id="app.magazine.slider.show_more" />
    const showMoreButton = (
      <button className="show-more" type="button" onClick={this.toggleExpandText}>
        {showMoreText}
      </button>
    )

    const image = showImage
      ? <Image url={article.imageUrl} width={289} height={273} className="article-image" />
      : (
        <VerticalTile
          {...article.tile}
          type={article.tileType}
        />
      )

    return (
      <Swipeable onSwipedLeft={onSwipe} onSwipedRight={onSwipe} trackMouse delta={60}>
        <Slide index={index} className={isExpanded ? 'expanded' : ''} id={article.id} tabIndex="-1">
          <h2> { article.title } </h2>
          <div className="slider-content">
            <div className="rotation-wrapper">
              <span className="article-category"> { article.category } </span>
            </div>
            <div className="article-content">
              { hasImage && <div className="article-image-container"> {image} </div> }
              {/* eslint-disable-next-line react/no-danger */}
              <span className="article-text" dangerouslySetInnerHTML={{ __html: content }} />
              { hasBigText && showMoreButton }
            </div>
          </div>
        </Slide>
      </Swipeable>
    )
  }
}

Article.propTypes = {
  article: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  swiped: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.bool
  ]).isRequired,
  goToTop: PropTypes.func.isRequired,
  onSwipe: PropTypes.func.isRequired
}

export default Article
