import { connect } from 'react-redux'
import React, { useState } from 'react'
import { Modal, TextField } from '@material-ui/core'
import { errorToast, successToast } from 'components/Toast'
import { t } from 'utils/i18n/translate'
import PropTypes from 'prop-types'
import ConfirmModal from 'components/Modal/ConfirmModal'
import { createStep } from 'redux/actions/stepDetails'

export const NewStep = ({
    workflowId,
    isOpen,
    handleCloseModal,
    createStep: create,
    createHandler
  }) => {
  const initialState = () => (
    {
      step: {
        name: '',
        dataSchema: { type: 'string' }
      },
      tries: 0,
      hasValue: false
    }
  )

  const [state, setState] = useState(initialState())

  const createNewStep = () => {
    create(workflowId, state.step).then(() => {
      successToast(t('app.steps.create.success'))
      handleCloseModal()
      createHandler()
    }).catch((error) => {
      if (error.response && error.response.data.message) {
        errorToast(error.response.data.message)
      } else if (state.tries < 3) {
          errorToast(t('app.steps.create.error'))
          setState({ ...state, tries: state.tries + 1 })
        } else {
          errorToast(t('app.steps.create.error_final'))
        }
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setState({
      ...state,
      hasValue: value.length > 0,
      step: { ...state.step, [name]: value }
    })
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ConfirmModal
        handleSaveClick={createNewStep}
        handleDiscardChanges={handleCloseModal}
        message=""
        title="app.steps.create.title"
        cancelButton="app.steps.create.cancel"
        confirmButton="app.steps.create.confirm"
        confirmEnabled={state.hasValue}
      >
        <TextField
          required
          name="name"
          onChange={handleChange}
          label={t('activerecord.attributes.step.name')}
          variant="outlined"
          value={state.step.name}
        />
      </ConfirmModal>
    </Modal>
  )
}

NewStep.defaultProps = {
  isOpen: false
}

NewStep.propTypes = {
  isOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func.isRequired,
  workflowId: PropTypes.string.isRequired,
  createStep: PropTypes.func.isRequired,
  createHandler: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
  createStep: (workflowId, data) => dispatch(createStep(workflowId, data))
})

export default connect(null, mapDispatchToProps)(NewStep)
