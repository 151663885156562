import React from 'react'
import PropTypes from 'prop-types'
import GridIcon from 'images/GridIcon'
import ListIcon from 'images/ListIcon'
import { connect } from 'react-redux'
import { setViewType } from 'redux/actions/system'
import classNames from 'classnames'
import { t } from 'utils/i18n/translate'

const ListGridToggleIcons = ({ isGridView, setDashboardViewType }) => {
  const gridIconClasses = classNames('grid-icon', { active: isGridView })
  const listIconClasses = classNames('list-icon', { active: !isGridView })

  return (
    <div className="change-view-icons d-flex">
      <button
        className={gridIconClasses}
        type="button"
        title={t('app.accessibility.switch_to_grid_view')}
        aria-label={t('app.accessibility.switch_to_grid_view')}
        onClick={() => setDashboardViewType('gridView')}
      >
        <GridIcon />
      </button>
      <button
        className={listIconClasses}
        type="button"
        title={t('app.accessibility.switch_to_list_view')}
        aria-label={t('app.accessibility.switch_to_list_view')}
        onClick={() => setDashboardViewType('listView')}
      >
        <ListIcon />
      </button>
    </div>
  )
}

ListGridToggleIcons.propTypes = {
  isGridView: PropTypes.bool.isRequired,
  setDashboardViewType: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  isGridView: state.system.isGridView,
})

const mapDispatchToProps = dispatch => ({
  setDashboardViewType: viewType => dispatch(setViewType(viewType))
})

export default connect(mapStateToProps, mapDispatchToProps)(ListGridToggleIcons)
