export const types = {
  LOAD_NOTIFICATION_TASKS_START: 'tasks/LOAD_NOTIFICATION_TASKS_START',
  LOAD_NOTIFICATION_TASKS_SUCCESS: 'tasks/LOAD_NOTIFICATION_TASKS_SUCCESS',
  LOAD_NOTIFICATION_TASKS_FAIL: 'tasks/LOAD_NOTIFICATION_TASKS_FAIL',
  MARK_NOTIFICATIONS_AS_READ_TASKS_START: 'tasks/MARK_NOTIFICATIONS_AS_READ_TASKS_START_START',
  MARK_NOTIFICATIONS_AS_READ_SUCCESS: 'tasks/MARK_NOTIFICATIONS_AS_READ_SUCCESS_SUCCESS',
  MARK_NOTIFICATIONS_AS_READ_FAIL: 'tasks/MARK_NOTIFICATIONS_AS_READ_FAIL_FAIL',
  ADD_NOTIFICATION_TASK: 'tasks/ADD_NOTIFICATION_TASK'
}

export const loadNotificationsTasks = (params = { scope: 'new_notifications' }) => ({
  types: [types.LOAD_NOTIFICATION_TASKS_START,
    types.LOAD_NOTIFICATION_TASKS_SUCCESS,
    types.LOAD_NOTIFICATION_TASKS_FAIL],
  promise: client => client.get('/tasks', { params })
})

export const markAllAsRead = () => ({
  types: [types.MARK_NOTIFICATIONS_AS_READ_TASKS_START,
    types.MARK_NOTIFICATIONS_AS_READ_SUCCESS,
    types.MARK_NOTIFICATIONS_AS_READ_FAIL],
  promise: client => client.post('/tasks/read_notifications')
})

export const addNotificationTask = task => ({
  type: types.ADD_NOTIFICATION_TASK,
  task
})
