import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { loadTaskList } from 'redux/actions/tasks'
import { loadTasksForWorkflow } from 'redux/actions/workflowDetails'
import { setTab } from 'redux/actions/system'
import PropTypes from 'prop-types'
import Spinner from 'components/Spinner'
import Tasks from '.'

class TasksContainer extends PureComponent {
  componentDidMount() {
    const { loadTasks, setCurrentTab, filterValue, tabsFilterValue } = this.props

    setCurrentTab('tasks')
    if (!filterValue.includes('notification')) {
      loadTasks({ scope: tabsFilterValue })
    }
  }

  render() {
    const { tasks, allTasks, isLoading, isLoaded, isExpanded, workflowId, filterValue } = this.props
    const isNotification = filterValue.includes('notifications')
    const currentTasks = workflowId ? tasks : allTasks

    if (isLoading || !isLoaded) {
      return <Spinner align="center" top={50} />
    }

    return (
      <Tasks tasks={currentTasks} isExpanded={isExpanded} isNotification={isNotification} />
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isLoading: ownProps.workflowId ? state.workflowDetails.isLoading : state.tasks.isLoading,
  isLoaded: ownProps.workflowId ? state.workflowDetails.isLoaded : state.tasks.isLoaded,
  allTasks: state.tasks.data,
  tasks: state.workflowDetails.tasks.data,
  filterValue: state.system.filters.tasks.value,
  tabsFilterValue: state.system.filters.dashboard.value || 'all'
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadTasks: params => dispatch(
    ownProps.workflowId ? loadTasksForWorkflow(ownProps.workflowId, params) : loadTaskList(params)
  ),
  setCurrentTab: tab => dispatch(setTab(tab))
})

TasksContainer.defaultProps = {
  isLoading: false,
  isLoaded: false,
  isExpanded: false,
  tasks: [],
  workflowId: null
}

TasksContainer.propTypes = {
  loadTasks: PropTypes.func.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool,
  isExpanded: PropTypes.bool,
  tasks: PropTypes.array,
  allTasks: PropTypes.array.isRequired,
  filterValue: PropTypes.string.isRequired,
  tabsFilterValue: PropTypes.string.isRequired,
  workflowId: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(TasksContainer)
