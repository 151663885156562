import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Trans from 'components/Trans'

const FilterLink = ({ filter, onSelectFilter, selected }) => {
  const selectFilter = () => {
    onSelectFilter(filter)
  }

  const classNames = () => `p-0 filter-value btn-link ${selected ? 'selected' : ''}`

  return (
    <button className={classNames()} type="button" onClick={selectFilter}>
      <Trans id={`app.filters.${filter}`} />
    </button>
  )
}

FilterLink.propTypes = {
  filter: PropTypes.string.isRequired,
  onSelectFilter: PropTypes.func.isRequired,
  selected: PropTypes.bool
}

FilterLink.defaultProps = {
  selected: false
}

const mapStateToProps = state => ({
  location: state.router.location.pathname
})

export default connect(mapStateToProps)(FilterLink)
