import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import TaskLink from 'components/TaskLink'
import FavoriteIconActionContainer from 'components/IconActions/FavoriteIconActionContainer'

const TaskActions = ({ task, isMobileOnly }) => (
  <Fragment>
    <div className="task-actions ml-auto">
      { isMobileOnly
        ? (
          <Fragment>
            <TaskLink id={task.id} metadata={task.metadata} withIcon isMobileOnly={isMobileOnly} />
            <FavoriteIconActionContainer type="Task" id={task.id} />
          </Fragment>
        ) : (
          <Fragment>
            <FavoriteIconActionContainer type="Task" id={task.id} />
            <TaskLink id={task.id} metadata={task.metadata} withIcon isMobileOnly={isMobileOnly} />
          </Fragment>
        )
      }
    </div>
  </Fragment>
)

TaskActions.defaultProps = {
  isMobileOnly: false,
}

TaskActions.propTypes = {
  task: PropTypes.object.isRequired,
  isMobileOnly: PropTypes.bool,
}

export default TaskActions
