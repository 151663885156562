import React, { PureComponent } from 'react'
import { PieChart, Pie, Cell } from 'recharts'

const statusList = [
  {
    name: 'On Time',
    value: 5
  }, {
    name: 'Expired',
    value: 2
  }
]

const COLORS = ['#246ee3', '#951c1c']

class PieChartDashboardSchedule extends PureComponent {
  renderCustomizedLabel = ({ x, y, index }) => (
    <text x={x} y={y} dy={-16} dx={16} textAnchor="middle">
      {`${statusList[index].name} (${statusList[index].value})`}
    </text>
  )

  render() {
    return (
      <PieChart width={320} height={200}>
        <Pie dataKey="value" data={statusList} startAngle={180} endAngle={0} cx={150} cy={150} outerRadius={80} fill="#246ee3" label={this.renderCustomizedLabel}>
          {
            // eslint-disable-next-line react/no-array-index-key
            statusList.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
          }
        </Pie>
      </PieChart>
    )
  }
}

export default PieChartDashboardSchedule
