import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'utils/i18n/translate'

const Image = ({ className, alt, url, height, width, color, withBlackOverlay }) => (
  <div
    className={`image ${className}`}
    alt={alt || t('app.accessibility.no_image_description')}
    style={{
      height,
      width,
      backgroundColor: color,
      backgroundImage: `url(${url})`,
      backgroundSize: 'cover'
    }}
  >
    { withBlackOverlay
      && <div className="black-overlay-20" />
    }
  </div>
)

Image.defaultProps = {
  alt: null,
  className: '',
  height: '100%',
  width: null,
  color: '#ededed',
  withBlackOverlay: false
}

Image.propTypes = {
  className: PropTypes.string,
  withBlackOverlay: PropTypes.bool,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  url: PropTypes.string.isRequired,
  color: PropTypes.string,
  alt: PropTypes.string
}


export default Image
