import axios from 'axios'
import { loadCookie } from 'utils/cookies_'
import { AUTH_TOKEN_KEY } from 'redux/actions/auth'

export const types = {
  FETCH_PROFILE: 'FETCH_PROFILE',
  PROFILE_UPDATED: 'PROFILE_UPDATED'
}

export const loadProfile = profile => ({
  type: types.FETCH_PROFILE,
  response: profile
})

export const updateProfile = profile => ({
  type: types.PROFILE_UPDATED,
  profile
})

const headers = {
  'Content-Type': 'application/vnd.api+json',
  Accept: 'application/vnd.api+json',
  Authorization: `Bearer ${loadCookie(AUTH_TOKEN_KEY)}`
}

export const fetchProfile = (profileId) => {
  const url = `${process.env.REACT_APP_SSO_HOST}/api/v2/profiles/${profileId}`

  return dispatch => axios
    .get(url, {
      headers
    })
    .then((response) => {
      dispatch(loadProfile(response.data))
    })
    .catch((error) => {
      throw error
    })
}

export const editProfile = (profileId, payload) => {
  const url = `${process.env.REACT_APP_SSO_HOST}/api/v2/profiles/${profileId}`

  return dispatch => axios
    .put(url, { data: { attributes: { ...payload } } }, { headers })
      .then((response) => { dispatch(updateProfile(response.data)) })
      .catch((error) => {
        throw error
      })
}
