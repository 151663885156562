export const createRowItem = row => (
  {
    id: row.id,
    types: row.types,
    name: row.name,
    avatar: [row.avatarName, row.avatarColor],
    group_admin: row.groupAdminEmail,
    actions: row.id }
)

export const createRows = collection => (
  collection.map(group => (
    createRowItem(group)
  ))
)
