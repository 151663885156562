import { types } from 'redux/actions/groups'
import * as loadActions from '../middleware/loadActions'

export default (state = loadActions.arrayInitialState, action = {}) => {
  switch (action.type) {
    case types.LOAD_START:
      return loadActions.onLoadStart(state)
    case types.LOAD_SUCCESS:
      return loadActions.onLoadSuccess(state, action)
    case types.LOAD_FAIL:
      return loadActions.onLoadFailure(state, action)
    case types.INSTALL_TEMPLATE_START:
      return loadActions.onLoadStart(state)
    case types.INSTALL_TEMPLATE_SUCCESS:
      return loadActions.onLoadSuccess(state, action)
    case types.INSTALL_TEMPLATE_FAIL:
      return loadActions.onLoadFailure(state, action)

    case types.ADD_GROUP_SUCCESS:
      if (state.data.some(el => el.id === action.group.id)) {
        return state
      }

      return {
        ...state,
        data: [
          {
            ...action.group.attributes,
            id: action.group.id,
            groupAdminEmail: action.response.included[0].attributes.email },
          ...state.data
      ]
      }
    case types.ADD_GROUP_FAIL:
      return loadActions.onLoadFailure(state, action)
    case types.UPDATE_GROUP_SUCCESS: {
      const groups = state.data.map((group) => {
        if (group.id === action.groupId) {
          return { ...group, ...action.attributes }
        }

        return { ...group }
      })

      return { ...state, data: groups }
    }
    default:
      return state
  }
}
