import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { isMobileOnly } from 'react-device-detect'
import {
  CarouselProvider,
  Slider,
  ButtonBack,
  ButtonNext,
  DotGroup,
  Slide
} from 'pure-react-carousel'
import Trans from 'components/Trans'
import { t } from 'utils/i18n/translate'
import VerticalTile from 'components/VerticalTile'

const Tools = ({ tools }) => {
  const slides = isMobileOnly ? 2 : 3
  return (
    <CarouselProvider
      naturalSlideWidth={30}
      naturalSlideHeight={30}
      totalSlides={tools.length}
      visibleSlides={slides}
      step={slides}
      dragStep={slides}
      className="tools"
    >
      <div className="header">
        <h3> <Trans id="app.magazine.tools.title" /> </h3>

        <div className="actions top-tools-actions">
          { !isMobileOnly && (
            <Fragment>
              <ButtonBack title={t('app.accessibility.part_1')} aria-label={t('app.accessibility.part_1')} />
              <ButtonNext title={t('app.accessibility.part_2')} aria-label={t('app.accessibility.part_2')} />
            </Fragment>
          )}
          { isMobileOnly && <DotGroup /> }
        </div>
      </div>
      <Slider>
        {tools.map((tool, i) => (
          <Slide index={i} id={tool.id} key={tool.id}>
            <VerticalTile
              {...tool}
              type="Tool"
            />
          </Slide>
        ))}
      </Slider>
    </CarouselProvider>
  )
}

Tools.defaultProps = {
  tools: []
}

Tools.propTypes = {
  tools: PropTypes.array
}

export default Tools
