import axios from 'axios'
import { Service } from 'axios-middleware'
import { snake } from 'change-case'
import { camel } from 'utils/changeCase'
import deepMapKeys from 'deep-map-keys'

const checkAndConvertData = (convertedData, method) => {
  const data = convertedData

  Object.keys(data).forEach((key) => {
    if (convertedData[key] == null) {
      data[method(key)] = null
      return
    }

    if (!convertedData[key].skipTransformKeys) {
      data[method(key)] = deepMapKeys(convertedData[key], method)
    } else {
      delete data[key].skipTransformKeys
    }
  })

  return data
}

export const convertData = (data, method, check = false) => {
  let convertedData
  if (typeof data === 'string') {
    convertedData = JSON.parse(data)
  } else {
    convertedData = data
  }

  convertedData = check ? checkAndConvertData(convertedData, method)
    : deepMapKeys(convertedData, method)

  return convertedData
}

// Register a new service middleware to work with Rails backends, where typically the JSON keys are
// sent as snake_case, that we want to convert to camelCase, since it's the default case in JS.
export const registerService = () => {
  const service = new Service(axios)

  service.register({
    onRequest(request) {
      if (request.data) {
        return { ...request, data: JSON.stringify(convertData(request.data, snake, true)) }
      }

      return request
    },
    onSync(promise) {
      return promise
    },
    onResponse(response) {
      if (response.data) {
        return { ...response, data: convertData(response.data, camel, true) }
      }

      return response
    }
  })
}

export default registerService
