import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadWorfklowList } from 'redux/actions/workflows'
import { dashboardLoaded, setTab } from 'redux/actions/system'
import PropTypes from 'prop-types'
import Spinner from 'components/Spinner'
import Workflows from '.'

class WorkflowsContainer extends Component {
  componentDidMount() {
    const { loadWorkflows, setHeight, filterValue, setCurrentTab } = this.props

    setCurrentTab('dashboard')
    loadWorkflows({ scope: filterValue }).then(setHeight())
  }

  componentDidUpdate() {
    const { setHeight } = this.props

    setHeight()
  }

  render() {
    const {
      workflows,
      isLoading,
      isLoaded,
      openWorkflowId,
      layoutCols,
      isGridView
    } = this.props

    if (isLoading || !isLoaded) {
      return <Spinner top={50} />
    }

    return (
      <Workflows
        workflows={workflows}
        openWorkflowId={openWorkflowId}
        isGridView={isGridView}
        layoutCols={layoutCols}
      />
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.workflows.isLoading,
  isLoaded: state.workflows.isLoaded,
  workflows: state.workflows.data,
  openWorkflowId: state.openWorkflow.id,
  filterValue: state.system.filters.dashboard.value,
  isGridView: state.system.isGridView,
  layoutCols: state.system.layoutCols
})

const mapDispatchToProps = dispatch => ({
  loadWorkflows: params => dispatch(loadWorfklowList(params)),
  setHeight: () => dispatch(dashboardLoaded()),
  setCurrentTab: tab => dispatch(setTab(tab))
})

WorkflowsContainer.defaultProps = {
  isLoading: true,
  isLoaded: false,
  openWorkflowId: ''
}

WorkflowsContainer.propTypes = {
  loadWorkflows: PropTypes.func.isRequired,
  setHeight: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isGridView: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool,
  workflows: PropTypes.array.isRequired,
  openWorkflowId: PropTypes.string,
  filterValue: PropTypes.string.isRequired,
  layoutCols: PropTypes.number.isRequired,
  setCurrentTab: PropTypes.func.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkflowsContainer)
