import { types } from 'redux/actions/openWorkflow'
import * as loadActions from '../middleware/loadActions'

const initialState = {
  id: null,
  tasks: [],
  steps: []
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.TOGGLE_INFO_BOX: {
      const openId = state.id !== action.workflowId ? action.workflowId : null

      return {
        ...state,
        id: openId,
        tasks: []
      }
    }
    case types.LOAD_TASKS_START:
      return loadActions.onLoadStart(state)
    case types.LOAD_TASKS_SUCCESS: {
      const { workflowId, tasks } = action

      if (workflowId === state.id) {
        return {
          ...loadActions.onLoadComplete(state),
          tasks
        }
      }

      return state
    }
    case types.LOAD_TASKS_FAIL:
      return loadActions.onLoadFailure(state)
    case types.LOAD_STEPS_START:
      return loadActions.onLoadStart(state)
    case types.LOAD_STEPS_SUCCESS: {
      const { workflowId, steps } = action

      if (workflowId === state.id) {
        return {
          ...loadActions.onLoadComplete(state),
          steps
        }
      }

      return state
    }
    case types.LOAD_STEPS_FAIL:
      return loadActions.onLoadFailure(state)
    case types.ADD_TASK_TO_OPEN_WORKFLOW: {
      if (state.id === action.task.workflow.id) {
        return { ...state, tasks: [action.task, ...state.tasks] }
      }

      return { ...state }
    }
    default:
      return state
  }
}
