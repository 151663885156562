import React from 'react'
import Trans from 'components/Trans'

const WorkflowsHeader = () => (
  <div className="header">
    <span> <Trans id="activerecord.models.workflow.other" /> </span>
    <span> <Trans id="activerecord.models.task.other" /> </span>
    <span> <Trans id="app.list_view.titles.new_process" /> </span>
    <span> <Trans id="app.list_view.titles.details" /> </span>
    <span> <Trans id="app.list_view.titles.favorite" /> </span>
  </div>
)

export default WorkflowsHeader
