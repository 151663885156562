import React, { PureComponent, Fragment } from 'react'
import Trans from 'components/Trans'
import { t } from 'utils/i18n/translate'
import ConfirmModal from 'components/Modal/ConfirmModal'
import { Modal } from '@material-ui/core'
import PropTypes from 'prop-types'
import BpmnModeler from 'bpmn-js/lib/Modeler'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css'
import 'bpmn-font/dist/css/bpmn-embedded.css'
import 'bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css'
import DashboardNavTabs from 'components/DashboardNavTabs'
import { TabContent, TabPane, Button } from 'reactstrap'
import propertiesPanelModule from 'bpmn-js-properties-panel'

import propertiesProviderModule from 'bpmn-js-properties-panel/lib/provider/camunda'
import camundaModdleDescriptor from 'camunda-bpmn-moddle/resources/camunda'
import ZoomScrollModule from 'diagram-js/lib/navigation/zoomscroll'
import MoveCanvasModule from 'diagram-js/lib/navigation/movecanvas'
import minimapModule from 'diagram-js-minimap'

import SaveHeader from 'components/SaveHeader'
import BpmnCustomContextPad from 'components/BpmnCustomContextPad'
import emptyBpmn from './empty.bpmn'
import StepListContainer from './StepList'

import 'diagram-js-minimap/assets/diagram-js-minimap.css'


class BpmnEditor extends PureComponent {
  modeler = null

  state = {
    buttonDisabled: true,
    selectedTab: 'bpmnPropertiesTab'
  }

  componentDidMount = () => {
    const { diagramXML, saveHandler, loadStepsForWorkflow, workflowId } = this.props

    loadStepsForWorkflow(workflowId)
    this.saveBtnRef = React.createRef()

    this.modeler = new BpmnModeler({
      container: '#bpmnview',
      keyboard: {
        bindTo: window
      },
      propertiesPanel: {
        parent: '#attributes-panel'
      },
      additionalModules: [
        propertiesPanelModule,
        propertiesProviderModule,
        ZoomScrollModule,
        MoveCanvasModule,
        minimapModule,
        BpmnCustomContextPad
      ],
      moddleExtensions: {
        camunda: camundaModdleDescriptor
      },
      bpmnRenderer: {
        defaultFillColor: '#e4e4e4',
        defaultStrokeColor: '#8893a4'
      }
    })

    this.modeler.on('element.changed', () => {
      saveHandler(false)
      this.setState({
        buttonDisabled: false
      })
    })
    this.openBpmnDiagram(diagramXML || emptyBpmn)
  }

  openBpmnDiagram = (xml) => {
    (async () => {
      await this.modeler.importXML(xml)
    })()
  }

  onClick = (publish = false) => {
    const { isModalOpened, handleSaveInModal, workflowId, loadStepsForWorkflow } = this.props

    this.setState({
      buttonDisabled: true
    })

    this.modeler.saveXML({ format: true }, (err, xml) => {
      const promise = this.props.handleUpdate(xml, publish)
      promise.then(() => {
        loadStepsForWorkflow(workflowId)

        if (isModalOpened) {
          handleSaveInModal()
        }
      }).catch(() => {
        this.disableButton()
      })

      return promise
    })
  }

  handleChange = (index) => {
    this.setState({
      selectedTab: index
    })
  }

  disableButton = () => {
    this.setState({
      buttonDisabled: false
    })
  }

  render() {
    const { buttonDisabled } = this.state
    const {
      isModalOpened,
      handleCloseModal,
      handleDiscardChanges,
      workflowId
    } = this.props

    const { selectedTab } = this.state

    const propertiesTab = t('app.workflows.workflow_chart.tabs.properties')
    const stepsTab = t('app.workflows.workflow_chart.tabs.steps')

    return (
      <Fragment>
        <Modal
          open={isModalOpened}
          onClose={handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ConfirmModal
            handleSaveClick={() => this.onClick(false)}
            workflowId={workflowId}
            handleDiscardChanges={handleDiscardChanges}
          />
        </Modal>
        <SaveHeader disabled={buttonDisabled} onClick={() => this.onClick(false)}>
          <Button color="primary" className="publish-chart" onClick={() => this.onClick(true)}>
            <Trans id="app.workflows.workflow_chart.buttons.publish" />
          </Button>
        </SaveHeader>
        <div id="bpmn-container">
          <div id="propview">
            <DashboardNavTabs handleChange={this.handleChange} activeTab={selectedTab}>
              <DashboardNavTabs.Tab name="bpmnPropertiesTab" href="#" title={propertiesTab} />
              <DashboardNavTabs.Tab name="bpmnStepsTab" href="#" title={stepsTab} />
            </DashboardNavTabs>
            <TabContent className="properties-content" activeTab={selectedTab}>
              <TabPane tabId="bpmnPropertiesTab">
                <div id="attributes-panel" />
              </TabPane>
            </TabContent>
            <TabContent className="steps-content" activeTab={selectedTab}>
              <TabPane tabId="bpmnStepsTab">
                <StepListContainer workflowId={workflowId} />
              </TabPane>
            </TabContent>
          </div>
          <div id="bpmnview" />
        </div>
      </Fragment>
    )
  }
}

BpmnEditor.defaultProps = {
  diagramXML: '',
}

BpmnEditor.propTypes = {
  diagramXML: PropTypes.string,
  handleUpdate: PropTypes.func.isRequired,
  workflowId: PropTypes.string.isRequired,
  isModalOpened: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  saveHandler: PropTypes.func.isRequired,
  handleDiscardChanges: PropTypes.func.isRequired,
  handleSaveInModal: PropTypes.func.isRequired,
  loadStepsForWorkflow: PropTypes.func.isRequired
}

export default BpmnEditor
