import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { loadTaskList } from 'redux/actions/tasks'
import { loadFavoriteList } from 'redux/actions/favorites'
import { dashboardSwitchLayout, toggleExpand, setFilter, toggleDashboardView, setTitle } from 'redux/actions/system'
import PropTypes from 'prop-types'
import { t } from 'utils/i18n/translate'
import { Container } from 'reactstrap'
import DefaultLayout from 'layouts/DefaultLayout'
import ListViewPage from 'pages/ListViewPage'
import classnames from 'classnames'
import Spinner from 'components/Spinner'
import LayoutSwitch from 'components/LayoutSwitch'

export class HomePage extends PureComponent {
  componentDidMount() {
    const {
      loadFavorites,
      toggleDashboardView: toggleDashboardViewRender,
      setPageTitle } = this.props

    toggleDashboardViewRender('dashboard')
    loadFavorites()
    setPageTitle(t('app.pages.titles.dashboard'))
  }

  componentWillUnmount() {
    const { toggleDashboardView: toggleDashboardViewRender } = this.props
    toggleDashboardViewRender('')
  }

  render() {
    const { isLoaded, layout } = this.props
    const mainCssClasses = classnames('main', 'listView')

    return (
      <Fragment>
        <DefaultLayout>
          <LayoutSwitch currentLayout={layout} />
          <Container className={mainCssClasses}>
            {isLoaded && (<ListViewPage currentLayout={layout} />)}
            {!isLoaded && <Spinner align="center" top={50} />}
          </Container>
        </DefaultLayout>
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  toggleExpandButton: () => dispatch(toggleExpand()),
  loadFavorites: () => dispatch(loadFavoriteList()),
  dashboardSwitchLayout: direction => dispatch(dashboardSwitchLayout(direction)),
  setTasksFilter: value => dispatch(setFilter('tasks', value)),
  loadTasks: params => (dispatch(loadTaskList(params))),
  toggleDashboardView: (area) => {
    dispatch(toggleDashboardView(area))
  },
  setPageTitle: title => (dispatch(setTitle(title))),
})

const mapStateToProps = state => ({
  layout: state.system.currentLayout
})

HomePage.propTypes = {
  loadFavorites: PropTypes.func.isRequired,
  toggleDashboardView: PropTypes.func.isRequired,
  isLoaded: PropTypes.bool,
  setPageTitle: PropTypes.func.isRequired,
  layout: PropTypes.string
}

HomePage.defaultProps = {
  isLoaded: true,
  layout: process.env.REACT_APP_LAYOUT || 'dashboard'
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage)
