import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { useDataProvider, useNotify, Button } from 'react-admin'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Form from 'react-jsonschema-form-bs4'
import { ActionCableConsumer } from 'react-actioncable-provider'
import { getCurrentUser } from 'admin/api/dashboardDataProvider'
import { t } from 'utils/i18n/translate'

export const ResourceActionButton = (props) => {
  const { record, resource, workflowId, label } = props
  const user = getCurrentUser
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const [open, setOpen] = useState(false)
  const [dataSchema, setDataSchema] = useState({ dataSchema: {}, schema: {} })
  const [taskId, setTaskId] = useState(null)
  const [previousData, setPreviousData] = useState({})
  const [workflowInstanceId, setWorkflowInstanceId] = useState(null)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleActionResponse = (data) => {
    const parsedData = JSON.parse(data)
    if (parsedData.starting_errors) {
      throw new Error(parsedData.starting_errors)
    }
    return parsedData.id
  }

  const handleReceivedTask = (response) => {
    const parsedResponse = JSON.parse(response)
    if (parsedResponse.request_error) {
      const errorMessage = t('app.resources.request_error', { message: parsedResponse.request_error })
      notify(errorMessage, 'warning')
    }

    if (parsedResponse.task_type === 'form'
    && parsedResponse.workflow_instance_id === workflowInstanceId) {
      setPreviousData(parsedResponse.workflow_instance_data.resource_data)
      setDataSchema(parsedResponse.data_schema)
      setTaskId(parsedResponse.id)
      handleClickOpen()
    }
  }

  const callAction = () => {
    const actionData = {
      resource_data: { [record.resource_name]: record.resource_data },
      id: record.id
    }
    dataProvider.callAction({ id: workflowId, data: actionData })
    .then((response) => {
      setWorkflowInstanceId(handleActionResponse(response.data))
    })
    .catch((error) => {
      const errorMessage = t('app.resources.request_error', { message: error.message })
      notify(errorMessage, 'warning')
    })
    setOpen(false)
  }

  const submitAction = (data) => {
    setWorkflowInstanceId(null)
    const { formData } = data
    dataProvider.submitAction({ id: taskId, data: formData })
    handleClose()
    notify(t('app.resources.update_success'), 'info')
    window.location.reload()
  }

  return (
    <Fragment>
      <ActionCableConsumer
        channel={{ channel: 'UserTasksChannel', email: user.email }}
        onReceived={handleReceivedTask}
      />
      <Button label={label} onClick={callAction} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        fullScreen={false}
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-description">
          {resource}
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            {label}
          </DialogContentText>
          <Form
            className="task-form"
            handleClose={handleClose}
            schema={dataSchema.schema}
            uiSchema={dataSchema.uiSchema}
            formData={previousData}
            onSubmit={submitAction}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

ResourceActionButton.propTypes = {
  workflowId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired
}


export default ResourceActionButton
