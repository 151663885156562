import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import DefaultLayout from 'layouts/DefaultLayout'
import { Container } from 'reactstrap'
import ConnectedMagazine from '../../components/Magazine'

export class MagazinePage extends PureComponent {
  render() {
    const { workflowCategories } = this.props

    // TODO: work around to fetch only the first item
    const workflowCategoriesSliced = workflowCategories.slice(0, 1)

    return (
      <DefaultLayout>
        <Container className="magazine_page">
          {
            workflowCategoriesSliced.map(
              category => (<ConnectedMagazine workflowCategory={category} key={category.id} />)
            )
          }
        </Container>
      </DefaultLayout>
    )
  }
}

MagazinePage.propTypes = {
  workflowCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired
}

export default MagazinePage
