const handleMouseDownOnce = () => {
  document.body.classList.remove('user-is-tabbing')

  window.removeEventListener('mousedown', handleMouseDownOnce)
  window.addEventListener('keydown', handleFirstKeyboardPress)
}

const handleFirstKeyboardPress = (e) => {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing')

    window.removeEventListener('keydown', handleFirstKeyboardPress)
    window.addEventListener('mousedown', handleMouseDownOnce)
  }
}
const overrideFocusBehavior = () => {
  window.addEventListener('keydown', handleFirstKeyboardPress)
}

export default overrideFocusBehavior
