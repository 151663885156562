export const types = {
  LOAD_PAGES_START: 'page/LOAD_PAGES_START',
  LOAD_PAGES_SUCCESS: 'page/LOAD_PAGES_SUCCESS',
  LOAD_PAGES_FAIL: 'page/LOAD_PAGES_FAIL'
}

export const loadPages = () => ({
  types: [types.LOAD_PAGES_START, types.LOAD_PAGES_SUCCESS, types.LOAD_PAGES_FAIL],
  promise: client => client.get('/pages')
})
