import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import FilterNavTabs from 'components/FilterNavTabs'
import { t } from 'utils/i18n/translate'

export const TABS = {
  workflows: 'Workflow',
  tools: 'Tool',
  folders: 'Folder',
  all: 'All',
}

class FoldersFilters extends PureComponent {
  state = {
    workflowsCount: 0,
    foldersCount: 0,
    toolsCount: 0
  }

  componentDidMount() {
    this.updateState()
  }

  componentDidUpdate() {
    this.updateState()
  }

  updateState = () => {
    const { folders } = this.props

    this.setState({
      workflowsCount: this.filterFolders(TABS.workflows, folders).length,
      toolsCount: this.filterFolders(TABS.tools, folders).length,
      foldersCount: this.filterFolders(TABS.folders, folders).length
    })
  }

  filterFolders = (filter, folders) => (
    filter === 'Folder' ? folders.filter(f => f.title) : folders.filter(f => f.sourceType === filter)
  )

  handleChange = (activeTab) => {
    const { setFilteredFolders } = this.props

    setFilteredFolders(activeTab)
  }

  render() {
    const { activeFilter } = this.props
    const { workflowsCount, foldersCount, toolsCount } = this.state
    const allCount = toolsCount + foldersCount + workflowsCount

    return (
      <Fragment>
        <FilterNavTabs handleChange={this.handleChange} activeTab={activeFilter}>
          <FilterNavTabs.Tab name={TABS.workflows} href="#workflows" title={t('activerecord.models.workflow.other')} count={workflowsCount} />
          <FilterNavTabs.Tab name={TABS.tools} href="#tools" title={t('activerecord.models.tool.other')} count={toolsCount} />
          <FilterNavTabs.Tab name={TABS.folders} href="#folders" title={t('activerecord.models.folder.other')} count={foldersCount} />
          <FilterNavTabs.Tab name={TABS.all} href="#all" title={t('app.globals.all')} count={allCount} />
        </FilterNavTabs>
      </Fragment>
    )
  }
}

FoldersFilters.defaultProps = {
  activeFilter: TABS.all
}

FoldersFilters.propTypes = {
  folders: PropTypes.array.isRequired,
  setFilteredFolders: PropTypes.func.isRequired,
  activeFilter: PropTypes.string
}

export default FoldersFilters
