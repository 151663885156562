import ModelErrors from 'utils/ModelErrors'
import { types } from 'redux/actions/auth'
import * as loadActions from '../middleware/loadActions'

const initialState = {
  ...loadActions.objectInitialState,
  isLoggedIn: false,
  isSessionChecked: false,
  redirectPath: '/'
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.LOGIN_FAIL:
      return {
        ...loadActions.onLoadFailure(state, action),
        errors: new ModelErrors(action.error.response.data.errors),
        isLoggedIn: false,
        isSessionChecked: true
      }
    case types.LOGIN_SUCCESS:
      return {
        ...loadActions.onLoadSuccess(state, action),
        isLoggedIn: true,
        isSessionChecked: true
      }
    case types.SET_REDIRECT_PATH:
      return {
        ...state,
        redirectPath: action.redirectPath,
      }
    case types.LOGOUT_SUCCESS:
      if (action.includeSSo) {
        window.location.href = `${process.env.REACT_APP_SSO_HOST}/logout?callback_url=${process.env.REACT_APP_FRONTEND_URL}&token=${action.response.data.token}`
        return { ...state }
      }
      return { ...initialState, isSessionChecked: true }
    default:
      return state
  }
}
