import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { addTask } from 'redux/actions/tasks'
import PropTypes from 'prop-types'
import Trans from 'components/Trans'
import ReactRouterPropTypes from 'react-router-prop-types'
import { loadWorkflowDetails, editWorkflowDetail } from 'redux/actions/workflowDetails'
import { loadFavoriteList } from 'redux/actions/favorites'
import { Container } from 'reactstrap'
import DefaultLayout from 'layouts/DefaultLayout'
import { setTitle } from 'redux/actions/system'
import { t } from 'utils/i18n/translate'
import WorkflowDetailsContainer from 'components/WorkflowDetails/container'
import Spinner from 'components/Spinner'
import { Redirect, Link } from 'react-router-dom'
import ConfigIcon from 'images/ConfigIcon'

export class WorkflowDetailsPage extends PureComponent {
  state = {
    redirect: false,
    editable: false
  }

  componentDidMount() {
    const { loadWorkflow, match, loadFavorites, setPageTitle, editWorkflow } = this.props
    const { id } = match.params

    loadFavorites()
    loadWorkflow(id).then((result) => {
      setPageTitle(`${result.data.name} - ${t('app.pages.titles.default')}`)
    })

    editWorkflow(id).then(() => {
      this.setState({
        editable: true
      })
    })
  }

  render() {
    const { workflow, isLoaded, status } = this.props
    const { redirect, editable } = this.state

    if (redirect) {
      return (
        <Redirect to="/dashboard/workflows" />
      )
    }

    return (
      <Fragment>
        <DefaultLayout status={status}>
          <div className="workflow-detail-page">
            {!isLoaded && <Spinner align="center" top={50} />}
            {isLoaded && Object.entries(workflow).length > 0 && (
              <Fragment>
                { editable && (
                  <div className="config-tab">
                    <Container>
                      <Link to={`/workflows/${workflow.id}/edit`}>
                        <ConfigIcon />
                        <span className="title"> <Trans id="app.workflows.edit.title" /> </span>
                      </Link>
                    </Container>
                  </div>
                )}
                <WorkflowDetailsContainer {...workflow} />
              </Fragment>
            )}
          </div>
        </DefaultLayout>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isLoaded: state.workflowDetails.isLoaded,
  userGroupIds: state.auth.data.groupIds,
  workflow: state.workflowDetails.data,
  userEmail: state.auth.data.email,
  status: state.workflowDetails.status
})

const mapDispatchToProps = dispatch => ({
  loadWorkflow: id => dispatch(loadWorkflowDetails(id)),
  addTask: task => dispatch(addTask(task)),
  loadFavorites: () => dispatch(loadFavoriteList()),
  setPageTitle: title => (dispatch(setTitle(title))),
  editWorkflow: id => dispatch(editWorkflowDetail(id))
})

WorkflowDetailsPage.defaultProps = {
  isLoaded: true,
}

WorkflowDetailsPage.propTypes = {
  loadWorkflow: PropTypes.func.isRequired,
  loadFavorites: PropTypes.func.isRequired,
  editWorkflow: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  isLoaded: PropTypes.bool,
  workflow: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    bannerImageUrl: PropTypes.string,
    imageUrl: PropTypes.string,
    altBannerImage: PropTypes.string,
    category: PropTypes.string,
    externalId: PropTypes.string
  }).isRequired,
  addTask: PropTypes.func.isRequired,
  status: PropTypes.number.isRequired,
  setPageTitle: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDetailsPage)
