import React from 'react'

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" id="prefix__dashboard-icon-gridViewM" width="24" height="24" viewBox="0 0 24 24">
    <g id="Rectangle_6724" className="cls-2" stroke="fill" fill="none" data-name="Rectangle 6724">
      <rect width="8.5" height="8.5" x=".75" y=".75" className="cls-1" fill="none" rx=".25" />
    </g>
    <g id="Rectangle_6726" className="cls-2" stroke="fill" fill="none" data-name="Rectangle 6726" transform="translate(0 14)">
      <rect width="8.5" height="8.5" x=".75" y=".75" className="cls-1" fill="none" rx=".25" />
    </g>
    <g id="Rectangle_6732" className="cls-2" stroke="fill" fill="none" data-name="Rectangle 6732" transform="translate(14)">
      <rect width="8.5" height="8.5" x=".75" y=".75" className="cls-1" fill="none" rx=".25" />
    </g>
    <g id="Rectangle_6735" className="cls-2" stroke="fill" fill="none" data-name="Rectangle 6735" transform="translate(14 14)">
      <rect width="8.5" height="8.5" x=".75" y=".75" className="cls-1" fill="none" rx=".25" />
    </g>
  </svg>
)
