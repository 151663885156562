import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadIntegrationList, removeIntegration } from 'redux/actions/integrations'
import PropTypes from 'prop-types'
import Spinner from 'components/Spinner'
import Integrations from '.'

class IntegrationsContainer extends Component {
  componentDidMount() {
    const { loadIntegrations } = this.props

    loadIntegrations()
  }


  render() {
    const { integrations, isLoading, isLoaded, deleteIntegration } = this.props

    if (isLoading || !isLoaded) {
      return <Spinner top={50} />
    }

    return (
      <Integrations
        integrations={integrations}
        deleteIntegration={deleteIntegration}
      />
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.integrations.isLoading,
  isLoaded: state.integrations.isLoaded,
  integrations: state.integrations.data,
})

const mapDispatchToProps = dispatch => ({
  loadIntegrations: () => dispatch(loadIntegrationList()),
  deleteIntegration: integration => dispatch(removeIntegration(integration)),
})

IntegrationsContainer.defaultProps = {
  isLoading: true,
  isLoaded: false,
}

IntegrationsContainer.propTypes = {
  loadIntegrations: PropTypes.func.isRequired,
  deleteIntegration: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool,
  integrations: PropTypes.array.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntegrationsContainer)
