import { connect } from 'react-redux'
import { fetchProfile, editProfile } from 'redux/actions/profile'
import { setTitle } from 'redux/actions/system'
import UserProfilePage from '.'

const mapStateToProps = state => ({
  name: state.auth.data.name,
  email: state.auth.data.email,
  profileId: state.auth.data.profileId,
  createdAt: state.auth.data.createdAt,
  profile: state.profile.data
})

const mapDispatchToProps = dispatch => ({
  fetchProfile: profileId => dispatch(fetchProfile(profileId)),
  editProfile: (profileId, payload) => dispatch(editProfile(profileId, payload)),
  setPageTitle: title => (dispatch(setTitle(title)))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserProfilePage)
