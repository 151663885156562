import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import TaskContainer from 'components/Task/container'
import EmptyState from 'components/EmptyState'
import { t } from 'utils/i18n/translate'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import TasksHeaderContainer from './TasksHeader'

const Tasks = ({ tasks, isExpanded, isNotification }) => {
  const uniqueTasks = _.uniqBy(tasks, 'id')
  const type = isNotification ? t('activerecord.models.notification.other')
    : t('activerecord.models.task.other')
  const tasksClasses = classNames('tasks', { notifications: isNotification })

  if (tasks.filter(task => task.status !== 'closed').length === 0) {
    return (
      <EmptyState
        title={t('app.task.none', { type })}
        subtitle={t('app.task.none_disclaimer', { type })}
      />
    )
  }

  return (
    <nav className={tasksClasses}>
      {
        isExpanded && !isMobile && (
          <TasksHeaderContainer />
        )
      }
      <ul>
        {
          uniqueTasks.map(task => (
            task.status !== 'closed'
            && <TaskContainer {...task} isExpanded={isExpanded} key={`task_${task.id}`} />
          ))
        }
      </ul>
    </nav>
  )
}

Tasks.defaultProps = {
  isExpanded: false,
  isNotification: false
}

Tasks.propTypes = {
  tasks: PropTypes.array.isRequired,
  isExpanded: PropTypes.bool,
  isNotification: PropTypes.bool
}

export default Tasks
