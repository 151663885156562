import React from 'react'
import PropTypes from 'prop-types'
import ModalComponent from 'components/Modal'
import { errorToast, successToast } from 'components/Toast'
import { t } from 'utils/i18n/translate'
import Trans from 'components/Trans'


const IntegrationItem = ({
  logoUrl,
  title,
  category,
  enabled,
  support,
  activationUrl,
  isInstalled,
  deleteIntegration,
  integrationId,
   }) => {
  const action = isInstalled ? 'uninstall' : 'install'

  function redirectToActivation() {
    window.location.href = activationUrl
  }


  function removeIntegration() {
    deleteIntegration(integrationId).then(() => {
      successToast(t('app.integrations.notifications.titles.integration_uninstalled', { integration: title }))
    }).catch(() => {
      errorToast(t('app.integrations.notifications.titles.integration_uninstall_failure'))
    })
  }


  function performAction() {
   const actionToPerform = action === 'uninstall' ? removeIntegration() : redirectToActivation()
   return actionToPerform
  }

   function renderInstall() {
    return (
      <button className="integration-content-link" type="button" onClick={performAction}>
        <Trans id={`app.integrations.actions.${ enabled ? action : 'contact'}`} />
      </button>
    )
  }

  function renderUninstall() {
    return (
      <ModalComponent
        buttonLabel={t('app.integrations.actions.uninstall')}
        className="integration-content-link"
        modalTitle={t('app.integrations.notifications.titles.uninstall_modal', { integration: title })}
        modalBody={t('app.integrations.notifications.body.uninstall_modal')}
        actionLabel={t('app.integrations.actions.uninstall')}
        actionFunction={removeIntegration}
      />
    )
  }

  return (
    <div className="integration-item">
      <header className="integration-header row">
        <div className={support ? 'd-none' : 'integration-header_logo col-sm-4'}>
          <div className="integration-header_logo-container">
            <img src={logoUrl} alt={title} className={isInstalled ? 'installed' : ''} />
          </div>
        </div>
        <div className={support ? 'integration-header_title support col-sm-12' : 'integration-header_title col-sm-8'}>
          <h5 className="title-main">
            {title}
          </h5>
          <h6 className="title-sub">
            {category}
          </h6>
        </div>
      </header>
      <div className="integration-content">
        { action === 'uninstall' ? renderUninstall() : renderInstall() }

      </div>
    </div>
  )
}

IntegrationItem.defaultProps = {
  isInstalled: false,
  integrationId: null
}

IntegrationItem.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  isInstalled: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object]).isRequired,
  category: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object]).isRequired,
  enabled: PropTypes.bool.isRequired,
  support: PropTypes.bool.isRequired,
  activationUrl: PropTypes.string.isRequired,
  deleteIntegration: PropTypes.func.isRequired,
  integrationId: PropTypes.string
}

export default IntegrationItem
