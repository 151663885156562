import React, { PureComponent, Fragment } from 'react'
import { Col, Row } from 'reactstrap'
import Trans from 'components/Trans'
import PropTypes from 'prop-types'
import { snake } from 'change-case'


class EditableAttribute extends PureComponent {
  state = {
    isEditable: false,
  }

  handleClick = () => {
    const { isEditable } = this.state
    this.setState({ isEditable: !isEditable })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.updateAttributeHandler()

    this.setState({ isEditable: false })
  }

  handleChange = (e) => {
    const { isDynamicAttribute } = this.props

    this.props.attributeChangeHandler(e, isDynamicAttribute)
  }

  renderEditAttribute = () => {
    const { attributeName, attributeValue, inputType } = this.props

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="editable-row">
          <div>
            <input
              type={inputType}
              name={attributeName}
              value={attributeValue}
              onChange={this.handleChange}
            />
          </div>
          <div>
            <button type="submit" className="submit btn btn-primary">
              <Trans id="app.profile.save_button" />
            </button>
          </div>
        </div>
      </form>
    )
  }

  renderShowAttribute = () => {
    const { attributeValue, attributeName, emptyValueTextId } = this.props

    return (
      <Fragment>
        <Col>
          <span className="attribute-value">
            {attributeValue || <Trans id={emptyValueTextId} />}
          </span>
        </Col>
        <Col>
          <div>
            <button
              type="button"
              tabIndex={0}
              className={`${snake(attributeName)} edit-button`}
              onClick={this.handleClick}
            >
              <Trans id="app.profile.edit_button" />
            </button>
          </div>
        </Col>
      </Fragment>
    )
  }

  render() {
    const { isEditable } = this.state
    const { title } = this.props
    const renderAttribute = isEditable ? this.renderEditAttribute() : this.renderShowAttribute()

    return (
      <Fragment>
        <div className="profile-attributes-title">
          <h2>
            { title }
          </h2>
        </div>
        <div className="profile-attribute">
          <Row>
            <span className="title">
              { title }
            </span>
          </Row>
          <Row>{renderAttribute}</Row>
        </div>
      </Fragment>
    )
  }
}

EditableAttribute.defaultProps = {
  isDynamicAttribute: false,
  inputType: 'text'
}

EditableAttribute.propTypes = {
  attributeValue: PropTypes.string.isRequired,
  attributeName: PropTypes.string.isRequired,
  attributeChangeHandler: PropTypes.func.isRequired,
  updateAttributeHandler: PropTypes.func.isRequired,
  isDynamicAttribute: PropTypes.string,
  title: PropTypes.string.isRequired,
  emptyValueTextId: PropTypes.string.isRequired,
  inputType: PropTypes.string,
}

export default EditableAttribute
