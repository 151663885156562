import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Routes from 'Routes'
import SessionWrapper from 'SessionWrapper'
import { ConnectedRouter } from 'connected-react-router'
import { history } from 'redux/configureStore'
import LocaleContext from 'utils/i18n/locale-context'
import i18n from 'i18n-js'
import { register } from 'timeago.js'
import deLocale from 'timeago.js/lib/lang/de'
import HelmetTags from 'HelmetTags'

import 'focus-within-polyfill'

import overrideFocusBehavior from 'utils/scripts/focusScript'

class App extends PureComponent {
  constructor(props) {
    super(props)
    const params = new URLSearchParams(window.location.search)

    if (params.get('lang') !== null) {
      localStorage.setItem('locale', params.get('lang'))
    }
    const localStorageLocale = localStorage.getItem('locale')

    this.state = { locale: localStorageLocale || process.env.REACT_APP_LOCALE || 'de' }
  }

  componentDidMount() {
    const { locale } = this.state
    this.setupLocale(locale)
    overrideFocusBehavior()
  }

  changeLocale = (locale) => {
    this.setState({ locale })
    this.setupLocale(locale)
  }

  setupLocale = (locale) => {
    register('de', deLocale)
    i18n.locale = locale
  }

  render() {
    const { root } = this.props
    const { locale } = this.state

    return (
      <SessionWrapper>
        <HelmetTags locale={locale} />
        <ConnectedRouter history={history}>
          <LocaleContext.Provider value={locale}>
            <Routes root={root} />
          </LocaleContext.Provider>
        </ConnectedRouter>
      </SessionWrapper>
    )
  }
}

App.propTypes = {
  root: PropTypes.string.isRequired
}

export default App
