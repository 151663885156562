import axios from 'axios'
import { loadCookie } from 'utils/cookies_'
import { AUTH_TOKEN_KEY } from 'redux/actions/auth'

export const types = {
  FETCH_GROUP_SUCCESS: 'group_details/FETCH_GROUP_SUCCESS',
  FETCH_GROUP_FAIL: 'group_details/FETCH_GROUP_FAIL',
  ADD_SUBGROUP_SUCCESS: 'group_details/ADD_SUBGROUP_SUCCESS',
  ADD_SUBGROUP_FAIL: 'group_details/ADD_SUBGROUP_FAIL',
  DELETE_SUBGROUP_SUCCESS: 'group_details/DELETE_SUBGROUP_SUCCESS',
  DELETE_SUBGROUP_FAIL: 'group_details/DELETE_SUBGROUP_FAIL',
  UPDATE_DETAILS_SUCCESS: 'group_details/UPDATE_DETAILS_SUCCESS',
  UPDATE_DETAILS_FAIL: 'group_details/UPDATE_DETAILS_FAIL',
  DELETE_GROUPUSER_SUCCESS: 'group_details/DELETE_GROUPUSER_SUCCESS',
  REMOVE_GROUPUSER_ADMIN_FAIL: 'group_details/REMOVE_GROUPUSER_ADMIN_FAIL',
}
const headers = {
  'Content-Type': 'application/vnd.api+json',
  Accept: 'application/vnd.api+json',
  Authorization: `Bearer ${loadCookie(AUTH_TOKEN_KEY)}`
}

export const loadGroupSuccess = response => ({
  type: types.FETCH_GROUP_SUCCESS,
  response: response.data,
  included: response.included,
})

export const loadGroupError = error => ({
  type: types.FETCH_GROUP_FAIL,
  error
})

export const loadGroup = (groupId) => {
  const url = `${process.env.REACT_APP_SSO_HOST}/api/v2/groups/${groupId}`

  return dispatch => axios
    .get(url, {
      headers
    })
    .then((response) => {
      dispatch(loadGroupSuccess(response))
    })
    .catch((error) => {
      throw error
    })
}

export const addSubGroupSuccess = group => ({
  type: types.ADD_SUBGROUP_SUCCESS,
  response: group,
  group: group.data,
})

export const addSubGroupError = error => ({
  type: types.ADD_SUBGROUP_FAIL,
  error
})

export const addSubGroup = (params) => {
  const url = `${process.env.REACT_APP_SSO_HOST}/api/v2/groups`

  return dispatch => axios
    .post(url,
      { data: { attributes: { ...params } } },
      { headers })
    .then((response) => {
      dispatch(addSubGroupSuccess(response.data))
    })
    .catch((error) => {
      dispatch(addSubGroupError(error))
      throw error
    })
}

export const updateGroupSuccess = (groupId, response) => ({
  type: types.UPDATE_DETAILS_SUCCESS,
  groupId,
  response: response.data,
  included: response.included,
})

export const updateGroupError = error => ({
  type: types.UPDATE_DETAILS_FAIL,
  response: error
})

export const updateGroup = (payload) => {
  const groupId = payload.parentId
  const dupPyaload = payload
  delete dupPyaload.parentId
  const url = `${process.env.REACT_APP_SSO_HOST}/api/v2/groups/${groupId}`

  return dispatch => axios
    .put(url, { data: { attributes: { ...dupPyaload } } }, { headers })
      .then((response) => {
        dispatch(updateGroupSuccess(groupId, response))
      })
      .catch((error) => {
        dispatch(updateGroupError(error))
        throw error
      })
}

export const deleteSubGroupSuccess = (groupId, response) => ({
  type: types.DELETE_SUBGROUP_SUCCESS,
  groupId,
  response: response.data
})

export const deleteSubGroupError = error => ({
  type: types.DELETE_SUBGROUP_FAIL,
  response: error
})

export const deleteSubGroup = (groupId) => {
  const url = `${process.env.REACT_APP_SSO_HOST}/api/v2/groups/${groupId}`

  return dispatch => axios
    .delete(url, { headers })
      .then((response) => {
        dispatch(deleteSubGroupSuccess(groupId, response))
      })
      .catch((error) => {
        dispatch(deleteSubGroupError(error))
        throw error
      })
}


export const deleteGroupUserSuccess = (groupId, userId, response) => ({
  type: types.DELETE_GROUPUSER_SUCCESS,
  groupId,
  userId,
  response: response.data
})

export const deleteGroupUserError = error => ({
  type: types.DELETE_GROUPUSER_FAIL,
  response: error
})

export const deleteGroupUser = (groupId, userId) => {
  const url = `${process.env.REACT_APP_SSO_HOST}/api/v2/groups/${groupId}/memberships/${userId}`

  return dispatch => axios
    .delete(url, { headers })
      .then((response) => {
        dispatch(deleteGroupUserSuccess(groupId, userId, response))
      })
      .catch((error) => {
        dispatch(deleteGroupUserError(error))
        throw error
      })
}
