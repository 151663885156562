import React from 'react'
import PropTypes from 'prop-types'
import Trans from 'components/Trans'
import Spinner from 'components/Spinner'
import Tasks from '../Tasks/index'

const TasksMagazine = ({ tasks, isLoading, isLoaded }) => {
  if (isLoading || !isLoaded) {
    return <Spinner align="center" top={50} />
  }

  return (
    <div>
      <div className="header">
        <h3> <Trans id="app.magazine.tasks.title" /> </h3>
      </div>
      <div className="magazine_tasks_list">
        {
          tasks.length > 0
          && <Tasks tasks={tasks} isExpanded={false} />
        }
        {
          tasks.length === 0
          && <div className="text-muted"><Trans id="app.magazine.tasks.no_favorites" /></div>
        }
      </div>
    </div>
  )
}

TasksMagazine.defaultProps = {
  tasks: [],
  isLoading: false,
  isLoaded: false,
}

TasksMagazine.propTypes = {
  tasks: PropTypes.array,
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool,
}

export default TasksMagazine
