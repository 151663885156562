import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import Moment from 'react-moment'
import DefaultLayout from 'layouts/DefaultLayout'
import { Container, Row, Col, Nav, NavItem, TabContent, TabPane } from 'reactstrap'
import Trans from 'components/Trans'
import BackButton from 'components/BackButton'
import Integrations from 'components/Integrations/container'
import { t } from 'utils/i18n/translate'
import classNames from 'classnames'
import EditableAttribute from './EditableAttribute'


class UserProfilePage extends PureComponent {
  state = {
    currentTab: 'profile',
    data: {},
  }

  componentDidMount() {
    const { fetchProfile, setPageTitle, profileId } = this.props

    setPageTitle(t('app.pages.titles.account_settings'))
    fetchProfile(profileId)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
     data: nextProps.profile.attributes.data,
     companyEmails: nextProps.profile.attributes.companyEmails
    })
  }

  toggleTab = (tab) => {
    const { currentTab } = this.state

    if (currentTab !== tab) {
      this.setState({
        currentTab: tab
      })
    }
  }

  attributeChangeHandler = (e, isDynamicAttribute) => {
    e.preventDefault()
    const { data } = this.state

    const attributeName = e.target.name
    const attributeValue = e.target.value

    if (isDynamicAttribute) {
      data[attributeName] = attributeValue

      this.setState({ data: { ...data } })
    } else {
      this.setState({ [attributeName]: [attributeValue] })
    }
  }

  updateAttributeHandler = (payload) => {
    const { profileId, editProfile } = this.props

    editProfile(profileId, payload)
  }

  render() {
    const { name, email, createdAt, profileId } = this.props
    const { data, companyEmails } = this.state
    const { currentTab } = this.state
    const membershipsPage = `${process.env.REACT_APP_SSO_HOST}/memberships_requests`
    const callback = `${membershipsPage}?from_portal=true`
    const employeeId = data && data.employeeId

    return (
      <Fragment>
        <DefaultLayout>
          <div className="profile-container">
            <Container>
              <BackButton />
            </Container>
            <div className="profile-page-title">
              <Container>
                <Nav tabs>
                  <NavItem>
                    <button
                      className={classNames({ active: currentTab === 'profile' }, 'profile', 'nav-link')}
                      onClick={() => this.toggleTab('profile')}
                      type="button"
                    >
                      <Trans id="app.profile.profile" />
                    </button>
                  </NavItem>
                  <NavItem>
                    <button
                      className={classNames({ active: currentTab === 'integrations' }, 'integrations', 'nav-link')}
                      onClick={() => this.toggleTab('integrations')}
                      type="button"
                    >
                      <Trans id="app.integrations.title" />
                    </button>
                  </NavItem>
                  <NavItem>
                    <button
                      className={classNames({ active: currentTab === 'memberships' }, 'memberships', 'nav-link')}
                      onClick={() => this.toggleTab('memberships')}
                      type="button"
                    >
                      <Trans id="app.profile.memberships" />
                    </button>
                  </NavItem>
                </Nav>
              </Container>
              <span>{name}</span>
            </div>
            <TabContent activeTab={currentTab}>
              <TabPane tabId="profile">
                <Container>
                  <Row>
                    <div className="col-lg-3" />

                    <div className="profile-attributes-container col-lg-6">
                      <div className="profile-attributes-title">
                        <h2>
                          <Trans id="app.profile.attributes_title" />
                        </h2>
                      </div>
                      <div className="profile-attributes">
                        <Row>
                          <span className="title">
                            <Trans id="app.profile.full_name" />
                          </span>
                        </Row>
                        <Row>
                          <span className="value">{name}</span>
                        </Row>

                        <Row>
                          <span className="title">
                            <Trans id="app.profile.email" />
                          </span>
                        </Row>
                        <Row>
                          <span className="value">{email}</span>
                        </Row>

                        <Row>
                          <span className="title">
                            <Trans id="app.profile.created_at" />
                          </span>
                        </Row>
                        <Row>
                          <span className="value">
                            <Moment local format="DD.MM.YYYY">
                              {createdAt}
                            </Moment>
                          </span>
                        </Row>
                        <Row>
                          <Col className="profile-attribute-container">
                            <EditableAttribute
                              profileId={profileId}
                              attributeValue={employeeId}
                              emptyValueTextId="app.profile.submit_employee_id"
                              attributeName="employeeId"
                              updateAttributeHandler={() => this.updateAttributeHandler({ data })}
                              attributeChangeHandler={this.attributeChangeHandler}
                              title={t('app.profile.employee_id')}
                              isDynamicAttribute
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="profile-attribute-container">
                            <EditableAttribute
                              profileId={profileId}
                              attributeValue={companyEmails && companyEmails[0]}
                              emptyValueTextId="app.profile.submit_company_email"
                              attributeName="companyEmails"
                              attributeChangeHandler={this.attributeChangeHandler}
                              updateAttributeHandler={
                                () => this.updateAttributeHandler({ companyEmails })
                              }
                              title={t('app.profile.company_email')}
                              inputType="email"
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div className="policy-container col-lg-3" />
                  </Row>
                </Container>
              </TabPane>
              <TabPane tabId="memberships">
                <Container>
                  <Row>
                    <div className="col-lg-3" />
                    <div className="col-lg-6 memberships-container">
                      <h3>
                        <Trans id="app.profile.membership_requests_title" />
                      </h3>
                      <p>
                        <Trans id="app.profile.membership_requests_info" />
                      </p>
                      <a href={callback} className="membership-requests-page">
                        {membershipsPage}
                      </a>
                    </div>
                    <div className="col-lg-3" />
                  </Row>
                </Container>
              </TabPane>
              <TabPane tabId="integrations">
                <Container>
                  <Row>
                    <Integrations />
                  </Row>
                </Container>
              </TabPane>
            </TabContent>
          </div>
        </DefaultLayout>
      </Fragment>
    )
  }
}

UserProfilePage.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  profile: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  fetchProfile: PropTypes.func.isRequired,
  editProfile: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
}

export default UserProfilePage
