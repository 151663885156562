import React, { PureComponent } from 'react'
import DashboardPage from 'pages/HomePage'
import { t } from 'utils/i18n/translate'
import PropTypes from 'prop-types'

class NotificationsPage extends PureComponent {
  state = {
    isLoaded: false
  }

  componentDidMount() {
    const {
      setTasksFilter,
      loadTasks,
      toggleExpandButton,
      toggleDashboardView,
      markAsRead,
      setPageTitle
    } = this.props
    const filter = 'notifications'

    setPageTitle(t('app.pages.titles.notifications'))
    toggleDashboardView('')
    toggleExpandButton()
    setTasksFilter(filter)
    loadTasks({ scope: 'notifications' }).then(() => {
      this.setState({
        isLoaded: true
      })
      markAsRead()
    })
  }

  componentWillReceiveProps() {
    const { loadTasks, markAsRead } = this.props
    const filter = 'notifications'

    loadTasks({ scope: filter })
    markAsRead()
  }

  componentWillUnmount() {
    const {
      setTasksFilter,
      toggleDashboardView,
      loadTasks
    } = this.props
    setTasksFilter('all')
    toggleDashboardView('dashboard')
    loadTasks({ scope: 'all' })
  }

  render() {
    const { isLoaded } = this.state
    return (<DashboardPage isLoaded={isLoaded} />)
  }
}

NotificationsPage.propTypes = {
  toggleExpandButton: PropTypes.func.isRequired,
  setTasksFilter: PropTypes.func.isRequired,
  loadTasks: PropTypes.func.isRequired,
  toggleDashboardView: PropTypes.func.isRequired,
  markAsRead: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired
}

export default NotificationsPage
