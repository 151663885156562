import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { t } from 'utils/i18n/translate'
import { errorToast, successToast } from 'components/Toast'
import Spinner from 'components/Spinner'
import StepTab from './StepTab'
import DataTab from './DataTab'

const Steps = (props) => {
  const {
    currentStep,
    saveHandler,
    updateStep,
    tab,
    loadStepsForWorkflow,
    workflowId,
    isLoading,
    isLoaded
  } = props

  const initialState = () => (
    {
      stepsLoaded: [],
      currentStep,
      currentTab: 0,
      stepsHasChanges: false
    }
  )

  const [state, setState] = useState(initialState())

  useEffect(() => {
    loadStepsForWorkflow(workflowId).then(res => (
      setState({ ...state, currentStep: res.data[0] })
    ))
  }, [])

  const saveStep = (id, data) => {
    const promise = updateStep(id, data)
    promise.then(() => {
      successToast(t('app.steps.save.success'))
      saveHandler(true)
      setState({ ...state, stepsHasChanges: false })
    }).catch((error) => {
      const msg = (error.response && error.response.data.message) || t('app.steps.save.error')
      errorToast(msg)
    })
    return promise
  }

  return (
    <Fragment>
      { (isLoading || !isLoaded) && <Spinner align="center" top={50} /> }
      { isLoaded && !isLoading && (
        tab === 'dataTab' ? (
          <DataTab
            saveStep={saveStep}
            {...props}
          />
        ) : (
          <StepTab
            saveStep={saveStep}
            {...props}
          />
        ))
      }
    </Fragment>
  )
}

Steps.propTypes = {
  saveHandler: PropTypes.func.isRequired,
  updateStep: PropTypes.func.isRequired,
  currentStep: PropTypes.object.isRequired,
  tab: PropTypes.string.isRequired,
  loadStepsForWorkflow: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  workflowId: PropTypes.string.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default Steps
