import React from 'react'
import PropTypes from 'prop-types'
import { isMobile, isTablet } from 'react-device-detect'
import FilterLink from './FilterLink'
import ListGridToggleIcons from '../../pages/DashboardPage/ListGridToggleIcons'

const Filters = ({
  value,
  setFilter,
  area,
  loadWorkflows,
  loadTools,
  loadTasks,
  loadWorkflowTasks,
  setHeight,
  isOpened,
  workflowId,
  currentTab
}) => {
  const isSelected = filterValue => (value === filterValue)
  const showListGridIcons = isTablet && currentTab !== 'tasks'
  const options = value !== 'notifications' ? ['all', 'favorites'] : []

  const selectFilter = (filterValue) => {
    if (value === filterValue) {
      return
    }

    setFilter(area, filterValue)
    if (area === 'tasks') {
      if (workflowId) {
        loadWorkflowTasks({ scope: filterValue })
      } else {
        loadTasks({ scope: filterValue })
      }
    } else {
      loadWorkflows({ scope: filterValue }).then(setHeight())
      loadTools({ scope: filterValue }).then(setHeight())
      loadTasks({ scope: filterValue })
    }
  }

  const isOpen = !isMobile || isOpened

  return (
    <div className={`filters-container ${isOpen ? '' : 'd-none'}`}>
      <div className="filter">
        {
          options.map(
            option => (
              <FilterLink
                filter={option}
                key={option}
                selected={isSelected(option)}
                onSelectFilter={selectFilter}
              />
            )
          )
        }
      </div>
      { showListGridIcons && <ListGridToggleIcons /> }
    </div>
  )
}

Filters.propTypes = {
  loadWorkflows: PropTypes.func.isRequired,
  loadTools: PropTypes.func.isRequired,
  loadTasks: PropTypes.func.isRequired,
  loadWorkflowTasks: PropTypes.func.isRequired,
  setHeight: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  area: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isOpened: PropTypes.bool.isRequired,
  currentTab: PropTypes.string.isRequired,
  workflowId: PropTypes.string
}

Filters.defaultProps = {
  workflowId: null
}

export default Filters
