import React, { PureComponent } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import ActionIcons from 'components/Workflows/ActionIcons'
import { history } from 'redux/configureStore'
import ToolActionIcons from 'components/Tools/ToolActionIcons'

class TileActions extends PureComponent {
  toggleFavorite = (event) => {
    // Avoid open/close the tile when add/remove favorite
    event.stopPropagation()
    const { favorite, handleMakeFavorite, handleRemoveFavorite } = this.props
    if (favorite) {
      handleRemoveFavorite(favorite.id)
    } else {
      handleMakeFavorite()
    }
  }

  openDetailPage = () => {
    const { detailPageUrl } = this.props
    history.push(detailPageUrl)
  }

  render() {
    const { status, tasksCount, id, name, type, link, isATemplate } = this.props
    const isTool = type === 'Tool'
    const cardCss = classNames('smart_tile_actions', status)
    const isRestricted = status === 'restricted'

    return (
      <div className={cardCss} id={`workflow${id}`}>
        {
          !isTool && (
            <ActionIcons
              count={tasksCount}
              action={this.openDetailPage}
              id={id}
              name={name}
              isATemplate={isATemplate}
            />
          )
        }
        {
          isTool && (
            <ToolActionIcons
              count={tasksCount}
              action={this.openDetailPage}
              id={id}
              name={name}
              disabled={isRestricted}
              link={link}
            />
          )
        }
      </div>
    )
  }
}

TileActions.defaultProps = {
  tasksCount: 0,
  favorite: null,
  isATemplate: false
}

TileActions.propTypes = {
  tasksCount: PropTypes.number,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  favorite: PropTypes.object,
  handleMakeFavorite: PropTypes.func.isRequired,
  handleRemoveFavorite: PropTypes.func.isRequired,
  detailPageUrl: PropTypes.string.isRequired,
  isATemplate: PropTypes.bool
}

export default TileActions
