import React from 'react'
import PropTypes from 'prop-types'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'

export const SimpleBarChart = ({ data }) => (
  <ResponsiveContainer width="99%" height={300}>
    <BarChart
      data={data}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="month" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar type="monotone" dataKey="amount" fill="#2196f3" />
    </BarChart>
  </ResponsiveContainer>
)

SimpleBarChart.propTypes = {
  data: PropTypes.array.isRequired
}

export default SimpleBarChart
