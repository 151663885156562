import { types } from 'redux/actions/metrics'
import * as loadActions from '../middleware/loadActions'

export default (state = loadActions.arrayInitialState, action = {}) => {
  switch (action.type) {
    case types.ADD_CLICK_METRIC_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.response.data]
      }

    default:
      return state
  }
}
