import { connect } from 'react-redux'
import { logout } from 'redux/actions/auth'
import ExternalLinkPage from '.'

const mapDispatchToProps = dispatch => ({
  handleLogout: () => {
    dispatch(logout())
  }
})

export default connect(null, mapDispatchToProps)(ExternalLinkPage)
