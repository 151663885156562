import React, { Fragment } from 'react'

import ReactRouterPropTypes from 'react-router-prop-types'
import DefaultLayout from 'layouts/DefaultLayout'
import GroupDetails from 'components/GroupDetails/container'


const GroupPage = ({ match }) => {
  const renderShow = () => (
    <GroupDetails match={match} />
  )

  return (
    <Fragment>
      <DefaultLayout>
        <div className="groups">
          { renderShow() }
        </div>
      </DefaultLayout>
    </Fragment>
  )
}

GroupPage.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
}

export default GroupPage
