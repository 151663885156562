import React from 'react'
import { ListGroup, ListGroupItem, Container } from 'reactstrap'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import shortid from 'shortid'


const Suggestions = ({ isOpen, results, onClick, highlighted }) => {
  const cssClasses = classNames('suggestions', 'list-unstyled', { 'd-none': !isOpen })

  const options = results.map((result, i) => (
    <ListGroupItem key={shortid.generate()} className="justify-content-between">
      <Container>
        <button type="button" title={result.name} aria-label={result.name} onClick={onClick} className={`btn ${highlighted === i ? 'highlighted' : null}`}>
          {result.name}
        </button>
      </Container>
    </ListGroupItem>
  ))

  return <ListGroup className={cssClasses}>{options}</ListGroup>
}

Suggestions.defaultProps = {
  results: [],
  isOpen: false
}

Suggestions.propTypes = {
  results: PropTypes.array,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  highlighted: PropTypes.number.isRequired
}

export default Suggestions
