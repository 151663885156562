import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { PieChart, Pie } from 'recharts'

const statusList = ['To do', 'Doing', 'Testing', 'Done']

class PieChartDashboard extends PureComponent {
  renderCustomizedLabel = ({ x, y, index }) => (
    <text x={x} y={y} dy={-16} dx={16} textAnchor="middle">
      {`${statusList[index]} (${this.getValueForStatus(statusList[index])})`}
    </text>
  )

  getValueForStatus = (status) => {
    const { tasks } = this.props

    return tasks.filter(task => task.status === status).length
  }

  render() {
    const data = statusList.map(status => ({ name: status, value: this.getValueForStatus(status) }))

    return (
      <PieChart width={320} height={200}>
        <Pie dataKey="value" startAngle={180} endAngle={0} data={data} cx={150} cy={150} outerRadius={80} fill="#246ee3" label={this.renderCustomizedLabel} />
      </PieChart>
    )
  }
}

PieChartDashboard.propTypes = {
  tasks: PropTypes.array.isRequired
}

export default PieChartDashboard
