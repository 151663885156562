import * as React from 'react'
import PropTypes from 'prop-types'
import { ResourceActionButton } from '../ResourceActionButton'

export const ResourceActionField = (props) => {
  const { record, resource, label, workflowId } = props

  return (
    <ResourceActionButton
      resource={resource}
      record={record}
      workflowId={workflowId}
      label={label}
    />
  )
}

ResourceActionField.propTypes = {
  label: PropTypes.string.isRequired,
  workflowId: PropTypes.string.isRequired,
  record: PropTypes.object,
  resource: PropTypes.string
}

ResourceActionField.defaultProps = {
  record: {},
  resource: ''
}

export default ResourceActionField
