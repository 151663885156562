import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { addTask } from 'redux/actions/tasks'
import PropTypes from 'prop-types'
import { t } from 'utils/i18n/translate'
import Trans from 'components/Trans'
import CloseIcon from 'images/cancel_outline'
import ReactRouterPropTypes from 'react-router-prop-types'
import { loadWorkflowDetails, editWorkflowDetail, updateWorkflowDetail } from 'redux/actions/workflowDetails'
import { loadFavoriteList } from 'redux/actions/favorites'
import { loadGroupList } from 'redux/actions/groups'
import { Container, TabContent, TabPane, Button } from 'reactstrap'
import DashboardNavTabs from 'components/DashboardNavTabs'
import DefaultLayout from 'layouts/DefaultLayout'
import { setTitle } from 'redux/actions/system'
import BpmnChartContainer from 'components/BpmnChart/container'
import Spinner from 'components/Spinner'
import StepsContainer from 'components/Steps/container'
import EditInfo from './EditInfo'

export class WorkflowEditPage extends PureComponent {
  state = {
    selectedTab: 'infoTab',
    infoTab: {
      isSaved: true,
      isModalOpened: false
    },
    bpmnTab: {
      isSaved: true,
      isModalOpened: false
    },
    stepsTab: {
      isSaved: true,
      isModalOpened: false
    },
    dataTab: {
      isSaved: true,
      isModalOpened: false
    }
  }

  componentDidMount() {
    const { loadWorkflow, match, setPageTitle, editWorkflow, fetchGroups } = this.props
    const { id } = match.params

    loadWorkflow(id).then((result) => {
      setPageTitle(`${result.data.name} - ${t('app.pages.titles.default')}`)
    })

    editWorkflow(id)
    fetchGroups()
  }

  handleChange = (index) => {
    const { selectedTab } = this.state
    const currentTabSaved = this.state[selectedTab].isSaved

    if (currentTabSaved) {
      this.setState({ selectedTab: index })
    } else {
      this.setState(prevState => ({
        [selectedTab]: { ...prevState[selectedTab], isModalOpened: true },
        tabDestination: index
      }))
    }
  }

  saveHandler = (currentTabIsSaved) => {
    const { selectedTab } = this.state

    this.setState(prevState => ({
      [selectedTab]: { ...prevState[selectedTab], isSaved: currentTabIsSaved }
    }))
  }

  handleCloseModal = () => {
    this.setState(prevState => ({
      infoTab: { ...prevState.infoTab, isModalOpened: false },
      bpmnTab: { ...prevState.bpmnTab, isModalOpened: false },
      stepsTab: { ...prevState.stepsTab, isModalOpened: false },
      dataTab: { ...prevState.dataTab, isModalOpened: false },
    }))
  }

  handleSaveInModal = () => {
    const { tabDestination } = this.state
    const { workflow } = this.props

    if (this.state.tabDestination) {
      this.setState({ selectedTab: tabDestination, tabDestination: null })
    } else {
      this.props.history.push(`/workflows/${workflow.id}`)
    }
    this.handleCloseModal()
  }

  handleCloseEditor = () => {
    const { selectedTab } = this.state
    const { workflow } = this.props
    const currentTabIsSaved = this.state[selectedTab].isSaved

    if (currentTabIsSaved) {
      this.props.history.push(`/workflows/${workflow.id }`)
    } else {
      this.setState(prevState => ({
        [selectedTab]: { ...prevState[selectedTab], isModalOpened: true }
      }))
    }
  }

  handleDiscardChanges = () => {
    const { tabDestination } = this.state
    const { workflow, loadWorkflow } = this.props
    loadWorkflow(workflow.id)
    if (tabDestination) {
      this.setState(prevState => ({
        selectedTab: tabDestination,
        tabDestination: null,
        [prevState.selectedTab]: { ...prevState[prevState.selectedTab], isSaved: true }
      }))
    } else {
      this.props.history.push(`/workflows/${workflow.id}`)
    }
    this.handleCloseModal()
  }

  render() {
    const { workflow, isLoaded, isLoading, status, updateWorkflow, groups } = this.props
    const { selectedTab, infoTab, bpmnTab, stepsTab, dataTab } = this.state

    const infoTabTitle = t('app.workflows.edit.tabs.info')
    const bpmnTabTitle = t('app.workflows.edit.tabs.bpmn')
    const stepsTabTitle = t('app.workflows.edit.tabs.steps')
    const dataTabTitle = t('app.workflows.edit.tabs.data')

    return (
      <Fragment>
        <DefaultLayout status={status}>
          <div className="workflow-edit-page">
            {(!isLoaded || isLoading) && <Spinner align="center" top={50} />}
            {isLoaded && !isLoading && (
              <Fragment>
                <div className="tabs-header">
                  <Container>
                    <DashboardNavTabs handleChange={this.handleChange} activeTab={selectedTab}>
                      <DashboardNavTabs.Tab name="infoTab" href="#" title={infoTabTitle} />
                      <DashboardNavTabs.Tab name="bpmnTab" href="#" title={bpmnTabTitle} />
                      <DashboardNavTabs.Tab name="stepsTab" href="#" title={stepsTabTitle} />
                      <DashboardNavTabs.Tab name="dataTab" href="#" title={dataTabTitle} />
                    </DashboardNavTabs>
                    <Button onClick={this.handleCloseEditor} className="close-editor">
                      <CloseIcon />
                      <span> <Trans id="app.workflows.workflow_chart.buttons.close" /> </span>
                    </Button>
                  </Container>
                </div>
                <TabContent className="info-content" activeTab={selectedTab}>
                  <TabPane tabId="infoTab">
                    <EditInfo
                      saveHandler={this.saveHandler}
                      workflow={workflow}
                      isModalOpened={infoTab.isModalOpened}
                      handleCloseModal={this.handleCloseModal}
                      handleSaveInModal={this.handleSaveInModal}
                      handleDiscardChanges={this.handleDiscardChanges}
                      updateWorkflow={updateWorkflow}
                      groups={groups}
                    />
                  </TabPane>
                </TabContent>
                <TabContent className="bpmn-content" activeTab={selectedTab}>
                  <TabPane tabId="bpmnTab">
                    <div className="chart-editor-page">
                      <BpmnChartContainer
                        saveHandler={this.saveHandler}
                        workflowId={workflow.id}
                        isModalOpened={bpmnTab.isModalOpened}
                        handleCloseModal={this.handleCloseModal}
                        handleSaveInModal={this.handleSaveInModal}
                        handleDiscardChanges={this.handleDiscardChanges}
                        isEditable
                      />
                    </div>
                  </TabPane>
                </TabContent>
                <TabContent className="steps-content" activeTab={selectedTab}>
                  <TabPane tabId="stepsTab">
                    <StepsContainer
                      saveHandler={this.saveHandler}
                      workflowId={workflow.id}
                      workflowName={workflow.name}
                      isModalOpened={stepsTab.isModalOpened}
                      handleCloseModal={this.handleCloseModal}
                      handleSaveInModal={this.handleSaveInModal}
                      handleDiscardChanges={this.handleDiscardChanges}
                      tab="stepsTab"
                    />
                  </TabPane>
                </TabContent>
                <TabContent className="data-content" activeTab={selectedTab}>
                  <TabPane tabId="dataTab">
                    <StepsContainer
                      saveHandler={this.saveHandler}
                      workflowId={workflow.id}
                      isModalOpened={dataTab.isModalOpened}
                      handleCloseModal={this.handleCloseModal}
                      handleSaveInModal={this.handleSaveInModal}
                      handleDiscardChanges={this.handleDiscardChanges}
                      tab="dataTab"
                    />
                  </TabPane>
                </TabContent>
              </Fragment>
            )}
          </div>
        </DefaultLayout>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isLoaded: state.workflowDetails.isLoaded,
  isLoading: state.workflowDetails.isLoading,
  userGroupIds: state.auth.data.groupIds,
  workflow: state.workflowDetails.data,
  userEmail: state.auth.data.email,
  status: state.workflowDetails.edit.status,
  groups: state.groups.data
})

const mapDispatchToProps = dispatch => ({
  loadWorkflow: id => dispatch(loadWorkflowDetails(id)),
  addTask: task => dispatch(addTask(task)),
  loadFavorites: () => dispatch(loadFavoriteList()),
  setPageTitle: title => (dispatch(setTitle(title))),
  editWorkflow: id => dispatch(editWorkflowDetail(id)),
  updateWorkflow: (id, data) => dispatch(updateWorkflowDetail(id, data)),
  fetchGroups: () => dispatch(loadGroupList())
})

WorkflowEditPage.defaultProps = {
  isLoaded: true,
  isLoading: false
}

WorkflowEditPage.propTypes = {
  history: PropTypes.object.isRequired,
  loadWorkflow: PropTypes.func.isRequired,
  editWorkflow: PropTypes.func.isRequired,
  updateWorkflow: PropTypes.func.isRequired,
  fetchGroups: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  isLoaded: PropTypes.bool,
  isLoading: PropTypes.bool,
  workflow: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    bannerImageUrl: PropTypes.string,
    imageUrl: PropTypes.string,
    altBannerImage: PropTypes.string,
    category: PropTypes.string,
    externalId: PropTypes.string,
    groupIds: PropTypes.array
  }).isRequired,
  status: PropTypes.number.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowEditPage)
