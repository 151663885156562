// Given an object it returns true or false whether the object is empty or not
export const isEmpty = (object) => {
  if (object === undefined || object === null) {
    return true
  }

  return Object.keys(object).length === 0 && object.constructor === Object
}

export function isObject(thing) {
  if (typeof File !== 'undefined' && thing instanceof File) {
    return false
  }
  return typeof thing === 'object' && thing !== null && !Array.isArray(thing)
}

export default { isEmpty }
