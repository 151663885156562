import * as React from 'react'
import { Card, Grid } from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import { Title } from 'react-admin'
import { SimpleLineChart } from './SimpleLineChart'
import { SimpleBarChart } from './SimpleBarChart'
import { SimplePieChart } from './SimplePieChart'

const totalOrdersData = [
  { month: 'Jan', orders: 28, amount: 560 },
  { month: 'Feb', orders: 30, amount: 570 },
  { month: 'Mar', orders: 20, amount: 380 },
  { month: 'Apr', orders: 20, amount: 300 },
  { month: 'May', orders: 10, amount: 220 },
  { month: 'Jun', orders: 20, amount: 380 },
  { month: 'Jul', orders: 30, amount: 530 }
]

const trafficSourceData = [
  { name: 'Social', value: 60 },
  { name: 'Search', value: 25 },
  { name: 'Direct', value: 15 }
]

const topProducts = [
  { name: 'Shoes', value: 6 },
  { name: 'Watch', value: 2 },
  { name: 'Sweatpants', value: 2 },
  { name: 'T-Shirt', value: 1 }
]

export default () => (
  <Grid container spacing={5} alignItems="stretch">
    <Grid item xs={12} sm={12} lg={6}>
      <Card>
        <Title title="Order Management" />
        <CardContent>Total Orders</CardContent>
        <SimpleLineChart data={totalOrdersData} />
      </Card>
    </Grid>
    <Grid item xs={12} sm={12} lg={6}>
      <Card>
        <CardContent>Revenue</CardContent>
        <SimpleBarChart data={totalOrdersData} />
      </Card>
    </Grid>
    <Grid item xs={12} sm={12} lg={6}>
      <Card>
        <CardContent>Sales by Traffic Source</CardContent>
        <SimplePieChart data={trafficSourceData} />
      </Card>
    </Grid>
    <Grid item xs={12} sm={12} lg={6}>
      <Card>
        <CardContent>Top Products</CardContent>
        <SimplePieChart data={topProducts} />
      </Card>
    </Grid>
  </Grid>
)
