import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Container, Row } from 'reactstrap'
import { t } from 'utils/i18n/translate'
import { isBrowser } from 'react-device-detect'
import Trans from 'components/Trans'
import ActionIcons from 'components/Workflows/ActionIcons'
import Sidebar from 'layouts/Sidebar/.'
import classNames from 'classnames'

class WorkflowDetails extends PureComponent {
  state = {
    openInfo: false
  }

  componentDidMount() {
    const { loadTasks } = this.props

    loadTasks()
  }

  handleChange = (index) => {
    this.setState({ selectedTab: index })
  }

  toggleInfo = () => {
    this.setState(prevState => ({
      openInfo: !prevState.openInfo
    }))
  }

  onDismiss = () => {
    this.setState({
      openInfo: false
    })
  }

  render() {
    const { id, name, description, tasksCount, category, externalId, isATemplate } = this.props
    const { openInfo, selectedTab } = this.state
    const alertClasses = classNames('alert',
      'alert-info', 'alert-dismissible', 'fade', 'description-alert', { show: openInfo })

    return (
      <Fragment>
        <div className="workflow-top">
          <Container>
            <Row>
              <div className="title-bar col-md-5">
                <h2>{ name }</h2>
                <span className="category">{ category }</span>
              </div>
              <div className="col-md-7">
                <ActionIcons
                  count={tasksCount}
                  action={this.toggleInfo}
                  id={id}
                  name={name}
                  isATemplate={isATemplate}
                />
              </div>
            </Row>
          </Container>
        </div>
        <div className={alertClasses} role="alert">
          <Container>
            <p className="label"> <Trans id="app.workflow.description" /> </p>
            <p className="description">{ description }</p>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.onDismiss}>
              <span aria-hidden="true">&times;</span>
            </button>
          </Container>
        </div>

        <Container>
          <Row className="workflow-content">
            <Sidebar
              title={t('activerecord.models.task.other')}
              isExpanded={isBrowser}
              isATemplate={isATemplate}
              showExpand={false}
              count={tasksCount}
              workflowId={id}
              selectedTab={selectedTab}
              handleChange={this.handleChange}
              externalId={externalId}
            />
          </Row>
        </Container>
      </Fragment>
    )
  }
}

WorkflowDetails.defaultProps = {
  tasksCount: 0,
  isATemplate: false
}

WorkflowDetails.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tasksCount: PropTypes.number,
  loadTasks: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  externalId: PropTypes.string.isRequired,
  isATemplate: PropTypes.bool
}

export default WorkflowDetails
