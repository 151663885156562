import React from 'react'
// import PropTypes from 'prop-types'
import Trans from 'components/Trans'
import i18n from 'i18n-js'
import { Grid, Container } from '@material-ui/core'
import { isMobile } from 'react-device-detect'
import DevoluteLogo from 'images/DevoluteLogo'
// import logo from 'images/brand/logo.svg'
// import { Link } from 'react-router-dom'

import { t } from 'utils/i18n/translate'

const currentYear = (new Date()).getFullYear()

const Footer = () => (
  <footer className="footer">
    <Container>
      {/* <div className="brand-images">
        <div className="brand-logo">
          <img src={logo} alt="Dashboard Logo" />
          <span className="sr-only">Dashboard Logo</span>
        </div>
      </div> */}
      <div className="footer-links">
        <Grid container spacing={isMobile ? 0 : 1}>
          <Grid item xs={3} lg={1}>
            <a target="_blank" rel="noreferrer" href="https://www.devolute.org/de/datenschutz"> <Trans id="app.footer.privacy_statement" /> </a>
            {/* {
              pages.map(page => (
                <Link
                  to={`/static_page/${page.slug}`}
                  rel="noopener noreferrer"
                  className="text-light"
                  key={page.slug}>
                  {page.name}
                </Link>
              ))
            } */}
          </Grid>
          <Grid item xs={3} lg={1}>
            <a target="_blank" rel="noreferrer" href={`https://www.devolute.org/${i18n.currentLocale()}/${t('app.footer.imprint')}`}> <Trans id="app.footer.imprint" /> </a>
          </Grid>

          <Grid item xs={6} lg={10} className="copyright">
            <p>© {currentYear} <DevoluteLogo /> </p>
          </Grid>
        </Grid>
      </div>
    </Container>
  </footer>
)

// Footer.propTypes = {
//   pages: PropTypes.array.isRequired
// }

export default Footer
