import React from 'react'
import { Admin, Resource } from 'react-admin'
import fakeDataProvider from 'ra-data-fakerest'
import DefaultLayout from 'layouts/DefaultLayout'
import { createBrowserHistory } from 'history'
import Dashboard from './Dashboard'

const history = createBrowserHistory({ basename: '/finance_dashboard' })
const financesData = require('./data.json')

export const dataProvider = fakeDataProvider({
  finances: financesData
})

export const FinanceDashboardPage = () => (
  <DefaultLayout>
    <div className="react-admin-layout">
      <Admin
        dataProvider={dataProvider}
        history={history}
        dashboard={Dashboard}
      >
        <Resource />
      </Admin>
    </div>
  </DefaultLayout>
)

export default FinanceDashboardPage
