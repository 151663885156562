import axios from 'axios'
import { loadCookie } from 'utils/cookies_'
import { AUTH_TOKEN_KEY } from 'redux/actions/auth'
import qs from 'qs'
import { registerService } from './rails_middleware'

const BASE = '/api/v1'

const methods = ['get', 'post', 'put', 'patch', 'delete']

document.numberOfAjaxCAllPending = 0

// Add a request interceptor
axios.interceptors.request.use((config) => {
  document.numberOfAjaxCAllPending += 1
  return config
}, error => Promise.reject(error))

// Add a response interceptor
axios.interceptors.response.use((response) => {
  document.numberOfAjaxCAllPending -= 1
  return response
}, error => Promise.reject(error))

export default class ApiClient {
  // Creates a method corresponding to each of the supported http verbs
  constructor() {
    methods.forEach((method) => {
      this[method] = (path, params = {}, basePath = BASE) => {
        const token = loadCookie(AUTH_TOKEN_KEY)

        const headers = {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }

        if (token) {
          headers.Authorization = `Bearer ${token}`
        }

        const options = { ...params }
        options.method = method
        options.url = basePath + path
        options.headers = headers
        options.paramsSerializer = p => (
          qs.stringify(p, {
            arrayFormat: 'brackets'
          })
        )

        return axios(options)
      }

      return this[method]
    })

    // Register the Rails middleware that converts camel case into snake_case and vice-versa.
    registerService()
  }
}
