export const types = {
  LOAD_TASKS_START: 'openWorkflow/LOAD_TASKS_START',
  LOAD_TASKS_SUCCESS: 'openWorkflow/LOAD_TASKS_SUCCESS',
  LOAD_TASKS_FAIL: 'openWorkflow/LOAD_TASKS_FAIL',
  LOAD_STEPS_START: 'openWorkflow/LOAD_STEPS_START',
  LOAD_STEPS_SUCCESS: 'openWorkflow/LOAD_STEPS_SUCCESS',
  LOAD_STEPS_FAIL: 'openWorkflow/LOAD_STEPS_FAIL',
  TOGGLE_INFO_BOX: 'openWorkflow/TOGGLE_INFO_BOX',
  ADD_TASK_TO_OPEN_WORKFLOW: 'workflows/ADD_TASK_TO_OPEN_WORKFLOW'
}

export const toggleInfoBox = workflowId => (
  {
    type: types.TOGGLE_INFO_BOX,
    workflowId
  }
)

export const loadTasksForWorkflowSuccess = (workflowId, tasks) => (
  {
    type: types.LOAD_TASKS_SUCCESS,
    workflowId,
    tasks
  }
)

export const loadTasksForWorkflow = workflowId => (
  (dispatch, _getState, client) => {
    dispatch({ type: types.LOAD_TASKS_START })

    return client.get(`/workflows/${workflowId}/tasks`)
      .then(({ data }) => {
        dispatch(loadTasksForWorkflowSuccess(workflowId, data))
      })
  }
)

export const loadStepsForWorkflowSuccess = (workflowId, steps) => (
  {
    type: types.LOAD_STEPS_SUCCESS,
    workflowId,
    steps
  }
)

export const loadStepsForWorkflow = workflowId => (
  (dispatch, _getState, client) => {
    dispatch({ type: types.LOAD_STEPS_START })

    return client.get(`/workflows/${workflowId}/steps`)
      .then(({ data }) => {
        dispatch(loadStepsForWorkflowSuccess(workflowId, data))
      })
  }
)

export const addTaskToOpenWorkflow = task => (
  {
    type: types.ADD_TASK_TO_OPEN_WORKFLOW,
    task
  }
)
