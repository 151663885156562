export const types = {
  LOAD_TASKS_START: 'openTool/LOAD_TASKS_START',
  LOAD_TASKS_SUCCESS: 'openTool/LOAD_TASKS_SUCCESS',
  LOAD_TASKS_FAIL: 'openTool/LOAD_TASKS_FAIL',
  TOGGLE_INFO_BOX: 'openTool/TOGGLE_INFO_BOX',
}

export const toggleInfoBox = toolId => (
  {
    type: types.TOGGLE_INFO_BOX,
    toolId
  }
)

export const loadTasksForToolSuccess = (toolId, tasks) => (
  {
    type: types.LOAD_TASKS_SUCCESS,
    toolId,
    tasks
  }
)

export const loadTasksForTool = toolId => (
  (dispatch, getState, client) => {
    dispatch({ type: types.LOAD_TASKS_START })

    return client.get(`/tools/${toolId}/tasks`)
      .then(({ data }) => {
        dispatch(loadTasksForToolSuccess(toolId, data))
      })
  }
)
