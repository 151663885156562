import React from 'react'
import PropTypes from 'prop-types'
import Url from 'url-parse'
import Trans from 'components/Trans'
import qs from 'querystringify'
import { isEmpty } from 'utils/objects'
import GoToIconAction from 'components/IconActions/GoToIconAction'

const TaskLink = ({ id, metadata, withIcon, isMobileOnly }) => {
  if (isEmpty(metadata) || isEmpty(metadata.url)) { return null }

  const urlWithtaskId = () => {
    const url = new Url(metadata.url)
    url.set('query', { ...qs.parse(url.query), _smart_task_id: id })

    return url
  }

  const iconSize = () => {
    if (isMobileOnly) {
      return { height: '16px', width: '16px' }
    }
    return undefined
  }

  return (
    <a href={urlWithtaskId()} rel="noopener noreferrer" target="_blank" className="task-link">
      { withIcon
        ? (
          <GoToIconAction
            link={urlWithtaskId}
            height={iconSize() && iconSize().height}
            width={iconSize() && iconSize().width}
          />
        )
        : <Trans id="app.home.view_tool" />
      }
    </a>
  )
}

TaskLink.defaultProps = {
  withIcon: false,
  isMobileOnly: false,
}

TaskLink.propTypes = {
  id: PropTypes.string.isRequired,
  metadata: PropTypes.object.isRequired,
  withIcon: PropTypes.bool,
  isMobileOnly: PropTypes.bool,
}

export default TaskLink
