import { connect } from 'react-redux'
import { logout } from 'redux/actions/auth'
import { toggleSearchBar } from 'redux/actions/search'
import { loadNotificationsTasks } from 'redux/actions/notifications'
import LoggedNav from './LoggedNav'


const mapDispatchToProps = dispatch => ({
  handleLogout: includeSso => dispatch(logout(includeSso)),
  toggleSearchBar: state => dispatch(toggleSearchBar(state)),
  loadNotifications: params => dispatch(loadNotificationsTasks(params))
})

const mapStateToProps = state => ({
  firstName: state.auth.data.firstName,
  lastName: state.auth.data.lastName,
  role: state.auth.data.role,
  isOpen: state.search.isOpen,
  hasHelpPage: state.pages.data.some(page => page.slug === 'help'),
  filterValue: state.system.filters.tasks.value,
  notifications: state.notifications.data,
  searchValue: state.search.searchValue
})

export default connect(mapStateToProps, mapDispatchToProps)(LoggedNav)
