import { connect } from 'react-redux'
import { setFilter } from 'redux/actions/search'
import { getAffiliatePagesCount, getTasksCount, getWorkflowsCount, getToolsCount } from 'redux/selectors/searchResults'
import FilterTabs from '.'

const mapStateToProps = state => ({
  activeFilter: state.search.activeFilter,
  tasksCount: getTasksCount(state).length,
  workflowsCount: getWorkflowsCount(state).length,
  affiliatePagesCount: getAffiliatePagesCount(state).length,
  toolsCount: getToolsCount(state).length
})

const mapDispatchToProps = dispatch => ({
  setFilter: filter => dispatch(setFilter(filter))
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterTabs)
