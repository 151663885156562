import React from 'react'
import PropTypes from 'prop-types'
import { ErrorContent } from 'pages/ErrorPage/.'
import { NotFoundContent } from 'pages/NotFoundPage'

class ErrorBoundary extends React.Component {
  state = { hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch() {
    this.setState({
      hasError: true
    })
  }

  render() {
    const { children, status } = this.props
    const { hasError } = this.state
    if (hasError) {
      return <ErrorContent />
    }

    if (status !== 200) {
      return [404, 403].includes(status) ? <NotFoundContent /> : <ErrorContent />
    }

    return children
  }
}

ErrorBoundary.defaultProps = {
  status: 200
}

ErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired,
  status: PropTypes.number
}

export default ErrorBoundary
