import { connect } from 'react-redux'
import * as dashboardViewActions from '../../redux/actions/system'

import NavBar from '.'

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn,
  dashboardView: state.system.dashboardView
})

const mapDispatchToProps = dispatch => ({
  toggleDashboardView: () => dispatch(dashboardViewActions.toggleDashboardView())
})

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
