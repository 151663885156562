import { types } from 'redux/actions/groupMemberships'
import * as loadActions from '../middleware/loadActions'

const initialState = {
  ...loadActions.objectInitialState,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SHOW_GROUP_MEMBERSHIP_FAIL:
      return loadActions.onLoadFailure(state, action)
    case types.SHOW_GROUP_MEMBERSHIP_SUCCESS: {
      return {
        isLoaded: true,
        isLoading: false,
        data: action.response.data,
      }
    }
    case types.UPDATE_GROUP_MEMBERSHIP_FAIL:
      return loadActions.onLoadFailure(state, action)
    case types.UPDATE_GROUP_MEMBERSHIP_SUCCESS: {
      return {
        isLoaded: true,
        isLoading: false,
        data: action.response.data,
        included: action.response.included
      }
    }

    default:
      return state
  }
}
