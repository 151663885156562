export const types = {
  LOAD_START: 'favorites/LOAD_START',
  LOAD_SUCCESS: 'favorites/LOAD_SUCCESS',
  LOAD_FAIL: 'favorites/LOAD_FAIL',
  MAKE_FAVORITE_START: 'favorites/MAKE_FAVORITE_START',
  MAKE_FAVORITE_SUCCESS: 'favorites/MAKE_FAVORITE_SUCCESS',
  MAKE_FAVORITE_FAIL: 'favorites/MAKE_FAVORITE_FAIL',
  REMOVE_FAVORITE_START: 'favorites/REMOVE_FAVORITE_START',
  REMOVE_FAVORITE_SUCCESS: 'favorites/REMOVE_FAVORITE_SUCCESS',
  REMOVE_FAVORITE_FAIL: 'favorites/REMOVE_FAVORITE_FAIL',
}

export const loadFavoriteList = () => ({
  types: [types.LOAD_START, types.LOAD_SUCCESS, types.LOAD_FAIL],
  promise: client => client.get('/favorites')
})

export const makeFavorite = (type, id) => ({
  types: [types.MAKE_FAVORITE_START, types.MAKE_FAVORITE_SUCCESS, types.MAKE_FAVORITE_FAIL],
  promise: client => client.post('/favorites', { data: { favorite: { favoritableType: type, favoritableId: id } } })
})

export const removeFavorite = id => ({
  types: [types.REMOVE_FAVORITE_START, types.REMOVE_FAVORITE_SUCCESS, types.REMOVE_FAVORITE_FAIL],
  promise: client => client.delete(`/favorites/${id}`),
  favoriteId: id
})
