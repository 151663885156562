import React from 'react'
import PropTypes from 'prop-types'
import Trans from 'components/Trans'
import SearchResult from './SearchResult'

const SearchResults = ({ results }) => (
  <ul className="search-results">
    {results.length ? (
      results.map(result => <SearchResult key={result.id} {...result} metadata={{}} />)
    ) : (
      <li className="no-results">
        <Trans id="app.search.no-results" />
      </li>
    )}
  </ul>
)

SearchResults.defaultProps = {
  results: []
}

SearchResults.propTypes = {
  results: PropTypes.array
}

export default SearchResults
