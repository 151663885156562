import React from 'react'
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts'
import PropTypes from 'prop-types'


export const StackedAreaChart = ({ data, kFormatter, formatter }) => (
  <ResponsiveContainer width="99%" height={400}>
    <AreaChart
      data={data}
      margin={{
            top: 10, right: 30, left: 0, bottom: 0,
        }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="month" />
      <YAxis tickFormatter={kFormatter} />
      <Tooltip formatter={value => formatter.format(value).slice(0, -3)} />
      <Area type="monotone" dataKey="value_2" name="Profit" stackId="1" stroke="#0945a5" fill="#0945a5" />
      <Area type="monotone" dataKey="value_1" name="Loss" stackId="1" stroke="#8884d8" fill="#246ee3" />
    </AreaChart>
  </ResponsiveContainer>
)
StackedAreaChart.propTypes = {
    data: PropTypes.object.isRequired,
    kFormatter: PropTypes.func.isRequired,
    formatter: PropTypes.func.isRequired
}
export default StackedAreaChart
