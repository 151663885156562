import React from 'react'
import PropTypes from 'prop-types'
import FavoriteIconActionContainer from 'components/IconActions/FavoriteIconActionContainer'
import InfoAction from 'components/IconActions/InfoAction'
import Clickable from 'components/Metrics/Clickable'
import GoToIconAction from '../IconActions/GoToIconAction'

const ToolActionIcons = ({ id, action, disabled, link }) => (
  <div className="icons">
    <Clickable id={id} resource="Tool">
      <GoToIconAction link={link} disabled={disabled} />
    </Clickable>
    <Clickable id={id} resource="Tool">
      <InfoAction action={action} disabled={disabled} />
    </Clickable>
    <FavoriteIconActionContainer type="Tool" id={id} disabled={disabled} />
  </div>
)

ToolActionIcons.propTypes = {
  action: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default ToolActionIcons
