export const types = {
  LOAD_START: 'integrations/LOAD_START',
  LOAD_SUCCESS: 'integrations/LOAD_SUCCESS',
  LOAD_FAIL: 'integrations/LOAD_FAIL',
  REMOVE_INTEGRATION_START: 'integrations/REMOVE_INTEGRATION_START',
  REMOVE_INTEGRATION_SUCCESS: 'integrations/REMOVE_INTEGRATION_SUCCESS',
  REMOVE_INTEGRATION_FAIL: 'integrations/REMOVE_INTEGRATION_FAIL',
}

export const loadIntegrationList = () => ({
  types: [types.LOAD_START, types.LOAD_SUCCESS, types.LOAD_FAIL],
  promise: client => client.get('/integrations')
})


export const removeIntegration = id => ({
  types: [
    types.REMOVE_INTEGRATION_START,
    types.REMOVE_INTEGRATION_SUCCESS,
    types.REMOVE_INTEGRATION_FAIL],
  promise: client => client.delete(`/integrations/${id}`),
  integrationId: id
})
