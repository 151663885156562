import { connect } from 'react-redux'
import { startWorkflow } from 'redux/actions/workflows'
import { successToast, errorToast } from 'components/Toast'
import { t } from 'utils/i18n/translate'
import PlayAction from './PlayAction'

const mapDispatchToProps = (dispatch, ownProps) => ({
  action: () => (
    dispatch(startWorkflow(ownProps.id)).then(() => {
      successToast(`${ownProps.name} - ${t('app.workflows.start_workflow.process_message')}`)
    }).catch(() => {
      errorToast(`${ownProps.name} - Prozess starten ist fehlgeschlagen`)
    })
  )
})

export default connect(null, mapDispatchToProps)(PlayAction)
