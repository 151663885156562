import React from 'react'
import PropTypes from 'prop-types'

const TaskDashboardIcon = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
    <path fill="#e7e8ea" d="M19 3h-4.18a2.988 2.988 0 0 0-5.64 0H5a2.006 2.006 0 0 0-2 2v14a2.006 2.006 0 0 0 2 2h14a2.006 2.006 0 0 0 2-2V5a2.006 2.006 0 0 0-2-2zm-7 0a1 1 0 1 1-1 1 1 1 0 0 1 1-1zm2 14H7v-2h7zm3-4H7v-2h10zm0-4H7V7h10z" transform="translate(-3 -1)" />

  </svg>
)

TaskDashboardIcon.defaultProps = {
    className: 'snack-task-icon'
}

TaskDashboardIcon.propTypes = {
    className: PropTypes.string
}

export default TaskDashboardIcon
