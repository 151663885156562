import { types } from 'redux/actions/magazine'
import { types as taskDetailsTypes } from 'redux/actions/taskDetails'
import * as loadActions from '../middleware/loadActions'

const initialState = {
  ...loadActions.objectInitialState,
  articles: loadActions.arrayInitialState,
  tools: loadActions.arrayInitialState,
  tasks: loadActions.arrayInitialState,
  folders: loadActions.arrayInitialState,
  currentFolder: {}
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.LOAD_ARTICLES_START:
      return {
        ...state,
        articles: {
          ...state.articles,
          isLoading: true
        }
      }
    case types.LOAD_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: {
          isLoading: false,
          isLoaded: true,
          data: action.response.data
        }
      }
    case types.LOAD_ARTICLES_FAIL:
      return {
        ...state,
        articles: {
          ...state.articles,
          isLoading: false
        }
      }
    case types.LOAD_TOOLS_START:
      return {
        ...state,
        tools: {
          ...state.tools,
          isLoading: true
        }
      }
    case types.LOAD_TOOLS_SUCCESS:
      return {
        ...state,
        tools: {
          isLoading: false,
          isLoaded: true,
          data: action.response.data
        }
      }
    case types.LOAD_TOOLS_FAIL:
      return {
        ...state,
        tasks: {
          ...state.tools,
          isLoading: false
        }
      }
    case types.LOAD_TASKS_START:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          isLoading: true
        }
      }
    case types.LOAD_TASKS_SUCCESS:
      return {
        ...state,
        tasks: {
          isLoading: false,
          isLoaded: true,
          data: action.response.data
        }
      }
    case types.LOAD_TASKS_FAIL:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          isLoading: false
        }
      }
    case taskDetailsTypes.UPDATE_DETAILS_SUCCESS: {
      const tasks = state.tasks.data.map((task) => {
        if (task.id === action.response.data.id) {
          return { ...task, ...action.response.data }
        }

        return { ...task }
      })

      return { ...state, tasks: { ...state.task, data: tasks } }
    }
    case types.LOAD_FOLDERS_START:
      return {
        ...state,
        folders: {
          ...state.folders,
          isLoading: true
        }
      }
    case types.LOAD_FOLDERS_SUCCESS:
      return {
        ...state,
        folders: {
          isLoading: false,
          isLoaded: true,
          data: action.response.data
        }
      }
    case types.LOAD_FOLDERS_FAIL:
      return {
        ...state,
        folders: {
          ...state.folders,
          isLoading: false
        }
      }
    case types.LOAD_FOLDER_START:
      return {
        ...state,
        currentFolder: {
          ...state.currentFolder,
          isLoading: true
        }
      }
    case types.LOAD_FOLDER_SUCCESS:
      return {
        ...state,
        currentFolder: {
          isLoading: false,
          isLoaded: true,
          data: action.response.data
        }
      }
    case types.LOAD_FOLDER_FAIL:
      return {
        ...state,
        currentFolder: {
          ...state.currentFolder,
          isLoading: false
        }
      }
    default:
      return state
  }
}
