import React from 'react'

const ToastErrorIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="prefix__Group_5805" data-name="Group 5805" transform="rotate(180 7.075 12.066)">
      <path id="prefix__Path_4251" fill="#951c1c" d="M-3053.911-5391.5a1.851 1.851 0 0 1-1.993 1.921 1.863 1.863 0 0 1-1.885-1.921 1.906 1.906 0 0 1 1.957-1.955 1.857 1.857 0 0 1 1.921 1.955zm-3.487 22.045v-17.211h3.131v17.211z" data-name="Path 4251" transform="translate(3057.79 5393.458)" />
    </g>
  </svg>
)

export default ToastErrorIcon
