import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as loadPagesActions from 'redux/actions/pages'
import Footer from '.'

class StaticPageContainer extends PureComponent {
  componentDidMount() {
    const { loadPages: loadPagesReact } = this.props

    loadPagesReact()
  }

  render() {
    const { pages, isLoading, isLoaded } = this.props

    if (isLoading || !isLoaded) {
      return null
    }

    return (
      <Footer pages={pages} />
    )
  }
}

StaticPageContainer.propTypes = {
  pages: PropTypes.array.isRequired,
  loadPages: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool
}

StaticPageContainer.defaultProps = {
  isLoading: false,
  isLoaded: false
}

const mapStateToProps = state => ({
  pages: state.pages.data,
  isLoading: state.pages.isLoading,
  isLoaded: state.pages.isLoaded
})

const mapDispatchToProps = dispatch => ({
  loadPages: () => dispatch(loadPagesActions.loadPages())
})

export default connect(mapStateToProps, mapDispatchToProps)(StaticPageContainer)
