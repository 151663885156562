import React, { Fragment, useState, useEffect } from 'react'
import DashboardNavTabs from 'components/DashboardNavTabs'
import { TabContent, TabPane } from 'reactstrap'
import Form from 'react-jsonschema-form-bs4'
import Trans from 'components/Trans'
import { t } from 'utils/i18n/translate'
import { Grid, Modal } from '@material-ui/core'
import ConfirmModal from 'components/Modal/ConfirmModal'
import PropTypes from 'prop-types'
import { isObject } from 'utils/objects/.'
import MonacoEditor from 'react-monaco-editor'
import HidePanel from 'images/HidePanel'
import ShowPanel from 'images/ShowPanel'
import SaveHeader from 'components/SaveHeader'
import StepList from './StepList'

const option = {}
require('json-schema-editor-visual/dist/main.css')

const schemaEditor = require('json-schema-editor-visual/dist/main')

const SchemaEditor = schemaEditor(option)

const DataTab = ({
  isModalOpened,
  handleCloseModal,
  handleSaveInModal,
  saveStep,
  workflowId,
  handleDiscardChanges,
  loadStepsForWorkflow,
  saveHandler,
  steps
}) => {
  const initialState = () => (
    {
      currentStep: {},
      selectedTab: 'builderTab',
      schema: null,
      uiSchema: null,
      previewOpen: true,
      stepsHasChanges: false
    }
  )

  const [state, setState] = useState(initialState())

  const initalSchema = { type: 'string' }

  useEffect(() => {
    if (steps.length === 0) return
    setState({
      ...state,
      schema: steps[0].dataSchema.schema || initalSchema,
      uiSchema: steps[0].dataSchema.uiSchema || {},
      currentStep: steps[0]
    })
  }, steps)

  const handleChange = (tab) => {
    setState({ ...state, selectedTab: tab })
  }

  const onUiSchemaChange = (uiSchema) => {
    try {
      const parsedCode = JSON.parse(uiSchema)
      const hasChanges = JSON.stringify(parsedCode) !== JSON.stringify(state.uiSchema)

      if (isObject(parsedCode)) {
        setState(prevState => ({
          ...state,
          uiSchema: parsedCode,
          stepsHasChanges: hasChanges || prevState.stepsHasChanges
        }))

        if (hasChanges) saveHandler(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onJsonSchemaChange = (schema) => {
    try {
      const parsedCode = JSON.parse(schema)
      const notInitial = JSON.stringify(parsedCode) !== JSON.stringify(initalSchema)
      const hasChanges = JSON.stringify(parsedCode) !== JSON.stringify(state.schema) && notInitial

      if (isObject(parsedCode)) {
        setState(prevState => ({
          ...state,
          schema: parsedCode,
          stepsHasChanges: hasChanges || prevState.stepsHasChanges
        }))

        if (hasChanges) saveHandler(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const togglePreviewOpen = () => {
    setState({ ...state, previewOpen: !(state.previewOpen) })
  }

  const setCurrentStep = (step) => {
    if (step === null) return
    setState({
      ...state,
      currentStep: step,
      schema: step.dataSchema.schema || initalSchema,
      uiSchema: step.dataSchema.uiSchema || {},
      stepsHasChanges: false
    })

    saveHandler(true)
  }

  const monacoEditorOptions = {
    minimap: {
      enabled: false,
    },
    automaticLayout: true,
  }

  const saveCurrentStep = () => {
    const data = { dataSchema: { schema: state.schema, uiSchema: state.uiSchema } }
    const promise = saveStep(state.currentStep.id, data)

    promise.then(() => {
      setState({ ...state, stepsHasChanges: false })
    })

    return promise
  }

  const saveWithModal = () => {
    saveCurrentStep().then(() => handleSaveInModal())
  }

  const builderTab = t('app.steps.data.tabs.builderTab')
  const jsonSchemaTab = t('app.steps.data.tabs.jsonSchemaTab')
  const uiSchemaTab = t('app.steps.data.tabs.uiSchemaTab')

  const editorData = JSON.stringify(state.schema)
  const monacoData = JSON.stringify(state.schema, null, 2)
  const monacoUiData = JSON.stringify(state.uiSchema, null, 2)

  return (
    <Fragment>
      <Modal
        open={isModalOpened}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ConfirmModal
          handleSaveClick={saveWithModal}
          workflowId={workflowId}
          handleDiscardChanges={handleDiscardChanges}
        />
      </Modal>
      <SaveHeader disabled={!state.stepsHasChanges} onClick={saveCurrentStep} />
      { state.schema && (
        <StepList
          steps={steps}
          loadStepsForWorkflow={loadStepsForWorkflow}
          setCurrentStep={setCurrentStep}
        >
          <DashboardNavTabs handleChange={handleChange} activeTab={state.selectedTab} className="step-data-tabs">
            <DashboardNavTabs.Tab name="builderTab" href="#" title={builderTab} />
            <DashboardNavTabs.Tab name="jsonSchemaTab" href="#" title={jsonSchemaTab} />
            <DashboardNavTabs.Tab name="uiSchemaTab" href="#" title={uiSchemaTab} />
          </DashboardNavTabs>
          <Grid container justify="space-between">
            <Grid item xs={12} md={8} className={`json-schema-editor ${!state.previewOpen && 'expanded'}`}>
              <h2> { state.currentStep.name } </h2>
              <TabContent className="builder-content" activeTab={state.selectedTab}>
                <TabPane tabId="builderTab">
                  { editorData && (
                    <SchemaEditor data={editorData} onChange={onJsonSchemaChange} />
                  )}
                </TabPane>
              </TabContent>
              <TabContent className="json-content" activeTab={state.selectedTab}>
                <TabPane tabId="jsonSchemaTab">
                  <div className="monaco-editor-container json-schema">
                    <div className="header"> <h3> <Trans id="app.steps.data.jsonSchema" /> </h3> </div>
                    <MonacoEditor
                      language="json"
                      value={monacoData}
                      theme="vs-light"
                      height={635}
                      onChange={onJsonSchemaChange}
                      options={monacoEditorOptions}
                    />
                  </div>
                </TabPane>
              </TabContent>
              <TabContent className="ui-content" activeTab={state.selectedTab}>
                <TabPane tabId="uiSchemaTab">
                  <div className="monaco-editor-container ui-schema">
                    <div className="header"> <h3> <Trans id="app.steps.data.uiSchema" /> </h3> </div>
                    <MonacoEditor
                      language="json"
                      value={monacoUiData}
                      theme="vs-light"
                      height={635}
                      onChange={onUiSchemaChange}
                      options={monacoEditorOptions}
                    />
                  </div>
                </TabPane>
              </TabContent>
            </Grid>
            <Grid item xs={12} md={4} className={`json-schema-preview ${!state.previewOpen && 'hidden'}`}>
              <button type="button" onClick={togglePreviewOpen} className="hide-show-icon">
                { state.previewOpen ? <HidePanel /> : <ShowPanel /> }
              </button>
              <div className="contents">
                <h3> <Trans id="app.steps.data.preview" /> </h3>
                { state.schema && (
                  <Form
                    className="task-form"
                    schema={state.schema}
                    uiSchema={state.uiSchema}
                    disabled
                  >
                    <span />
                  </Form>
                )}
              </div>
            </Grid>
          </Grid>
        </StepList>
      )}
    </Fragment>
  )
}

DataTab.propTypes = {
  isModalOpened: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleSaveInModal: PropTypes.func.isRequired,
  saveStep: PropTypes.func.isRequired,
  saveHandler: PropTypes.func.isRequired,
  workflowId: PropTypes.string.isRequired,
  handleDiscardChanges: PropTypes.func.isRequired,
  loadStepsForWorkflow: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
}

export default DataTab
