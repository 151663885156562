import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const buttonCancel = { float: 'left', background: 'transparent', color: '#246ee3' }

const ModalComponent = (props) => {
  const {
    buttonLabel,
    className,
    modalTitle,
    iconComponent,
    formComponent,
    modalBody,
    actionLabel,
    actionFunction
  } = props

  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  const action = () => {
    toggle()
    actionFunction()
  }

  const renderIcon = () => (
    <span className="icon"> { iconComponent } </span>
  )

  return (
    <div>
      <button className={className} type="button" onClick={toggle}>
        { iconComponent !== null ? renderIcon() : <span />}
        {buttonLabel}
      </button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>
          {modalBody}
          {formComponent != null ? formComponent : null}
          <hr />
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle} style={buttonCancel}>Cancel</Button>
          <Button color="primary" onClick={action}>{actionLabel}</Button>

        </ModalFooter>
      </Modal>
    </div>
  )
}

ModalComponent.defaultProps = {
  iconComponent: null,
  formComponent: null
}
ModalComponent.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  modalBody: PropTypes.string.isRequired,
  actionLabel: PropTypes.string.isRequired,
  actionFunction: PropTypes.func.isRequired,
  iconComponent: PropTypes.object,
  formComponent: PropTypes.object
}

export default ModalComponent
