export const types = {
  LOAD_DETAILS_START: 'task_details/LOAD_DETAILS_START',
  LOAD_DETAILS_SUCCESS: 'task_details/LOAD_DETAILS_SUCCESS',
  LOAD_DETAILS_FAIL: 'task_details/LOAD_DETAILS_FAIL',
  UPDATE_DETAILS_START: 'task_details/UPDATE_DETAILS_START',
  UPDATE_DETAILS_SUCCESS: 'task_details/UPDATE_DETAILS_SUCCESS',
  UPDATE_DETAILS_FAIL: 'task_details/UPDATE_DETAILS_FAIL'
}

export const loadTaskDetails = id => ({
  types: [types.LOAD_DETAILS_START, types.LOAD_DETAILS_SUCCESS, types.LOAD_DETAILS_FAIL],
  promise: client => client.get(`/tasks/${id}`)
})

export const updateTaskDetails = (id, data) => ({
  types: [types.UPDATE_DETAILS_START, types.UPDATE_DETAILS_SUCCESS, types.UPDATE_DETAILS_FAIL],
  promise: client => client.patch(`/tasks/${id}`, {
    data: {
      payload: data.formData,
      status: data.status,
      user_id: data.userId,
      ...data
    }
  }, '/workflow_engine/api/v1')
})
