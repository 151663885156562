import React, { Fragment } from 'react'
import { Grid, Container } from '@material-ui/core'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'
import AppBar from '@material-ui/core/AppBar'
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import PropTypes from 'prop-types'
import EditIcon from '@material-ui/icons/Edit'
import AddGroupIcon from 'images/AddGroupIcon'
import DialogComponent from 'components/Dialog'
import GroupForm from 'components/Groups/GroupForm'
import { t } from 'utils/i18n/translate'

const GroupDetailsHeader = ({
  addGroup,
  updateGroup,
  fetchGroupDetails,
  groupId,
  groupName,
  userEmail,
  isUserGroupAdmin }) => {
  const renderEditModal = () => (
    <DialogComponent
      buttonLabel={t('app.groups.actions.edit')}
      buttonVariant="contained"
      buttonColor="primary"
      modalTitle={t('app.groups.modal.edit.title')}
      actionLabel={t('app.groups.actions.edit')}
      onlyIcon
      iconComponent={EditIcon}
      formComponent={GroupForm}
      actionFunction={updateGroup}
      parentId={groupId}
      isNewObject={false}
      userEmail={userEmail}
      successToast={t('app.groups.notifications.update_success')}
      errorToast={t('app.groups.notifications.update_failure')}
      fullScreen={isMobile}
    />
    )

  const renderAddSubGroupModal = () => (
    <DialogComponent
      buttonLabel={t('app.groups.actions.add_subgroup')}
      buttonVariant="contained"
      buttonColor="secondary"
      modalTitle={t('app.groups.modal.add_subgroup.title')}
      actionLabel={t('app.groups.actions.add_subgroup')}
      iconComponent={AddGroupIcon}
      formComponent={GroupForm}
      userEmail={userEmail}
      actionFunction={addGroup}
      callbackFunction={fetchGroupDetails}
      parentId={groupId}
      onlyIcon
      successToast={t('app.groups.notifications.add_subgroup_success')}
      errorToast={t('app.groups.notifications.add_subgroup_failure')}
      fullScreen={isMobile}
    />
    )

  const editGroup = () => (
    <Fragment>

      <Grid container spacing={isMobile ? 0 : 1}>
        <Grid item lg={6} xs={8}>
          <Link to="/groups">
            <TrendingFlatIcon style={{ transform: 'scaleX(-1)' }} />
          </Link>
          <span style={{ marginLeft: '20px', textTransform: 'uppercase', fontSize: '14px', fontWeight: '700', letterSpacing: '1px' }}>{groupName}</span>
        </Grid>
        <Grid item lg={6} xs={4}>
          { isUserGroupAdmin && (
            <div style={{ float: 'right' }}>
              {renderAddSubGroupModal()}
              {renderEditModal()}
            </div>
            )}
        </Grid>
      </Grid>

    </Fragment>
  )
  return (

    <div className="header-groups-container">
      <AppBar position="static" elevation={0}>
        <Container>
          { editGroup() }
        </Container>
      </AppBar>
    </div>

  )
}


GroupDetailsHeader.defaultProps = {
  fetchGroupDetails: null
}


GroupDetailsHeader.propTypes = {
  addGroup: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
  fetchGroupDetails: PropTypes.func,
  groupId: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  isUserGroupAdmin: PropTypes.bool.isRequired
}


export default GroupDetailsHeader
