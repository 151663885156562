import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Grid, Container } from '@material-ui/core'
import { isMobile } from 'react-device-detect'
import DialogComponent from 'components/Dialog'
import GroupForm from 'components/Groups/GroupForm'
import AddGroupIcon from 'images/AddGroupIcon'
import { t } from 'utils/i18n/translate'

const GroupsHeader = ({ createGroup, userEmail, groups }) => {
  const showCreateButton = groups.length === 1 && groups[0].isOwnGroup


  const renderNewModal = () => (
    <DialogComponent
      buttonLabel={t('app.groups.actions.add')}
      buttonVariant="contained"
      className="btn btn-primary-icon"
      modalTitle={t('app.groups.modal.add.title')}
      actionLabel={t('app.groups.actions.add')}
      iconComponent={AddGroupIcon}
      actionFunction={createGroup}
      formComponent={GroupForm}
      userEmail={userEmail}
      successToast={t('app.groups.notifications.add_success')}
      errorToast={t('app.groups.notifications.add_failure')}
      fullWidth
      fullScreen={isMobile}
      float="right"
    />
  )

  const addNewGroup = () => (
    <Fragment>
      <div className="container-header">
        <Grid container spacing={isMobile ? 0 : 1}>
          { !isMobile
          && (
            <Grid item xs={12} lg={6}>
              <p> {t('app.groups.modal.description')}</p>
            </Grid>
          )}
          {!showCreateButton && (
            <Grid item xs={12} lg={6}>
              <div className="actionButtons">
                {renderNewModal()}
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </Fragment>
  )

  return (
    <div className="header-groups-container">
      <Container>
        { addNewGroup() }
      </Container>
    </div>
  )
}


GroupsHeader.propTypes = {
  createGroup: PropTypes.func.isRequired,
  userEmail: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
}

export default GroupsHeader
