import React from 'react'
import PropTypes from 'prop-types'

const ExportIcon = ({ className, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" id="devolute_logo" width="86.369" height="16.001" viewBox="0 0 86.369 16.001">
    <g id="Group_2040">
      <g id="devolute_merch__signa_portachaves_SMK5500" transform="translate(20.123 .014)">
        <g id="Layer_1-2">
          <path id="Path_1576" d="M147.082 7.154V1.885A1.764 1.764 0 0 1 148.5.129a1.2 1.2 0 0 1 .32-.029.153.153 0 0 1 .174.173v15.749a.031.031 0 0 1-.029.029h-1.9V15a5.015 5.015 0 1 1-3.621-8.9 4.791 4.791 0 0 1 3.638 1.054zm-3.143 7.025a3.132 3.132 0 1 0-3.129-3.239v.13a3.124 3.124 0 0 0 3.128 3.109z" fill={color} transform="translate(-138.897 -.1)" />
          <path id="Path_1577" d="M217.091 47.784a2.745 2.745 0 0 0 .579.826 3.152 3.152 0 0 0 4.447.058 3.4 3.4 0 0 0 .521-.681l1.478.869c.13.072.145.116.058.246a4.9 4.9 0 0 1-3.245 2.245A4.666 4.666 0 0 1 217.8 51a4.895 4.895 0 0 1-2.883-3.911 4.993 4.993 0 0 1 2.39-5.07 4.663 4.663 0 0 1 2.636-.637 4.955 4.955 0 0 1 4.592 4.041 9.188 9.188 0 0 1 .072 1.55.155.155 0 0 1-.13.159h-.015c-.449.043-.9.087-1.347.116l-2.491.2-2.173.188-1.26.1a.223.223 0 0 1-.1.048zm2.68-4.5a3.554 3.554 0 0 0-.956.145 2.664 2.664 0 0 0-1.9 2c-.1.435-.13.493.029.464.724-.058 1.449-.116 2.173-.188l3.491-.3a2.864 2.864 0 0 0-.435-.956 2.917 2.917 0 0 0-2.4-1.159z" fill={color} className={className} transform="translate(-203.97 -35.499)" />
          <path id="Path_1578" d="M284.818 47.165c.043-.029.043-.072.058-.116.782-2.1 1.564-4.2 2.332-6.287a.253.253 0 0 1 .246-.174h1.593c.217 0 .261.072.188.261-1.2 3.245-2.4 6.475-3.607 9.72a.2.2 0 0 1-.217.159h-1.159a.219.219 0 0 1-.232-.159c-.811-2.173-1.608-4.346-2.419-6.518-.391-1.057-.782-2.129-1.173-3.187a.422.422 0 0 1-.029-.13c.014-.116.1-.145.2-.145h1.622a.194.194 0 0 1 .217.159c.579 1.579 1.159 3.172 1.753 4.751z" fill={color} className={className} transform="translate(-260.093 -34.822)" />
          <path id="Path_1579" d="M539.909 43.692a4.679 4.679 0 0 1 4.621 3.868 5.46 5.46 0 0 1 .043 1.535c-.014.116-.087.145-.188.145l-2.158.014-4.2.029c-.521 0-1.043.014-1.565.014-.087 0-.1.014-.087.1a3.78 3.78 0 0 0 6.011 2.187 3.434 3.434 0 0 0 .71-.666c.145-.174.159-.188.333-.087s.333.232.507.348c.232.159.159.217-.145.579a4.869 4.869 0 0 1-8.488-2.376 4.894 4.894 0 0 1 3.042-5.432 4.383 4.383 0 0 1 1.564-.258zm2.709 4.519v-.029h.84c.087 0 .1-.029.1-.1a3.556 3.556 0 0 0-.232-1.1 3.646 3.646 0 0 0-7 1.159c-.015.1.029.1.116.1 1.086-.014 2.173-.014 3.259-.029z" fill={color} className={className} transform="translate(-478.363 -37.476)" />
          <path id="Path_1580" d="M349.445 48.492a4.875 4.875 0 1 1-4.751-4.9 4.9 4.9 0 0 1 4.751 4.9zm-4.867-3.81a3.81 3.81 0 1 0 3.8 3.853 3.837 3.837 0 0 0-3.8-3.853z" fill={color} className={className} transform="translate(-310.884 -37.395)" />
          <path id="Path_1581" d="M443.436 51.945a3.581 3.581 0 0 1-.739.666 3.834 3.834 0 0 1-5.316-1 3.476 3.476 0 0 1-.449-.9 4.058 4.058 0 0 1-.217-1.318v-2.112c0-1.26 0-2.52-.015-3.766a.014.014 0 0 1 .015-.014h1.043c0 1.927 0 3.853-.015 5.765a2.966 2.966 0 0 0 .377 1.608 2.729 2.729 0 0 0 2.593 1.405.736.736 0 0 0 .246-.043c2.1-.7 2.434-1.753 2.463-2.912.043-1.854 0-3.723.014-5.577 0-.217.029-.246.246-.246h.579c.188 0 .232.043.232.217v9.56h-1.057v-1.333z" fill={color} className={className} transform="translate(-393.956 -37.313)" />
          <path id="Path_1582" d="M501.378 30.715v2.955a1.506 1.506 0 0 0 .261.927A1.184 1.184 0 0 0 503.1 35a1.46 1.46 0 0 0 .333-.174c.13-.087.188-.072.275.043.13.188.275.362.406.55.087.116.072.174-.043.261a2.407 2.407 0 0 1-2.115.391 2.259 2.259 0 0 1-1.579-1.8 3.372 3.372 0 0 1-.072-.768V27.63c0-.116-.029-.145-.145-.145h-1.36v-1.058h1.506c0-.971 0-1.941-.014-2.912a.907.907 0 0 1 1.057.724.954.954 0 0 1 .015.2v1.984c.565-.014 1.13 0 1.695 0V27.5h-1.68z" fill={color} className={className} transform="translate(-447.205 -20.211)" />
          <path id="Path_1583" d="M417.257 29.83v6.345H416.2V23.5h1.057z" fill={color} className={className} transform="translate(-376.361 -20.21)" />
        </g>
      </g>
      <path id="Path_1586" d="M99.253 9.247a28.537 28.537 0 0 0-1.115-3.056c-.217-.507-.42-.971-.637-1.391.681.058 1.333.145 1.956.246v.232c-.014.637-.043 1.956-.159 3.476l-.045.493z" className="cls-1" transform="translate(-83.374 -4.105)" />
      <path id="Path_1587" d="M92.641 1.647l.42.058c0 .232-.014.521-.029.855-.058-.145-.116-.29-.188-.435s-.13-.319-.2-.478M90.7.3a21.812 21.812 0 0 1 1.086 2.274 23.856 23.856 0 0 1 1.347 3.94 8.613 8.613 0 0 1 .2 2.984 7.019 7.019 0 0 1-.159 1.086 26.349 26.349 0 0 0 .884-5.635c.116-1.55.159-2.9.159-3.52V.908a.231.231 0 0 0-.174-.217A26.018 26.018 0 0 0 90.7.3z" fill={color} className={className} transform="translate(-77.559 -.257)" />
      <path id="Path_1589" d="M11.368 1.152c.348 0 .724 0 1.1.014.188.36.362.734.521 1.094A23.825 23.825 0 0 1 14.5 6.478a6.954 6.954 0 0 1 .174 2.562c-.043.3-.1.59-.174.893a6.153 6.153 0 0 1-.869 1.843l-.2.3a6.807 6.807 0 0 1-9.161 1.686A6.612 6.612 0 0 1 2.2 4.549V4.52a6.139 6.139 0 0 1 2.1-2.073 7.424 7.424 0 0 1 2.433-.907 23.009 23.009 0 0 1 3.967-.374c.217-.014.435-.014.666-.014m0-1.152c-.232 0-.464 0-.7.014A23.812 23.812 0 0 0 6.5.417a8.621 8.621 0 0 0-2.81 1.051A7.175 7.175 0 0 0 1.228 3.93a7.772 7.772 0 0 0 2.361 10.782c.014.014.029.014.043.029a8.009 8.009 0 0 0 4.3 1.252 7.886 7.886 0 0 0 6.634-3.57A7.142 7.142 0 0 0 15.6 10.25a6.617 6.617 0 0 0 .217-1.065 8.5 8.5 0 0 0-.188-2.965 26.73 26.73 0 0 0-1.567-4.42 19.279 19.279 0 0 0-.869-1.728l-.014-.029C12.57.014 11.962 0 11.368 0z" className={className} fill={color} transform="translate(.02)" />
    </g>
  </svg>

)

ExportIcon.defaultProps = {
  color: '#8b8f97',
  className: 'task-export-icon'
}

ExportIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string
}

export default ExportIcon
