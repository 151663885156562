export const getPropertiesFromBase64 = (data) => {
  const fileName = data.toString()
    .match(/name=([^"]*);/g)[0]
    .match(/=\s*(.*)$/)[1]
    .replace(';', '')
  const type = data.toString()
    .match(/data:([^;]*)/)[1]
  const b64Data = data.split(',')[1]
  const byteString = atob(b64Data)
  return { fileName, type, byteString, b64Data }
}

export default getPropertiesFromBase64
