import React, { PureComponent } from 'react'
import { Card, Grid, LinearProgress } from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import Board from 'react-trello'
import { Title } from 'react-admin'
import PieChart from './PieChartDashboard'
import PieChartDashboardSchedule from './PieChartDashboardSchedule'

class Dashboard extends PureComponent {
  state = {
    tasks: [
      { id: 'id_1', status: 'To do', laneId: 'To do', title: 'Buy some food', description: 'Buy food for dinner', label: '5 hours', draggable: true, workflow: 'Workflow Test', created_at: '2020-07-24T09:25:43.511Z' },
      { id: 'id_2', status: 'To do', laneId: 'To do', title: 'Buy drinks', description: 'Buy drinks for happy hour', label: '6 hours', draggable: true, workflow: 'Workflow Test', created_at: '2020-07-24T09:25:43.511Z' },
      { id: 'id_3', status: 'To do', laneId: 'To do', title: 'Pay the bills', description: 'Pay open bills', label: '1 hours', draggable: true, workflow: 'Workflow Test', created_at: '2020-07-24T09:25:43.511Z' },
      { id: 'id_4', status: 'Doing', laneId: 'To do', title: 'Check the Weather', description: 'Is it raining or sunny?', label: '30 mins', draggable: true, workflow: 'Workflow Test', created_at: '2020-07-24T09:25:43.511Z' },
      { id: 'id_5', status: 'Doing', laneId: 'To do', title: 'Buy winter clothes', description: 'Buy some clothes for when the cold arrives.', label: '30 mins', draggable: true, workflow: 'Workflow Test', created_at: '2020-07-24T09:25:43.511Z' },
      { id: 'id_6', status: 'Testing', laneId: 'To do', title: 'Walk the dog', description: 'Walk the dog for at least 1 hour.', label: '2 hours ago', draggable: true, workflow: 'Workflow Test', created_at: '2020-07-24T09:25:43.511Z' },
      { id: 'id_7', status: 'Done', laneId: 'To do', title: 'Plan the week', description: 'Write the schedule for this week.', label: '2 hours ago', draggable: true, workflow: 'Workflow Test', created_at: '2020-07-24T09:25:43.511Z' }
    ]
  }

  handleDragEnd = (id, sourceLaneId, targetLaneId) => {
    const { tasks } = this.state
    const newTasksArray = [...tasks]
    const movedTaskIndex = tasks.findIndex(task => task.id === id)
    newTasksArray[movedTaskIndex] = { ...newTasksArray[movedTaskIndex], status: targetLaneId }

    this.setState({
      tasks: newTasksArray
    })
    return false
  }

  render() {
    const { tasks } = this.state
    const data = {
      lanes: [
        {
          id: 'To do',
          title: 'To do',
          label: '3/7',
          cards: tasks.filter(task => task.status === 'To do')
        },
        {
          id: 'Doing',
          title: 'Doing',
          label: '2/7',
          cards: tasks.filter(task => task.status === 'Doing')
        },
        {
          id: 'Testing',
          title: 'Testing',
          label: '1/7',
          cards: tasks.filter(task => task.status === 'Testing')
        },
        {
          id: 'Done',
          title: 'Done',
          label: '1/7',
          cards: tasks.filter(task => task.status === 'Done')
        }
      ]
    }

    const progress = tasks.filter(task => task.status === 'Done').length / tasks.length * 100

    return (
      <Grid container spacing={5} alignItems="stretch">
        <Grid item xs={12} lg={12}>
          <Card>
            <Title title="Something" />
            <CardContent>
              <h2>Progress</h2>
              <LinearProgress variant="determinate" value={progress} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Card>
            <Title title="Tasks" />
            <CardContent>
              <h2>Tasks</h2>
              <Board hideCardDeleteIcon data={data} handleDragEnd={this.handleDragEnd} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <Title title="Status" />
            <CardContent>
              <h2>Status</h2>
              <PieChart tasks={tasks} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <Title title="Schedule" />
            <CardContent>
              <h2>Schedule</h2>
              <PieChartDashboardSchedule />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }
}

export default Dashboard
