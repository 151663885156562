import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import FoldersContainer from 'components/Folders/FoldersContainer'
import { t } from 'utils/i18n/translate'
import { setTitle } from 'redux/actions/system'

export class FoldersPage extends PureComponent {
  componentDidMount() {
    const { setPageTitle } = this.props
    setPageTitle(t('app.pages.titles.folder'))
  }

  render() {
    const { folders } = this.props

    return (
      <FoldersContainer filteredFolders={folders} />
    )
  }
}

const mapStateToProps = state => ({
  folders: state.magazine.folders.data
})

const mapDispatchToProps = dispatch => ({
  setPageTitle: title => (dispatch(setTitle(title))),
})

FoldersPage.propTypes = {
  folders: PropTypes.array.isRequired,
  setPageTitle: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(FoldersPage)
