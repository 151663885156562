import React from 'react'
import { Admin, Resource } from 'react-admin'
import fakeDataProvider from 'ra-data-fakerest'
import DefaultLayout from 'layouts/DefaultLayout'
import { createBrowserHistory } from 'history'
import { ResourceList } from 'admin/components/ResourceList'
import Dashboard from './Dashboard'

const history = createBrowserHistory({ basename: '/order_management' })
const ordersData = require('./orders_data.json')

const options = {
  attributes: ['Amount', 'Item', 'Customer', 'Date']
}

const dataProvider = fakeDataProvider({
  orders: ordersData
})

const OrderManagementPage = () => (
  <DefaultLayout>
    <div className="order-management react-admin-layout">
      <Admin
        dataProvider={dataProvider}
        history={history}
        dashboard={Dashboard}
      >
        <Resource name="orders" list={ResourceList} options={options} />
      </Admin>
    </div>
  </DefaultLayout>
)

export default OrderManagementPage
