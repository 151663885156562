import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
  CarouselProvider,
  Slider,
  ButtonBack,
  ButtonNext,
  Dot
} from 'pure-react-carousel'
import Trans from 'components/Trans'
import { t } from 'utils/i18n/translate'
import Article from './Article'

class Articles extends PureComponent {
  state = {
    swiped: false,
  }

  goToTop = () => {
    const articlesContainer = document.getElementById('magazine-articles')
    const offset = articlesContainer.offsetTop - 80
    if (window.pageYOffset > offset) {
      window.scrollTo(0, offset)
    }
  }

  swipe = () => {
    setTimeout(() => (this.setState({
      swiped: new Date(),
    })), 500)
    this.goToTop()
  }

  render() {
    const { articles } = this.props
    const { swiped } = this.state
    const slides = articles.map((article, i) => (
      <Article
        index={i}
        key={article.id}
        article={article}
        swiped={swiped}
        goToTop={this.goToTop}
        onSwipe={this.swipe}
      />
    ))

    return (
      <CarouselProvider
        naturalSlideWidth={30}
        naturalSlideHeight={8}
        totalSlides={articles.length}
        visibleSlides={1}
        step={1}
        infinite
        className="articles"
        id="magazine-articles"
      >
        <Slider>
          {slides}
        </Slider>
        <div className="actions article-actions">
          <ButtonBack
            onClick={this.swipe}
            title={t('app.accessibility.back')}
            aria-label={t('app.accessibility.back')}
          >
            <Trans id="app.magazine.slider.back" />
          </ButtonBack>
          <div className="dots">
            {articles.map((slide, i) => (
              <Dot
                key={slide.id}
                title={`${t('app.accessibility.article')} ${i + 1}`}
                aria-label={`${t('app.accessibility.article')} ${i + 1}`}
                slide={i}
                onClick={this.swipe}
              />
            ))}
          </div>
          <ButtonNext
            onClick={this.swipe}
            title={t('app.accessibility.next')}
            aria-label={t('app.accessibility.next')}
          >
            <Trans id="app.magazine.slider.foward" />
          </ButtonNext>
        </div>
      </CarouselProvider>
    )
  }
}

Articles.defaultProps = {
  articles: []
}

Articles.propTypes = {
  articles: PropTypes.array
}

export default Articles
