import { connect } from 'react-redux'
import { loadWorfklowList } from 'redux/actions/workflows'
import { loadToolList } from 'redux/actions/tools'
import { loadTaskList } from 'redux/actions/tasks'
import { loadTasksForWorkflow } from 'redux/actions/workflowDetails'
import { dashboardLoaded, setFilter } from 'redux/actions/system'
import Filters from '.'

const mapStateToProps = (state, ownProps) => ({
  isOpened: state.system.filters[ownProps.area].opened,
  value: state.system.filters[ownProps.area].value,
  currentTab: state.system.currentTab
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadWorkflows: params => dispatch(loadWorfklowList(params)),
  loadTools: params => dispatch(loadToolList(params)),
  loadTasks: params => (dispatch(loadTaskList(params))),
  loadWorkflowTasks: params => (dispatch(loadTasksForWorkflow(ownProps.workflowId, params))),
  setHeight: () => dispatch(dashboardLoaded()),
  setFilter: (area, value) => dispatch(setFilter(area, value))
})

export default connect(mapStateToProps, mapDispatchToProps)(Filters)
