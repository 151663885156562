import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Container } from 'reactstrap'
import ReactRouterPropTypes from 'react-router-prop-types'
import { loadMagazineFolders, loadCurrentFolder } from 'redux/actions/magazine'
import { loadFavoriteList } from 'redux/actions/favorites'
import PropTypes from 'prop-types'
import Trans from 'components/Trans'
import Spinner from 'components/Spinner'
import DefaultLayout from 'layouts/DefaultLayout'
import FoldersFilters from 'components/Folders/FoldersFilters'
import FoldersPageContents from '.'
import FoldersPath from './FoldersPath'

export class FoldersPageContainer extends PureComponent {
  state = {
    activeFilter: 'All',
    allFolders: []
  }

  componentDidMount() {
    this.setup()
  }

  componentWillReceiveProps(nextProps) {
    const { match } = this.props
    if (nextProps.match.params.id !== match.params.id) {
      this.setup(nextProps)
    }
  }

  setup = (nextProps) => {
    const props = nextProps || this.props
    const { loadFolders, match, loadCurrentFolder: loadFolder, loadFavorites } = props
    const { id } = match.params

    loadFavorites()
    loadFolder(id)
    loadFolders({ node_id: id }).then((res) => {
      this.setState({
        allFolders: res.data
      })
    })
  }

  setFilteredFolders = (filter) => {
    const { loadFolders, match } = this.props
    const { id } = match.params
    loadFolders({ node_id: id, scope: filter })
    this.setState({
      activeFilter: filter
    })
  }

  render() {
    const { activeFilter, allFolders } = this.state
    const { isLoading, isLoaded, node, status } = this.props
    const title = isLoaded && node ? node.title : ''
    const path = node.parent ? <Container> <FoldersPath folder={node} /> </Container> : ''

    return (
      <DefaultLayout status={status}>
        { (isLoading || !isLoaded) ? (
          <Spinner size={40} top={0} align="center" />
        ) : (
          <div className="folders-container folder-page">
            <div className="header">
              <Container>
                <h2> <Trans id="app.magazine.folders.title" /> </h2>
                <h3> {title} </h3>
                <FoldersFilters
                  folders={allFolders}
                  setFilteredFolders={this.setFilteredFolders}
                  activeFilter={activeFilter}
                />
              </Container>
            </div>
            {path}
            <Container>
              <FoldersPageContents />
            </Container>
          </div>
        )}
      </DefaultLayout>
    )
  }
}

const mapStateToProps = state => ({
  isLoaded: state.magazine.currentFolder.isLoaded,
  isLoading: state.magazine.currentFolder.isLoading,
  node: state.magazine.currentFolder.data,
  folders: state.magazine.folders.data,
  status: state.magazine.currentFolder.isRequired
})

const mapDispatchToProps = dispatch => ({
  loadFolders: params => dispatch(loadMagazineFolders(params)),
  loadCurrentFolder: id => dispatch(loadCurrentFolder(id)),
  loadFavorites: () => dispatch(loadFavoriteList())
})

FoldersPageContainer.defaultProps = {
  isLoaded: false,
  isLoading: false,
  node: {}
}

FoldersPageContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  isLoaded: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadFolders: PropTypes.func.isRequired,
  node: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  loadCurrentFolder: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  loadFavorites: PropTypes.func.isRequired,
  status: PropTypes.number.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(FoldersPageContainer)
