import React from 'react'

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="dashboard-icon-notifications">
      <g id="noun_notification_1031848" transform="translate(-17 -11.866)">
        <path id="Path_4282" d="M40.159 31.663l-3.931-15.207a5.965 5.965 0 0 0-3.689-3.956 9.616 9.616 0 0 0-6.646-.077 6 6 0 0 0-3.914 4.017l-3.937 15.223c-.064.249-.223 1.032-.223 1.032h7.16a3.85 3.85 0 0 0 2.437 2.9 4.1 4.1 0 0 0 3.845-.482 3.815 3.815 0 0 0 1.539-2.418h7.636s-.213-.784-.277-1.032zm-9.933 2.12a2.284 2.284 0 0 1-1.337.426 2.247 2.247 0 0 1-2.078-1.331h4.156a2.162 2.162 0 0 1-.741.905z" data-name="Path 4282" />
      </g>
    </g>
  </svg>
)
