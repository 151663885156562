import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import TaskStatus from './TaskStatus'
import TaskInfo from './TaskInfo'
import TaskActions from './TaskActions'


const TaskHeaderMobileView = (props) => {
  const { task, favoriteClassNames, updateTaskDetails, toggleFavorite, isMobileOnly } = props
  const isNotification = task.taskType.includes('notification')

  return (
    <Fragment>
      <div className="task-header-container d-flex justify-content-between">
        <div className="header-left-content">
          <div className="task-details-section d-flex flex-grow-1 justify-content-between align-items-center">
            <TaskInfo
              userName={task.user ? task.user.name : ''}
              workflowName={task.workflow.name}
              taskName={task.name}
              updatedAt={task.updatedAt}
            />
          </div>
          <div className="task-details-section d-flex align-items-center">
            <TaskStatus status={task.status} />
          </div>
        </div>
        { !isNotification && (
          <TaskActions
            task={task}
            favoriteClassNames={favoriteClassNames}
            updateTaskDetails={updateTaskDetails}
            toggleFavorite={toggleFavorite}
            isMobileOnly={isMobileOnly}
          />
        )}
      </div>
    </Fragment>
  )
}

const TaskHeaderStandartView = (props) => {
  const { task, favoriteClassNames, updateTaskDetails, toggleFavorite } = props
  const isNotification = task.taskType.includes('notification')

  return (
    <Fragment>
      <div className="task-details-section d-flex align-items-center">
        <TaskStatus
          status={task.status}
        />
      </div>

      <div className="task-details-section d-flex flex-grow-1 justify-content-between align-items-center">
        <TaskInfo
          userName={task.user ? task.user.name : ''}
          workflowName={task.workflow.name}
          taskName={task.name}
          updatedAt={task.updatedAt}
        />
        { !isNotification && (
          <div className="task-actions-container ml-auto d-md-flex">
            <TaskActions
              task={task}
              favoriteClassNames={favoriteClassNames}
              updateTaskDetails={updateTaskDetails}
              toggleFavorite={toggleFavorite}
            />
          </div>
        )}
      </div>
    </Fragment>
  )
}

const TaskHeader = (props) => {
  const { isMobileOnly, task } = props

  return (
    <header className={`task-header ${task.status} d-flex`}>
      { isMobileOnly ? TaskHeaderMobileView(props) : TaskHeaderStandartView(props) }
    </header>
  )
}

TaskHeader.propTypes = {
  task: PropTypes.object.isRequired,
  isMobileOnly: PropTypes.bool.isRequired,
}

TaskHeaderMobileView.propTypes = {
  ...TaskHeader.propTypes,
  updateTaskDetails: PropTypes.func.isRequired,
  toggleFavorite: PropTypes.func.isRequired,
}

TaskHeaderStandartView.propTypes = TaskHeaderMobileView.propTypes

export default TaskHeader
