import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { searchWorkflows } from 'redux/actions/search'
import { setTitle } from 'redux/actions/system'
import { getSearchString } from 'utils/searchString'
import DefaultLayout from 'layouts/DefaultLayout'
import Spinner from 'components/Spinner'
import SearchResults from 'components/SearchResults'
import { getFilteredResults } from 'redux/selectors/searchResults'
import SearchFilterTabsContainer from 'components/SearchFilterTabs/container'
import { t } from 'utils/i18n/translate'
import BackButton from 'components/BackButton'

export class SearchResultsPageContainer extends PureComponent {
  componentDidMount() {
    const { loadSearchResults, setPageTitle } = this.props

    setPageTitle(t('app.pages.titles.search_results'))
    loadSearchResults()
  }

  componentWillReceiveProps(nextProps) {
    const { location: newLocation } = nextProps
    const { location: oldLocation } = this.props
    const { search: newSearch } = newLocation
    const { search: oldSearch } = oldLocation

    if (newSearch !== oldSearch) {
      const { loadSearchResults } = this.props

      loadSearchResults()
    }
  }

  render() {
    const { results, isLoading, isLoaded } = this.props

    return (
      <DefaultLayout>
        <div className="container pt-3">
          <BackButton />
        </div>

        <SearchFilterTabsContainer />

        <div className="container">
          {(isLoading || !isLoaded) && <Spinner />}
          {(!isLoading && isLoaded) && <SearchResults results={results} />}
        </div>
      </DefaultLayout>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.search.isLoading,
  isLoaded: state.search.isLoaded,
  results: getFilteredResults(state)
})

const mapDispatchToProps = dispatch => ({
  loadSearchResults: () => dispatch(searchWorkflows(getSearchString())),
  setPageTitle: title => (dispatch(setTitle(title)))
})

SearchResultsPageContainer.defaultProps = {
  isLoading: true,
  isLoaded: false
}

SearchResultsPageContainer.propTypes = {
  loadSearchResults: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool,
  results: PropTypes.array.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsPageContainer)
