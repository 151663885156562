export const types = {
  LOAD_DETAILS_START: 'workflow_details/LOAD_DETAILS_START',
  LOAD_DETAILS_SUCCESS: 'workflow_details/LOAD_DETAILS_SUCCESS',
  LOAD_DETAILS_FAIL: 'workflow_details/LOAD_DETAILS_FAIL',

  EDIT_START: 'workflow_details/EDIT_START',
  EDIT_SUCCESS: 'workflow_details/EDIT_SUCCESS',
  EDIT_FAIL: 'workflow_details/EDIT_FAIL',

  UPDATE_START: 'workflow_details/UPDATE_START',
  UPDATE_SUCCESS: 'workflow_details/UPDATE_SUCCESS',
  UPDATE_FAIL: 'workflow_details/UPDATE_FAIL',

  LOAD_TASKS_START: 'workflow_details/LOAD_TASKS_START',
  LOAD_TASKS_SUCCESS: 'workflow_details/LOAD_TASKS_SUCCESS',
  LOAD_TASKS_FAIL: 'workflow_details/LOAD_TASKS_FAIL',

  LOAD_STEPS_START: 'workflow_details/LOAD_STEPS_START',
  LOAD_STEPS_SUCCESS: 'workflow_details/LOAD_STEPS_SUCCESS',
  LOAD_STEPS_FAIL: 'workflow_details/LOAD_STEPS_FAIL',

  ADD_TASK_TO_CURRENT_WORKFLOW: 'ADD_TASK_TO_CURRENT_WORKFLOW'
}

export const loadWorkflowDetails = id => ({
  types: [types.LOAD_DETAILS_START, types.LOAD_DETAILS_SUCCESS, types.LOAD_DETAILS_FAIL],
  promise: client => client.get(`/workflows/${id}`)
})

export const editWorkflowDetail = id => ({
  types: [types.EDIT_START, types.EDIT_SUCCESS, types.EDIT_FAIL],
  promise: client => client.get(`/workflows/${id}/edit`)
})

export const updateWorkflowDetail = (id, data) => ({
  types: [types.UPDATE_START, types.UPDATE_SUCCESS, types.UPDATE_FAIL],
  promise: client => client.patch(`/workflows/${id}`, { data: { ...data } })
})

export const loadTasksForWorkflow = (id, params = {}) => ({
  types: [types.LOAD_TASKS_START, types.LOAD_TASKS_SUCCESS, types.LOAD_TASKS_FAIL],
  promise: client => client.get(`/workflows/${id}/tasks`, { params })
})

export const loadStepsForWorkflow = id => ({
  types: [types.LOAD_STEPS_START, types.LOAD_STEPS_SUCCESS, types.LOAD_STEPS_FAIL],
  promise: client => client.get(`/workflows/${id}/steps`)
})

export const addTaskToCurrentWorkflow = task => (
  {
    type: types.ADD_TASK_TO_CURRENT_WORKFLOW,
    task
  }
)
