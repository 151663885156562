import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'
import { timeAgo, isOlderThan24Hours } from 'utils/time'
import Trans from 'components/Trans'
import StarOutlineIcon from 'images/star-outline'
import StarSolidIcon from 'images/star-solid'
import Truncate from 'react-truncate'
import NotificationIcon from 'images/ringtone-on'
import GroupIcon from 'images/GroupIcon'
import { isMobile } from 'react-device-detect'
import Url from 'url-parse'
import qs from 'querystringify'
import { isEmpty } from 'utils/objects'
import { Link } from 'react-router-dom'
import { t } from 'utils/i18n/translate'
import KebabDropdown from './KebabDropdown'
import ExpandedTaskContainer from './ExpandedTask'

const Task = ({
  id,
  name,
  updatedAt,
  workflow,
  user,
  metadata,
  justIn,
  favorite,
  status,
  taskType,
  group,
  handleMakeFavorite,
  handleRemoveFavorite,
  updateTask,
  currentUserId,
  canBePicked,
  canBeDelegated,
  isExpanded,
  incrementTasksCount: incrementTasksCountRedux,
  decreaseTasksCount: decreaseTasksCountRedux,
}) => {
  const isNotification = (taskType || '').includes('notification')
  const taskClasses = classNames('task', { group: !user }, { notification: isNotification })
  const innerClasses = classNames('d-flex', 'task-inner', { 'just-in': justIn }, { mobile: isMobile })
  const actionsContainerClasses = classNames('actions-container', 'd-flex', 'flex-column', 'align-items-center')
  const taskInfoClasses = classNames('task-info', { group: !user })
  const taskIconClasses = classNames('task-icon', { 'group-icon': !user }, { 'icon-favorite': user })

  const toggleFavorite = (event) => {
    // Avoid open/close the tile when add/remove favorite
    event.preventDefault()
    event.stopPropagation()

    if (favorite) {
      handleRemoveFavorite(favorite.id)
    } else {
      handleMakeFavorite()
    }
  }

  const favoriteClasses = () => {
    let favClassNames = 'favorite'

    if (favorite) {
      favClassNames += ' active'
    }

    return favClassNames
  }

  const taskDetailsClasses = () => {
    let taskDetailsClassNames = 'task-details'

    if (!user && !isNotification) {
      taskDetailsClassNames += ' disabled-link'
    }

    return taskDetailsClassNames
  }

  const delegateTask = () => {
    updateTask({ userId: null, delegate: true }).then(() => decreaseTasksCountRedux(workflow.id))
  }

  const pickTask = () => {
    updateTask({ userId: currentUserId, pick: true })
      .then(() => incrementTasksCountRedux(workflow.id))
  }

  const handleTime = (timestamp) => {
    if (isOlderThan24Hours(timestamp)) {
      return moment(timestamp).format('DD.MM.YYYY')
    }

    return timeAgo(timestamp)
  }

  const urlWithtaskId = () => {
    const url = new Url(metadata.url)
    url.set('query', { ...qs.parse(url.query), _smart_task_id: id })
    return url
  }

  const ownerName = user ? user.name : group.name
  const kebab = (
    <KebabDropdown
      delegateTask={delegateTask}
      pickTask={pickTask}
      isAssignedToUser={!!user}
      canBePicked={canBePicked}
      canBeDelegated={canBeDelegated}
      id={id}
      metadata={metadata}
      externalUrl={urlWithtaskId()}
    />
  )

  const accessibilityTagsTextForFavorite = favorite ? t('app.accessibility.unmark_favorite') : t('app.accessibility.mark_as_favorite')
  const accessibilityTagsText = user ? accessibilityTagsTextForFavorite : t('app.accessibility.group_task')

  const taskIcon = (
    <div className={taskIconClasses} aria-label={accessibilityTagsText} title={accessibilityTagsText} tabIndex="0" role="button">
      { user ? (
        <div className={favoriteClasses()} role="presentation" onClick={toggleFavorite}>
          { favorite ? <StarSolidIcon /> : <StarOutlineIcon />}
        </div>
      )
        : <GroupIcon />
      }
    </div>
  )

  const taskContent = (
    <span className="task-detail-link">
      <h4 className="task-name">
        {
          isExpanded
            ? <span>{name}</span>
            : (
              <Truncate lines={2} width={250}>
                {name}
              </Truncate>
            )
        }
      </h4>
      <h5 className="task-process-name">{workflow.name}</h5>
      {
        (!isExpanded || isMobile) && (
          <div className={taskInfoClasses}>
            { user
              ? (
                <Trans
                  id="app.task.from_user_time_ago"
                  name={user.name}
                  time_ago={handleTime(updatedAt)}
                />
              )
              : (
                [`${group.name}, `,
                  <Trans
                    key={id}
                    id="app.task.time_ago"
                    time_ago={handleTime(updatedAt)}
                  />
                ]
              )
            }
          </div>
        )
      }
    </span>
  )

  const bellIcon = <div className="bell-icon"> <NotificationIcon /> </div>
  const taskComponent = (isExpanded && !isMobile) ? <ExpandedTaskContainer name={ownerName} status={status} taskType={taskType} updatedAt={updatedAt} kebab={kebab} /> : ''
  const isRedirectTask = !isEmpty(metadata.url)
  const taskUrl = isRedirectTask ? urlWithtaskId() : `/tasks/${id}`

  return (
    <li title={name} aria-label={name} className={taskClasses}>
      <div className="container">
        <span className={innerClasses}>
          {(isMobile || isExpanded) && (
            <div className={actionsContainerClasses}>
              {isNotification ? bellIcon : taskIcon}
            </div>
          )}
          {
            isRedirectTask
              ? (
                <a className={taskDetailsClasses()} href={taskUrl} target="_blank" rel="noreferrer noopener">
                  {taskContent }
                </a>
              ) : <Link className={taskDetailsClasses()} to={taskUrl}> { taskContent } </Link>
          }
        </span>
        {
          !isExpanded && isNotification && bellIcon
        }
        {
          !isExpanded && !isNotification && (
            <div className={actionsContainerClasses}>
              {kebab}
              {!isMobile && taskIcon}
            </div>
          )
        }
        {taskComponent}
      </div>
    </li>
  )
}

Task.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  currentUserId: PropTypes.string.isRequired,
  incrementTasksCount: PropTypes.func.isRequired,
  decreaseTasksCount: PropTypes.func.isRequired,
  workflow: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired,
  user: PropTypes.object,
  metadata: PropTypes.object.isRequired,
  justIn: PropTypes.bool,
  favorite: PropTypes.object,
  group: PropTypes.object,
  handleMakeFavorite: PropTypes.func.isRequired,
  handleRemoveFavorite: PropTypes.func.isRequired,
  updateTask: PropTypes.func.isRequired,
  taskType: PropTypes.string,
  isExpanded: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  canBePicked: PropTypes.bool.isRequired,
  canBeDelegated: PropTypes.bool.isRequired
}

Task.defaultProps = {
  justIn: false,
  favorite: null,
  group: null,
  user: null,
  taskType: null
}

export default Task
