import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadToolList } from 'redux/actions/tools'
import { dashboardLoaded, setTab } from 'redux/actions/system'
import PropTypes from 'prop-types'
import Spinner from 'components/Spinner'
import Tools from '.'

class ToolsContainer extends Component {
  componentDidMount() {
    const { loadTools, setHeight, filterValue, setCurrentTab } = this.props

    setCurrentTab('dashboard')
    loadTools({ scope: filterValue }).then(setHeight())
  }

  componentDidUpdate() {
    const { setHeight } = this.props

    setHeight()
  }

  render() {
    const { tools, isLoading, isLoaded, openToolId, layoutCols, isGridView } = this.props

    if (isLoading || !isLoaded) {
      return <Spinner top={50} />
    }

    return (
      <Tools
        tools={tools}
        openToolId={openToolId}
        isGridView={isGridView}
        layoutCols={layoutCols}
      />
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.tools.isLoading,
  isLoaded: state.tools.isLoaded,
  tools: state.tools.data,
  openToolId: state.openTool.id,
  filterValue: state.system.filters.dashboard.value,
  isGridView: state.system.isGridView,
  layoutCols: state.system.layoutCols
})

const mapDispatchToProps = dispatch => ({
  loadTools: params => dispatch(loadToolList(params)),
  setHeight: () => dispatch(dashboardLoaded()),
  setCurrentTab: tab => dispatch(setTab(tab))
})

ToolsContainer.defaultProps = {
  isLoading: true,
  isLoaded: false,
  openToolId: ''
}

ToolsContainer.propTypes = {
  loadTools: PropTypes.func.isRequired,
  setHeight: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool,
  tools: PropTypes.array.isRequired,
  openToolId: PropTypes.string,
  filterValue: PropTypes.string.isRequired,
  isGridView: PropTypes.bool.isRequired,
  layoutCols: PropTypes.number.isRequired,
  setCurrentTab: PropTypes.func.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToolsContainer)
