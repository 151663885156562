import React, { PureComponent, Fragment } from 'react'
import {
  TextField,
  MenuItem,
  Button,
  Grid,
  Modal
 } from '@material-ui/core'
import { errorToast, successToast } from 'components/Toast'
import ConfirmModal from 'components/Modal/ConfirmModal'
import PropTypes from 'prop-types'
import { t } from 'utils/i18n/translate'
import SaveHeader from 'components/SaveHeader'
import Image from 'components/Image'
import _ from 'lodash'

class EditInfo extends PureComponent {
  state = {
    saveDisabled: true,
    name: this.props.workflow.name,
    category: this.props.workflow.category,
    imageUrl: this.props.workflow.imageUrl,
    altImage: this.props.workflow.altImage,
    categories: this.props.workflow.workflow_categories,
    imageFileName: this.props.workflow.imageFileName,
    groupIds: this.props.workflow.groupIds,
    fileName: null,
    base64: null,
    description: this.props.workflow.description,
    statuses: this.props.workflow.statuses,
    status: this.props.workflow.status
  }

  handleChange = (e) => {
    const { saveHandler } = this.props
    const { name, value } = e.target

    this.setState(prevState => ({
      ...prevState.worklow,
      [name]: value,
      saveDisabled: false
    }))

    saveHandler(false)
  }

  handleClick = () => {
    const { updateWorkflow, workflow, saveHandler } = this.props
    const {
      name,
      category,
      fileName,
      base64,
      description,
      status,
      altImage,
      groupIds,
      categories } = this.state

    const data = {
      name,
      workflowCategoryId: _.find(categories, { name: category }).id,
      image: { base64, fileName },
      base64,
      description,
      status,
      altImage,
      groupIds
    }

    const promise = updateWorkflow(workflow.id, data)
    promise.then(() => {
      successToast(t('app.workflow-edit.save.success'))
      this.setState({ saveDisabled: true })
      saveHandler(true)
    }).catch((error) => {
      const msg = (error.response && error.response.data.message) || t('app.workflow-edit.save.error')
      errorToast(msg)
    })

    return promise
  }

  handleClickUploadButton = () => {
    document.getElementById('contained-button-file').click()
    this.setState({ saveDisabled: false })
  }

  handleFileUpload = (e) => {
    e.preventDefault()
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      this.setState({
        fileName: file.name,
        base64: reader.result,
        imageUrl: URL.createObjectURL(file),
        saveDisabled: false
      })
    }
  }

  saveWithModal = () => {
    const { handleSaveInModal } = this.props

    this.handleClick().then(() => handleSaveInModal())
  }

  render() {
    const {
      id,
      name,
      category,
      imageUrl,
      description,
      status,
      statuses,
      saveDisabled,
      altImage,
      imageFileName,
      fileName,
      categories,
      groupIds } = this.state

    const { isModalOpened, handleCloseModal, handleDiscardChanges, groups } = this.props

    return (
      <Fragment>
        <Modal
          open={isModalOpened}
          onClose={handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ConfirmModal
            handleSaveClick={this.saveWithModal}
            workflowId={id}
            handleDiscardChanges={handleDiscardChanges}
          />
        </Modal>
        <SaveHeader onClick={this.handleClick} disabled={saveDisabled} />
        <form className="workflow-edit container" autoComplete="off">
          <Grid container justify="space-between">
            <Grid item xs={12} md={6}>
              <h1 className="form-title">{t('app.workflow-edit.details')}</h1>
              <TextField
                required
                name="name"
                error={name === ''}
                helperText={name === '' && t('app.errors.input.required_field')}
                onChange={this.handleChange}
                label={t('activerecord.attributes.workflow.name')}
                variant="outlined"
                value={name}
              />
              <TextField
                id="select-category"
                required
                error={category === ''}
                name="category"
                onChange={this.handleChange}
                label={t('activerecord.attributes.workflow.category')}
                value={category}
                select
                variant="outlined"
              >
                { categories && categories.map(c => (
                  <MenuItem value={c.name}>{c.name}</MenuItem>
                ))
                }
              </TextField>

              <TextField
                error={groupIds === []}
                id="select-group-ids"
                name="groupIds"
                value={groupIds}
                SelectProps={{ multiple: true }}
                onChange={this.handleChange}
                variant="outlined"
                label={t('app.workflow-edit.group_ids')}
                fullWidth
                required
                select
              >
                { groups.map(group => (
                  <MenuItem value={group.id}>{group.name}</MenuItem>
                ))}
              </TextField>
              <div className="image-container d-flex justify-content-between">
                <div className="image-attributes">
                  <div className="d-flex upload-image">
                    <TextField disable className="file-name" name="image" onChange={this.handleChange} label={t('activerecord.attributes.workflow.image')} variant="outlined" value={fileName || imageFileName} />
                    <input
                      onChange={this.handleFileUpload}
                      accept="image/*"
                      id="contained-button-file"
                      type="file"
                      hidden
                    />
                    <Button variant="outlined" onClick={this.handleClickUploadButton}>
                      {t('app.workflow-edit.choose_file')}
                    </Button>
                  </div>
                  <TextField
                    name="altImage"
                    onChange={this.handleChange}
                    label={t('activerecord.attributes.workflow.image_description')}
                    placeholder={t('activerecord.attributes.workflow.image_description')}
                    variant="outlined"
                    value={altImage}
                  />
                </div>
                <Image className="workflow-image" url={imageUrl} width="123px" height="123px" />
              </div>
              <TextField
                multiline
                rows={2}
                name="description"
                onChange={this.handleChange}
                placeholder={t('activerecord.attributes.workflow.description')}
                label={t('activerecord.attributes.workflow.description')}
                variant="outlined"
                value={description}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <h1 className="form-title">{t('app.workflow-edit.status')}</h1>
              <TextField
                id="select-status"
                name="status"
                onChange={this.handleChange}
                label={t('activerecord.attributes.workflow.status')}
                value={status}
                select
                variant="outlined"
              >
                { statuses && statuses.map(st => (
                  <MenuItem value={st}>{st}</MenuItem>
                ))
                }
              </TextField>
            </Grid>
          </Grid>
        </form>
      </Fragment>

    )
  }
}

EditInfo.propTypes = {
  updateWorkflow: PropTypes.func.isRequired,
  saveHandler: PropTypes.func.isRequired,
  workflow: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    imageFileName: PropTypes.string,
    workflow_categories: PropTypes.array,
    altImage: PropTypes.string,
    statuses: PropTypes.array,
    imageUrl: PropTypes.string,
    status: PropTypes.string,
    category: PropTypes.string,
    groupIds: PropTypes.array
  }).isRequired,
  isModalOpened: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleDiscardChanges: PropTypes.func.isRequired,
  handleSaveInModal: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
}

export default EditInfo
