import { createSelector } from 'reselect'

const getSearchResults = state => state.search.data
const getSearchFilter = state => state.search.activeFilter

export const getFilteredResults = createSelector(
  [getSearchResults, getSearchFilter],
  (results, filter) => {
    if (filter && filter !== 'All') {
      return results.filter(result => result.type === filter)
    }
    return results
  }
)

const getResultsOfType = type => (
  createSelector(
    [getSearchResults],
    results => results.filter(result => result.type === type)
  )
)

export const getWorkflowsCount = getResultsOfType('Workflow')
export const getTasksCount = getResultsOfType('Task')
export const getAffiliatePagesCount = getResultsOfType('AffiliatePage')
export const getToolsCount = getResultsOfType('Tool')
