import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab } from '@material-ui/core'

const StepList = ({
    steps,
    workflowName,
    children,
    setCurrentStep
  }) => {
  const filteredSteps = steps.filter(step => !step.status || step.status !== 'not_created')

  const initialState = () => (
    {
      stepsLoaded: [],
      currentStep: filteredSteps[0],
      currentTab: 0,
      stepsHasChanges: false
    }
  )

  const [state, setState] = useState(initialState())

  const handleTabChange = (event, newValue) => {
    setCurrentStep(filteredSteps[newValue])
    setState({ ...state, currentTab: newValue, currentStep: filteredSteps[newValue] })
  }

  return (
    <Fragment>
      <div className="steps-page">
        <div className="steps-tabs">
          <h3> { workflowName } </h3>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={state.currentTab}
            onChange={handleTabChange}
            aria-label="Vertical tabs for steps"
          >
            { filteredSteps.map(step => (
              <Tab label={step.name} id={`vertical-tab-${step.id}`} aria-controls={`vertical-tabpanel-${step.id}`} />
            ))}
          </Tabs>
        </div>
        { filteredSteps.map((step, i) => (
          <div
            role="tabpanel"
            className={state.currentTab === i ? 'steps-tab-content' : ''}
            id={`vertical-tabpanel-${step.id}`}
            aria-labelledby={`vertical-tab-${step.id}`}
          >
            { children && state.currentTab === i && state.currentStep && (
              React.Children.map(children, (
                child => React.cloneElement(child, { ...step })
              ))
            )}
          </div>
        ))}
      </div>
    </Fragment>
  )
}

StepList.defaultProps = {
  workflowName: ''
}
StepList.propTypes = {
  steps: PropTypes.array.isRequired,
  workflowName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  setCurrentStep: PropTypes.func.isRequired
}

export default StepList
