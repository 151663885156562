import React from 'react'
import PropTypes from 'prop-types'

const EmptyState = ({ title, subtitle }) => (
  <div className="empty-state">
    <p className="title">{title}</p>
    <p className="subtitle">{subtitle}</p>
  </div>
)

EmptyState.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
}

export default EmptyState
