import React from 'react'

export default () => (
  <svg id="Component_885_2" data-name="Component 885 – 2" xmlns="http://www.w3.org/2000/svg" width="15.999" height="16" viewBox="0 0 15.999 16">
    <path id="ic_add_box_24px" d="M9.654,3H3.832A.832.832,0,0,0,3,3.832V9.654a.832.832,0,0,0,.832.832H9.654a.834.834,0,0,0,.832-.832V3.832A.834.834,0,0,0,9.654,3Z" transform="translate(-3 5.514)" fill="#fff" />
    <path id="ic_add_box_24px-2" data-name="ic_add_box_24px" d="M9.654,3H3.832A.832.832,0,0,0,3,3.832V9.654a.832.832,0,0,0,.832.832H9.654a.834.834,0,0,0,.832-.832V3.832A.834.834,0,0,0,9.654,3Z" transform="translate(-3 -3)" fill="#fff" />
    <path id="ic_add_box_24px-3" data-name="ic_add_box_24px" d="M9.654,3H3.832A.832.832,0,0,0,3,3.832V9.654a.832.832,0,0,0,.832.832H9.654a.834.834,0,0,0,.832-.832V3.832A.834.834,0,0,0,9.654,3Z" transform="translate(5.513 -3)" fill="#fff" />
    <path id="ic_add_box_24px-4" data-name="ic_add_box_24px" d="M15.514,13.08H13.08v2.434H11.862V13.08H9.428V11.862h2.434V9.428H13.08v2.434h2.434Z" transform="translate(-0.134 -0.135)" fill="#fff" />
  </svg>
)
