import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { t } from 'utils/i18n/translate'
import { errorToast, successToast } from 'components/Toast'
import BpmnEditor from './BpmnEditor'
import BpmnViewer from './BpmnViewer'

class BpmnChart extends PureComponent {
  state = {
    tries: 0
  }

  componentDidMount() {
    const { workflowId, loadWorkflowChart } = this.props

    if (workflowId) {
      loadWorkflowChart(workflowId)
    }
  }

  handleUpdate = (xml, publish = false) => {
    const { updateWorkflowChart, publishWorkflowChart, workflowId } = this.props

    const promise = updateWorkflowChart(workflowId, { xml })
    promise.then(() => {
      if (publish) {
        publishWorkflowChart(workflowId, { xml }).then(() => {
          this.handleSuccess()
        })
      } else {
        this.handleSuccess()
      }
    }).catch(() => {
      this.setState(prevState => ({
        tries: prevState.tries + 1
      }))

      errorToast(this.errorMessage())
    })

    return promise
  }

  handleSuccess = () => {
    const { saveHandler } = this.props

    this.setState({
      tries: 0
    })

    saveHandler(true)
    successToast(t('app.workflows.workflow_chart.deploy.success'))
  }

  errorMessage = () => {
    const { tries } = this.state

    if (tries > 3) {
      return t('app.workflows.workflow_chart.deploy.error_contact_support')
    }

    return t('app.workflows.workflow_chart.deploy.error')
  }

  render() {
    const {
      diagramXML,
      isLoaded,
      isEditable,
      workflowId,
      isModalOpened,
      handleCloseModal,
      handleDiscardChanges,
      handleSaveInModal,
      saveHandler,
      loadStepsForWorkflow
    } = this.props

    if (!isLoaded) {
      return null
    }

    if (isEditable) {
      return (
        <BpmnEditor
          saveHandler={saveHandler}
          diagramXML={diagramXML}
          workflowId={workflowId}
          handleUpdate={this.handleUpdate}
          isModalOpened={isModalOpened}
          handleCloseModal={handleCloseModal}
          handleDiscardChanges={handleDiscardChanges}
          handleSaveInModal={handleSaveInModal}
          loadStepsForWorkflow={loadStepsForWorkflow}
        />
      )
    }

    return <BpmnViewer diagramXML={diagramXML} />
  }
}

BpmnChart.defaultProps = {
  isEditable: false,
  diagramXML: null,
  workflowId: null
}

BpmnChart.propTypes = {
  isEditable: PropTypes.bool,
  isLoaded: PropTypes.bool.isRequired,
  diagramXML: PropTypes.string,
  workflowId: PropTypes.string,
  loadWorkflowChart: PropTypes.func.isRequired,
  updateWorkflowChart: PropTypes.func.isRequired,
  publishWorkflowChart: PropTypes.func.isRequired,
  isModalOpened: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleDiscardChanges: PropTypes.func.isRequired,
  handleSaveInModal: PropTypes.func.isRequired,
  saveHandler: PropTypes.func.isRequired,
  loadStepsForWorkflow: PropTypes.func.isRequired
}

export default BpmnChart
