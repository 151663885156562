import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { isMobileOnly } from 'react-device-detect'
import { Row, Col } from 'reactstrap'
import HorizontalTileContainer from 'components/HorizontalTile/HorizontalTileContainer'
import VerticalTile from 'components/VerticalTile'
import ToolsHeader from './ToolsHeader'

const Tools = ({ tools, layoutCols, isGridView }) => {
  const renderGridView = () => {
    const layoutClass = isMobileOnly ? 'layout-2' : `layout-${layoutCols}`
    return (
      <Row className={`dashboard-row ${layoutClass}`}>
        {tools.map(tool => (

          <div key={`list_${tool.id}`} className="tile_grid">
            <VerticalTile
              {...tool}
              type="Tool"
            />
          </div>
        ))}
      </Row>
    )
  }

  const renderListView = () => (
    <Fragment>
      <ToolsHeader />
      { tools.map(tool => (
        <Row key={`list_${tool.id}`} className="dashboard-row list">
          <Col>
            <HorizontalTileContainer
              {...tool}
              type="Tool"
            />
          </Col>
        </Row>
      )) }
    </Fragment>
  )

  return (
    <main className="mt-4" role="main" id="tabs-container">
      {isGridView ? renderGridView() : renderListView()}
    </main>
  )
}

Tools.propTypes = {
  tools: PropTypes.array.isRequired,
  isGridView: PropTypes.bool.isRequired,
  layoutCols: PropTypes.number.isRequired,
}

export default Tools
