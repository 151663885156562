class BpmnCustomContextPad {
  constructor(config, contextPad, create, elementFactory, injector, translate) {
    this.create = create
    this.elementFactory = elementFactory
    this.translate = translate

    if (config.autoPlace !== false) {
      this.autoPlace = injector.get('autoPlace', false)
    }

    contextPad.registerProvider(this)
  }

  getContextPadEntries() {
    const {
      autoPlace,
      create,
      elementFactory,
      translate
    } = this

    function appendUserTaskStart(event, element) {
      const shape = elementFactory.createShape({ type: 'bpmn:UserTask' })

      create.start(event, shape, element)
    }


    function appendUserTask(event, element) {
      if (autoPlace) {
        const shape = elementFactory.createShape({ type: 'bpmn:UserTask' })

        autoPlace.append(element, shape)
      } else {
        appendUserTaskStart(event, element)
      }
    }

    return {
      'append.user-task': {
        group: 'model',
        className: 'bpmn-icon-user-task',
        title: translate('Append UserTask'),
        action: {
          click: appendUserTask,
          dragstart: appendUserTaskStart
        }
      }
    }
  }
}

BpmnCustomContextPad.$inject = [
  'config',
  'contextPad',
  'create',
  'elementFactory',
  'injector',
  'translate'
]
export default BpmnCustomContextPad
