import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Trans from 'components/Trans'
import { timeAgo } from 'utils/time'

const ExpandedTask = ({
  name,
  status,
  taskType,
  updatedAt,
  kebab
}) => {
  const colClasses = 'align-self-center'
  const isNotification = taskType && taskType.includes('notification')

  return (
    <Fragment>
      <div className={`${colClasses} task-type`}>
        <Trans id={`app.task.types.${taskType}`} />
      </div>
      <div className={`${colClasses} task-owner`}>{name}</div>
      <div className={`${colClasses} task-time`}>{timeAgo(updatedAt)}</div>
      <div className={`${colClasses} ${isNotification ? '' : 'small-column'} task-status`}>
        <Trans id={`app.task.status.${status}`} />
      </div>
      { !isNotification
        && <div className={`${colClasses} justify-content-end d-flex small-column more-options`}>{kebab}</div> }
    </Fragment>
  )
}

ExpandedTask.propTypes = {
  name: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  taskType: PropTypes.string.isRequired,
  kebab: PropTypes.object
}

ExpandedTask.defaultProps = {
  kebab: {}
}

export default ExpandedTask
