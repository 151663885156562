import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { adminReducer } from 'react-admin'
import auth from './reducers/auth'
import tasks from './reducers/tasks'
import workflows from './reducers/workflows'
import flashMessages from './reducers/flashMessages'
import favorites from './reducers/favorites'
import search from './reducers/search'
import workflowDetails from './reducers/workflowDetails'
import workflowCharts from './reducers/workflowCharts'
import workflowCategories from './reducers/workflowCategories'
import taskDetails from './reducers/taskDetails'
import searchSuggestions from './reducers/searchSuggestions'
import openWorkflow from './reducers/openWorkflow'
import page from './reducers/page'
import pages from './reducers/pages'
import tools from './reducers/tools'
import toolDetails from './reducers/toolDetails'
import openTool from './reducers/openTool'
import system from './reducers/system'
import notifications from './reducers/notifications'
import magazine from './reducers/magazine'
import profile from './reducers/profile'
import metrics from './reducers/metrics'
import users from './reducers/users'
import groups from './reducers/groups'
import integrations from './reducers/integrations'
import snackbar from './reducers/snackbar'
import groupDetails from './reducers/groupDetails'
import groupMemberships from './reducers/groupMemberships'

export default history => combineReducers({
  router: connectRouter(history),
  admin: adminReducer,
  flashMessages,
  favorites,
  auth,
  tasks,
  workflows,
  workflowCategories,
  search,
  workflowDetails,
  workflowCharts,
  taskDetails,
  searchSuggestions,
  openWorkflow,
  page,
  profile,
  pages,
  tools,
  toolDetails,
  openTool,
  system,
  notifications,
  magazine,
  metrics,
  users,
  integrations,
  groups,
  groupDetails,
  snackbar,
  groupMemberships
})
