export const types = {
  LOAD_START: 'tools/LOAD_START',
  LOAD_SUCCESS: 'tools/LOAD_SUCCESS',
  LOAD_FAIL: 'tools/LOAD_FAIL',
}

export const loadToolList = (params = {}) => ({
  types: [types.LOAD_START, types.LOAD_SUCCESS, types.LOAD_FAIL],
  promise: client => client.get('/tools', { params })
})
