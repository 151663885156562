import React, { Fragment, useState } from 'react'
import { isMobile } from 'react-device-detect'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import AddIcon from '@material-ui/icons/Add'

const DialogComponent = (props) => {
  const {
    fullScreen,
    fullWidth,
    buttonLabel,
    buttonColor,
    formComponent,
    iconComponent,
    onlyIcon,
    modalTitle,
    modalBody,
    actionFunction,
    buttonVariant,
    parentId,
    isNewObject,
    callbackFunction,
    actionLabel,
    userEmail,
    successToast,
    errorToast,
    float,
    isOwnGroup
  } = props

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const renderButton = () => {
    const Inner = iconComponent
    if (onlyIcon === true) {
      return (
        <IconButton
          size="small"
          color={buttonColor}
          onClick={handleClickOpen}
        >
          { iconComponent != null ? <Inner /> : <AddIcon /> }
        </IconButton>
      )
    }
    return (
      <Button
        variant={buttonVariant}
        color={buttonColor}
        onClick={handleClickOpen}
        float={float}
        startIcon={iconComponent != null ? <Inner /> : <AddIcon />}
        fullWidth={fullWidth && isMobile}
      >
        {buttonLabel}
      </Button>
    )
  }

  const FormComponent = formComponent

  return (
    <Fragment>
      {renderButton()}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        fullScreen={fullScreen}
        maxWidth="sm"
      >

        <DialogTitle id="alert-dialog-title">
          {modalTitle}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {modalBody !== '' ? modalBody : ''}
          </DialogContentText>
          <FormComponent
            handleClose={handleClose}
            actionFunction={actionFunction}
            callbackFunction={callbackFunction}
            parentId={parentId}
            actionLabel={actionLabel}
            isNewObject={isNewObject}
            successToastMessage={successToast}
            errorToastMessage={errorToast}
            userEmail={userEmail}
            isOwnGroup={isOwnGroup}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

DialogComponent.defaultProps = {
  modalBody: '',
  parentId: '',
  buttonVariant: 'contained',
  buttonColor: 'primary',
  onlyIcon: false,
  formComponent: null,
  iconComponent: null,
  actionFunction: null,
  actionLabel: '',
  callbackFunction: null,
  isNewObject: true,
  fullWidth: false,
  fullScreen: false,
  float: 'none',
  isOwnGroup: false
}

DialogComponent.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  modalBody: PropTypes.string,
  buttonVariant: PropTypes.string,
  actionLabel: PropTypes.string,
  callbackFunction: PropTypes.func,
  onlyIcon: PropTypes.bool,
  isNewObject: PropTypes.bool,
  iconComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]),
  formComponent: PropTypes.object,
  buttonColor: PropTypes.string,
  parentId: PropTypes.string,
  userEmail: PropTypes.string.isRequired,
  successToast: PropTypes.string.isRequired,
  errorToast: PropTypes.string.isRequired,
  float: PropTypes.string,
  actionFunction: PropTypes.func,
  fullWidth: PropTypes.bool,
  fullScreen: PropTypes.bool,
  isOwnGroup: PropTypes.bool
}

export default DialogComponent
