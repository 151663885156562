import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Nav, NavItem, Container } from 'reactstrap'
import DashboardNavLink from './DashboardNavLink'

class DashboardNavTabs extends PureComponent {
  handleChange = index => () => {
    const { handleChange } = this.props

    handleChange(index)
  }

  render() {
    const { children, activeTab } = this.props

    return (
      <Nav className="main-underline-tabs" tabs>
        <Container className="d-flex px-0">
          {React.Children.map(children, (child, index) => {
            if (child.props.hidden) { return null }
            const key = `smart_nav_item_${index}`
            const name = child.props.name || index

            const options = {
              ...child.props,
              key,
              onClick: this.handleChange(name),
              active: activeTab === name,
              name
            }

            return (
              React.cloneElement(child, options)
            )
          })}
        </Container>
      </Nav>
    )
  }
}

DashboardNavTabs.defaultProps = {
  handleChange: () => {},
  activeTab: 0
}

DashboardNavTabs.propTypes = {
  children: PropTypes.node.isRequired,
  handleChange: PropTypes.func,
  activeTab: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

const SmarNavTab = ({ active, onClick, href, title, name, hidden }) => (
  <NavItem className={`px-0 ${name} ${hidden}`}>
    <DashboardNavLink active={active} onClick={onClick} href={href} title={title} />
  </NavItem>
)

SmarNavTab.defaultProps = {
  active: false,
  href: '',
  onClick: () => {},
  name: undefined,
  hidden: false
}

SmarNavTab.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  href: PropTypes.string,
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  hidden: PropTypes.bool
}

DashboardNavTabs.Tab = SmarNavTab

export default DashboardNavTabs
