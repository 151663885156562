import { types } from 'redux/actions/search'

const initialState = {
  isLoading: false,
  isLoaded: false,
  isOpen: false,
  data: [],
  searchValue: ''
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.TOGGLE_SEARCH:
      return {
        ...state,
        isOpen: action.state || !state.isOpen
      }
    case types.SEARCH_START:
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      }
    case types.SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: action.response.data
      }
    case types.SEARCH_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoaded: true
      }
    case types.SET_SEARCH_FILTER:
      return {
        ...state,
        activeFilter: action.filter
      }
    case types.SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.value
      }
    default:
      return state
  }
}
