import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const getViewBox = (name) => {
  switch (name) {
    case 'description':
      return '0 0 24 24'
    case 'goto':
      return '0 0 24 24'
    case 'close':
      return '0 0 24.055 24.055'
    case 'favorite':
      return '0 0 26 24'
    case 'favorite_active':
      return '0 0 26 24'
    case 'search':
      return '0 0 23.994 24'
    case 'lock':
      return '0 0 19.714 24'
    case 'play':
      return '0 0 24 25'
    case 'download':
      return '0 0 26 56'
    case 'dashboard':
      return '0 0 24 24'
    case 'magazine':
      return '0 0 24 24'
    default:
      return '0 0 32 32'
  }
}

const setSVGState = (props) => {
  switch (props.state) {
    case 'normal':
      return '#000'
    case 'focus':
      return '#0945a5'
    case 'active':
      return '#0945a5'
    case 'active_sec':
      return '#6c6c6c'
    case 'disable':
      return '#000'
    default:
      return 'normal'
  }
}

const getPath = (name, svgValues) => {
  /* eslint no-param-reassign: [
  "error", { "props": true, "ignorePropertyModificationsFor": ["svgValues"] }
  ] */
  svgValues.fill = setSVGState(svgValues)
  svgValues.stroke = setSVGState(svgValues)
  switch (name) {
    case 'description':
      return (
        <Fragment>
          <g id="ic_insert_emoticon_24px">
            <g id="ic_insert_emoticon_24px-2" fill="none" stroke="none" data-name="ic_insert_emoticon_24px">
              <path fill="#192130" d="M13.988 3.5C8.205 3.5 3.5 8.21 3.5 14s4.705 10.5 10.488 10.5C19.784 24.5 24.5 19.79 24.5 14S19.784 3.5 13.988 3.5m0-1.5C20.624 2 26 7.376 26 14s-5.376 12-12.012 12C7.364 26 2 20.624 2 14S7.364 2 13.988 2z" transform="translate(-2 -2)" />
            </g>
          </g>
          <g id="Group_5744" data-name="Group 5744" transform="translate(-1447 -1150)">
            <g id="Group_5730" data-name="Group 5730" transform="translate(1458.25 1157)">
              <path id="Path_4251" fill="#192130" d="M-3056.137-5392.626a.788.788 0 0 1-.849.818.794.794 0 0 1-.8-.818.812.812 0 0 1 .834-.833.791.791 0 0 1 .815.833zm-1.486 9.393v-7.333h1.334v7.333z" data-name="Path 4251" transform="translate(3057.79 5393.459)" />
            </g>
          </g>
        </Fragment>
      )
    case 'list':
      return (
        <path
          {...svgValues}
          d="M 2.56635 12.9241C 0.708307 9.55549 0 6.83983 0 5.00558C 0 3.17134 0.450658 2.64526 0.907953 2.22025C 1.36525 1.79524 3.42732 0.523908 3.77867 0.286808C 4.13002 0.0497085 5.47099 -0.41107 6.31193 0.798636C 7.15287 2.00834 8.73646 4.43718 9.82825 6.05069C 11.5415 8.33611 10.1766 9.33937 9.73572 9.94069C 8.92447 11.0472 8.45734 11.3201 8.45734 12.6788C 8.45734 14.0375 12.2545 17.8976 13.1625 18.8487C 14.0635 19.7926 17.8471 23.1094 19.0195 23.2868C 20.2002 23.4654 21.7807 22.2154 22.1168 21.8985C 23.8263 20.586 24.7912 21.581 25.5787 22.0136C 26.3661 22.4461 29.9239 24.663 31.0264 25.4103C 32.0641 26.1576 31.9992 27.3125 31.9992 27.3125C 31.9992 27.3125 29.859 30.7092 29.5996 31.1168C 29.2753 31.5924 28.4971 32 26.746 32C 24.995 32 23.1241 31.6802 18.6777 29.2349C 15.0396 27.234 11.5714 24.1009 9.75551 22.2666C 7.87475 20.4324 4.68876 16.772 2.56635 12.9241Z"
        />
      )
    case 'magazine':
      return (
        <Fragment>
          <path id="Path_4636" d="M.994.109L23.925 0S21.941 1.653 21.9 2.793l-.019 7.987a.982.982 0 0 1-.994.97H.994A.982.982 0 0 1 0 10.78v-9.7A.982.982 0 0 1 .994.109z" className="cls-1" data-name="Path 4636" transform="rotate(90 5.875 5.875)" />
          <path id="Path_4637" d="M.994 11.7L24 11.75s-2.061-1.6-2.106-2.75l-.02-8.025A.985.985 0 0 0 20.88 0H.994A.985.985 0 0 0 0 .975v9.747a.985.985 0 0 0 .994.978z" className="cls-1" data-name="Path 4637" transform="rotate(90 12 12)" />
          <path id="ic_menu_24px" fill="#fff" d="M3 11.5h8.251v-.917H3zm0-2.291h8.251v-.917H3zM3 6v.917h8.251V6z" transform="translate(11.112 -2.896)" />
        </Fragment>
      )
    case 'dashboard':
      return (
        <Fragment>
          <rect id="prefix__Rectangle_6824" width="14" height="8" className="prefix__cls-2" data-name="Rectangle 6824" rx="1" transform="translate(10 16)" />
          <rect id="prefix__Rectangle_6828" width="8" height="24" className="prefix__cls-2" data-name="Rectangle 6828" rx="1" />
          <rect id="prefix__Rectangle_6829" width="14" height="14" className="prefix__cls-2" data-name="Rectangle 6829" rx="1" transform="translate(10)" />
        </Fragment>
      )
    case 'goto':
      return (
        <g id="ic_reply_24px" fill="none" stroke="none">
          <path fill="#192130" d="M19.167 8.621v3.013l-1.288.184c-6.028.861-9.473 4.103-11.428 7.77.636-.41 1.303-.776 2.003-1.1 2.604-1.203 5.618-1.788 9.213-1.788h1.5V20.045l5.712-5.712-5.712-5.712M17.667 5L27 14.333l-9.333 9.334V18.2C11 18.2 6.333 20.333 3 25c1.333-6.667 5.333-13.333 14.667-14.667V5z" transform="translate(-3 -3.441)" />
        </g>
      )
    case 'close':
      return (
        <Fragment>
          <g id="prefix__Group_5736" data-name="Group 5736" transform="rotate(45 2145.866 -591.876)">
            <path id="prefix__ic_vertical_align_bottom_24px" d="M4 24.333V27h21.34v-2.667z" className="prefix__cls-2" transform="translate(1049.33 1318)" />
            <circle id="prefix__Ellipse_898" cx="1.33" cy="1.33" r="1.33" className="prefix__cls-2" data-name="Ellipse 898" transform="translate(1052 1342.35)" />
            <circle id="prefix__Ellipse_899" cx="1.33" cy="1.33" r="1.33" className="prefix__cls-2" data-name="Ellipse 899" transform="translate(1073.34 1342.35)" />
          </g>
          <g id="prefix__Group_5737" data-name="Group 5737" transform="rotate(45 2145.866 -591.876)">
            <path id="prefix__ic_vertical_align_bottom_24px-2" d="M0 0v2.667h21.342V0z" className="prefix__cls-2" data-name="ic_vertical_align_bottom_24px" transform="rotate(90 -134.16 1199.17)" />
            <circle id="prefix__Ellipse_909" cx="1.33" cy="1.33" r="1.33" className="prefix__cls-2" data-name="Ellipse 909" transform="rotate(90 -133.504 1198.496)" />
            <circle id="prefix__Ellipse_910" cx="1.33" cy="1.33" r="1.33" className="prefix__cls-2" data-name="Ellipse 910" transform="rotate(90 -144.175 1209.167)" />
          </g>
        </Fragment>
      )
    case 'favorite':
      return (
        <g id="prefix__dashboard-icon-star" transform="translate(1.208 .192)">
          <g id="prefix__Group_5617" data-name="Group 5617" transform="translate(0 1)">
            <path id="prefix__Path_3528" fill="none" d="M12 0l3.667 7.727L24 8.371l-6.444 5.8L19.444 22l-7.667-4.078L4.556 22l1.778-8.263L0 8.371 8.667 7.4z" data-name="Path 3528" />
          </g>
        </g>
      )
    case 'favorite_active':
      return (
        <g id="prefix__dashboard-icon-star" transform="translate(1.208 .192)">
          <g id="prefix__Group_5617" data-name="Group 5617" transform="translate(0 1)">
            <path id="prefix__Path_3528" d="M12 0l3.667 7.727L24 8.371l-6.444 5.8L19.444 22l-7.667-4.078L4.556 22l1.778-8.263L0 8.371 8.667 7.4z" data-name="Path 3528" />
          </g>
        </g>
      )
    case 'search':
      return (
        <path
          {...svgValues}
          d="M14.153 1.284a8.561 8.561 0 1 1 0 17.122 8.33 8.33 0 0 1-4.966-1.635h-.051a7.949 7.949 0 0 1-1.943-1.913 8.561 8.561 0 0 1 6.96-13.574m0-1.284a9.845 9.845 0 0 0-8 15.581 9.336 9.336 0 0 0 2.269 2.226 9.593 9.593 0 0 0 5.731 1.883 9.845 9.845 0 1 0 0-19.69zm-8.4 16.441L.408 21.775a1.286 1.286 0 1 0 1.755 1.879l.064-.064 5.351-5.346a10.222 10.222 0 0 1-1.832-1.803z"
        />
      )
    case 'lock':
      return (
        <path
          {...svgValues}
          d="M3.857 9.002v-3a5.91 5.91 0 0 1 5.82-6h.18a5.91 5.91 0 0 1 6 5.82v3.18h-1.286v-3a4.611 4.611 0 0 0-4.5-4.714h-.21a4.611 4.611 0 0 0-4.714 4.5v3.214zm14.571 2.143H1.286v11.57h17.142zm.429-1.286a.857.857 0 0 1 .857.857v12.427a.857.857 0 0 1-.857.857h-18A.857.857 0 0 1 0 23.143V10.715a.857.857 0 0 1 .857-.857z"
        />
      )
    case 'play':
      return (
        <g id="dashboard-icon-start" transform="translate(0 .771)">
          <g id="Baseline-Icons" transform="translate(4)">
            <g id="Baseline-Icons-_No-Text_" data-name="Baseline-Icons-(No-Text)">
              <g id="Dark-Icons">
                <g id="Play-Filled-Dark">
                  <path id="Icon-Shape" d="M4 1.033v21.933a.962.962 0 0 0 1.5.859l15.35-10.949a1.073 1.073 0 0 0 0-1.716L5.5.175a.963.963 0 0 0-1.5.858" transform="translate(-4)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      )
    case 'download':
      return (
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="download_outline" transform="translate(-1.000000, 0.000000)" fill="#262626" fillRule="nonzero">
            <path d="M25.152,53 L2.478,53 C1.649,53 0.978,53.672 0.978,54.5 C0.978,55.328 1.649,56 2.478,56 L25.153,56 C25.982,56 26.653,55.328 26.653,54.5 C26.653,53.672 25.981,53 25.152,53 Z M27.001,33 L15.501,33 L15.501,1.739 C15.501,0.911 14.83,0.239 14.001,0.239 C13.172,0.239 12.501,0.911 12.501,1.739 L12.501,33 L1.001,33 L14.001,46 L27.001,33 Z" id="Shape" />
          </g>
        </g>
      )
    default:
      return <path />
  }
}

const SVGIcon = ({
  name = '',
  style = {},
  fill = '#fff',
  state = '',
  stroke = '#fff',
  viewBox = '',
  width = '100%',
  className = '',
  height = '100%'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox || getViewBox(name)}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    focusable="false"
  >
    {getPath(name, { fill, stroke, state })}
  </svg>
)

SVGIcon.defaultProps = {
  name: '',
  style: {},
  fill: '#fff',
  state: '',
  stroke: '#fff',
  viewBox: '',
  width: '100%',
  className: '',
  height: '100%'
}

SVGIcon.propTypes = {
  stroke: PropTypes.string,
  fill: PropTypes.string,
  name: PropTypes.string,
  style: PropTypes.object,
  state: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.string,
}

export default SVGIcon
