export const types = {
  LOAD_ARTICLES_START: 'tasks/LOAD_ARTICLES_START',
  LOAD_ARTICLES_SUCCESS: 'tasks/LOAD_ARTICLES_SUCCESS',
  LOAD_ARTICLES_FAIL: 'tasks/LOAD_ARTICLES_FAIL',
  LOAD_TOOLS_START: 'tasks/LOAD_TOOLS_START',
  LOAD_TOOLS_SUCCESS: 'tasks/LOAD_TOOLS_SUCCESS',
  LOAD_TOOLS_FAIL: 'tasks/LOAD_TOOLS_FAIL',
  LOAD_TASKS_START: 'tasks/LOAD_TASKS_START',
  LOAD_TASKS_SUCCESS: 'tasks/LOAD_TASKS_SUCCESS',
  LOAD_TASKS_FAIL: 'tasks/LOAD_TASKS_FAIL',
  LOAD_FOLDERS_START: 'tasks/LOAD_FOLDERS_START',
  LOAD_FOLDERS_SUCCESS: 'tasks/LOAD_FOLDERS_SUCCESS',
  LOAD_FOLDERS_FAIL: 'tasks/LOAD_FOLDERS_FAIL',
  LOAD_FOLDER_START: 'tasks/LOAD_FOLDER_START',
  LOAD_FOLDER_SUCCESS: 'tasks/LOAD_FOLDER_SUCCESS',
  LOAD_FOLDER_FAIL: 'tasks/LOAD_FOLDER_FAIL'
}

export const loadMagazineArticles = (workflowCategoryId, params = {}) => ({
  types: [types.LOAD_ARTICLES_START, types.LOAD_ARTICLES_SUCCESS, types.LOAD_ARTICLES_FAIL],
  promise: client => client.get(`/workflow_categories/${workflowCategoryId}/articles`, { params })
})

export const loadMagazineTaskList = params => ({
  types: [types.LOAD_TASKS_START, types.LOAD_TASKS_SUCCESS, types.LOAD_TASKS_FAIL],
  promise: client => client.get('/tasks/', { params })
})

export const loadMagazineTools = (params = {}) => ({
  types: [types.LOAD_TOOLS_START, types.LOAD_TOOLS_SUCCESS, types.LOAD_TOOLS_FAIL],
  promise: client => client.get('/tools', { params })
})

export const loadMagazineFolders = (params = {}) => ({
  types: [types.LOAD_FOLDERS_START, types.LOAD_FOLDERS_SUCCESS, types.LOAD_FOLDERS_FAIL],
  promise: client => client.get('/nodes', { params })
})

export const loadCurrentFolder = id => ({
  types: [types.LOAD_FOLDER_START, types.LOAD_FOLDER_SUCCESS, types.LOAD_FOLDER_FAIL],
  promise: client => client.get(`/nodes/${id}`)
})
