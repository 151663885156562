import { types } from 'redux/actions/tasks'
import { types as taskDetailsTypes } from 'redux/actions/taskDetails'
import * as loadActions from '../middleware/loadActions'

export default (state = loadActions.arrayInitialState, action = {}) => {
  switch (action.type) {
    case types.LOAD_TASKS_START:
      return loadActions.onLoadStart(state)
    case types.LOAD_TASKS_SUCCESS:
      return loadActions.onLoadSuccess(state, action)
    case types.LOAD_TASKS_FAIL:
      return loadActions.onLoadFailure(state, action)
    case types.ADD_TASK:
      if (state.data.some(el => el.id === action.task.id)) {
        return state
      }

      return {
        ...state,
        data: [action.task, ...state.data]
      }
    case types.UPDATE_TASK: {
      const tasks = state.data.map((task) => {
        if (task.id === action.taskId) {
          return { ...task, ...action.params }
        }

        return { ...task }
      })

      return { ...state, data: tasks }
    }
    case taskDetailsTypes.UPDATE_DETAILS_SUCCESS: {
      const tasks = state.data.map((task) => {
        if (task.id === action.response.data.id) {
          return { ...task, ...action.response.data }
        }

        return { ...task }
      })

      return { ...state, data: tasks }
    }
    default:
      return state
  }
}
