import { format } from 'timeago.js'
import i18n from 'i18n-js'
import moment from 'moment'
import 'moment/min/locales'
// Converts a timestamp into 'x minutes/hours/days ago'
export const timeAgo = timestamp => format(timestamp, i18n.locale)

export const isOlderThan24Hours = (datetime) => {
  const before = new Date(datetime)
  const now = new Date()

  return (now - before) > 1000 * 60 * 60 * 24
}

export const isIso8601DateTime = value => (
  moment(value, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]', true).isValid()
)

export const formatDate = value => (
  newDate(value).format('l')
)

export const formatTime = value => (
  newDate(value).format('LT')
)

const newDate = (value) => {
  const date = moment(value)
  return date.locale(i18n.locale)
}

export default { timeAgo, isIso8601DateTime, formatDate }
