import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'utils/i18n/translate'
import SVGIcon from '../SVGIcon/SVGIcons'

const PlayAction = ({ action, width, height, disabled }) => (
  <button
    onClick={action}
    type="button"
    className="icon-action icon-play"
    tabIndex={disabled ? -1 : 0}
    aria-label={t('app.accessibility.play')}
    title={t('app.accessibility.play')}
  >
    <SVGIcon width={width} height={height} name="play" state="normal" stroke="#000" />
  </button>
)

PlayAction.defaultProps = {
  width: '24px',
  height: '21px',
  disabled: false
}

PlayAction.propTypes = {
  action: PropTypes.func.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  disabled: PropTypes.bool
}

export default PlayAction
