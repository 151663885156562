export const types = {
  TOGGLE_SEARCH: 'search/TOGGLE_SEARCH',
  SEARCH_START: 'search/SEARCH_START',
  SEARCH_SUCCESS: 'search/SEARCH_SUCCESS',
  SEARCH_FAIL: 'search/SEARCH_FAIL',
  SUGGESTIONS_START: 'search/SUGGESTIONS_START',
  SUGGESTIONS_SUCCESS: 'search/SUGGESTIONS_SUCCESS',
  SUGGESTIONS_FAIL: 'search/SUGGESTIONS_FAIL',
  SET_SEARCH_FILTER: 'search/SET_SEARCH_FILTER',
  SET_SEARCH_VALUE: 'search/SET_SEARCH_VALUE'
}

export const toggleSearchBar = state => ({
  type: types.TOGGLE_SEARCH,
  state
})

export const setFilter = filter => ({
  type: types.SET_SEARCH_FILTER,
  filter
})

export const setSearchValue = value => ({
  type: types.SET_SEARCH_VALUE,
  value
})

export const searchWorkflows = query => ({
  types: [types.SEARCH_START, types.SEARCH_SUCCESS, types.SEARCH_FAIL],
  promise: client => client.get('/search', {
    params: {
      query
    }
  })
})

// TODO: find a solution for suggestions - Using search query instead now in order to filter results
export const getSuggestions = query => ({
  types: [types.SUGGESTIONS_START, types.SUGGESTIONS_SUCCESS, types.SUGGESTIONS_FAIL],
  promise: client => client.get('/search', {
    params: {
      query,
      count: 10
    }
  })
})
