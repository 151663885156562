import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Container } from 'reactstrap'
import { errorToast, successToast } from 'components/Toast'
import { t } from 'utils/i18n/translate'
import Trans from 'components/Trans'
import TemplateIcon from 'images/templates'

// const ownGroup = groups && groups.length > 0 ? groups.filter(g => g.isOwngroup)[0] : null
const WorkflowTemplatesHeader = ({
  groups,
  installTemplates,
  fetchGroups,
  updateGroup,
  loadTemplates
  }) => {
  const showInstallButton = groups.length === 1
        && groups[0].isOwnGroup && !groups[0].templatesInstalled
  const groupId = groups.length === 1 && groups[0].isOwnGroup ? groups[0].id : null

  const onClickInstall = () => {
    installTemplates(groupId).then(() => {
      const params = { parentId: groupId, templatesInstalled: true }
      updateGroup(params).then(() => {
        fetchGroups()
        loadTemplates()
        successToast(t('app.workflows.templates.import_success'))
      })
    }).catch(() => {
      errorToast(t('app.workflows.templates.import_error'))
    })
  }

  return (
    <Fragment>
      <h2>
        <Container> <Trans id="app.workflows.templates.page_title" /> </Container>
      </h2>

      {showInstallButton && (
      <Container>
        <div className="container-header">
          <span className="description"> <Trans id="app.workflows.templates.import_explation" /> </span>

          <button type="button" onClick={onClickInstall} className="btn btn-primary-icon">
            <span className="icon"> <TemplateIcon /> </span>
            <span className="text"> <Trans id="app.workflows.templates.import_button" /> </span>
          </button>

        </div>
      </Container>
      )}
    </Fragment>
  )
}

WorkflowTemplatesHeader.propTypes = {
  groups: PropTypes.array.isRequired,
  installTemplates: PropTypes.func.isRequired,
  fetchGroups: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
  loadTemplates: PropTypes.func.isRequired,
}

export default WorkflowTemplatesHeader
