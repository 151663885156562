import React from 'react'

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26.411" height="24.192" viewBox="0 0 26.411 24.192">
    <g id="prefix__dashboard-icon-star" transform="translate(1.208 .192)">
      <path id="prefix__Rectangle_6711" fill="none" d="M0 0H24V24H0z" data-name="Rectangle 6711" />
      <g id="prefix__Group_5617" data-name="Group 5617" transform="translate(0 1)">
        <path id="prefix__Path_3528" fill="none" fillRule="evenodd" stroke="#192130" d="M12 0l3.667 7.727L24 8.371l-6.444 5.8L19.444 22l-7.667-4.078L4.556 22l1.778-8.263L0 8.371 8.667 7.4z" data-name="Path 3528" />
      </g>
    </g>
  </svg>
)
