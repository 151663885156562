import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Trans from 'components/Trans'

const BackButton = ({ localeKey, className }) => (
  <Link to="/dashboard" className={`${className} text-dark mt-3`}>
    &larr; <Trans id={localeKey || 'app.search.back'} />
  </Link>
)

BackButton.propTypes = {
  localeKey: PropTypes.string,
  className: PropTypes.string
}

BackButton.defaultProps = {
  localeKey: null,
  className: ''
}

export default BackButton
