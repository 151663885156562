import React from 'react'
import PropTypes from 'prop-types'
import ConfirmModal from './ConfirmModal'

const ConfirmDeleteModal = ({
  handleSaveClick,
  handleDiscardChanges,
  message,
  title,
  cancelButton,
  confirmButton
}) => (

  <ConfirmModal
    handleSaveClick={handleSaveClick}
    handleDiscardChanges={handleDiscardChanges}
    title={title}
    message={message}
    cancelButton={cancelButton}
    confirmButton={confirmButton}
  />
)

ConfirmDeleteModal.defaultProps = {
  message: 'app.delete_modal.message',
  title: 'app.delete_modal.title',
  confirmButton: 'app.delete_modal.confirm',
  cancelButton: 'app.delete_modal.cancel'
}

ConfirmDeleteModal.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  cancelButton: PropTypes.string,
  confirmButton: PropTypes.string,
  handleSaveClick: PropTypes.func.isRequired,
  handleDiscardChanges: PropTypes.func.isRequired,
}

export default ConfirmDeleteModal
