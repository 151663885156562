import React from 'react'
import logo from 'images/brand/logo.svg'
import { Link } from 'react-router-dom'

const BrandBar = () => (
  <div className="brandbar">
    <div className="container">
      <div className="brand-logo">
        <Link to="/">
          <img src={logo} alt="Dashboard Logo" />
          <span className="sr-only">Dashboard Logo</span>
        </Link>
      </div>
    </div>
  </div>
)

export default BrandBar
