import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import DashboardPage from 'pages/HomePage'
import StaticPageContainer from 'pages/StaticPage/container'
import UserProfilePage from 'pages/UserProfilePage/container'
import NotificationsPage from 'pages/NotificationsPage/container'
import NotFoundPage from 'pages/NotFoundPage'
import ErrorPage from 'pages/ErrorPage'
import PrivateRouteContainer from 'components/PrivateRoute/container'
import ExternalLinkPageContainer from 'pages/ExternalLinkPage/container'
import GroupPage from 'pages/GroupPage'
import GroupShowPage from 'pages/GroupPage/show'
import WorkflowDetailsPage from 'pages/WorkflowDetailsPage'
import WorkflowEditPage from 'pages/WorkflowEditPage'
import ToolDetailPage from 'pages/ToolDetailPage'
import TaskDetailsPage from 'pages/TaskDetailsPage'
import SearchResultsPage from 'pages/SearchResultsPage'
import MagazinePageContainer from 'pages/MagazinePage/container'
import FoldersPageContainer from 'pages/FoldersPage/FoldersPageContainer'
import OrderManagementPage from 'admin/pages/OrderManagementPage'
import ProjectManagementPage from 'admin/pages/ProjectManagementPage'
import FinanceDashboardPage from 'admin/pages/FinanceDashboardPage'
import ResourceDashboardPage from 'admin/pages/ResourceDashboardPage'
import WorkflowTemplatesPage from 'pages/WorkflowTemplatesPage/container'

const Routes = ({ root }) => (
  <Switch>
    <Route
      path="/login"
      component={() => {
              window.location = `${process.env.REACT_APP_BACKEND_HOST}/users/auth/dashboard_sso`
              return null
          }}
    />
    <PrivateRouteContainer exact path="/notifications" component={NotificationsPage} />
    <PrivateRouteContainer exact path="/search" component={SearchResultsPage} />
    <PrivateRouteContainer exact path="/profile" component={UserProfilePage} />
    <PrivateRouteContainer exact path="/groups" component={GroupPage} />
    <PrivateRouteContainer exact path="/groups/:id" component={GroupShowPage} />
    <PrivateRouteContainer exact path="/magazin/marketing" component={MagazinePageContainer} />
    <PrivateRouteContainer exact path="/workflows/:id" component={WorkflowDetailsPage} />
    <PrivateRouteContainer exact path="/workflows/:id/edit" component={WorkflowEditPage} />
    <PrivateRouteContainer exact path="/templates" component={WorkflowTemplatesPage} />
    <PrivateRouteContainer exact path="/folders/:id" component={FoldersPageContainer} />
    <PrivateRouteContainer exact path="/tools/:id" component={ToolDetailPage} />
    <PrivateRouteContainer exact path="/tasks/:id" component={TaskDetailsPage} />
    <PrivateRouteContainer exact path="/external/:url" component={ExternalLinkPageContainer} />
    <PrivateRouteContainer exact path="/order_management" component={OrderManagementPage} />
    <PrivateRouteContainer exact path="/project_management" component={ProjectManagementPage} />
    <PrivateRouteContainer exact path="/finance_dashboard" component={FinanceDashboardPage} />
    <PrivateRouteContainer exact path="/resource_dashboard" component={ResourceDashboardPage} />
    <Route exact path="/static_page/:slug" component={StaticPageContainer} />
    <PrivateRouteContainer path="/dashboard" component={DashboardPage} />
    <PrivateRouteContainer path="/404" component={NotFoundPage} />
    <PrivateRouteContainer path="/500" component={ErrorPage} />
    <Redirect exact from="/" to={root || '/dashboard'} />
    <Route component={NotFoundPage} />
  </Switch>
)

Routes.propTypes = {
  root: PropTypes.string.isRequired
}

export default Routes
