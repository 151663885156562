import React from 'react'
import PropTypes from 'prop-types'
import Trans from 'components/Trans'

const TaskStatus = ({ status }) => (
  <div className={`task-status text-center d-md-block ${status}`}>
    <div className="status-circle outer-circle mb-md-2">
      <div className="inner-circle" />
    </div>
    <p className="task-details-section-text task-status-label">
      <Trans id={`app.task.status.${status}`} />
    </p>
  </div>
)

TaskStatus.propTypes = {
  status: PropTypes.string.isRequired,
}

export default TaskStatus
