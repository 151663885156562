import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'reactstrap'

const DashboardNavLink = ({ title, href, onClick, active }) => {
  const linkId = `nav-link-${href.replace('#', '')}`
  return (
    <NavLink className="smart-nav-link" active={active} href={href} id={linkId} onClick={onClick}>
      <span className="pink-underline-tab-title">{title}</span>
    </NavLink>
  )
}

DashboardNavLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
}

export default DashboardNavLink
