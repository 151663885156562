import { types } from 'redux/actions/snackbar'

const initialState = {
    open: false,
    message: '',
    taskId: '',
    workflowName: '',
    taskGroupId: ''
}

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case types.SHOW_SNACKBAR:
            return {
                ...state,
                open: true,
                message: action.params.message,
                taskId: action.params.taskId,
                taskGroupId: action.params.taskGroupId,
                workflowName: action.params.workflowName
            }
        case types.CLEAR_SNACKBAR:
            return {
                ...state,
                open: false,
                taskId: '',
                taskGroupId: '',
                workflowName: ''
            }
        default:
            return state
    }
}
