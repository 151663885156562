import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { loadGroupList, addGroup } from 'redux/actions/groups'
import Spinner from 'components/Spinner'
import Groups from '.'

class GroupsContainer extends PureComponent {
  componentDidMount() {
    const { fetchGroups } = this.props
    fetchGroups()
  }

  render() {
    const { groups, isLoading, isLoaded, createGroup, userEmail } = this.props

    if (isLoading || !isLoaded) {
      return <Spinner align="center" top={50} />
    }

    return (
      <Groups groups={groups} createGroup={createGroup} userEmail={userEmail} />
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.groups.isLoading,
  isLoaded: state.groups.isLoaded,
  groups: state.groups.data,
  userEmail: state.auth.data.email,
})

const mapDispatchToProps = dispatch => ({
  fetchGroups: () => dispatch(loadGroupList()),
  createGroup: data => dispatch(addGroup(data))
})

GroupsContainer.defaultProps = {
  isLoading: false,
  isLoaded: false,
  groups: [],
}

GroupsContainer.propTypes = {
  fetchGroups: PropTypes.func.isRequired,
  createGroup: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool,
  groups: PropTypes.array,
  userEmail: PropTypes.string.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupsContainer)
