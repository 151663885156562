import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const FoldersPath = ({ folder }) => {
  let folders = [<span className="current"> {folder.title} </span>]

  const buildParentTitle = (node) => {
    if (!node) {
      return ''
    }

    folders.push(
      <span className="parent">
        <Link
          to={`/folders/${node.id}`}
          rel="noopener noreferrer"
        >
          {node.title}
        </Link>
        <span className="signal"> &gt; </span>
      </span>
    )
    const parent = buildParentTitle(node.parent)
    folders.push(parent)

    return folders
  }

  if (folder.parent) {
    folders = buildParentTitle(folder.parent)
  }

  return (
    <div className="folders-path">
      {/* eslint-disable-next-line react/no-array-index-key */}
      {folders.reverse().map((component, i) => <Fragment key={i}> { component } </Fragment>)}
    </div>
  )
}

FoldersPath.defaultProps = {
  folder: {}
}

FoldersPath.propTypes = {
  folder: PropTypes.object
}

export default FoldersPath
