// Given an array with objects containing an 'id' key, removeItemById will return a new array
// without the element that matches the id given as argument. If no element matches the id then it
// returns the original array.
export const removeItemById = (array, id) => {
  const index = array.findIndex(item => item.id === id)

  if (index >= 0) {
    return [
      ...array.slice(0, index),
      ...array.slice(index + 1)
    ]
  }

  return array
}

export const sortObjects = (key, order = 'asc') => (
  (a, b) => {
    if (!Object.prototype.hasOwnProperty.call(a, key)
        || !Object.prototype.hasOwnProperty.call(b, key)) {
      return 0
    }

    const varA = (typeof a[key] === 'string')
      ? a[key].toUpperCase() : a[key]
    const varB = (typeof b[key] === 'string')
      ? b[key].toUpperCase() : b[key]

    let comparison = 0
    if (varA > varB) {
      comparison = 1
    } else if (varA < varB) {
      comparison = -1
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    )
  }
)

export default { removeItemById }
