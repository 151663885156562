import React from 'react'
import { snakeCase, paramCase } from 'change-case'
import DefaultImg from 'images/default-img'
import Trans from 'components/Trans'
import PropTypes from 'prop-types'

const urls = {
  Task: 'tasks',
  Workflow: 'workflows',
  AffiliatePage: 'affiliate_pages',
  Tool: 'tools'
}

const SearchResult = ({ id, name, imageUrl, category, workflow, type, url }) => {
  const link = `/${urls[type]}/${id}`
  const title = <Trans id={`activerecord.models.${snakeCase(type)}.one`} />
  const defaultImageUrl = imageUrl ? <img src={imageUrl} className="results-icon" alt="icon" />
    : (<div className="results-icon"> <DefaultImg /> </div>)

  return (
    <li className={`search-result ${paramCase(type)}`}>
      <a
        href={url || link}
        className="search-result-link"
        title={name}
        aria-label={name}
        alt={title}
        target={type === 'AffiliatePage' ? '_blank' : '_self'}
        rel="noreferrer"
      >
        {defaultImageUrl}

        <div className="d-flex flex-column search-result-info">
          <h4 className="search-result-title">{name}</h4>
          <h5 className="search-result-subtitle">{category || (workflow && workflow.category) || url}</h5>
        </div>

        <span className="type"> {title} </span>
      </a>
    </li>
  )
}

SearchResult.defaultProps = {
  workflow: null,
  url: null,
  imageUrl: null,
  category: null
}

SearchResult.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  category: PropTypes.string,
  workflow: PropTypes.object,
  type: PropTypes.string.isRequired,
  url: PropTypes.string
}

export default SearchResult
