import { types } from 'redux/actions/users'
import * as loadActions from '../middleware/loadActions'

export default (state = loadActions.arrayInitialState, action = {}) => {
  switch (action.type) {
    case types.LOAD_START:
      return loadActions.onLoadStart(state)
    case types.LOAD_SUCCESS:
      return loadActions.onLoadSuccess(state, action)
    case types.LOAD_FAIL:
      return loadActions.onLoadFailure(state, action)
    default:
      return state
  }
}
