import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Trans from 'components/Trans'
import getPropertiesFromBase64 from 'utils/files'
import b64toBlob from 'b64-to-blob'
import { t } from 'utils/i18n/translate'
import { isIso8601DateTime, formatDate, formatTime } from 'utils/time'
import SVGIcon from '../SVGIcon/SVGIcons'
import { parseFields } from './parseFields'

const RenderDownloadableFile = (key, data) => {
  const { fileName, type, b64Data } = getPropertiesFromBase64(data)

  const downloadFileIE = () => {
    if (navigator.msSaveBlob) {
      const blob = b64toBlob(b64Data, type)

      navigator.msSaveOrOpenBlob(blob, fileName)
    }
  }

  return (
    <Fragment>
      <a
        download={fileName}
        href={data}
        onClick={downloadFileIE}
      >
        {fileName}
      </a>
      <a
        download={fileName}
        href={data}
        onClick={downloadFileIE}
      >
        <SVGIcon width="8px" height="24px" className="download-icon" name="download" />
      </a>
    </Fragment>
  )
}

const RenderKeyValuePair = ({ key, data, isFile, localeId, isNested }) => (
  <div className={`tr d-flex align-items-center ${isNested ? 'nested-row' : ''}`} key={key || localeId}>
    <div className={`d-flex ${isNested ? 'nested-row' : 'container'}`}>
      {
        localeId ? (
          <div className="td flex-shrink-0 key"><Trans id={localeId} /></div>
        ) : (
          <div className="td flex-shrink-0 key" key={key}>{key}</div>
        )
      }
      <div className="td d-flex justify-content-between w-100">
        {isFile ? RenderDownloadableFile(key, data) : data && dataValue(data)}
      </div>
    </div>
  </div>
)

const dataValue = (value) => {
  if (isIso8601DateTime(value)) {
    return <div dangerouslySetInnerHTML={{ __html: `${formatDate(value)} <br /> ${formatTime(value)}` }} />
  }

  return value.toString()
}

const renderNestedTable = (key, data) => (
  <div className="tr d-flex align-items-center" key={`${key}-parent`}>
    <div className="container nested-table-container align-items-center">
      <div className="tr key nested-row">{key}</div>
      <div className="tr">
        <div className="nested-table">
          <div className="nested-table-body">
            {
              data.map(field => (
                RenderTaskData(field.key, field.value, true) // eslint-disable-line
              ))
            }
          </div>
        </div>
      </div>
    </div>
  </div>
)

const RenderTaskData = (key, data, isNested) => {
  if (typeof data !== 'object') {
    const isFile = data.toString().includes('data:')

    return (
      RenderKeyValuePair({ key, data, isFile, isNested })
    )
  }

  return renderNestedTable(key, data)
}

const TaskDataTable = ({ title, taskName, taskType, taskData, taskDataSchema, taskMetadata }) => (
  <div className="table table-striped table-task-data">
    <div className="table-title mb-1 container">
      {title}
    </div>
    <div className="table-body">
      { RenderKeyValuePair({ localeId: 'app.task.type', data: t(`app.task.types.${taskType}`) }) }
      { RenderKeyValuePair({ localeId: 'app.task.name', data: taskName }) }
      {
        taskMetadata && Object.entries(taskMetadata).map(([key, value]) => (
          RenderKeyValuePair({ localeId: `app.task.metadata.${key}`,
            data: value })
        ))
      }
      {
        taskData && parseFields(taskData, taskDataSchema).map(field => (
          RenderTaskData(field.key, field.value)
        ))
      }
    </div>
  </div>
)

RenderKeyValuePair.defaultProps = {
  isFile: false,
  localeId: null,
}

RenderKeyValuePair.propTypes = {
  data: PropTypes.object.isRequired,
  key: PropTypes.string.isRequired,
  localeId: PropTypes.string,
  isFile: PropTypes.bool,
  isNested: PropTypes.bool.isRequired,
}

TaskDataTable.defaultProps = {
  taskData: null,
  taskDataSchema: null,
  taskType: null,
  title: null,
  taskMetadata: {}
}

TaskDataTable.propTypes = {
  taskData: PropTypes.object,
  taskType: PropTypes.string,
  title: PropTypes.string,
  taskName: PropTypes.string.isRequired,
  taskDataSchema: PropTypes.object,
  taskMetadata: PropTypes.object
}

export default TaskDataTable
