// Old browsers polyfills
import '@babel/polyfill'

import React from 'react'
import { AppContainer } from 'react-hot-loader'
import { Provider } from 'react-redux'
import { ActionCableProvider } from 'react-actioncable-provider'
import ReactDOM from 'react-dom'
import 'antd/dist/antd.css'
import 'styles/application.scss'
import 'react-toastify/dist/ReactToastify.css'
import 'pure-react-carousel/dist/react-carousel.es.css'
import ApiClient from 'api/ApiClient'
import configureStore, { history } from 'redux/configureStore'
import { ToastContainer } from 'react-toastify'
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles'
import AppPageContainer from './AppContainer'
import Scripts from './Scripts'


const client = new ApiClient()
const store = configureStore(client)

const theme = createMuiTheme({
  typography: {
    fontFamily: 'inherit'
  }
})
const themed = responsiveFontSizes(theme)


// Scroll to top in every page change
history.listen((_location, action) => {
  if (action === 'PUSH') {
    window.scrollTo(0, 0)
  }
})

const render = () => {
  ReactDOM.render(
    <AppContainer>
      <ThemeProvider theme={themed}>
        <ActionCableProvider url={process.env.REACT_APP_CABLE_HOST}>
          <Provider store={store}>
            <Scripts />
            <ToastContainer limit={1} />
            <AppPageContainer history={history} />
          </Provider>
        </ActionCableProvider>
      </ThemeProvider>
    </AppContainer>,
    document.getElementById('root')
  )
}

render()

// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept('./App', () => {
    render()
  })
}
