import { sentenceCase } from 'change-case'

class ModelErrors {
  constructor(errorsObject) {
    this.errors = errorsObject || {}
  }

  any() {
    if (!this.errors) {
      return false
    }

    return Object.keys(this.errors).length > 0
  }

  for(fieldName, full = true) {
    if (!this.any()) {
      return false
    }

    if (!this.errors[fieldName]) {
      return null
    }

    if (full) {
      return this.fullMessage(fieldName)
    }
    return this.errors[fieldName]
  }

  fullMessage(fieldName) {
    const message = this.errors[fieldName]

    return `${sentenceCase(fieldName)} ${message}`
  }

  fullMessages() {
    const keys = Object.keys(this.errors)
    const list = []

    keys.forEach((key) => {
      list.push(this.fullMessage(key))
    })

    return list
  }
}

export default ModelErrors
