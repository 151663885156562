import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'utils/i18n/translate'
import SVGIcon from '../SVGIcon/SVGIcons'

const GoToIconAction = ({ link, width, height, disabled }) => (
  <a
    className="goto-link
    icon-action
    icon-goto
    external-link"
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    tabIndex={disabled ? -1 : 0}
    aria-label={t('app.accessibility.go_to_tool')}
    height="24"
    title={t('app.accessibility.go_to_tool')}
  >
    <SVGIcon width={width} height={height} name="goto" state="normal" stroke="#9eefef" />
  </a>
)

GoToIconAction.defaultProps = {
  width: '24px',
  height: '21px',
  disabled: false
}

GoToIconAction.propTypes = {
  link: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  disabled: PropTypes.bool
}

export default GoToIconAction
