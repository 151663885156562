export const types = {
  LOAD_START: 'users/LOAD_START',
  LOAD_SUCCESS: 'users/LOAD_SUCCESS',
  LOAD_FAIL: 'users/LOAD_FAIL',
}

export const loadUsersList = () => ({
  types: [types.LOAD_START, types.LOAD_SUCCESS, types.LOAD_FAIL],
  promise: client => client.get('/users')
})
