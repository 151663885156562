export const types = {
  ADD_FLASH_MESSAGE: 'flashMessages/ADD_FLASH_MESSAGE',
  DELETE_FLASH_MESSAGE: 'flashMessages/DELETE_FLASH_MESSAGE',
  CLEAR_FLASH_MESSAGES: 'flashMessages/CLEAR_FLASH_MESSAGES'
}

export const addFlashMessage = message => ({
  type: types.ADD_FLASH_MESSAGE,
  message
})

export const deleteFlashMessage = id => ({
  type: types.DELETE_FLASH_MESSAGE,
  id
})

export const clearFlashMessages = () => ({
  type: types.CLEAR_FLASH_MESSAGES
})
