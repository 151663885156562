import React from 'react'
import PropTypes from 'prop-types'
import {
  NavItem,
  Badge
} from 'reactstrap'

const UtilityNavItem = (props) => {
  const {
    id,
    children,
    badgeNumber,
    onClick,
    classes,
    titleTag,
    ariaLabel
  } = props

  // TODO: can be removed after removing the UncontrolledTooltip
  const linkId = `link${id}`
  const showBadge = badgeNumber > 0

  return (
    <NavItem>
      <button
        id={linkId}
        type="button"
        title={titleTag}
        aria-label={ariaLabel}
        data-open="brandnav"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={onClick}
        className={`navbar-icon nav-link ${linkId} ${classes}`}
        tabIndex="0"
      >
        {showBadge
          && <Badge color="primary small">{badgeNumber}</Badge>
        }
        {children}
      </button>
    </NavItem>
  )
}

UtilityNavItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  badgeNumber: PropTypes.number,
  onClick: PropTypes.func,
  children: PropTypes.node,
  classes: PropTypes.string,
  titleTag: PropTypes.string,
  ariaLabel: PropTypes.string
}

UtilityNavItem.defaultProps = {
  onClick: () => { },
  children: null,
  classes: '',
  badgeNumber: 0,
  titleTag: '',
  ariaLabel: ''
}

export default UtilityNavItem
