import { connect } from 'react-redux'
import { toggleMenu, toggleExpand } from 'redux/actions/system'
import HamburgerMenu from './HamburgerMenu'

const mapStateToProps = state => ({
  isOpen: state.system.menu.isOpen,
  notifications: state.notifications.data
})

const mapDispatchToProps = dispatch => ({
  toggleMenu: state => dispatch(toggleMenu(state)),
  toggleExpandButton: () => dispatch(toggleExpand('close'))
})

export default connect(mapStateToProps, mapDispatchToProps)(HamburgerMenu)
