import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { getSearchString } from 'utils/searchString'
import { Nav, NavItem, Container } from 'reactstrap'
import { isMobileOnly } from 'react-device-detect'
import { snakeCase } from 'change-case'
import Trans from 'components/Trans'
import FilterNavLink from './FilterNavLink'

class FilterNavTabs extends PureComponent {
  handleChange = index => () => {
    const { handleChange } = this.props

    handleChange(index)
  }

  render() {
    const { children, activeTab } = this.props
    const types = children.map(child => child.props)
    const activeType = types.filter(type => type.name === activeTab)[0]

    const filterCount = (
      <div className="filter-count">
        <span>
          <span className="count">{activeType.count}</span> <Trans id="app.search.total_message" /> &apos;{getSearchString()}&apos;
          { (activeType.name !== 'All') && <span> in <Trans id={`activerecord.models.${snakeCase(activeType.name)}.one`} /> </span> }
        </span>
      </div>
    )

    return (
      <Fragment>
        <Nav className="filter-tabs">
          <Container>
            { !isMobileOnly && filterCount }
            <div className="filter-links">
              {React.Children.map(children, (child, index) => {
                const key = `filter_nav_item_${index}`
                const name = child.props.name || index

                const options = {
                  ...child.props,
                  key,
                  onClick: this.handleChange(name),
                  active: activeTab === name
                }

                return (
                  React.cloneElement(child, options)
                )
              })}
            </div>
          </Container>
        </Nav>
        { isMobileOnly && (
          <Container>
            { filterCount }
          </Container>
        )}
      </Fragment>
    )
  }
}

FilterNavTabs.defaultProps = {
  handleChange: () => {},
  activeTab: 0
}

FilterNavTabs.propTypes = {
  children: PropTypes.node.isRequired,
  handleChange: PropTypes.func,
  activeTab: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

const FilterNavTab = ({ active, onClick, href, title, count, name }) => {
  const classes = `filter-tab ${name.toLowerCase()}`
  return (
    <NavItem className={classes} active={active}>
      <FilterNavLink onClick={onClick} href={href} title={title} count={count} />
    </NavItem>
  )
}

FilterNavTab.defaultProps = {
  active: false,
  href: '',
  name: '',
  count: undefined,
  onClick: () => {}
}

FilterNavTab.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  href: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string.isRequired,
  count: PropTypes.number
}

FilterNavTabs.Tab = FilterNavTab

export default FilterNavTabs
