import React from 'react'

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" id="prefix__dashboard-icon-listView" width="24" height="24" viewBox="-0.5 -0.5 25 25">
    <g id="Rectangle_6817" className="cls-1" fill="none" data-name="Rectangle 6817">
      <rect width="6" height="6" className="cls-2" stroke="none" rx="1" />
      <rect width="4.5" height="4.5" x=".75" y=".75" className="cls-3" fill="none" rx=".25" />
    </g>
    <g id="Rectangle_6818" className="cls-1" fill="none" data-name="Rectangle 6818" transform="translate(0 9)">
      <rect width="6" height="6" className="cls-2" stroke="none" rx="1" />
      <rect width="4.5" height="4.5" x=".75" y=".75" className="cls-3" fill="none" rx=".25" />
    </g>
    <g id="Rectangle_6819" className="cls-1" fill="none" data-name="Rectangle 6819" transform="translate(0 18)">
      <rect width="6" height="6" className="cls-2" stroke="none" rx="1" />
      <rect width="4.5" height="4.5" x=".75" y=".75" className="cls-3" fill="none" rx=".25" />
    </g>
    <g id="Rectangle_6820" className="cls-1" fill="none" data-name="Rectangle 6820" transform="translate(7)">
      <rect width="17" height="6" className="cls-2" stroke="none" rx="1" />
      <rect width="15.5" height="4.5" x=".75" y=".75" className="cls-3" fill="none" rx=".25" />
    </g>
    <g id="Rectangle_6826" className="cls-1" fill="none" data-name="Rectangle 6826" transform="translate(7 9)">
      <rect width="17" height="6" className="cls-2" stroke="none" rx="1" />
      <rect width="15.5" height="4.5" x=".75" y=".75" className="cls-3" fill="none" rx=".25" />
    </g>
    <g id="Rectangle_6827" className="cls-1" fill="none" data-name="Rectangle 6827" transform="translate(7 18)">
      <rect width="17" height="6" className="cls-2" stroke="none" rx="1" />
      <rect width="15.5" height="4.5" x=".75" y=".75" className="cls-3" fill="none" rx=".25" />
    </g>
  </svg>
)
