import { connect } from 'react-redux'
import { setFilter, toggleExpand, toggleDashboardView, setTitle } from 'redux/actions/system'
import { markAllAsRead } from 'redux/actions/notifications'
import { loadTaskList } from 'redux/actions/tasks'
import NotificationsPage from '.'

const mapDispatchToProps = dispatch => ({
  setTasksFilter: value => dispatch(setFilter('tasks', value)),
  loadTasks: params => (dispatch(loadTaskList(params))),
  toggleExpandButton: () => dispatch(toggleExpand('open')),
  toggleDashboardView: (area) => { dispatch(toggleDashboardView(area)) },
  markAsRead: () => dispatch(markAllAsRead()),
  setPageTitle: title => (dispatch(setTitle(title)))
})

export default connect(null, mapDispatchToProps)(NotificationsPage)
