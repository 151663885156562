import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { t } from 'utils/i18n/translate'
import SVGIcon from '../SVGIcon/SVGIcons'

class FavoriteIconAction extends PureComponent {
  toggleFavorite = (event) => {
    event.stopPropagation()

    const { favorite, handleMakeFavorite, handleRemoveFavorite } = this.props

    if (favorite) {
      handleRemoveFavorite(favorite.id)
    } else {
      handleMakeFavorite()
    }
  }

  render() {
    const { favorite, height, width, disabled } = this.props
    const iconClass = classNames('icon-action icon-favorite', { favorite })
    const accessibilityTagsText = favorite ? t('app.accessibility.unmark_favorite') : t('app.accessibility.mark_as_favorite')

    return (
      <button
        type="button"
        className={iconClass}
        onClick={this.toggleFavorite}
        tabIndex={disabled ? -1 : 0}
        title={accessibilityTagsText}
        aria-label={accessibilityTagsText}
      >
        <SVGIcon width={width} height={height} name={favorite ? 'favorite_active' : 'favorite'} state={favorite ? 'active' : 'normal'} />
      </button>
    )
  }
}

FavoriteIconAction.defaultProps = {
  favorite: null,
  width: '24px',
  height: '24px',
  disabled: false
}

FavoriteIconAction.propTypes = {
  favorite: PropTypes.object,
  handleMakeFavorite: PropTypes.func.isRequired,
  handleRemoveFavorite: PropTypes.func.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  disabled: PropTypes.bool
}

export default FavoriteIconAction
