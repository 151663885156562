import React from 'react'
import PropTypes from 'prop-types'
import Sticky from 'react-sticky-el'
import { isMobile } from 'react-device-detect'
import { t } from 'utils/i18n/translate'
import TasksContainer from 'components/Tasks/container'
import FiltersContainer from 'components/Filters/container'
import BpmnChartContainer from 'components/BpmnChart/container'
import classnames from 'classnames'
import DashboardNavTabs from 'components/DashboardNavTabs'
import { TabContent, TabPane } from 'reactstrap'


const Sidebar = (props) => {
  const { title, height, isExpanded, count, workflowId, filterValue,
    selectedTab, handleChange, isATemplate } = props
  const asideClasses = classnames('sidebar', { expanded: isExpanded, 'col-xs-2': !isExpanded, 'col-lg-3': !isExpanded })
  const taskContainerStyle = isExpanded ? {} : { maxHeight: height, overflow: 'auto' }
  const isNotification = filterValue === 'notifications'

  const sidebarTitle = isNotification ? t('app.filters.notifications') : title
  const overviewTab = t('app.workflows.overview')
  const currentTab = isNotification ? 'tasks-tab' : selectedTab

  return (
    <aside className={asideClasses}>
      <Sticky
        disabled={isMobile || isExpanded}
        topOffset={-10}
        bottomOffset={0}
        boundaryElement=".sidebar"
        style={{ position: 'relative' }}
        hideOnBoundaryHit={false}
      >
        <header className="sidebar-header">
          <div className="container">
            <DashboardNavTabs handleChange={handleChange} activeTab={currentTab}>
              <DashboardNavTabs.Tab name="overview-tab" className="data" href="#" title={overviewTab} hidden={isNotification} />
              <DashboardNavTabs.Tab name="tasks-tab" className="form" href="#" hidden={isATemplate} title={`${sidebarTitle} (${count})`} />
            </DashboardNavTabs>
          </div>
        </header>

        { !isATemplate && (
          <TabContent className="tasks-content" activeTab={currentTab}>
            <TabPane tabId="tasks-tab">
              <FiltersContainer area="tasks" workflowId={workflowId} />
              <div className="mt-4" style={taskContainerStyle}>
                <TasksContainer isExpanded={isExpanded} workflowId={workflowId} />
              </div>
            </TabPane>
          </TabContent>
        )}

        <TabContent className="overview-content" activeTab={currentTab}>
          <TabPane tabId="overview-tab">
            <BpmnChartContainer workflowId={workflowId} />
          </TabPane>
        </TabContent>
      </Sticky>
    </aside>
  )
}

Sidebar.defaultProps = {
  height: 250,
  isExpanded: false,
  count: 0,
  workflowId: null,
  filterValue: null,
  isATemplate: false,
  selectedTab: 'overview-tab'
}

Sidebar.propTypes = {
  title: PropTypes.string.isRequired,
  height: PropTypes.number,
  isExpanded: PropTypes.bool,
  count: PropTypes.number,
  isATemplate: PropTypes.bool,
  workflowId: PropTypes.string,
  filterValue: PropTypes.string,
  selectedTab: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
}

export default Sidebar
