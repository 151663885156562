import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import VerticalTile from 'components/VerticalTile'

const Folder = ({ folder }) => {
  const folderComponent = folder.source
    ? (
      <VerticalTile
        className="folder"
        {...folder.source}
        type={folder.sourceType}
      />
    ) : (
      <Link className="folder" to={`/folders/${folder.id}`} rel="noopener noreferrer">
        <div className="image" />
        <h4>
          { folder.title }
        </h4>
      </Link>
    )

  return (
    <Fragment>
      {folderComponent}
    </Fragment>
  )
}

Folder.propTypes = {
  folder: PropTypes.object.isRequired
}

export default Folder
