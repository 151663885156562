import React from 'react'
import PropTypes from 'prop-types'
import { PieChart, Pie, Tooltip, Legend, ResponsiveContainer } from 'recharts'

export const SimplePieChart = ({ data }) => (
  <ResponsiveContainer width="100%" height={300}>
    <PieChart>
      <Pie dataKey="value" data={data} outerRadius="80%" fill="#2196f3" />
      <Tooltip />
      <Legend />
    </PieChart>
  </ResponsiveContainer>
)

SimplePieChart.propTypes = {
  data: PropTypes.array.isRequired
}

export default SimplePieChart
