import React, { PureComponent, Fragment } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Card,
  CardImgOverlay,
} from 'reactstrap'
import classNames from 'classnames'
import Image from 'components/Image/'
import lockIcon from 'images/lock_outline.svg'
import ActionIcons from 'components/Workflows/ActionIcons'
import ToolActionIcons from 'components/Tools/ToolActionIcons'
import { history } from 'redux/configureStore'
import Clickable from 'components/Metrics/Clickable'

class HorizontalTile extends PureComponent {
  toggleFavorite = (event) => {
    // Avoid open/close the tile when add/remove favorite
    event.stopPropagation()

    const { favorite, handleMakeFavorite, handleRemoveFavorite } = this.props

    if (favorite) {
      handleRemoveFavorite(favorite.id)
    } else {
      handleMakeFavorite()
    }
  }

  favoriteClasses = () => {
    const { favorite } = this.props
    let favClassNames = 'favorite'

    if (favorite) {
      favClassNames += ' active'
    }

    return favClassNames
  }

  openDetailPage = () => {
    const { id, type } = this.props
    const url = type === 'Workflow' ? 'workflows' : 'tools'
    const detailPageUrl = `/${url}/${id}`
    history.push(detailPageUrl)
  }

  render() {
    const {
      type,
      imageUrl,
      name,
      category,
      isATemplate,
      status,
      link,
      id,
      tasksCount,
      altImage } = this.props

    const isRestricted = status === 'restricted'
    const isTool = type === 'Tool'
    const isWorkflow = type === 'Workflow'
    const cardClasses = classNames('d-flex', 'flex-row', 'justify-content-between', { disabled: isRestricted }, { tool: isTool }, { workflow: isWorkflow })
    const detailPageUrl = `/workflows/${id}`

    return (
      <Fragment>
        <Card className={cardClasses}>
          <div className="left-content d-flex align-items-center">
            <Image
              withBlackOverlay
              url={imageUrl}
              alt={altImage || undefined}
              width={130}
              height={87}
            />
            {
              isRestricted && (
                <CardImgOverlay className="d-flex justify-content-center align-items-center text-center">
                  <img src={lockIcon} alt="lock" className="lock-icon" />
                </CardImgOverlay>
              )
            }
            <div className="info-container">
              <h4>{name}</h4>
              <h5>{category}</h5>
            </div>
          </div>
          {isWorkflow && <Link className="card-link" title={name} aria-label={name} to={detailPageUrl} rel="noopener noreferrer" /> }
          {
            // eslint-disable-next-line
            isTool && <Clickable id={id} resource="Tool"> <a className="card-link" href={link} target="_blank" title={name} aria-label={name} rel="noopener noreferrer" /> </Clickable>
          }
          <div className="right-content d-flex align-items-center">
            {
              !isTool && (
                <ActionIcons
                  count={tasksCount}
                  action={this.openDetailPage}
                  isATemplate={isATemplate}
                  id={id}
                  name={name}
                  disabled={isRestricted}
                />
              )
            }
            { isTool && (
              <ToolActionIcons
                count={tasksCount}
                action={this.openDetailPage}
                id={id}
                name={name}
                disabled={isRestricted}
                link={link}
              />
            )}
          </div>
        </Card>
      </Fragment>
    )
  }
}

HorizontalTile.defaultProps = {
  favorite: null,
  tasksCount: 0,
  category: null,
  link: null,
  altImage: null,
  isATemplate: false
}

HorizontalTile.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  isATemplate: PropTypes.bool,
  tasksCount: PropTypes.number,
  category: PropTypes.string,
  favorite: PropTypes.object,
  handleMakeFavorite: PropTypes.func.isRequired,
  handleRemoveFavorite: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  link: PropTypes.string,
  altImage: PropTypes.string
}

export default HorizontalTile
