/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { addClickMetric } from 'redux/actions/metrics'

export const Clickable = ({ children, id, resource, addClick }) => {
  const sendClickMetric = () => {
    const params = { id, resource, type: 'click' }
    addClick(params)
  }

  return (
    <div onClick={sendClickMetric} className="clickable-metric" tabIndex={-1}>
      {children}
    </div>
  )
}

Clickable.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  id: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  addClick: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
  addClick: params => dispatch(addClickMetric(params))
})

export default connect(null, mapDispatchToProps)(Clickable)
