import simpleRestProvider from 'ra-data-simple-rest'
import { fetchUtils } from 'react-admin'
import { loadCookie } from 'utils/cookies_'
import { AUTH_TOKEN_KEY } from 'redux/actions/auth'
import decodeJwt from 'jwt-decode'

const token = loadCookie(AUTH_TOKEN_KEY)
const fetchJson = (url, options = {}) => {
  const headers = new Headers({ Authorization: `Bearer ${token}` })

  return fetchUtils.fetchJson(url, { ...options, headers })
}

const getCurrentUser = (
  token ? decodeJwt(token).user : null
)

const dataProvider = simpleRestProvider(
  `${process.env.REACT_APP_BACKEND_HOST}/api/v1/resources`, fetchJson
)

const dashboardDataProvider = {
  ...dataProvider,
  callAction: (params) => {
    const url = `${process.env.REACT_APP_BACKEND_HOST}/workflow_engine/api/v1/start`
    return fetchJson(`${url}/${params.id}`, {
      method: 'POST',
      body: JSON.stringify({ data: params.data }),
    }).then(response => ({ data: response.body }))
  },
  submitAction: (params) => {
    const url = `${process.env.REACT_APP_BACKEND_HOST}/workflow_engine/api/v1/tasks/`
    return fetchJson(`${url}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify({ payload: params.data }),
    }).then(response => ({ data: response.body }))
  }
}

export {
  dashboardDataProvider,
  getCurrentUser
}
