import React, { PureComponent, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { t } from 'utils/i18n/translate'
import {
  Card,
  CardImgOverlay,
} from 'reactstrap'
import Image from 'components/Image'
import Clickable from 'components/Metrics/Clickable'
import lockIcon from 'images/lock_outline.svg'
import TileActionsContainer from './TileActionsContainer'

class VerticalTile extends PureComponent {
  render() {
    const {
      status,
      id,
      imageUrl,
      name,
      category,
      link,
      isATemplate,
      type,
      layout,
      altImage,
      tasksCount,
      className
    } = this.props
    const layoutClass = isMobileOnly ? 'layout-2' : `layout-${layout}`
    const isTool = type === 'Tool'
    const isWorkflow = type === 'Workflow'
    const cardCss = classNames('smart_tile', status, layoutClass, { tool: isTool }, { workflow: isWorkflow }, className)
    const isRestricted = status === 'restricted'
    const detailPageUrl = isWorkflow ? `/workflows/${id}` : `/tools/${id}`
    const workflowSubTitle = isATemplate ? t('app.workflows.templates.title') : category

    const renderImageOverlay = () => (
      <CardImgOverlay className="d-flex justify-content-center align-items-center text-center">
        <img src={lockIcon} alt="lock" className="lock-icon" />
      </CardImgOverlay>
    )

    return (
      <Fragment>
        <Card className={cardCss}>
          {isRestricted && renderImageOverlay()}
          {isWorkflow && <Link className="card-link" title={name} aria-label={name} tabIndex="0" to={detailPageUrl} rel="noopener noreferrer" />}
          {
            // eslint-disable-next-line
            isTool && <Clickable id={id} resource="Tool"> <a className="card-link"  href={link} title={name} aria-label={name} target="_blank" rel="noopener noreferrer" /> </Clickable>
          }
          <div className="smart_tile_img">
            <Image withBlackOverlay url={imageUrl} alt={altImage || undefined} />
            <div className="tile-gradient" />
          </div>
          <div className="smart_tile_info">
            { !isRestricted
              && (
                <div className="smart_tile_actions_wrapper">
                  <TileActionsContainer
                    type={type}
                    id={id}
                    name={name}
                    isATemplate={isATemplate}
                    detailPageUrl={detailPageUrl}
                    link={link}
                    status={status}
                    tasksCount={tasksCount}
                    isTool={isTool}
                    isWorkflow={!isTool}
                  />
                </div>
              )
            }
            <div className="smart_tile_name">{name}</div>
            <div className="smart_tile_category">{workflowSubTitle}</div>
          </div>
        </Card>
      </Fragment>
    )
  }
}

VerticalTile.defaultProps = {
  tasksCount: 0,
  className: null,
  link: '',
  altImage: null,
  isATemplate: false
}

VerticalTile.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  status: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  link: PropTypes.string,
  isATemplate: PropTypes.bool,
  layout: PropTypes.number.isRequired,
  tasksCount: PropTypes.number,
  className: PropTypes.string,
  altImage: PropTypes.string
}

const mapStateToProps = (state, ownProps) => ({
  category: ownProps.type === 'Tool' ? ownProps.workflowCategory.name : ownProps.category,
  layout: state.system.layoutCols
})

export default connect(mapStateToProps)(VerticalTile)
