import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import { checkSession } from 'redux/actions/auth'
import qs from 'querystringify'
// import { clearFlashMessages } from 'redux/actions/flashMessages'
import PropTypes from 'prop-types'
// import ReactRouterPropTypes from 'react-router-prop-types'

class SessionWrapper extends Component {
  componentDidMount() {
    const { checkSession: check, location } = this.props
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })

    if (query.automatic_login) {
      const backendURL = `${process.env.REACT_APP_BACKEND_HOST || ''}/users/auth/dashboard_sso`
      window.location.href = backendURL
      return
    }
    check()
  }

  // componentDidUpdate(prevProps) {
  //   const { location, clearFlashMessages } = this.props

  //   // TODO: quick hack for now. Maybe we can include a "scope" in the flash messages to know in
  //   // which location they belong to so we don't remove them?
  //   if (location.pathname !== prevProps.location.pathname && location.pathname === '/register') {
  //     handlePageChange()
  //   }
  // }

  render() {
    const { isSessionChecked, children } = this.props

    if (!isSessionChecked) {
      // TODO: replace by propper loading
      return null
    }

    return (<Fragment>{children}</Fragment>)
  }
}

const mapStateToProps = state => ({
  isSessionChecked: state.auth.isSessionChecked,
  location: state.router.location
})

const mapDispatchToProps = dispatch => ({
  checkSession: () => dispatch(checkSession())
  // clearFlashMessages: () => dispatch(clearFlashMessages())
})

SessionWrapper.defaultProps = {
  isSessionChecked: false
}

SessionWrapper.propTypes = {
  checkSession: PropTypes.func.isRequired,
  isSessionChecked: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  location: PropTypes.object.isRequired,
  // clearFlashMessages: PropTypes.func.isRequired,
  // location: ReactRouterPropTypes.location.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionWrapper)
