/* eslint-disable react/no-danger */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import DefaultLayout from 'layouts/DefaultLayout'
import BackButton from 'components/BackButton'
import { connect } from 'react-redux'
import ReactRouterPropTypes from 'react-router-prop-types'
import * as loadPageActions from 'redux/actions/page'
import { t } from 'utils/i18n/translate'
import { setTitle } from 'redux/actions/system'

export class StaticPageContainer extends PureComponent {
  componentDidMount() {
    const { loadPage: loadPageReact, match, setPageTitle } = this.props
    const { slug } = match.params

    loadPageReact(slug)
    setPageTitle(t(`app.pages.titles.${slug}`))
  }

  componentWillReceiveProps(nextProps) {
    const { match } = this.props
    const { slug } = match.params
    const { slug: nextSlug } = nextProps.match.params

    if (slug !== nextSlug) {
      const { loadPage: loadPageReact } = this.props

      loadPageReact(nextSlug)
    }
  }

  componentDidUpdate() {
    const { match, setPageTitle } = this.props
    const { slug } = match.params
    setPageTitle(t(`app.pages.titles.${slug}`))
  }

  render() {
    const { content } = this.props

    return (
      <DefaultLayout>
        <div className="container pt-3">
          <BackButton />
          <div dangerouslySetInnerHTML={{ __html: content }} className="static-page" />
        </div>
      </DefaultLayout>
    )
  }
}

StaticPageContainer.propTypes = {
  loadPage: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  content: PropTypes.string.isRequired,
  setPageTitle: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  name: state.page.data.name,
  content: state.page.data.content
})

const mapDispatchToProps = dispatch => ({
  loadPage: slug => dispatch(loadPageActions.loadPage(slug)),
  setPageTitle: title => (dispatch(setTitle(title)))
})

export default connect(mapStateToProps, mapDispatchToProps)(StaticPageContainer)
