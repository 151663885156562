import ModelErrors from 'utils/ModelErrors'

export const initialStateWithLoadStatus = {
  isLoaded: false,
  isLoading: false,
  status: 200
}

export const objectInitialState = {
  ...initialStateWithLoadStatus,
  data: {},
  errors: new ModelErrors()
}

export const arrayInitialState = {
  ...initialStateWithLoadStatus,
  data: []
}

export const onLoadStart = state => ({
  ...state,
  isLoading: true,
  isLoaded: false,
  status: 200
})

export const onLoadComplete = state => ({
  ...state,
  isLoading: false,
  isLoaded: true,
  status: 200
})

export const onLoadSuccess = (state, action) => ({
  ...state,
  isLoading: false,
  isLoaded: true,
  data: action.response.data,
  status: 200
})

export const onLoadFailure = (state, action) => ({
  ...state,
  isLoading: false,
  isLoaded: true,
  status: action.error.response.status
})
