import React, { PureComponent } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { NavItem, Badge } from 'reactstrap'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { slide as Menu } from 'react-burger-menu'
import SVGIcon from 'components/SVGIcon/SVGIcons'
import UserAccountIcon from 'images/user-account'
import NotificationIcon from 'images/ringtone-on'
import Trans from 'components/Trans'
import CloseIcon from 'images/close-menu'
import MenuIcon from 'images/menu'
import HelpIcon from 'images/help'
import classNames from 'classnames'
import GroupIcon from 'images/GroupIcon'


class HamburgerMenu extends PureComponent {
  logoutAllApps = () => {
    const { handleLogout } = this.props
    handleLogout(true)
  }

  toggleOpen = (state) => {
    const { toggleMenu } = this.props
    toggleMenu(state.isOpen)
  }

  render() {
    const {
      handleLogout,
      firstName,
      role,
      hasHelpPage,
      isOpen,
      notifications,
      toggleExpandButton: collapse
    } = this.props
    const menuIcon = isOpen ? <span className="close-menu"><CloseIcon /></span> : <MenuIcon />
    const isAdmin = role === 'admin'
    const isEditor = role === 'editor'
    const adminURL = `${process.env.REACT_APP_BACKEND_HOST || ''}/admin`
    const editorURL = `${process.env.REACT_APP_BACKEND_HOST || ''}/editor`
    const badgeNumber = notifications.length
    const shouldShowBadge = badgeNumber > 0 && !isOpen
    const menuClasses = classNames({ open: isOpen }, 'burger-menu', 'over-menu')

    return (
      <NavItem className={menuClasses}>
        {shouldShowBadge
          && <Badge color="primary small menu" />
        }
        <Menu
          right
          customBurgerIcon={menuIcon}
          onStateChange={this.toggleOpen}
          isOpen={isOpen}
          customCrossIcon={<CloseIcon />}
        >
          <button className="menu-item name" type="button">{firstName}</button>
          <Link className="menu-item dashboard" to="/dashboard" onClick={collapse}>
            <SVGIcon className="dashboard-item" name="dashboard" state="normal" />
            <Trans id="app.menu.dashboard" />
          </Link>
          { process.env.REACT_APP_SHOW_FOOTER === 'true' && (
            <Link className="menu-item magazine" to="/magazin/marketing">
              <SVGIcon className="magazine-item" name="magazine" state="normal" />
              <Trans id="app.menu.magazine" />
            </Link>
          )}
          { isMobileOnly && (
            <Link className="menu-item notifications" to="/notifications">
              <NotificationIcon />
              <Trans id="app.menu.notifications" />
              {badgeNumber > 0
                && <Badge color="primary small">{badgeNumber}</Badge>
              }
            </Link>
          )}
          <Link className="menu-item profile-menu" to="/groups">
            <GroupIcon />
            <Trans id="app.menu.groups" />
          </Link>

          <Link className="menu-item profile-menu" to="/profile">
            <UserAccountIcon />
            <Trans id="app.menu.profile" />
          </Link>
          { hasHelpPage && (
            <Link className="menu-item help" to="/static_page/help">
              <HelpIcon />
              <Trans id="app.menu.help" />
            </Link>
          )}
          { isAdmin && (
            <a id="admin" className="menu-item admin" href={adminURL}>
              <Trans id="app.menu.admin" />
            </a>
          )}
          { isEditor && (
            <a id="editor" className="menu-item" href={editorURL}>
              <Trans id="app.menu.editor" />
            </a>
          )}

          <div className="logout-links">
            <button id="logout-portal" className="menu-item" onClick={() => handleLogout()} type="button">
              <Trans id="app.auth.logout_from_portal" />
            </button>
            <button id="logout-sso" className="menu-item" onClick={this.logoutAllApps} type="button">
              <Trans id="app.auth.logout_from_sso" />
            </button>
          </div>
        </Menu>
      </NavItem>
    )
  }
}

HamburgerMenu.defaultProps = {
  role: null,
  isOpen: false
}

HamburgerMenu.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  role: PropTypes.string,
  hasHelpPage: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool,
  toggleMenu: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
  toggleExpandButton: PropTypes.func.isRequired
}

export default HamburgerMenu
