import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { toggleExpand } from 'redux/actions/system'
import {
  NavItem
} from 'reactstrap'
import PropTypes from 'prop-types'
import SVGIcon from 'components/SVGIcon/SVGIcons'
import { Link } from 'react-router-dom'
import { t } from 'utils/i18n/translate'

export const DashboardViewNavItem = ({ dashboardView, toggleExpandButton, showMagazine }) => {
  const collapseSidebar = () => {
    toggleExpandButton()
  }

  return (
    <Fragment>
      <NavItem>
        <Link
          title={t('app.accessibility.dashboard')}
          aria-label={t('app.accessibility.dashboard')}
          className="dashboard-link
          navbar-icon nav-link"
          to="/dashboard"
          onClick={collapseSidebar}
        >
          <SVGIcon className="dashboard-item" width="24" height="24" name="dashboard" state={dashboardView === 'dashboard' ? 'active' : 'normal'} />
        </Link>
      </NavItem>
      { (showMagazine || process.env.REACT_APP_SHOW_MAGAZINE === 'true') && (
        <NavItem>
          <Link
            className="magazine-link navbar-icon nav-link"
            to="/magazin/marketing"
            title={t('app.accessibility.magazine')}
            aria-label={t('app.accessibility.magazine')}
          >
            <SVGIcon className="magazine-item" width="24" height="28" name="magazine" state={dashboardView === 'magazine' ? 'active' : 'normal'} />
          </Link>
        </NavItem>
        )
      }
    </Fragment>
  )
}

DashboardViewNavItem.defaultProps = {
  showMagazine: undefined
}

DashboardViewNavItem.propTypes = {
  dashboardView: PropTypes.string.isRequired,
  toggleExpandButton: PropTypes.func.isRequired,
  showMagazine: PropTypes.bool
}

const mapStateToProps = state => ({
  dashboardView: state.system.dashboardView
})

const mapDispatchToProps = dispatch => ({
  toggleExpandButton: () => dispatch(toggleExpand('close'))
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardViewNavItem)
