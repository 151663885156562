export const types = {
  LOAD_TASKS_START: 'tasks/LOAD_TASKS_START',
  LOAD_TASKS_SUCCESS: 'tasks/LOAD_TASKS_SUCCESS',
  LOAD_TASKS_FAIL: 'tasks/LOAD_TASKS_FAIL',
  ADD_TASK: 'tasks/ADD_TASK',
  UPDATE_TASK: 'tasks/UPDATE_TASK'
}

export const loadTaskList = (params = {}) => ({
  types: [types.LOAD_TASKS_START, types.LOAD_TASKS_SUCCESS, types.LOAD_TASKS_FAIL],
  promise: client => client.get('/tasks', { params })
})

export const loadTaskListByWorkflowCategory = (params = {}) => ({
  types: [types.LOAD_TASKS_START, types.LOAD_TASKS_SUCCESS, types.LOAD_TASKS_FAIL],
  promise: client => client.get('/tasks', { params })
})

export const addTask = task => ({
  type: types.ADD_TASK,
  task
})

export const updateTask = (taskId, params) => ({
  type: types.UPDATE_TASK,
  taskId,
  params
})
