import React from 'react'

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" id="prefix__dashboard-icon-cancel" width="24" height="24" viewBox="0 0 24 24">
    <g id="prefix__Group_5736" data-name="Group 5736" transform="rotate(45 2145.866 -591.876)">
      <path id="prefix__ic_vertical_align_bottom_24px" d="M4 24.333V27h21.34v-2.667z" className="prefix__cls-2" transform="translate(1049.33 1318)" />
      <circle id="prefix__Ellipse_898" cx="1.33" cy="1.33" r="1.33" className="prefix__cls-2" data-name="Ellipse 898" transform="translate(1052 1342.35)" />
      <circle id="prefix__Ellipse_899" cx="1.33" cy="1.33" r="1.33" className="prefix__cls-2" data-name="Ellipse 899" transform="translate(1073.34 1342.35)" />
    </g>
    <g id="prefix__Group_5737" data-name="Group 5737" transform="rotate(45 2145.866 -591.876)">
      <path id="prefix__ic_vertical_align_bottom_24px-2" d="M0 0v2.667h21.342V0z" className="prefix__cls-2" data-name="ic_vertical_align_bottom_24px" transform="rotate(90 -134.16 1199.17)" />
      <circle id="prefix__Ellipse_909" cx="1.33" cy="1.33" r="1.33" className="prefix__cls-2" data-name="Ellipse 909" transform="rotate(90 -133.504 1198.496)" />
      <circle id="prefix__Ellipse_910" cx="1.33" cy="1.33" r="1.33" className="prefix__cls-2" data-name="Ellipse 910" transform="rotate(90 -144.175 1209.167)" />
    </g>
  </svg>

)
