export const types = {
  LOAD_START: 'workflows/LOAD_START',
  LOAD_SUCCESS: 'workflows/LOAD_SUCCESS',
  LOAD_FAIL: 'workflows/LOAD_FAIL',

  LOAD_TEMPLATES_START: 'workflows/LOAD_TEMPLATES_START',
  LOAD_TEMPLATES_SUCCESS: 'workflows/LOAD_TEMPLATES_SUCCESS',
  LOAD_TEMPLATES_FAIL: 'workflows/LOAD_TEMPLATES_FAIL',

  CREATION_START: 'workflows/CREATION_START',
  CREATION_SUCCESS: 'workflows/CREATION_SUCCESS',
  CREATION_FAIL: 'workflows/CREATION_FAIL',

  INCREMENT_TASKS_COUNT: 'workflows/INCREMENT_TASKS_COUNT',
  DECREASE_TASKS_COUNT: 'workflows/DECREASE_TASKS_COUNT',

  CREATE_FROM_TEMPLATE_START: 'workflows/CREATE_FROM_TEMPLATE_START',
  CREATE_FROM_TEMPLATE_SUCCESS: 'workflows/CREATE_FROM_TEMPLATE_SUCCESS',
  CREATE_FROM_TEMPLATE_FAIL: 'workflows/CREATE_FROM_TEMPLATE_FAIL',
}

export const loadWorfklowList = (params = {}) => ({
  types: [types.LOAD_START, types.LOAD_SUCCESS, types.LOAD_FAIL],
  promise: client => client.get('/workflows', { params })
})

export const loadWorfklowTemplates = () => ({
  types: [types.LOAD_TEMPLATES_START, types.LOAD_TEMPLATES_SUCCESS, types.LOAD_TEMPLATES_FAIL],
  promise: client => client.get('/workflows', { params: { templates: 'true' } })
})

export const startWorkflow = id => ({
  types: [types.CREATION_START, types.CREATION_SUCCESS, types.CREATION_FAIL],
  promise: client => client.post(`/start/${id}`, {}, '/workflow_engine/api/v1')
})

export const createTaskFromStep = id => ({
  types: [types.CREATION_START, types.CREATION_SUCCESS, types.CREATION_FAIL],
  promise: client => client.post('/tasks/', { data: { stepId: id } }, '/workflow_engine/api/v1')
})

export const incrementTasksCount = workflowId => (
  {
    type: types.INCREMENT_TASKS_COUNT,
    id: workflowId
  }
)

export const decreaseTasksCount = workflowId => (
  {
    type: types.DECREASE_TASKS_COUNT,
    id: workflowId
  }
)

export const createWorkflow = (workflowId, groupId) => ({
  types: [
    types.CREATE_FROM_TEMPLATE_START,
    types.CREATE_FROM_TEMPLATE_SUCCESS,
    types.CREATE_FROM_TEMPLATE_FAIL
  ],
  promise: client => client.post('/workflows/', { params: { template_id: workflowId, group_id: groupId } })
})

export const importWorkflows = groupId => ({
  types: [
    types.IMPORT_WORKFLOWS_START,
    types.IMPORT_WORKFLOWS_SUCCESS,
    types.IMPORT_WORKFLOWS_FAIL
  ],
  promise: client => client.post('/workflows_templates/', { params: { group_id: groupId } })
})
