import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { isMobileOnly } from 'react-device-detect'
import { Row, Col, Container } from 'reactstrap'
import HorizontalTileContainer from 'components/HorizontalTile/HorizontalTileContainer'
import VerticalTile from 'components/VerticalTile'
import Trans from 'components/Trans'
import EmptyState from 'components/EmptyState'
import { t } from 'utils/i18n/translate'
import { Link } from 'react-router-dom'
import TemplateIcon from 'images/templates'
import WorkflowsHeader from './WorkflowsHeader'

const Workflows = ({ workflows, layoutCols, isGridView }) => {
  const emptyState = () => (
    <EmptyState title={t('app.workflow.none')} />
  )

  const workflowsHeader = () => (
    <Container>
      <div className="container-header">
        <span className="description"> <Trans id="app.workflows.explanation" /> </span>

        <Link to="/templates" className="btn btn-primary-icon">
          <span className="icon"> <TemplateIcon /> </span>
          <span className="text"> <Trans id="app.workflows.templates.page_title" /> </span>
        </Link>
      </div>
    </Container>
  )

  const renderGridView = () => {
    const layoutClass = isMobileOnly ? 'layout-2' : `layout-${layoutCols}`
    return (
      <Fragment>
        <div className="main-workflows-container">
          { workflowsHeader() }
          <Row className={`dashboard-row ${layoutClass}`}>
            {workflows.map(workflow => (
              <div key={`grid_${workflow.id}`} className="tile_grid">
                <VerticalTile
                  {...workflow}
                  type="Workflow"
                />
              </div>
            ))}
          </Row>
          { workflows.length === 0 && emptyState() }
        </div>
      </Fragment>
    )
  }

  const renderListView = () => (
    <Fragment>
      <div className="main-workflows-container">
        { workflowsHeader() }
        { workflows.length > 0 && (
          <Fragment>
            <WorkflowsHeader />
            { workflows.map(workflow => (
              <Row key={`list_${workflow.id}`} className="dashboard-row list">
                <Col>
                  <HorizontalTileContainer
                    {...workflow}
                    type="Workflow"
                  />
                </Col>
              </Row>
            )) }
          </Fragment>
        )}
        { workflows.length === 0 && emptyState() }
      </div>
    </Fragment>
  )

  return (
    <main className="mt-4" role="main" id="tabs-container">
      {isGridView ? renderGridView() : renderListView()}
    </main>
  )
}

Workflows.defaultProps = {}

Workflows.propTypes = {
  workflows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      status: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      tasksCount: PropTypes.number.isRequired,
      iconLink: PropTypes.string.isRequired
    })
  ).isRequired,
  layoutCols: PropTypes.number.isRequired,
  isGridView: PropTypes.bool.isRequired,
}

export default Workflows
