import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import ToastErrorIcon from './ToastErrorIcon'
import ToastSuccessIcon from './ToastSuccessIcon'

const Toast = ({ type, text }) => (
  <Fragment>
    <div className={`toast-icon ${type}`}>
      {type === toast.TYPE.SUCCESS ? <ToastSuccessIcon /> : <ToastErrorIcon />}
    </div>

    <div className="toast-text">
      {text}
    </div>
  </Fragment>
)

Toast.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

const defaultToast = (text, options = {}) => {
  const closeIn = options.type === toast.TYPE.SUCCESS ? 5000 : 10000
  const closeButton = options.type !== toast.TYPE.SUCCESS
  const draggable = options.type === toast.TYPE.SUCCESS

  toast(<Toast text={text} type={options.type} />, {
    className: 'toast-message',
    autoClose: closeIn,
    pauseOnFocusLoss: true,
    closeOnClick: false,
    newestOnTop: true,
    draggable,
    closeButton,
    pauseOnHover: true,
    hideProgressBar: true,
    ...options
  })
}

export const errorToast = (text, options = {}) => {
  defaultToast(text, {
    type: toast.TYPE.ERROR,
    ...options
  })
}

export const successToast = (text, options = {}) => {
  defaultToast(text, {
    type: toast.TYPE.SUCCESS,
    ...options
  })
}

export default { successToast, errorToast }
