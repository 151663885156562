export default function clientMiddleware(client) {
  return ({ dispatch, getState }) => (
    next => (action) => {
      if (typeof action === 'function') {
        return action(dispatch, getState, client)
      }

      const { promise, types, ...rest } = action
      if (!promise) {
        return next(action)
      }

      const [REQUEST, SUCCESS, FAILURE] = types
      next({ ...rest, type: REQUEST })

      const actionPromise = promise(client)

      actionPromise
        .then(response => next({ ...rest, response, type: SUCCESS }))
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            return next({ ...rest, error, type: 'auth/LOGOUT_SUCCESS' })
          }

          return next({ ...rest, error, type: FAILURE })
        })

      return actionPromise
    }
  )
}
