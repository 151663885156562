import React from 'react'
import { switchLayout, toggleExpand, setViewType } from 'redux/actions/system'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import ModularIcon from 'images/ModularIcon'
import ListIcon from 'images/ListIcon'
import classNames from 'classnames'
import { t } from 'utils/i18n/translate'

const LayoutSwitch = (
    {
      switchLayout: changeCurrentLayout,
      currentLayout,
      toggleExpandButton: collapse,
      filterValue,
      setViewType: changeView,
      canSwitchLayout
    }
  ) => {
  const changeLayout = (layout) => {
    changeCurrentLayout(layout)

    if (filterValue !== 'notifications') {
      collapse()
    }

    if (layout === 'dashboard') {
      changeView('gridView')
    } else {
      changeView('listView')
    }
  }

  const dashboardClasses = classNames('dashboard-button', { active: currentLayout === 'dashboard' })
  const listViewClasses = classNames('list-view-button', { active: currentLayout === 'listView' })
  const showSwitchLayout = canSwitchLayout || process.env.REACT_APP_CAN_SWITCH_LAYOUT === 'true'

  return (
    showSwitchLayout ? (
      <div className="layout-switch">
        <Button
          onClick={() => changeLayout('dashboard')}
          className={dashboardClasses}
          title={t('app.accessibility.switch_to_grid_view')}
          aria-label={t('app.accessibility.switch_to_grid_view')}
        >
          <ModularIcon />
        </Button>
        <Button
          onClick={() => changeLayout('listView')}
          className={listViewClasses}
          title={t('app.accessibility.switch_to_list_view')}
          aria-label={t('app.accessibility.switch_to_list_view')}
        >
          <ListIcon />
        </Button>
      </div>
    ) : ''
  )
}

LayoutSwitch.defaultProps = {
  canSwitchLayout: false
}

LayoutSwitch.propTypes = {
  switchLayout: PropTypes.func.isRequired,
  toggleExpandButton: PropTypes.func.isRequired,
  currentLayout: PropTypes.string.isRequired,
  filterValue: PropTypes.string.isRequired,
  setViewType: PropTypes.func.isRequired,
  canSwitchLayout: PropTypes.bool
}

const mapStateToProps = state => ({
  filterValue: state.system.filters.tasks.value
})

const mapDispatchToProps = dispatch => ({
  switchLayout: layout => dispatch(switchLayout(layout)),
  toggleExpandButton: () => dispatch(toggleExpand('close')),
  setViewType: viewType => dispatch(setViewType(viewType))
})

export default connect(mapStateToProps, mapDispatchToProps)(LayoutSwitch)
