import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { errorToast, successToast } from 'components/Toast'
import { t } from 'utils/i18n/translate'
import GroupDetailsTableRow from './GroupDetailsTableRow'

const GroupDetailsTable = ({
  tableTitle,
  deleteRow,
  makeGroupUserAdmin,
  columns,
  rows,
  showPagination,
  parentId,
  userEmail,
  isUserGroupAdmin,
  noActions }) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)


  // HandleEvents
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRowOptionsClick = (row) => {
    if (typeof deleteRow === 'function') {
     const funcDelete = (deleteRow.name === 'destroyGroupUser') ? deleteRow(parentId, row.id) : deleteRow(row.id)

     funcDelete.then(() => {
        successToast(t(`app.table.notifications.${tableTitle.toLowerCase()}.delete_success`))
      }).catch(() => {
        errorToast(t(`app.table.notifications.${tableTitle.toLowerCase()}.delete_fail`))
      })
    }
  }

  const handleRowMakeAdminClick = (row) => {
    if (typeof makeGroupUserAdmin === 'function') {
     makeGroupUserAdmin(parentId, row.id).then(() => {
        successToast(t('app.table.notifications.admins.create_success'))
      }).catch(() => {
        errorToast(t('app.table.notifications.admins.create_fail'))
      })
    }
  }

  // Text, Empty, Actions and Avatar helpers
  const renderEmpty = () => (
    <Fragment>
      <Typography variant="body1" style={{ fontSize: '14px', lineHeight: '16px', marginTop: '20px', float: 'left', color: '#8b8f97', marginLeft: '15px' }}> { t('app.table.no_rows', { tableTitle })}</Typography>
    </Fragment>
  )

  const rowsToDisplay = () => (rowsPerPage > 0 && showPagination
    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : rows)


  // render Table, Rows, Pagination / Footer
  const renderTableFooter = () => (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: t('app.table.pagination.all'), value: -1 }]}
          colSpan={4}
          rowsPerPage={rowsPerPage}
          page={page}
          count={rows.length}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={t('app.table.pagination.rows_per_page')}
          SelectProps={{
            inputProps: {
              'aria-label': t('app.table.pagination.rows_per_page'),
            },
            native: true,
          }}
        />
      </TableRow>
    </TableFooter>
  )

  const renderRowsMobile = () => (
    <Fragment>
      {rows.map(row => (
        <GroupDetailsTableRow
          key={`group.details.table.mobile.row${row.id}`}
          row={row}
          columns={columns}
          noActions={noActions}
          userEmail={userEmail}
          makeGroupUserAdmin={makeGroupUserAdmin}
          tableTitle={tableTitle}
          handleRowMakeAdminClick={handleRowMakeAdminClick}
          handleRowOptionsClick={handleRowOptionsClick}
          showPagination={showPagination}
          deleteRow={deleteRow}
          isUserGroupAdmin={isUserGroupAdmin}
          groupId={parentId}
        />
      ))}
    </Fragment>
  )

  const renderTableMobile = () => (
    <Fragment>
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="dashboard table">
          <TableBody>
            {renderRowsMobile()}
          </TableBody>

        </Table>
      </TableContainer>
    </Fragment>
  )

  const renderRows = () => (
    <Fragment>
      {rowsToDisplay().map(row => (
        <GroupDetailsTableRow
          key={`group.details.table.row${row.id}`}
          row={row}
          columns={columns}
          noActions={noActions}
          userEmail={userEmail}
          makeGroupUserAdmin={makeGroupUserAdmin}
          tableTitle={tableTitle}
          handleRowMakeAdminClick={handleRowMakeAdminClick}
          handleRowOptionsClick={handleRowOptionsClick}
          showPagination={showPagination}
          deleteRow={deleteRow}
          isUserGroupAdmin={isUserGroupAdmin}
          groupId={parentId}
        />
      ))}
    </Fragment>
  )

  const renderTable = () => (
    <Fragment>
      <TableContainer className="groups_table_container" component={Paper} elevation={0}>
        <Table aria-label="dashboard table">
          <TableBody>
            {isMobile ? renderRowsMobile() : renderRows()}
          </TableBody>
          { showPagination && renderTableFooter()}
        </Table>
      </TableContainer>
    </Fragment>
  )

  const renderATable = () => (
    isMobile ? renderTableMobile() : renderTable()
  )
  return (
    <Fragment>
      <p className="filterTitle">
        {tableTitle}
      </p>
      { rows.length > 0 ? renderATable() : renderEmpty() }
    </Fragment>
    )
}


GroupDetailsTable.defaultProps = {
  rows: [],
  columns: ['name'],
  deleteRow: undefined,
  makeGroupUserAdmin: undefined,
  showPagination: false,
  noActions: false,
  parentId: '',
  userEmail: ''
}

GroupDetailsTable.propTypes = {
  tableTitle: PropTypes.string.isRequired,
  deleteRow: PropTypes.func,
  makeGroupUserAdmin: PropTypes.func,
  rows: PropTypes.array,
  columns: PropTypes.array,
  showPagination: PropTypes.bool,
  noActions: PropTypes.bool,
  parentId: PropTypes.string,
  userEmail: PropTypes.string,
  isUserGroupAdmin: PropTypes.bool.isRequired
}

export default GroupDetailsTable
