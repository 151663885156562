import React from 'react'
import PropTypes from 'prop-types'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'

export const SimpleLineChart = ({ data }) => (
  <ResponsiveContainer width="99%" height={300}>
    <LineChart
      data={data}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
      <XAxis dataKey="month" />
      <YAxis />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="orders" ske="#82ca9d" />
    </LineChart>
  </ResponsiveContainer>
)

SimpleLineChart.propTypes = {
  data: PropTypes.array.isRequired
}

export default SimpleLineChart
