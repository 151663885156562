import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import { t } from 'utils/i18n/translate'

export const TasksHeader = ({ value }) => {
  const isNotification = value === 'notifications'
  const lastColumnClasses = 'justify-content-end d-flex'
  const title = isNotification ? t('app.filters.notifications') : t('activerecord.models.task.other')

  return (
    <div className="tasks-header">
      <div className="container">
        <div className="task-inner"> {title} </div>
        <div> {t('app.task.type')} </div>
        <div> {t('app.task.owner')} </div>
        <div> {t('app.task.last_updated_at')} </div>
        <div className={isNotification ? '' : 'small-column'}> {t('app.task.current_status')} </div>
        { !isNotification && <div className={`${lastColumnClasses} small-column`}> {t('app.globals.options')} </div> }
      </div>
    </div>
  )
}

TasksHeader.propTypes = {
  value: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  value: state.system.filters.tasks.value
})

export default connect(mapStateToProps)(TasksHeader)
