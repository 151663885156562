import { connect } from 'react-redux'
import {
  toggleSearchBar,
  getSuggestions,
  setSearchValue
} from 'redux/actions/search'
import SearchBar from '.'

const mapStateToProps = state => ({
  results: state.searchSuggestions.data,
  isOpen: state.search.isOpen,
  value: state.search.searchValue
})

const mapDispatchToProps = dispatch => ({
  toggleSearchBar: () => {
    dispatch(toggleSearchBar())
  },
  getSuggestions: query => dispatch(getSuggestions(query)),
  setValue: value => dispatch(setSearchValue(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar)
