import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import FilterNavTabs from 'components/FilterNavTabs'
import { t } from 'utils/i18n/translate'

export const TABS = {
  all: 'All',
  workflows: 'Workflow',
  tasks: 'Task',
  affiliates: 'AffiliatePage',
  tools: 'Tool'
}

class FilterTabs extends PureComponent {
  handleChange = (activeTab) => {
    const { setFilter } = this.props

    setFilter(activeTab)
  }

  render() {
    const { tasksCount, workflowsCount, affiliatePagesCount, toolsCount, activeFilter } = this.props
    const allCount = toolsCount + tasksCount + workflowsCount + affiliatePagesCount

    return (
      <Fragment>
        <FilterNavTabs handleChange={this.handleChange} activeTab={activeFilter}>
          <FilterNavTabs.Tab name={TABS.tasks} href="#tasks" title={t('activerecord.models.task.other')} count={tasksCount} />
          <FilterNavTabs.Tab name={TABS.tools} href="#tools" title={t('activerecord.models.tool.other')} count={toolsCount} />
          <FilterNavTabs.Tab name={TABS.workflows} href="#workflows" title={t('activerecord.models.workflow.other')} count={workflowsCount} />
          <FilterNavTabs.Tab name={TABS.affiliates} href="#affiliatepages" title={t('activerecord.models.affiliate_page.other')} count={affiliatePagesCount} />
          <FilterNavTabs.Tab name={TABS.all} href="#all" title={t('app.globals.all')} count={allCount} />
        </FilterNavTabs>
      </Fragment>
    )
  }
}

FilterTabs.defaultProps = {
  tasksCount: 0,
  workflowsCount: 0,
  affiliatePagesCount: 0,
  toolsCount: 0,
  activeFilter: TABS.all
}

FilterTabs.propTypes = {
  setFilter: PropTypes.func.isRequired,
  tasksCount: PropTypes.number,
  workflowsCount: PropTypes.number,
  affiliatePagesCount: PropTypes.number,
  toolsCount: PropTypes.number,
  activeFilter: PropTypes.string
}

export default FilterTabs
