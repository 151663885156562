import React from 'react'
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts'
import PropTypes from 'prop-types'

export const StackedBarChart = ({ data, kFormatter, formatter }) => (
  <ResponsiveContainer width="99%" height={400}>
    <BarChart
      data={data}
      margin={{
            top: 15, right: 30, left: 20, bottom: 5,
        }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="month" />
      <YAxis
        tickFormatter={kFormatter}
      />
      <Tooltip formatter={value => formatter.format(value).slice(0, -3)} />
      <Legend />
      <Bar dataKey="value_1" name="Incoming" stackId="a" fill="#246ee3" />
      <Bar dataKey="value_2" name="Outgoing" stackId="a" fill="#0945a5" />
    </BarChart>
  </ResponsiveContainer>
)

StackedBarChart.propTypes = {
    data: PropTypes.object.isRequired,
    kFormatter: PropTypes.func.isRequired,
    formatter: PropTypes.func.isRequired
}
export default StackedBarChart
