import { types } from 'redux/actions/favorites'
import * as loadActions from '../middleware/loadActions'

export default (state = loadActions.arrayInitialState, action = {}) => {
  switch (action.type) {
    case types.LOAD_START:
      return loadActions.onLoadStart(state)
    case types.LOAD_SUCCESS:
      return loadActions.onLoadSuccess(state, action)
    case types.LOAD_FAIL:
      return loadActions.onLoadFailure(state, action)
    case types.MAKE_FAVORITE_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.response.data]
      }
    case types.REMOVE_FAVORITE_SUCCESS: {
      const updatedData = [...state.data]

      const element = state.data.find(favorite => (favorite.id === action.favoriteId))
      const index = updatedData.indexOf(element)
      updatedData.splice(index, 1)

      return {
        ...state,
        data: updatedData
      }
    }

    default:
      return state
  }
}
