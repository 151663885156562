export const types = {
  LOAD_DETAILS_START: 'workflow_chart_details/LOAD_DETAILS_START',
  LOAD_DETAILS_SUCCESS: 'workflow_chart_details/LOAD_DETAILS_SUCCESS',
  LOAD_DETAILS_FAIL: 'workflow_chart_details/LOAD_DETAILS_FAIL',
  CREATION_START: 'workflow_chart_details/CREATION_START',
  CREATION_SUCCESS: 'workflow_chart_details/CREATION_SUCCESS',
  CREATION_FAIL: 'workflow_chart_details/CREATION_FAIL',
  UPDATE_START: 'workflow_chart_details/UPDATE_START',
  UPDATE_SUCCESS: 'workflow_chart_details/UPDATE_SUCCESS',
  UPDATE_FAIL: 'workflow_chart_details/UPDATE_FAIL',
  PUBLISH_START: 'workflow_chart_details/PUBLISH_START',
  PUBLISH_SUCCESS: 'workflow_chart_details/PUBLISH_SUCCESS',
  PUBLISH_FAIL: 'workflow_chart_details/PUBLISH_FAIL',
}

export const loadWorkflowChartDetails = id => ({
  types: [types.LOAD_DETAILS_START, types.LOAD_DETAILS_SUCCESS, types.LOAD_DETAILS_FAIL],
  promise: client => client.get(`/workflow_charts/${id}`, {}, '/workflow_engine/api/v1')
})

export const updateWorkflowChart = (id, data) => ({
  types: [types.UPDATE_START, types.UPDATE_SUCCESS, types.UPDATE_FAIL],
  promise: client => client.patch(`/workflow_charts/${id}`, {
    data: {
      bpmn: data.xml,
    }
  }, '/workflow_engine/api/v1')
})

export const publishWorkflowChart = (id, data) => ({
  types: [types.PUBLISH_START, types.PUBLISH_SUCCESS, types.PUBLISH_FAIL],
  promise: client => client.patch(`/deploy_workflow_charts/${id}`, {
    data: {
      bpmn: data.xml,
    }
  }, '/workflow_engine/api/v1')
})
