import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import { connect } from 'react-redux'
import {
  loadGroup,
  addSubGroup,
  updateGroup,
  deleteSubGroup,
  deleteGroupUser } from 'redux/actions/groupDetails'
import Spinner from 'components/Spinner'
import GroupDetails from '.'

class GroupDetailsContainer extends PureComponent {
  componentDidMount() {
    const { fetchGroupDetails, match } = this.props
    if (match && match.params) {
      const { id } = match.params
      fetchGroupDetails(id)
    }
  }

  render() {
    const {
      groupDetails,
      isLoading,
      isLoaded,
      createGroup,
      editGroup,
      fetchGroupDetails,
      match,
      included,
      destroyGroup,
      destroyGroupUser,
      userEmail,
      location
    } = this.props

    if (isLoading || !isLoaded) {
      return <Spinner align="center" top={50} />
    }

    return (
      <GroupDetails
        group={groupDetails}
        groupId={match.params.id}
        fetchGroupDetails={fetchGroupDetails}
        addGroup={createGroup}
        updateGroup={editGroup}
        destroyGroup={destroyGroup}
        destroyGroupUser={destroyGroupUser}
        included={included}
        userEmail={userEmail}
        location={location}
      />
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.groupDetails.isLoading,
  isLoaded: state.groupDetails.isLoaded,
  groupDetails: state.groupDetails.data.attributes,
  included: state.groupDetails.included,
  userEmail: state.auth.data.email
})

const mapDispatchToProps = dispatch => ({
  fetchGroupDetails: groupId => dispatch(loadGroup(groupId)),
  createGroup: data => dispatch(addSubGroup(data)),
  editGroup: (groupId, data) => dispatch(updateGroup(groupId, data)),
  destroyGroup: groupId => dispatch(deleteSubGroup(groupId)),
  destroyGroupUser: (groupId, userId) => dispatch(deleteGroupUser(groupId, userId))
})

GroupDetailsContainer.defaultProps = {
  isLoading: false,
  isLoaded: false,
  groupDetails: {},
  included: []
}

GroupDetailsContainer.propTypes = {
  fetchGroupDetails: PropTypes.func.isRequired,
  createGroup: PropTypes.func.isRequired,
  editGroup: PropTypes.func.isRequired,
  destroyGroup: PropTypes.func.isRequired,
  destroyGroupUser: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool,
  match: ReactRouterPropTypes.match.isRequired,
  groupDetails: PropTypes.object,
  included: PropTypes.array,
  userEmail: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetailsContainer)
