import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { isMobileOnly } from 'react-device-detect'
import { Row, Container } from 'reactstrap'


import DefaultLayout from 'layouts/DefaultLayout'
import { t } from 'utils/i18n/translate'
import EmptyState from 'components/EmptyState'
import Spinner from 'components/Spinner'
import VerticalTile from 'components/VerticalTile'
import Trans from 'components/Trans'
import WorkflowTemplatesHeader from './WorkflowTemplatesHeader'


class WorkflowTemplatesPage extends PureComponent {
  componentDidMount() {
    const { loadTemplates, fetchGroups, setPageTitle } = this.props

    setPageTitle(t('app.pages.titles.templates_workflows'))
    loadTemplates()
    fetchGroups()
  }

  render() {
    const {
      templates,
      isLoaded,
      isLoading,
      status,
      groups,
      importWorkflowTemplates,
      fetchGroups,
      setGroupDetails,
      loadTemplates
    } = this.props
    const layoutClass = isMobileOnly ? 'layout-2' : 'layout-3'


    return (
      <Fragment>
        <DefaultLayout status={status}>
          {!isLoaded && isLoading && <Spinner align="center" top={50} />}
          {isLoaded && (
            <Fragment>

              <div className="main-templates-container" id="tabs-container">

                { templates.length > 0 && (
                  <Fragment>
                    <h2>
                      <Container> <Trans id="app.workflows.templates.page_title" /> </Container>
                    </h2>
                    <Container>
                      <div className="container-header">
                        <h3> <Trans id="app.workflows.templates.title" /> </h3>
                        <span className="description"> <Trans id="app.workflows.templates.sub_title" /> </span>
                      </div>

                      <Row className={`dashboard-row ${layoutClass}`}>
                        {templates.map(workflow => (
                          <div key={`grid_${workflow.id}`} className="tile_grid">
                            <VerticalTile
                              {...workflow}
                              type="Workflow"
                            />
                          </div>
                        ))}
                      </Row>
                    </Container>
                  </Fragment>
                )}
                { isLoaded && templates.length === 0 && (
                  <Fragment>
                    <WorkflowTemplatesHeader
                      loadTemplates={loadTemplates}
                      updateGroup={setGroupDetails}
                      fetchGroups={fetchGroups}
                      installTemplates={importWorkflowTemplates}
                      groups={groups}
                    />
                    <Container>
                      <EmptyState title={t('app.workflows.templates.empty')} />
                    </Container>
                  </Fragment>
                )}
              </div>
            </Fragment>
          )}
        </DefaultLayout>
      </Fragment>
    )
  }
}

WorkflowTemplatesPage.defaultProps = {
  isLoaded: true,
  isLoading: false
}

WorkflowTemplatesPage.propTypes = {
  setGroupDetails: PropTypes.func.isRequired,
  importWorkflowTemplates: PropTypes.func.isRequired,
  fetchGroups: PropTypes.func.isRequired,
  loadTemplates: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  templates: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool,
  status: PropTypes.number.isRequired
}

export default WorkflowTemplatesPage
