import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import FormLabel from '@material-ui/core/FormLabel'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import {
  showGroupMembership,
  updateGroupMembership,
} from 'redux/actions/groupMemberships'
import { t } from 'utils/i18n/translate'

class GroupMembershipForm extends PureComponent {
  state = {
    data: {
      groupId: '',
      userid: '',
      role: '',
      permissions: {
        userPermission: false,
        newControllerPermission: false
      }
    }
  }

  componentDidMount() {
    const { fetchGroupMembership, parentId, rowId } = this.props
    fetchGroupMembership(parentId, rowId)
  }

  componentWillReceiveProps(nextProps) {
    const { data } = nextProps

    this.setState({
      data: {
        ...data,
      }
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { putGroupMembership, parentId, rowId, handleClose } = this.props
    const { data } = this.state
    putGroupMembership(parentId, rowId, data)
    handleClose()
  }

  handleChange = (event) => {
    const { name, value } = event.target
    const { data } = this.state
    this.setState({
      data: {
        ...data,
        [name]: value,

      }
    })
  }

  handlePermissionChange = (event) => {
    const { name, checked } = event.target
    const { data } = this.state
    const { permissions } = data
    this.setState({
      data: {
        ...data,
        permissions: {
          ...permissions,
          [name]: checked,
        }
      }
    })
  }


  render() {
    const { data } = this.state
    const {
      role,
      permissions
    } = data
    const {
      userPermission,
      newControllerPermission
    } = permissions

    const { handleClose } = this.props

    const classes = makeStyles(theme => ({
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        paddingTop: 20
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
    }))

    return (
      <div className="group-membership__form-container">
        <form id="group-membership__form">

          <FormControl component="fieldset" className={classes.formControl} fullWidth>
            <InputLabel id="role-select-label">{t('activerecord.models.group_memberships.one')}</InputLabel>
            <Select
              labelId="groupIdLabel"
              name="role"
              value={role}
              onChange={this.handleChange}
              style={{ marginBottom: '30px' }}
            >
              <MenuItem value="user">{t('app.group_memberships.roles.user')}</MenuItem>
              <MenuItem value="group_admin">{t('app.group_memberships.roles.admin')}</MenuItem>
            </Select>
          </FormControl>
          <FormControl component="fieldset" className={classes.formControl} fullWidth>
            <FormLabel component="legend">{t('app.group_memberships.assign_permissions')}</FormLabel>

            <FormControlLabel
              id="user_permission_label"
              className="permission_checkbox"
              control={<Checkbox checked={userPermission} onChange={this.handlePermissionChange} name="userPermission" />}
              label="User Permission"
            />
            <FormControlLabel
              id="new_controller_permission_label"
              className="permission_checkbox"
              control={<Checkbox checked={newControllerPermission} onChange={this.handlePermissionChange} name="newControllerPermission" />}
              label="New Controller Permission"
            />
          </FormControl>
          <div className="dialog-actions-container">
            <Button onClick={handleClose} color="primary" style={{ float: 'left' }}>
              {t('app.globals.cancel')}
            </Button>
            <Button onClick={this.handleSubmit} type="submit" form="dialog-form" color="primary" variant="contained" autoFocus style={{ float: 'right' }}>
              {t('app.globals.update')}
            </Button>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.groupMemberships.isLoading,
  isLoaded: state.groupMemberships.isLoaded,
  data: state.groupMemberships.data.attributes,
  userEmail: state.auth.data.email
})

const mapDispatchToProps = dispatch => ({
  fetchGroupMembership: (groupId, userId) => dispatch(showGroupMembership(groupId, userId)),
  putGroupMembership: (
    groupId,
    userId,
    data
  ) => dispatch(updateGroupMembership(groupId, userId, data)),
})

GroupMembershipForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  parentId: PropTypes.string.isRequired,
  rowId: PropTypes.string.isRequired,
  fetchGroupMembership: PropTypes.func.isRequired,
  putGroupMembership: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupMembershipForm)
