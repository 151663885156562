import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { toggleDashboardView, setTitle } from 'redux/actions/system'
import { loadFavoriteList } from 'redux/actions/favorites'
import { loadWorkflowCategoriesList } from 'redux/actions/workflowCategories'
import { t } from 'utils/i18n/translate'
import { MagazinePage } from './index'

export class MagazinePageContainer extends Component {
  componentDidMount() {
    const { toggleDashboardView: toggleDashboardViewReact,
      loadWorkflowCategories,
      loadFavorites, setPageTitle } = this.props
    setPageTitle(t('app.pages.titles.magazine'))
    toggleDashboardViewReact('magazine')
    loadWorkflowCategories()
    loadFavorites()
  }

  componentWillUnmount() {
    const { toggleDashboardView: toggleDashboardViewRender } = this.props
    toggleDashboardViewRender('')
  }

  render() {
    const { workflowCategories } = this.props

    return (
      <MagazinePage workflowCategories={workflowCategories} />
    )
  }
}

MagazinePageContainer.propTypes = {
  toggleDashboardView: PropTypes.func.isRequired,
  loadWorkflowCategories: PropTypes.func.isRequired,
  workflowCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  loadFavorites: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
  toggleDashboardView: (area) => {
    dispatch(toggleDashboardView(area))
  },
  loadWorkflowCategories: params => dispatch(loadWorkflowCategoriesList(params)),
  loadFavorites: () => dispatch(loadFavoriteList()),
  setPageTitle: title => (dispatch(setTitle(title)))
})

const mapStateToProps = state => ({
  workflowCategories: state.workflowCategories.data
})

export default connect(mapStateToProps, mapDispatchToProps)(MagazinePageContainer)
