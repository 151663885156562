import React, { Fragment } from 'react'
import { Container } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'

import Trans from 'components/Trans'

const GroupPageHeader = () => (
  <Fragment>
    <AppBar position="static" elevation={0}>
      <Container>
        <ul className="appbar-list">
          <li className="appbar-item">
            <span className="appbar-link">
              <Trans id="app.groups.title" />
            </span>
          </li>
        </ul>
      </Container>
    </AppBar>

  </Fragment>
)


export default GroupPageHeader
