import axios from 'axios'
import { loadCookie } from 'utils/cookies_'
import { AUTH_TOKEN_KEY } from 'redux/actions/auth'

export const types = {
  LOAD_START: 'groups/LOAD_START',
  LOAD_SUCCESS: 'groups/LOAD_SUCCESS',
  LOAD_FAIL: 'groups/LOAD_FAIL',
  ADD_GROUP_SUCCESS: 'groups/ADD_GROUP_SUCCESS',
  ADD_GROUP_FAIL: 'groups/ADD_GROUP_FAIL',
  UPDATE_GROUP_SUCCESS: 'groups/UPDATE_GROUP_SUCCESS',
  UPDATE_GROUP_ERROR: 'groups/UPDATE_GROUP_ERROR',
  INSTALL_TEMPLATE_START: 'groups/INSTALL_TEMPLATE_START',
  INSTALL_TEMPLATE_SUCCESS: 'groups/INSTALL_TEMPLATE_SUCCESS',
  INSTALL_TEMPLATE_FAIL: 'groups/INSTALL_TEMPLATE_FAIL',

}

const headers = {
  'Content-Type': 'application/vnd.api+json',
  Accept: 'application/vnd.api+json',
  Authorization: `Bearer ${loadCookie(AUTH_TOKEN_KEY)}`
}

export const loadGroupList = () => ({
  types: [types.LOAD_START, types.LOAD_SUCCESS, types.LOAD_FAIL],
  promise: client => client.get('/groups')
})

export const installTemplateGroup = groupId => ({
  types: [
    types.INSTALL_TEMPLATE_START,
    types.INSTALL_TEMPLATE_SUCCESS,
    types.INSTALL_TEMPLATE_FAIL],
  promise: client => client.put(`/groups/${ groupId}`)
})

export const addGroupSuccess = group => ({
  type: types.ADD_GROUP_SUCCESS,
  response: group,
  group: group.data
})

export const addGroupError = error => ({
  type: types.ADD_GROUP_FAIL,
  error
})

export const addGroup = (params) => {
  const url = `${process.env.REACT_APP_SSO_HOST}/api/v2/groups`

  return dispatch => axios
    .post(url,
      { data: { attributes: { ...params } } },
      { headers })
    .then((response) => {
      dispatch(addGroupSuccess(response.data))
    })
    .catch((error) => {
      dispatch(addGroupError(error))
      throw error
    })
}
