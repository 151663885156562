export const types = {
  ADD_CLICK_METRIC_START: 'metrics/ADD_CLICK_METRIC_START',
  ADD_CLICK_METRIC_SUCCESS: 'metrics/ADD_CLICK_METRIC_SUCCESS',
  ADD_CLICK_METRIC_FAIL: 'metrics/ADD_CLICK_METRIC_FAIL'
}

export const addClickMetric = params => ({
  types: [
    types.ADD_CLICK_METRIC_START,
    types.ADD_CLICK_METRIC_SUCCESS,
    types.ADD_CLICK_METRIC_FAIL],
  promise: client => client.post('/metrics', { data: params })
})
