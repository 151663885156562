import React from 'react'
import { Container } from 'reactstrap'
import { Link } from 'react-router-dom'
import DefaultLayout from 'layouts/DefaultLayout'
import Trans from 'components/Trans'

export const ErrorPage = () => (
  <DefaultLayout>
    <ErrorContent />
  </DefaultLayout>
)

export const ErrorContent = () => (
  <Container className="error-page">
    <h1><Trans id="app.errors.500.title" /></h1>
    <p className="mb-4"><Trans id="app.errors.500.text" /></p>
    <Link to="/" className="btn btn-primary"><Trans id="app.globals.back_home" /></Link>
  </Container>
)

export default ErrorPage
