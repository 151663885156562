export const types = {
  CREATE_START: 'step_details/REMOVE_START',
  CREATE_SUCCESS: 'step_details/REMOVE_SUCCESS',
  CREATE_FAIL: 'step_details/REMOVE_FAIL',

  UPDATE_DETAILS_START: 'step_details/UPDATE_DETAILS_START',
  UPDATE_DETAILS_SUCCESS: 'step_details/UPDATE_DETAILS_SUCCESS',
  UPDATE_DETAILS_FAIL: 'step_details/UPDATE_DETAILS_FAIL',

  REMOVE_START: 'step_details/REMOVE_START',
  REMOVE_SUCCESS: 'step_details/REMOVE_SUCCESS',
  REMOVE_FAIL: 'step_details/REMOVE_FAIL'
}

export const createStep = (workflowId, params) => ({
  types: [types.CREATE_START, types.CREATE_SUCCESS, types.CREATE_FAIL],
  promise: client => client.post(`/workflows/${workflowId}/steps`, {
    data: { ...params }
  })
})

export const updateStepDetails = (id, params) => ({
  types: [types.UPDATE_DETAILS_START, types.UPDATE_DETAILS_SUCCESS, types.UPDATE_DETAILS_FAIL],
  promise: client => client.patch(`/steps/${id}`, {
    data: { ...params }
  })
})

export const removeStep = id => ({
  types: [types.UPDATE_DETAILS_START, types.UPDATE_DETAILS_SUCCESS, types.UPDATE_DETAILS_FAIL],
  promise: client => client.delete(`/steps/${id}`)
})
