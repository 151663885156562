import React from 'react'
import PropTypes from 'prop-types'
import { ListGroup, ListGroupItem } from 'reactstrap'
import Trans from 'components/Trans'

const NoSuggestions = ({ isOpen }) => (
  <ListGroup className={isOpen ? 'suggestions list-unstyled ' : 'd-none'}>
    <ListGroupItem key="no-suggestions">
      <div className="no-suggestions">
        <Trans id="app.search.no-suggestions" />
      </div>
    </ListGroupItem>
  </ListGroup>
)

NoSuggestions.defaultProps = {
  isOpen: false
}

NoSuggestions.propTypes = {
  isOpen: PropTypes.bool
}

export default NoSuggestions
