import i18n from 'i18n-js'

// Get the translations from the object in the window
i18n.translations = window.I18n.translations

const translate = {}

export const t = (id, options) => i18n.t(id, options)

export const changeLocale = (locale) => {
  i18n.locale = locale
}

translate.t = t

export default translate
