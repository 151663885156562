import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Trans from 'components/Trans'
import slackIcon from 'images/integrations/slack.png'
import msTeamsIcon from 'images/integrations/ms-teams.png'

import IntegrationItem from './IntegrationItem'


const slackUrl = `https://slack.com/oauth/authorize?scope=commands,chat:write:user&client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}`
const msTeamUrl = `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=MSTeamIntegration`

const integrationOptions = [{
  id: 'first_integration',
  logoUrl: slackIcon,
  title: 'slack',
  category: 'Business Communication',
  enabled: true,
  support: false,
  activationUrl: slackUrl
}, {
  id: 'second_integration',
  logoUrl: msTeamsIcon,
  title: 'microsoft teams',
  category: 'Microsoft Corporation',
  enabled: false,
  support: false,
  activationUrl: msTeamUrl
}, {
  id: 'last_integration',
  logoUrl: 'https://via.placeholder.com/300.png/',
  title: <Trans id="app.integrations.support.title" />,
  category: <Trans id="app.integrations.support.subtitle" />,
  enabled: false,
  support: true,
  activationUrl: msTeamUrl
}]

const Integrations = ({ integrations, deleteIntegration }) => {
  const providers = integrations.map(integration => integration.provider)

  const renderIntegrations = () => (
    <div className="integrations-attributes">
      { integrationOptions.map((option) => {
        const result = providers.indexOf(option.title)
        const isInstalled = providers.indexOf(option.title) !== -1

        const integrationId = isInstalled ? integrations[result].id : null
        return (
          <IntegrationItem
            key={option.id}
            isInstalled={isInstalled}
            deleteIntegration={deleteIntegration}
            integrationId={integrationId}
            {...option}
          />
        )
      }) }

    </div>
  )

  const renderView = () => (
    <Fragment>
      <div className="integrations-attributes-container col-lg-12">
        { integrationOptions.length > 0 ? renderIntegrations() : <span>Empty</span>}
      </div>
    </Fragment>
  )


  return (renderView())
}

Integrations.propTypes = {
  integrations: PropTypes.array.isRequired,
  deleteIntegration: PropTypes.func.isRequired,
}

export default Integrations
