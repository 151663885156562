export const types = {
  LOAD_PAGE_START: 'page/LOAD_PAGE_START',
  LOAD_PAGE_SUCCESS: 'page/LOAD_PAGE_SUCCESS',
  LOAD_PAGE_FAIL: 'page/LOAD_PAGE_FAIL'
}

export const loadPage = slug => ({
  types: [types.LOAD_PAGE_START, types.LOAD_PAGE_SUCCESS, types.LOAD_PAGE_FAIL],
  promise: client => client.get(`/pages/${slug}`)
})
