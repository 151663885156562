import { connect } from 'react-redux'
import React, { Fragment, useEffect, useState } from 'react'
import { errorToast, successToast } from 'components/Toast'
import PropTypes from 'prop-types'
import { t } from 'utils/i18n/translate'
import { snake } from 'change-case'
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal'
import { loadStepsForWorkflow } from 'redux/actions/workflowDetails'
import { removeStep } from 'redux/actions/stepDetails'
import { loadUsersList } from 'redux/actions/users'
import { loadGroupList } from 'redux/actions/groups'
import RemoveIcon from 'images/remove'
import Trans from 'components/Trans'
import { TextField, Grid, MenuItem, FormControlLabel, Checkbox, Modal } from '@material-ui/core'

export const Step = (
    {
      step,
      handleChange,
      handleGroupChange,
      handleEndpointChange,
      loadGroups,
      loadUsers,
      steps,
      users,
      groups,
      removeCurrentStep,
      loadStepsForWorkflow: loadSteps
    }
  ) => {
  const initialState = () => (
    {
      confirmDeleteOpen: false,
      tries: 0
    }
  )
  const [state, setState] = useState(initialState())

  useEffect(() => {
    loadUsers()
    loadGroups()
  }, [])

  const toggleModal = () => {
    setState({ ...state, confirmDeleteOpen: !state.confirmDeleteOpen })
  }

  const deleteStep = () => {
    removeCurrentStep(step.id).then(() => {
      successToast(t('app.steps.remove.success'))
      loadSteps(step.workflowId)
    }).catch(() => {
      if (state.tries < 3) {
        errorToast(t('app.steps.remove.error'))
        setState({ ...state, tries: state.tries + 1 })
      } else {
        errorToast(t('app.steps.remove.error_final'))
      }
    })
  }

  const parentSteps = steps.filter(parent => parent.id !== step.id)
  /* eslint-disable max-len */
  return (
    <Fragment>
      <Modal
        open={state.confirmDeleteOpen}
        onClose={toggleModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ConfirmDeleteModal
          handleSaveClick={deleteStep}
          handleDiscardChanges={toggleModal}
          title="app.steps.remove.modal.title"
          message={`app.steps.remove.modal.message_${step.status}`}
        />
      </Modal>
      <h2>
        <span className="title"> {step.name} </span>
        <button type="button" className="remove-step" onClick={toggleModal}>
          <RemoveIcon />
          <span> <Trans id="app.steps.remove.button" /> </span>
        </button>
      </h2>
      <form className="step-edit" autoComplete="off">
        <Grid container justify="space-between">
          <Grid item xs={12} md={7} className="step-fields">
            <TextField
              required
              name="name"
              onChange={handleChange}
              label={t('activerecord.attributes.step.name')}
              variant="outlined"
              value={step.name}
            />
            <TextField
              name="parentId"
              onChange={handleChange}
              label={t('activerecord.attributes.step.parent')}
              value={step.parentId}
              select
              variant="outlined"
            >
              <MenuItem value={null}> </MenuItem>
              { parentSteps && parentSteps.map(parent => (
                <MenuItem value={parent.id}>{parent.name}</MenuItem>
              ))
              }
            </TextField>
            <TextField
              name="userId"
              onChange={handleChange}
              label={t('activerecord.models.user.one')}
              value={step.userId}
              select
              variant="outlined"
            >
              <MenuItem value={null}> </MenuItem>
              { users && users.map(user => (
                <MenuItem value={user.id}>{user.name}</MenuItem>
              ))
              }
            </TextField>
            <TextField
              name="group"
              onChange={e => handleGroupChange(e, groups)}
              label={t('activerecord.attributes.step.group')}
              value={step.group.id}
              select
              variant="outlined"
            >
              <MenuItem value={null}> </MenuItem>
              { groups && groups.map(group => (
                <MenuItem value={group.id}>{group.name}</MenuItem>
              ))
              }
            </TextField>
            <TextField
              required
              name="taskType"
              onChange={handleChange}
              label={t('activerecord.attributes.step.type')}
              value={step.taskType}
              select
              variant="outlined"
            >
              { step.taskTypes && step.taskTypes.map(type => (
                <MenuItem value={snake(type)}>{type}</MenuItem>
              ))
              }
            </TextField>
            <TextField
              name="metadata"
              onChange={handleChange}
              label={t('activerecord.attributes.step.metadata')}
              variant="outlined"
              multiline
              rows={4}
              value={step.metadata}
            />
            <TextField
              required
              name="endpoints"
              onChange={e => handleEndpointChange(e, step.endpoints)}
              label={t('activerecord.attributes.step.endpoints')}
              value={step.endpoint === undefined || step.endpoint === null ? null : step.endpoint.id}
              select
              variant="outlined"
            >
              <MenuItem value={null}> </MenuItem>
              { step.endpoints && step.endpoints.map(endpoint => (
                <MenuItem value={endpoint.id}>{endpoint.name}</MenuItem>
                  ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={5} className="step-config">
            <TextField
              disabled
              name="id"
              label={t('activerecord.attributes.step.id')}
              variant="outlined"
              value={step.id}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={step.previousData}
                  onChange={handleChange}
                  name="previousData"
                  color="primary"
                />
              )}
              label={t('activerecord.attributes.step.previous_data')}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={step.limitToUserGroup}
                  onChange={handleChange}
                  name="limitToUserGroup"
                  color="primary"
                />
              )}
              label={t('activerecord.attributes.step.limit_to_user_group')}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={step.applyGroupOwner}
                  onChange={handleChange}
                  name="applyGroupOwner"
                  color="primary"
                />
              )}
              label={t('activerecord.attributes.step.apply_group_owner')}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={step.canDelegateBackToMainGroup}
                  onChange={handleChange}
                  name="canDelegateBackToMainGroup"
                  color="primary"
                />
              )}
              label={t('activerecord.attributes.step.can_delegate_back_to_main_group')}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={step.applyProcessOwner}
                  onChange={handleChange}
                  name="applyProcessOwner"
                  color="primary"
                />
              )}
              label={t('activerecord.attributes.step.apply_process_owner')}
            />
            <TextField
              name="prefillDataKeys"
              onChange={handleChange}
              label={t('activerecord.attributes.step.prefill_data_keys')}
              variant="outlined"
              helperText={t('app.step.prefill_data_keys.tooltip')}
              value={step.prefillDataKeys}
            />
          </Grid>
        </Grid>
      </form>
    </Fragment>
  )
}

Step.propTypes = {
  step: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  loadUsers: PropTypes.func.isRequired,
  loadGroups: PropTypes.func.isRequired,
  removeCurrentStep: PropTypes.func.isRequired,
  handleGroupChange: PropTypes.func.isRequired,
  loadStepsForWorkflow: PropTypes.func.isRequired,
  handleEndpointChange: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  workflows: state.workflows.data,
  users: state.users.data,
  groups: state.groups.data
})

const mapDispatchToProps = dispatch => ({
  loadUsers: () => dispatch(loadUsersList()),
  loadGroups: () => dispatch(loadGroupList()),
  removeCurrentStep: id => dispatch(removeStep(id)),
  loadStepsForWorkflow: workflowId => dispatch(loadStepsForWorkflow(workflowId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Step)
