import React, { Fragment } from 'react'

import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'

const DialogComponent = (props) => {
  const {
    fullScreen,
    buttonLabel,
    formComponent,
    modalTitle,
    modalBody,
    actionFunction,
    parentId,
    isNewObject,
    callbackFunction,
    actionLabel,
    userEmail,
    successToast,
    errorToast,
    open,
    setOpen,
    rowId,
    closeMenu,
    onClose,
  } = props

  const handleClickOpen = () => {
    closeMenu()
    setOpen(rowId)
  }

  const handleCloseDialog = () => {
    onClose()
  }

  const renderMenuItem = () => (
  (
    <MenuItem className="last" onClick={handleClickOpen}>
      <ListItemText
        primary={buttonLabel}
      />
    </MenuItem>
    )
  )
  const FormComponent = formComponent

  return (
    <Fragment>
      {renderMenuItem()}
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        fullScreen={fullScreen}
        maxWidth="sm"
      >

        <DialogTitle id="alert-dialog-title">
          {modalTitle}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {modalBody !== '' ? modalBody : ''}
          </DialogContentText>
          <FormComponent
            handleClose={handleCloseDialog}
            actionFunction={actionFunction}
            callbackFunction={callbackFunction}
            parentId={parentId}
            actionLabel={actionLabel}
            isNewObject={isNewObject}
            successToastMessage={successToast}
            errorToastMessage={errorToast}
            userEmail={userEmail}
            rowId={rowId}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

DialogComponent.defaultProps = {
  modalBody: '',
  parentId: '',
  formComponent: null,
  actionFunction: null,
  actionLabel: '',
  callbackFunction: null,
  isNewObject: true,
  fullScreen: false,
}

DialogComponent.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  modalBody: PropTypes.string,
  actionLabel: PropTypes.string,
  callbackFunction: PropTypes.func,
  isNewObject: PropTypes.bool,
  formComponent: PropTypes.object,
  parentId: PropTypes.string,
  userEmail: PropTypes.string.isRequired,
  successToast: PropTypes.string.isRequired,
  errorToast: PropTypes.string.isRequired,
  actionFunction: PropTypes.func,
  rowId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool,
  closeMenu: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default DialogComponent
