import { connect } from 'react-redux'
import { loadWorkflowChartDetails, updateWorkflowChart, publishWorkflowChart } from 'redux/actions/workflowCharts'
import { loadStepsForWorkflow } from 'redux/actions/workflowDetails'
import BpmnChart from 'components/BpmnChart'


const mapStateToProps = state => ({
  diagramXML: state.workflowCharts.data.chart,
  isLoaded: state.workflowCharts.isLoaded
})

const mapDispatchToProps = dispatch => ({
  loadWorkflowChart: id => dispatch(loadWorkflowChartDetails(id)),
  loadStepsForWorkflow: workflowId => dispatch(loadStepsForWorkflow(workflowId)),
  updateWorkflowChart: (id, data) => dispatch(updateWorkflowChart(id, data)),
  publishWorkflowChart: (id, data) => dispatch(publishWorkflowChart(id, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BpmnChart)
