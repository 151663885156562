import React, { Fragment } from 'react'
import { Navbar as NavbarReact, NavbarBrand, Container } from 'reactstrap'

import PropTypes from 'prop-types'
import LoggedNavContainer from './LoggedNavContainer'

const Navbar = ({ title, isLoggedIn }) => {
  const navbarclassName = isLoggedIn ? 'loggedin' : 'loggedout'
  return (
    <NavbarReact light className={`brand-navbar ${navbarclassName}`} expand="md">
      <Container
        style={isLoggedIn ? { justifyContent: 'space-between' } : { justifyContent: 'left' }}
        className="align-items-start"
      >
        <Fragment>
          <Fragment>
            <NavbarBrand className="d-md-block" href="/">
              <h1>
                <span className="app-title-first">{title}</span>
              </h1>
            </NavbarBrand>
            { isLoggedIn && <LoggedNavContainer />}
          </Fragment>
        </Fragment>
      </Container>
    </NavbarReact>
  )
}

Navbar.defaultProps = {
  isLoggedIn: false
}

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool
}

export default Navbar
