import { types } from 'redux/actions/search'

const initialState = {
  isLoading: false,
  isLoaded: false,
  data: []
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SUGGESTIONS_START:
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      }
    case types.SUGGESTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: action.response.data
      }
    case types.SUGGESTIONS_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoaded: true
      }
    default:
      return state
  }
}
