import React, { Fragment, PureComponent } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'
import { t } from 'utils/i18n/translate'
import { Row, Col } from 'reactstrap'
import { clearSnackbar } from '../../redux/actions/snackbar'
import TaskDashboardIcon from './TaskDashboardIcon'
import theme from './style'

class TaskSnackBar extends PureComponent {
    render() {
        const { open, message, taskId, handleClose, workflowName, taskGroupId } = this.props
        return (
          <Fragment>
            <ThemeProvider theme={theme}>
              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                autoHideDuration={20000}
                onClose={handleClose}
                open={open}
              >
                <SnackbarContent
                  message={(<TaskMessage message={message} workflowName={workflowName} />)}
                  action={(
                    <Actions
                      close={handleClose}
                      taskId={taskId}
                      taskGroupId={taskGroupId}
                    />
)}
                />
              </Snackbar>
            </ThemeProvider>
          </Fragment>
        )
    }
}

const Actions = ({ close, taskId, taskGroupId }) => (
  <Fragment>
    <Button id="later-button" key="later" aria-label="later" color="inherit" onClick={close}>
      { t('app.workflows.later') }
    </Button>
    <Button id="open-button" key="open" aria-label="open" color="inherit" onClick={close} component={RouterLink} to={taskGroupId !== null ? '/dashboard/tasks' : `/tasks/${taskId}`}>
      {t('app.workflows.open')}
    </Button>
  </Fragment>
)

const TaskMessage = ({ message, workflowName }) => (
  <Fragment>
    <Row className="row-message">
      <TaskDashboardIcon key="icon" />
      <Col>
        <div key="message" className="message">{message}</div>
        <div key="workflow" className="workflow-name">{workflowName} </div>
      </Col>
    </Row>
  </Fragment>
)

Actions.defaultProps = {
    taskGroupId: null
}

Actions.propTypes = {
    close: PropTypes.func.isRequired,
    taskId: PropTypes.string.isRequired,
    taskGroupId: PropTypes.string,
}

TaskMessage.propTypes = {
    message: PropTypes.string.isRequired,
    workflowName: PropTypes.string.isRequired
}

TaskSnackBar.defaultProps = {
    taskGroupId: null
}

TaskSnackBar.propTypes = {
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    taskId: PropTypes.string.isRequired,
    taskGroupId: PropTypes.string,
    workflowName: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    open: state.snackbar.open,
    message: state.snackbar.message,
    taskId: state.snackbar.taskId,
    taskGroupId: state.snackbar.taskGroupId,
    workflowName: state.snackbar.workflowName
})

const mapDispatchToProps = dispatch => ({
    handleClose: () => (
        dispatch(clearSnackbar())
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskSnackBar)
