import { connect } from 'react-redux'
import { makeFavorite, removeFavorite } from 'redux/actions/favorites'
import { updateTaskDetails } from 'redux/actions/taskDetails'
import { incrementTasksCount, decreaseTasksCount } from 'redux/actions/workflows'
import Task from '.'

const getFavorite = (favorites, workflowId) => (
  favorites.find(favorite => favorite.favoritableType === 'Task' && favorite.favoritableId === workflowId)
)

const mapStateToProps = (state, ownProps) => ({
  favorite: getFavorite(state.favorites.data, ownProps.id),
  currentUserId: state.auth.data.id
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleMakeFavorite: () => dispatch(makeFavorite('Task', ownProps.id)),
  handleRemoveFavorite: id => dispatch(removeFavorite(id)),
  updateTask: params => dispatch(updateTaskDetails(ownProps.id, params)),
  incrementTasksCount: workflowId => dispatch(incrementTasksCount(workflowId)),
  decreaseTasksCount: workflowId => dispatch(decreaseTasksCount(workflowId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Task)
