import { connect } from 'react-redux'
import { loadWorfklowTemplates } from 'redux/actions/workflows'
import { loadGroupList, installTemplateGroup } from 'redux/actions/groups'
import { updateGroup } from 'redux/actions/groupDetails'
import { setTitle } from 'redux/actions/system'
import WorkflowTemplatesPage from '.'

const mapStateToProps = state => ({
  templates: state.workflows.templates.data,
  status: state.workflows.templates.status,
  isLoading: state.workflows.templates.isLoading,
  isLoaded: state.workflows.templates.isLoaded,
  groups: state.groups.data
})

const mapDispatchToProps = dispatch => ({
  fetchGroups: () => dispatch(loadGroupList()),
  loadTemplates: () => dispatch(loadWorfklowTemplates()),
  setPageTitle: title => dispatch(setTitle(title)),
  importWorkflowTemplates: groupId => dispatch(installTemplateGroup(groupId)),
  setGroupDetails: payload => dispatch(updateGroup(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowTemplatesPage)
