import React from 'react'
import {
    ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts'
import PropTypes from 'prop-types'


export const SimpleBarChart = ({ data, kFormatter, formatter }) => (
  <ResponsiveContainer width="99%" height={400}>
    <BarChart
      data={data}
      margin={{
                top: 15, right: 30, left: 20, bottom: 5,
            }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="month" />
      <YAxis
        tickFormatter={kFormatter}
      />
      <Tooltip formatter={value => formatter.format(value).slice(0, -3)} />
      <Legend />
      <Bar dataKey="revenue" name="Revenue" fill="#0945a5" />
    </BarChart>
  </ResponsiveContainer>

)

SimpleBarChart.propTypes = {
    data: PropTypes.object.isRequired,
    kFormatter: PropTypes.func.isRequired,
    formatter: PropTypes.func.isRequired
}
export default SimpleBarChart
