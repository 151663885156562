import React, { Fragment } from 'react'
import { Nav, NavLink, NavItem, Navbar, NavbarBrand, Container } from 'reactstrap'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import logo from 'images/brand/logo.svg'
import Trans from 'components/Trans'

const ExternalLinkPage = ({ match, handleLogout }) => {
  const decodedURL = decodeURIComponent(match.params.url)

  return (
    <Fragment>
      <Navbar light expand="md" className="brandbar external-link-bar">
        <Container>
          <NavbarBrand tag={Link} to="/">
            <img src={logo} alt="Dashboard Logo" />
            <span className="sr-only">Dashboard Logo</span>
            <span className="navbar-brand-title"><Trans id="app.name" /></span>
          </NavbarBrand>

          <Nav className="ml-auto justify-content-end" navbar>
            <NavItem>
              <NavLink tag={Link} to="/" onClick={handleLogout} className="btn btn-primary">
                <Trans id="app.auth.logout" />
              </NavLink>
            </NavItem>
          </Nav>
        </Container>
      </Navbar>

      <iframe
        src={decodedURL}
        title="Website content"
        className="external-link-frame"
        width="100%"
        height="100%"
        frameBorder="0"
      />
    </Fragment>
  )
}

ExternalLinkPage.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  handleLogout: PropTypes.func.isRequired
}

export default ExternalLinkPage
