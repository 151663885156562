import { types } from 'redux/actions/openTool'
import * as loadActions from '../middleware/loadActions'

const initialState = {
  id: null,
  tasks: []
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.TOGGLE_INFO_BOX: {
      const openId = state.id !== action.toolId ? action.toolId : null

      return {
        ...state,
        id: openId,
        tasks: []
      }
    }
    case types.LOAD_TASKS_START:
      return loadActions.onLoadStart(state)
    case types.LOAD_TASKS_SUCCESS: {
      const { toolId, tasks } = action

      if (toolId === state.id) {
        return {
          ...loadActions.onLoadComplete(state),
          tasks
        }
      }

      return state
    }
    case types.LOAD_TASKS_FAIL:
      return loadActions.onLoadFailure(state)
    default:
      return state
  }
}
