import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import { Grid, Container } from '@material-ui/core'
import { t } from 'utils/i18n/translate'
import GroupDetailsHeader from './GroupDetailsHeader'
import GroupDetailsTable from './GroupDetailsTable'

const GroupDetails = ({
  removeGroupUserAdmin,
  destroyGroupUser,
  destroyGroup,
  updateGroup,
  addGroup,
  fetchGroupDetails,
  upgradeGroupUserAdmin,
  groupId,
  group,
  userEmail,
  included }) => {
  const createRowItem = (row, attribute) => {
    const status = row.attributes.status !== undefined
                  ? row.attributes.status : row.attributes[attribute] === userEmail
    return ({
      id: row.id,
      type: row.type,
      name: row.attributes[attribute],
      status
    })
  }

  const createRows = (collection, attribute) => (
    collection.map(item => (
      createRowItem(item, attribute)
    ))
  )

  const { name } = group

  const filterByType = filterType => (
    included.filter(element => (
      element.type === filterType
    ))
  )

  const isUserGroupAdmin = (filterByType('group_admins').map(u => u.attributes.email).indexOf(userEmail) >= 0)

  return (
    <Fragment>
      <div className="group-show">
        <GroupDetailsHeader
          groupId={groupId}
          groupName={name}
          fetchGroupDetails={fetchGroupDetails}
          addGroup={addGroup}
          updateGroup={updateGroup}
          userEmail={userEmail}
          isUserGroupAdmin={isUserGroupAdmin}
        />
        <Container>
          <Grid container item xs={12} spacing={isMobile ? 0 : 3}>
            <Grid item xs={12} lg={4} className="group_details_column">
              <GroupDetailsTable
                tableTitle="Users"
                columns={[
                    { field: 'name', headerName: t('app.groups.attributes.name') }
                  ]}
                rows={createRows(filterByType('users'), 'email')}
                deleteRow={destroyGroupUser}
                parentId={groupId}
                userEmail={userEmail}
                makeGroupUserAdmin={upgradeGroupUserAdmin}
                isUserGroupAdmin={isUserGroupAdmin}
              />
            </Grid>
            <Grid item xs={12} lg={4} className="group_details_column">
              <GroupDetailsTable
                tableTitle="Admins"
                columns={[
                    { field: 'name', headerName: t('app.groups.attributes.name') }
                  ]}
                rows={createRows(filterByType('group_admins'), 'email')}
                makeGroupUserAdmin={removeGroupUserAdmin}
                parentId={groupId}
                userEmail={userEmail}
                isUserGroupAdmin={isUserGroupAdmin}
              />
            </Grid>
            <Grid item xs={12} lg={4} className="group_details_column">
              <GroupDetailsTable
                tableTitle="Subgroups"
                deleteRow={destroyGroup}
                columns={[
                    { field: 'name', headerName: t('app.groups.attributes.name') }
                  ]}
                rows={createRows(filterByType('groups'), 'name')}
                isUserGroupAdmin={isUserGroupAdmin}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </Fragment>
  )
}
GroupDetails.defaultProps = {
  fetchGroupDetails: null,
  included: []
}

GroupDetails.propTypes = {
  groupId: PropTypes.string.isRequired,
  addGroup: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
  destroyGroup: PropTypes.func.isRequired,
  destroyGroupUser: PropTypes.func.isRequired,
  upgradeGroupUserAdmin: PropTypes.func.isRequired,
  removeGroupUserAdmin: PropTypes.func.isRequired,
  fetchGroupDetails: PropTypes.func,
  included: PropTypes.array,
  group: PropTypes.object.isRequired,
  userEmail: PropTypes.string.isRequired,
}

export default GroupDetails
