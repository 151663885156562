import React from 'react'
import PropTypes from 'prop-types'

const ALIGNMENTS = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end'
}

const Spinner = ({ size, align, top }) => (
  <div className="spinner-container">
    <div className="spinner-inner" style={{ justifyContent: ALIGNMENTS[align] }}>
      <div className="spinner" style={{ width: size, marginTop: top }}>
        <svg className="circular" viewBox="25 25 50 50">
          <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
        </svg>
      </div>
    </div>
  </div>
)

Spinner.defaultProps = {
  size: 60,
  top: 0,
  align: 'center'
}

Spinner.propTypes = {
  size: PropTypes.number,
  align: PropTypes.oneOf(Object.keys(ALIGNMENTS)),
  top: PropTypes.number
}

export default Spinner
