import { createMuiTheme } from '@material-ui/core/styles'

const defaultTheme = createMuiTheme()
const theme = createMuiTheme({
    overrides: {
        MuiSnackbar: {
            root: {
                backgroundColor: '#24344e',
                borderRadius: '10px',
                borderBottomRightRadius: '0px',
                [defaultTheme.breakpoints.down(636)]: {
                    width: '338px',
                    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
                    backgroundColor: '#24344e',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: '17px'
                }
            }
        },
        MuiPaper: {
            root: {
                width: '503px',
                height: '60px',
                boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
                backgroundColor: '#24344e!important'
            }
        },
        MuiSnackbarContent: {
            root: {
                borderRadius: '10px',
                borderBottomRightRadius: '0px',
                [defaultTheme.breakpoints.up(636)]: {
                    width: 'calc(100% + 503px)'
                }
            },
            message: {
                height: '22px',
                fontFamily: 'OpenSans',
                fontSize: '16px',
                fontWeight: '600',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.38,
                letterSpacing: 'normal',
                textAlign: 'left',
                color: '#ffffff',
                marginLeft: '14px',
                alignItems: 'center',
                display: 'flex',
                [defaultTheme.breakpoints.down('md')]: {
                    marginLeft: '0px'
                }
            }
        },
        MuiButton: {
            label: {
                width: '41px',
                height: '19px',
                fontFamily: 'OpenSans',
                fontSize: '14px',
                fontWeight: '600',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: '1.36',
                letterSpacing: 'normal',
                textAlign: 'right',
                color: '#3ea6f8',
                [defaultTheme.breakpoints.down('md')]: {
                    fontSize: '14px',
                    fontWeight: 'normal',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 1.36,
                    letterSpacing: '0.24px',
                    textAlign: 'left',
                    color: '#3ea6ff'
                }
            }
        }
    },
})

export default theme
