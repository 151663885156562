import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'reactstrap'

const FilterNavLink = ({ title, href, count, onClick }) => {
  const linkId = `filter-nav-link-${href.replace('#', '')}`
  return (
    <NavLink href={href} className="filter-tab-link" id={linkId} onClick={onClick}>
      <span className="filter-tab-title">{title}</span>
      <span className="filter-tab-count"> ({count}) </span>
    </NavLink>
  )
}

FilterNavLink.defaultProps = {
  count: undefined
}

FilterNavLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  count: PropTypes.number
}

export default FilterNavLink
