import axios from 'axios'
import { loadCookie } from 'utils/cookies_'
import { AUTH_TOKEN_KEY } from 'redux/actions/auth'
import * as groupDetails from 'redux/actions/groupDetails'

export const types = {
  SHOW_GROUP_MEMBERSHIP_SUCCESS: 'group_details/SHOW_GROUP_MEMBERSHIP_SUCCESS',
  SHOW_GROUP_MEMBERSHIP_FAIL: 'group_details/SHOW_GROUP_MEMBERSHIP_FAIL',
  UPDATE_GROUP_MEMBERSHIP_SUCCESS: 'group_details/UPDATE_GROUP_MEMBERSHIP_SUCCESS',
  UPDATE_GROUP_MEMBERSHIP_FAIL: 'group_details/UPDATE_GROUP_MEMBERSHIP_FAIL'
}

const headers = {
  'Content-Type': 'application/vnd.api+json',
  Accept: 'application/vnd.api+json',
  Authorization: `Bearer ${loadCookie(AUTH_TOKEN_KEY)}`
}

export const showGroupMembershipSuccess = (groupId, userId, response) => ({
  type: types.SHOW_GROUP_MEMBERSHIP_SUCCESS,
  groupId,
  userId,
  response: response.data
})

export const showGroupMembershipError = error => ({
  type: types.SHOW_GROUP_MEMBERSHIP_FAIL,
  response: error
})

export const showGroupMembership = (
  groupId,
  userId
) => {
  const url = `${process.env.REACT_APP_SSO_HOST}/api/v2/groups/${groupId}/memberships/${userId}`


  return dispatch => axios
    .get(url, { headers })
      .then((response) => {
        dispatch(showGroupMembershipSuccess(groupId, userId, response))
      })
      .catch((error) => {
        dispatch(showGroupMembershipError(error))
        throw error
      })
}


export const updateGroupMembershipSuccess = (groupId, response) => ({
  type: groupDetails.types.UPDATE_DETAILS_SUCCESS,
  groupId,
  response: response.data,
  included: response.included,
})

export const updateGroupMembershipError = error => ({
  type: types.UPDATE_GROUP_MEMBERSHIP_FAIL,
  response: error
})

export const updateGroupMembership = (
  groupId,
  userId,
  data
) => {
  const url = `${process.env.REACT_APP_SSO_HOST}/api/v2/groups/${groupId}/memberships/${userId}`


  return dispatch => axios
    .put(url, { data }, { headers })
      .then((response) => {
        dispatch(updateGroupMembershipSuccess(groupId, response))
      })
      .catch((error) => {
        dispatch(updateGroupMembershipError(error))
        throw error
      })
}
