export default function truncateString(str, num) {
  if (str !== null) {
    if (str.length <= num) {
      return str
    }
    const truncatedString = `${str.slice(0, num)}...`
    return truncatedString
  }
  return undefined
}
