import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Nav, Container } from 'reactstrap'
import { Switch, NavLink, Redirect } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { t } from 'utils/i18n/translate'
import WorkflowsContainer from 'components/Workflows/container'
import ToolsContainer from 'components/Tools/container'
import ListViewTasks from 'components/Tasks/ListViewTasks'
import PrivateRouteContainer from 'components/PrivateRoute/container'
import { history } from 'redux/configureStore'
import FiltersContainer from 'components/Filters/container'
import 'rc-slider/assets/index.css'

class ListViewTabs extends PureComponent {
  componentDidMount() {
    const { location } = this.props
    if (location === '/dashboard') {
      history.push(this.initialTab())
    }
  }

  initialTab = () => {
    const { tasks } = this.props
    const newTasks = tasks.filter(task => task.status !== 'closed')
    return newTasks.length > 0 ? 'tasks' : 'workflows'
  }

  render() {
    const { currentTab } = this.props
    const area = isMobile ? currentTab : 'dashboard'

    return (
      <Fragment>
        <div className="main-tabs-container">
          <Container className="header">
            <Nav className="main-underline-tabs dashboard-tabs" tabs>
              <Tab path="tasks" label={t('activerecord.models.task.other')} />
              <Tab path="tools" label={t('activerecord.models.tool.other')} />
              <Tab path="workflows" label={t('activerecord.models.workflow.other')} />
            </Nav>
            <FiltersContainer area={area} />
          </Container>
        </div>

        <Switch>
          <PrivateRouteContainer exact path="/dashboard/tasks" component={ListViewTasks} />
          <PrivateRouteContainer exact path="/dashboard/workflows" component={WorkflowsContainer} />
          <PrivateRouteContainer exact path="/dashboard/tools" component={ToolsContainer} />

          <Redirect from="/dashboard" to={`/dashboard/${this.initialTab()}`} />
        </Switch>
      </Fragment>
    )
  }
}

const Tab = ({ path, label }) => (
  <li className="nav-item">
    <NavLink to={`/dashboard/${path}`} className="nav-link">
      <span className="pink-underline-tab-title">{label}</span>
    </NavLink>
  </li>
)

Tab.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

ListViewTabs.propTypes = {
  currentTab: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  tasks: PropTypes.array.isRequired,
}

export default ListViewTabs
