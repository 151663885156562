import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { t } from 'utils/i18n/translate'

const IconActionDialogComponent = ({
  onClick,
  modalTitle,
  modalBody,
  formComponent,
  iconComponent,
  templateId,
  disabled,
  groups
}) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const IconComponent = iconComponent
  const FormComponent = formComponent

  return (
    <Fragment>
      <button
        onClick={handleClickOpen}
        type="button"
        className="icon-action icon-create create-action"
        tabIndex={disabled ? -1 : 0}
        title={t('app.accessibility.create_from_template')}
        aria-label={t('app.accessibility.create_from_template')}
      >
        <IconComponent />
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">
          {modalTitle}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {modalBody !== '' ? modalBody : ''}
          </DialogContentText>
          <FormComponent
            handleClose={handleClose}
            actionFunction={onClick}
            templateId={templateId}
            groups={groups}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

IconActionDialogComponent.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  modalBody: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  formComponent: PropTypes.func.isRequired,
  iconComponent: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  templateId: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired
}

export default IconActionDialogComponent
