import React from 'react'
import PropTypes from 'prop-types'

const GroupAvatar = ({ color, name, float }) => (
  <div className="group_avatar dark" style={{ background: color, float }}>{name}</div>
)

GroupAvatar.defaultProps = {
  color: '#112233',
  name: 'AV',
  float: 'none',
}

GroupAvatar.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string,
  float: PropTypes.string
}

export default GroupAvatar
