import { types } from 'redux/actions/groupDetails'
import * as loadActions from '../middleware/loadActions'

const initialState = {
  ...loadActions.objectInitialState,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.FETCH_GROUP_SUCCESS:
      return loadActions.onLoadSuccess({
        ...state,
        included: [...action.response.included]
      }, action)
    case types.FETCH_GROUP_FAIL:
      return loadActions.onLoadFailure(state, action)
    case types.ADD_SUBGROUP_SUCCESS:
      return {
        ...state,
        included: [action.group, ...state.included]
      }
    case types.ADD_SUBGROUP_FAIL:
      return loadActions.onLoadFailure(state, action)
    case types.UPDATE_DETAILS_FAIL:
      return loadActions.onLoadFailure(state, action)
    case types.UPDATE_DETAILS_SUCCESS: {
      return {
        isLoaded: true,
        isLoading: false,
        data: action.response.data,
        included: [...action.response.included]
      }
    }
    case types.DELETE_GROUPUSER_FAIL:
      return loadActions.onLoadFailure(state, action)
    case types.DELETE_GROUPUSER_SUCCESS: {
      const includedData = [...state.included]
      const element = state.included.find(user => (user.id === action.userId))
      const index = includedData.indexOf(element)

      includedData.splice(index, 1)

      return {
        isLoaded: true,
        isLoading: false,
        data: state.data,
        included: includedData
      }
    }
    case types.DELETE_SUBGROUP_FAIL:
      return loadActions.onLoadFailure(state, action)
    case types.DELETE_SUBGROUP_SUCCESS: {
      const includedData = [...state.included]
      const element = state.included.find(group => (group.id === action.groupId))
      const index = includedData.indexOf(element)
      includedData.splice(index, 1)

      return {
        isLoaded: true,
        isLoading: false,
        data: state.data,
        included: includedData
      }
    }

    default:
      return state
  }
}
