export const types = {
    SHOW_SNACKBAR: 'SHOW_SNACKBAR',
    CLEAR_SNACKBAR: 'CLEAR_SNACKBAR'
}

export const showSnackBar = params => (dispatch) => {
        dispatch({ type: 'SHOW_SNACKBAR', params })
    }

export const clearSnackbar = () => (dispatch) => {
        dispatch({ type: 'CLEAR_SNACKBAR' })
    }
