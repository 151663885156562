import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'utils/i18n/translate'
import SVGIcon from '../SVGIcon/SVGIcons'

const InfoAction = ({ action, width, height, disabled }) => (
  <button
    onClick={action}
    type="button"
    className="icon-action icon-info info-action"
    tabIndex={disabled ? -1 : 0}
    title={t('app.accessibility.more_details')}
    aria-label={t('app.accessibility.more_details')}
  >
    <SVGIcon width={width} height={height} name="description" state="normal" stroke="#000" />
  </button>
)

InfoAction.defaultProps = {
  width: '24px',
  height: '24px',
  disabled: false
}

InfoAction.propTypes = {
  action: PropTypes.func.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  disabled: PropTypes.bool
}

export default InfoAction
