import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavItem
} from 'reactstrap'
import UserAccountIcon from 'images/user-account'
import { t } from 'utils/i18n/translate'
import { Link } from 'react-router-dom'
import Trans from 'components/Trans'
import classNames from 'classnames'

const adminURL = `${process.env.REACT_APP_BACKEND_HOST || ''}/admin`
const editorURL = `${process.env.REACT_APP_BACKEND_HOST || ''}/editor`

class ProfileNavItem extends PureComponent {
  state = { dropdownOpen: false }

  logoutAllApps = () => {
    const { handleLogout } = this.props

    handleLogout(true)
  }

  toggle = () => {
    const { dropdownOpen } = this.state
    this.setState({ dropdownOpen: !dropdownOpen })
  }

  render() {
    const { dropdownOpen } = this.state
    const { firstName, role, hasHelpPage } = this.props
    const isAdmin = role === 'admin'
    const isEditor = role === 'editor'
    const profileClasses = classNames('navbar-icon nav-link profile-menu', { active: dropdownOpen })

    return (
      <NavItem className="profile-menu">
        <ButtonDropdown isOpen={dropdownOpen} toggle={this.toggle}>
          <DropdownToggle
            className={profileClasses}
            tabIndex="0"
            data-toggle="dropdown"
            title={t('app.accessibility.user_account')}
            aria-label={t('app.accessibility.user_account')}
          >
            <UserAccountIcon />
          </DropdownToggle>
          <div className="triangle" />
          <DropdownMenu right>
            <DropdownItem disabled className="profile-name">
              {firstName}
            </DropdownItem>
            <DropdownItem className="profile-link" tag={Link} to="/profile">
              <Trans id="app.user.profile" />
            </DropdownItem>
            {
              isAdmin && <DropdownItem tag="a" href={adminURL} target="_blank">Admin</DropdownItem>
            }
            {
              isEditor && (
              <DropdownItem tag="a" href={editorURL} target="_blank">
                <Trans id="app.auth.editor_menu" />
              </DropdownItem>
              )
            }
            <DropdownItem tag="a" onClick={this.logoutAllApps} className="profile-logout-sso">
              <Trans id="app.auth.logout_from_sso" />
            </DropdownItem>
            { hasHelpPage && (
              <DropdownItem tag="a" href="/static_page/help" className="help-page">
                <Trans id="app.globals.help" />
              </DropdownItem>
            )
            }
          </DropdownMenu>
        </ButtonDropdown>
      </NavItem>
    )
  }
}

ProfileNavItem.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  role: PropTypes.string,
  hasHelpPage: PropTypes.bool.isRequired
}

ProfileNavItem.defaultProps = {
  role: null
}

export default ProfileNavItem
