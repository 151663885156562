import { connect } from 'react-redux'
import { loadStepsForWorkflow } from 'redux/actions/workflowDetails'
import { updateStepDetails } from 'redux/actions/stepDetails'
import Steps from '.'

const mapStateToProps = state => ({
  steps: state.workflowDetails.steps.data,
  isLoaded: state.workflowDetails.steps.isLoaded,
  isLoading: state.workflowDetails.steps.isLoading
})

const mapDispatchToProps = dispatch => ({
  loadStepsForWorkflow: workflowId => dispatch(loadStepsForWorkflow(workflowId)),
  updateStep: (id, data) => dispatch(updateStepDetails(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Steps)
