
import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'
import ColorPicker from 'material-ui-color-picker'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import DialogTitle from '@material-ui/core/DialogTitle'
import { errorToast, successToast } from 'components/Toast'
import { t } from 'utils/i18n/translate'
import GroupAvatar from './GroupAvatar'

class GroupForm extends PureComponent {
  state = {
    groupDetails: {
      name: '',
      avatarName: '',
      avatarColor: '',
      userEmails: ''
    }
  }

  componentDidMount = () => {
    const { groupDetails, isNewObject } = this.props

    if (!isNewObject) {
      this.setState({
        groupDetails: {
          ...groupDetails,

        }
      })
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target
    const { groupDetails } = this.state
    this.setState({
      groupDetails: {
        ...groupDetails,
        [name]: value,

      }
    })
  }

  handleColorChanger = (color) => {
    const { groupDetails } = this.state
    this.setState({
      groupDetails: {
        ...groupDetails,
        avatarColor: color,

      }
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      handleClose,
      actionFunction,
      parentId,
      successToastMessage,
      userEmail,
    } = this.props

    const payload = { ...this.state.groupDetails, parentId, groupAdminEmail: userEmail }
    handleClose()
    actionFunction(payload).then(() => {
      successToast(successToastMessage)
    }).catch((error) => {
      errorToast(error.response.data.message)
    })
  }

  render() {
    const { handleClose, actionLabel } = this.props
    const { groupDetails } = this.state

    const {
      name,
      avatarName,
      avatarColor,
      userEmails,
      isOwnGroup
    } = groupDetails
    return (
      <Fragment>
        <form id="dialog-form" onSubmit={this.handleSubmit}>
          <TextField
            id="group_name"
            name="name"
            label={t('app.groups.labels.group_name')}
            placeholder="Group 1"
            margin="normal"
            variant="outlined"
            fullWidth
            value={name}
            onChange={this.handleChange}
            disabled={isOwnGroup}
          />
          <Grid container spacing={1}>
            <Grid container item xs={12} spacing={isMobile ? 0 : 1}>
              <Grid item xs={12} lg={6}>
                <TextField
                  id="group_avatar_name"
                  name="avatarName"
                  label={t('app.groups.labels.avatar_name')}
                  variant="outlined"
                  placeholder="JD"
                  margin="normal"
                  value={avatarName}
                  onChange={this.handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={6}>

                <ColorPicker
                  onChange={this.handleColorChanger}
                  value={avatarColor}
                  TextFieldProps={{
                    id: 'group_avatar_color',
                    label: 'Hex',
                    variant: 'outlined',
                    type: 'text',
                    name: 'avatarColor',
                    value: avatarColor,
                    placeholder: '#112233',
                    margin: 'normal',
                    InputProps: {
                      endAdornment: <GroupAvatar name={avatarName} color={avatarColor} />,
                    }
                   }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <DialogTitle id="alert-dialog-title">
            Add Users
          </DialogTitle>
          <TextField
            id="group_user_emails"
            label={t('app.groups.labels.group_users')}
            name="userEmails"
            helperText={t('app.groups.helper.group_users')}
            placeholder="diego@devolute.org"
            fullWidth
            margin="normal"
            variant="outlined"
            onChange={this.handleChange}
            value={userEmails}
            multiline

          />
          <div className="dialog-actions-container">
            <Button onClick={handleClose} color="primary" style={{ float: 'left' }}>
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} type="submit" form="dialog-form" color="primary" variant="contained" autoFocus style={{ float: 'right' }}>
              {actionLabel}
            </Button>
          </div>
        </form>
      </Fragment>
    )
  }
}


const mapStateToProps = state => ({
  isLoading: state.groupDetails.isLoading,
  isLoaded: state.groupDetails.isLoaded,
  groupDetails: state.groupDetails.data.attributes
})


GroupForm.defaultProps = {
  parentId: '',
  groupDetails: {},
  isNewObject: true
}

GroupForm.propTypes = {
  parentId: PropTypes.string,
  actionFunction: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  actionLabel: PropTypes.string.isRequired,
  groupDetails: PropTypes.object,
  isNewObject: PropTypes.bool,
  successToastMessage: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, null)(GroupForm)
