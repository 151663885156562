import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Trans from 'components/Trans'
import { loadMagazineArticles, loadMagazineTools, loadMagazineFolders, loadMagazineTaskList } from 'redux/actions/magazine'
import FoldersSection from 'components/Folders/FoldersSection'
import ArticlesContainer from './ArticlesContainer'
import ToolsContainer from './ToolsContainer'
import TasksMagazineContainer from './TasksMagazineContainer'

export class Magazine extends PureComponent {
  componentDidMount() {
    const {
      loadArticles,
      loadTools,
      loadFolders,
      loadMagazineTaskList: loadMagazineTaskListReact,
      workflowCategory
    } = this.props
    loadArticles()
    loadTools()
    loadFolders()
    loadMagazineTaskListReact({
      scope: 'favorites',
      magazine: true,
      workflow_category_id: workflowCategory.id
    })

    const nodes = document.querySelectorAll('.carousel__slider, .carousel__slide')
    nodes.forEach(node => node.setAttribute('tabindex', '-1'))
  }

  render() {
    const { workflowCategory } = this.props

    return (
      <div className="container pt-3">
        <span>
          <Trans id="app.home.magazine" />
        </span>
        <div className="magazine-header">
          <div className="magazine_image" />
          <h2>
            <Trans id={`app.magazine.${workflowCategory.name.toLowerCase()}`} defaultValue={workflowCategory.name} />
          </h2>
        </div>
        <div className="contents">
          <div className="articles-container">
            <ArticlesContainer />
          </div>
          <div className="task-tools-container row">
            <div className="tasks-container col-lg-3 col-md-12">
              <TasksMagazineContainer />
            </div>
            <div className="tools-container col-lg-9 col-md-12">
              <ToolsContainer />
            </div>
          </div>
          <div className="folders-container">
            <FoldersSection />
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadArticles: () => {
    dispatch(loadMagazineArticles(ownProps.workflowCategory.id, { scope: 'published' }))
  },
  loadTools: () => {
    dispatch(loadMagazineTools({ magazine: true }))
  },
  loadFolders: () => {
    if (!ownProps.workflowCategory.folder) {
      return
    }
    dispatch(loadMagazineFolders({ folder_id: ownProps.workflowCategory.folder.id }))
  },
  loadMagazineTaskList: params => dispatch(loadMagazineTaskList(params))
})

Magazine.propTypes = {
  workflowCategory: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  loadArticles: PropTypes.func.isRequired,
  loadTools: PropTypes.func.isRequired,
  loadFolders: PropTypes.func.isRequired,
  loadMagazineTaskList: PropTypes.func.isRequired
}

export default connect(null, mapDispatchToProps)(Magazine)
