import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { DelayInput } from 'react-delay-input'
import {
  Form,
  FormGroup,
  InputGroup,
  Container
} from 'reactstrap'
import classNames from 'classnames'
import { t } from 'utils/i18n/translate'
import Suggestions from './Suggestions'
import NoSuggestions from './NoSuggestions'

const KEYS = {
  TAB: 9,
  LEFT_ARROW: 37,
  UP_ARROW: 38,
  RIGHT_ARROW: 39,
  DOWN_ARROW: 40,
  DELETE: 46,
  ESCAPE: 27,
  COMMA: 188,
  ENTER: 13
}

class SearchBar extends PureComponent {
  state = {
    showSuggestions: false,
    highlighted: -1,
    typedQuery: '',
    searchFocused: false
  }

  textInputRef = React.createRef()

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  handleClick = (event) => {
    const form = document.getElementById('search-form')

    if (form.contains(event.target)) {
      return
    }

    this.hideSuggestions()
  }

  hideSuggestions = () => {
    this.setState({ showSuggestions: false })
  }

  toggleFocusedSearch = (focused = false) => {
    this.setState(prevState => ({
      searchFocused: focused || !prevState.searchFocused
    }))
  }

  handleInputChange = (event) => {
    const { getSuggestions, setValue } = this.props
    const query = event.target.value
    if (query.length < 1) {
      this.setState({
        showSuggestions: false,
        typedQuery: ''
      })
      setValue('')
    } else {
      setValue(query)
      this.setState({ typedQuery: query })
      getSuggestions(query).then(() => this.setState({ showSuggestions: true }))
    }
  }

  handleSuggestionClick = (event) => {
    const { value, setValue } = this.props
    const { textContent } = event.target

    setValue(textContent)
    this.setState({ showSuggestions: false }, () => {
      document.querySelector('#search-form input').value = value
      document.getElementById('search-form').submit()
    })
  }

  handleKeyDown = (event) => {
    const { highlighted, typedQuery } = this.state
    const { results, setValue } = this.props

    if (event.keyCode === KEYS.UP_ARROW) {
      if (highlighted > 0) {
        this.setState(prevState => ({
          highlighted: prevState.highlighted - 1
        }))
        setValue(results[this.state.highlighted - 1].name)
      } else {
        setValue(typedQuery)

        this.setState({
          highlighted: -1
        })
      }
    } else if (event.keyCode === KEYS.DOWN_ARROW && highlighted < results.length - 1) {
      this.setState(prevState => ({
        highlighted: prevState.highlighted + 1
      }))

      setValue(results[this.state.highlighted + 1].name)
    } else if (event.keyCode === KEYS.DELETE) {
      setValue('')

      this.setState({
        showSuggestions: false
      })
    }
  }

  handleSearch = (e) => {
    const { toggleSearchBar, isOpen } = this.props

    if (!isOpen) {
      toggleSearchBar(true)
      setTimeout(() => {
        this.textInputRef.current.focus()
      }, 1)
      e.preventDefault()
    }
  }

  render() {
    const { showSuggestions, highlighted } = this.state
    const { results, value } = this.props
    const searchClasses = classNames('search-form', 'active')

    return (
      <Form id="search-form" className={searchClasses} action="/search">
        <Container>
          <FormGroup>
            <InputGroup>
              <DelayInput
                onChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown}
                onFocus={this.toggleFocusedSearch}
                value={value}
                minLength={1}
                id="search-input"
                autoFocus
                inputRef={this.textInputRef}
                delayTimeout={300}
                name="query"
                autoComplete="off"
                className="form-control"
                placeholder={t('app.home.search_placeholder')}
              />
            </InputGroup>
          </FormGroup>
        </Container>

        {results.length > 0 ? (
          <Suggestions
            isOpen={showSuggestions}
            results={results}
            onClick={this.handleSuggestionClick}
            highlighted={highlighted}
          />
        ) : (
          <NoSuggestions isOpen={showSuggestions} />
        )}
      </Form>
    )
  }
}

SearchBar.propTypes = {
  getSuggestions: PropTypes.func.isRequired,
  toggleSearchBar: PropTypes.func.isRequired,
  results: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
}

export default SearchBar
