import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import { t } from 'utils/i18n/translate'

class WorkflowTemplateForm extends PureComponent {
  state = {
    data: {
      groupId: '',
      templateId: ''
    }
  }

  componentDidMount() {
    const { templateId } = this.props
    this.setState({
      data: {
        templateId
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    const { data } = nextProps

    this.setState({
      data: {
        ...data,

      }
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { actionFunction } = this.props
    const { data } = this.state

    actionFunction(data.groupId)
  }

  handleChange = (event) => {
    const { name, value } = event.target
    const { data } = this.state
    this.setState({
      data: {
        ...data,
        [name]: value,

      }
    })
  }


  render() {
    const { data } = this.state
    const {
      groupId
    } = data
    const { handleClose, groups } = this.props


    return (
      <div className="workflow_template__form-container">
        <form id="workflow_template__form">

          <TextField
            error={groupId === []}
            id="select-group-ids"
            name="groupId"
            variant="outlined"
            value={groupId}
            onChange={this.handleChange}
            label={t('app.workflow-edit.group_ids')}
            required
            select
            fullWidth
          >
            { groups.map(group => (
              <MenuItem key={`group_${group.id}`} value={group.id}>{group.name}</MenuItem>
            ))}
          </TextField>


          <div className="dialog-actions-container">
            <Button onClick={handleClose} color="primary" style={{ float: 'left' }}>
              {t('app.globals.cancel')}
            </Button>
            <Button onClick={this.handleSubmit} type="submit" form="dialog-form" color="primary" variant="contained" autoFocus style={{ float: 'right' }}>
              {t('app.globals.install')}
            </Button>
          </div>
        </form>
      </div>
    )
  }
}

WorkflowTemplateForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  actionFunction: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  templateId: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired
}

export default WorkflowTemplateForm
