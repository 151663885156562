import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'utils/i18n/translate'
import { successToast, errorToast } from 'components/Toast'
import { history } from 'redux/configureStore'
import TemplateIcon from 'images/templates'
import IconActionDialogComponent from 'components/Dialog/IconActionDialog'

import WorkflowTemplateFrom from 'components/Workflows/WorkflowTemplateForm'

const CreateIconAction = ({ createFromTemplate, id, groups }) => {
  const onClick = (groupId) => {
    createFromTemplate(groupId).then((res) => {
      history.push(`/workflows/${res.data.id}`)
      successToast(t('app.workflows.templates.created_successfully'))
    }).catch((error) => {
      const errorId = error && error.response && error.response.data ? error.response.data : 'app.workflows.templates.copying_error'
      errorToast(t(errorId))
    })
  }

  const cleanedGroups = groups.map(group => ({ id: group.id, name: group.name }))
  return (
    <div>
      <IconActionDialogComponent
        onClick={onClick}
        modalTitle={t('app.workflows.templates.install')}
        formComponent={WorkflowTemplateFrom}
        iconComponent={TemplateIcon}
        errorToast={errorToast}
        successToast={successToast}
        templateId={id}
        groups={cleanedGroups}
      />
    </div>
  )
}

CreateIconAction.defaultProps = {
  groups: []
}

CreateIconAction.propTypes = {
  createFromTemplate: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  groups: PropTypes.array
}

export default CreateIconAction
