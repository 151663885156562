import Cookies from 'js-cookie'

export const loadCookie = key => Cookies.getJSON(key)

// Saves a cookie for a key with a given value. The options parameter is any valid option from
// js-cookie (expires, path, domain, secure). Check https://www.npmjs.com/package/js-cookie for
// details.
export const saveCookie = (key, val, options = {}) => {
  Cookies.set(key, val, options)
}

export const removeCookie = (key) => {
  Cookies.remove(key)
}

export default { loadCookie, saveCookie, removeCookie }
