export const types = {
  LOAD_START: 'tool_details/LOAD_START',
  LOAD_SUCCESS: 'tool_details/LOAD_SUCCESS',
  LOAD_FAIL: 'tool_details/LOAD_FAIL'
}

export const loadToolDetails = id => ({
  types: [types.LOAD_START, types.LOAD_SUCCESS, types.LOAD_FAIL],
  promise: client => client.get(`/tools/${id}`)
})
