import { types } from 'redux/actions/profile'
import * as loadActions from '../middleware/loadActions'

export default (state = loadActions.arrayInitialState, action = {}) => {
  switch (action.type) {
    case types.FETCH_PROFILE:
      return loadActions.onLoadSuccess(state, action)
    case types.PROFILE_UPDATED:
      return {
        ...state,
        data: {
          ...state.data,
          attributes: {
            ...state.data.attributes,
            companyEmails: action.profile.data.attributes.companyEmails,
            data: action.profile.data.attributes.data
          }
        }
      }
    default:
      return state
  }
}
