import React, { PureComponent, Fragment } from 'react'
import Trans from 'components/Trans'
import PropTypes from 'prop-types'
import MoreOptions from 'images/more-options'
import { t } from 'utils/i18n/translate'
import { isEmpty } from 'utils/objects'
import { Link } from 'react-router-dom'

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

class KebabDropdown extends PureComponent {
  state = { dropdownOpen: false }

  toggle = () => {
    const { dropdownOpen } = this.state
    this.setState({ dropdownOpen: !dropdownOpen })
  }

  render() {
    const { dropdownOpen } = this.state
    const {
      id,
      delegateTask,
      pickTask,
      canBePicked,
      isAssignedToUser,
      canBeDelegated,
      metadata,
      externalUrl
    } = this.props

    const isRedirectTask = !isEmpty(metadata.url)

    return (
      <Dropdown
        className="kebab-dropdown"
        isOpen={dropdownOpen}
        toggle={this.toggle}
        direction="left"
        inNavbar
      >
        <DropdownToggle
          className="toggle-button"
          title={t('app.accessibility.show_more_options')}
          aria-label={t('app.accessibility.show_more_options')}
        >
          <MoreOptions />
        </DropdownToggle>
        <DropdownMenu className="kebab-menu">
          { canBePicked && (
            <Fragment>
              <DropdownItem className="pick-button" onClick={pickTask}>
                <Trans id="app.home.pick" />
              </DropdownItem>
              <DropdownItem divider />
            </Fragment>
          )
          }
          { canBeDelegated && (
            <Fragment>
              <DropdownItem className="delegate-button" onClick={delegateTask}>
                <Trans id="app.home.delegate" />
              </DropdownItem>
              <DropdownItem divider />
            </Fragment>
          )
          }
          { isAssignedToUser
            && (
              <Fragment>
                { isRedirectTask && (
                  <Fragment>
                    <DropdownItem href={externalUrl} target="_blank" rel="noopener noreferrer" className="go-to-tool-button">
                      <Trans id="app.home.view_tool" />
                    </DropdownItem>
                    <DropdownItem divider />
                  </Fragment>
                )
                }
                <DropdownItem className="view-details-button" tag={Link} to={`/tasks/${id}`}>
                  <Trans id="app.home.view_details" />
                </DropdownItem>
                <DropdownItem divider />
              </Fragment>
            )
          }
        </DropdownMenu>
      </Dropdown>
    )
  }
}

KebabDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  externalUrl: PropTypes.object.isRequired,
  delegateTask: PropTypes.func.isRequired,
  pickTask: PropTypes.func.isRequired,
  canBePicked: PropTypes.bool.isRequired,
  isAssignedToUser: PropTypes.bool.isRequired,
  canBeDelegated: PropTypes.bool.isRequired,
  metadata: PropTypes.object.isRequired,
}

export default KebabDropdown
