export const types = {
  LOGIN_START: 'auth/LOGIN_START',
  LOGIN_FAIL: 'auth/LOGIN_FAIL',
  LOGIN_SUCCESS: 'auth/LOGIN_SUCCESS',
  LOGOUT_START: 'auth/LOGOUT_START',
  LOGOUT_SUCCESS: 'auth/LOGOUT_SUCCESS',
  LOGOUT_FAIL: 'auth/LOGOUT_FAIL',
  SET_REDIRECT_PATH: 'auth/SET_REDIRECT_PATH'
}

export const AUTH_TOKEN_KEY = '_dashboard_sso_jwt'

export const checkSession = () => ({
  types: [types.LOGIN_START, types.LOGIN_SUCCESS, types.LOGIN_FAIL],
  promise: client => client.get('/users/me')
})

export const logout = (includeSSo = false) => ({
  types: [types.LOGOUT_START, types.LOGOUT_SUCCESS, types.LOGOUT_FAIL],
  promise: client => client.delete(`/session${includeSSo === true ? '?include_sso=true' : ''}`),
  includeSSo
})

export const setRedirect = redirectPath => ({
  type: types.SET_REDIRECT_PATH,
  redirectPath
})
