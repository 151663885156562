import React from 'react'
import PropTypes from 'prop-types'
import Trans from 'components/Trans'
import { timeAgo } from 'utils/time'

const TaskInfo = ({ taskName, workflowName, userName, updatedAt }) => (
  <div className="task-info-container">
    <h2 className="task-details-section-title">
      {taskName}
    </h2>
    <h5>{workflowName}</h5>
    <p className="task-details-section-text">
      <Trans id="app.task.from_user_time_ago" name={userName} time_ago={timeAgo(updatedAt)} />
    </p>
  </div>
)

TaskInfo.propTypes = {
  workflowName: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  taskName: PropTypes.string.isRequired,
}

export default TaskInfo
