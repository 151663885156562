import React from 'react'
import Trans from 'components/Trans'
import { Container, Button } from 'reactstrap'
import PropTypes from 'prop-types'


const SaveHeader = ({ onClick, disabled, children }) => (
  <div className="save-header">
    <Container>
      { children }
      <Button color="primary" id="save-button" onClick={onClick} disabled={disabled}>
        <Trans id="app.workflows.workflow_chart.buttons.save" />
      </Button>
    </Container>
  </div>
)

SaveHeader.defaultProps = {
  children: []
}

SaveHeader.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node
  ])
}

export default SaveHeader
