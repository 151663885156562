import { types } from 'redux/actions/workflowDetails'
import { types as workflowTypes } from 'redux/actions/workflows'
import * as loadActions from '../middleware/loadActions'

const initialState = {
  ...loadActions.objectInitialState,
  tasks: loadActions.arrayInitialState,
  steps: loadActions.arrayInitialState,
  edit: { ...loadActions.objectInitialState }
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.LOAD_DETAILS_START:
      return { ...state, ...loadActions.onLoadStart(state) }
    case types.LOAD_DETAILS_SUCCESS:
      return { ...state, ...loadActions.onLoadSuccess(state, action) }
    case types.LOAD_DETAILS_FAIL:
      return { ...state, ...loadActions.onLoadFailure(state, action) }
    case types.EDIT_START:
      return { ...state, edit: loadActions.onLoadStart(state) }
    case types.EDIT_SUCCESS:
      return { ...state, edit: loadActions.onLoadSuccess(state, action) }
    case types.EDIT_FAIL:
      return { ...state, edit: loadActions.onLoadFailure(state, action) }
    case types.UPDATE_START:
      return {
        ...state,
        isUpdating: true,
        isUptated: false
      }
    case types.UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isUptated: true,
        data: {
          ...state.data,
        }
      }
    case types.UPDATE_FAIL:
      return loadActions.onLoadFailure(state, action)
    case types.LOAD_TASKS_START:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          isLoading: true
        }
      }
    case types.LOAD_TASKS_FAIL:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          isLoading: false
        }
      }
    case types.LOAD_TASKS_SUCCESS: {
      return {
        ...state,
        tasks: {
          isLoading: false,
          isLoaded: true,
          data: action.response.data
        }
      }
    }
    case types.LOAD_STEPS_START:
      return {
        ...state,
        steps: {
          ...state.steps,
          isLoading: true
        }
      }
    case types.LOAD_STEPS_FAIL:
      return {
        ...state,
        steps: {
          ...state.steps,
          isLoading: false
        }
      }
    case types.LOAD_STEPS_SUCCESS: {
      return {
        ...state,
        steps: {
          isLoading: false,
          isLoaded: true,
          data: action.response.data
        }
      }
    }
    case types.ADD_TASK_TO_CURRENT_WORKFLOW: {
      return {
        ...state,
        tasks: {
          data: [action.task, ...state.tasks.data],
          isLoaded: true,
          isLoading: false,
        }
      }
    }
    case workflowTypes.INCREMENT_TASKS_COUNT: {
      return {
        ...state,
        data: {
          ...state.data,
          tasksCount: state.data.tasksCount + 1
        }
      }
    }
    default:
      return state
  }
}
