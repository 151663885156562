import { types } from 'redux/actions/toolDetails'
import * as loadActions from '../middleware/loadActions'

const initialState = {
  ...loadActions.objectInitialState
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.LOAD_START:
      return loadActions.onLoadStart(state)
    case types.LOAD_SUCCESS:
      return loadActions.onLoadSuccess(state, action)
    case types.LOAD_FAIL:
      return loadActions.onLoadFailure(state, action)
    default:
      return state
  }
}
