import { connect } from 'react-redux'
import { createWorkflow } from 'redux/actions/workflows'

import CreateAction from './CreateIconAction'

const mapStateToProps = state => ({
  groups: state.groups.data,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  createFromTemplate: groupId => dispatch(createWorkflow(ownProps.id, groupId))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateAction)
