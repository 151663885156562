import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import FavoriteIconActionContainer from 'components/IconActions/FavoriteIconActionContainer'
import CountAction from 'components/IconActions/CountAction'
import WorkflowPlayActionContainer from 'components/IconActions/WorkflowPlayActionContainer'
import CreateIconActionContainer from 'components/IconActions/CreateIconActionContainer'
import InfoAction from 'components/IconActions/InfoAction'

const ActionIcons = ({ id, name, isATemplate, count, action, disabled }) => (
  <div className="icons">
    { !isATemplate && (
      <Fragment>
        <CountAction count={count} />
        <WorkflowPlayActionContainer id={id} name={name} disabled={disabled} />
      </Fragment>
    )}
    <InfoAction action={action} disabled={disabled} />
    { !isATemplate && <FavoriteIconActionContainer type="Workflow" id={id} disabled={disabled} /> }
    { isATemplate && <CreateIconActionContainer id={id} disabled={disabled} /> }
  </div>
)

ActionIcons.defaultProps = {
  isATemplate: false,
  disabled: false
}


ActionIcons.propTypes = {
  action: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isATemplate: PropTypes.bool
}

export default ActionIcons
