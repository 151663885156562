import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import LocaleContext from 'utils/i18n/locale-context'
import { t } from 'utils/i18n/translate'

class Trans extends PureComponent {
  static contextType = LocaleContext

  render() {
    const { id, ...rest } = this.props

    return (
      <Fragment>
        {t(id, rest)}
      </Fragment>
    )
  }
}

Trans.propTypes = {
  id: PropTypes.string.isRequired
}

export default Trans
