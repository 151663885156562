import React, { Fragment } from 'react'

import DefaultLayout from 'layouts/DefaultLayout'
import GroupList from 'components/Groups/container'
import GroupPageHeader from './GroupPageHeader'


const GroupPage = () => {
  const renderAdmin = () => (
    <GroupList />
  )

  return (
    <Fragment>
      <DefaultLayout>
        <div className="groups">
          <GroupPageHeader />
          { renderAdmin() }
        </div>
      </DefaultLayout>
    </Fragment>
  )
}

export default GroupPage
