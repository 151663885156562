import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { loadWorfklowList } from 'redux/actions/workflows'
import App from './App'

class AppContainer extends PureComponent {
  state = {
    isLoaded: false
  }

  componentDidMount() {
    const { loadWorkflows } = this.props
    loadWorkflows().then(
      setTimeout(() => (this.setState({ isLoaded: true })), 300)
    )
  }

  render() {
    const { workflows } = this.props
    const { isLoaded } = this.state
    const root = workflows.length > 0 ? '/dashboard' : '/templates'

    return (
      isLoaded && <App root={root} />
    )
  }
}

AppContainer.propTypes = {
  workflows: PropTypes.array.isRequired,
  loadWorkflows: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  workflows: state.workflows.data
})

const mapDispatchToProps = dispatch => ({
  loadWorkflows: () => dispatch(loadWorfklowList({ scope: 'all' }))
})

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer)
