import { createStore, applyMiddleware } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import clientMiddleware from 'redux/middleware/clientMiddleware'
import { createBrowserHistory } from 'history'
import reducer from './reducer'

export const history = createBrowserHistory()

const configureStore = (client) => {
  const middleware = [clientMiddleware(client), thunk, routerMiddleware(history)]
  const withDevTools = composeWithDevTools(applyMiddleware(...middleware))
  const store = createStore(reducer(history), withDevTools)

  // Hot reloading
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducer', () => {
      store.replaceReducer(reducer(history))
    })
  }
  return store
}

export default configureStore
