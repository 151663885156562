import { types } from 'redux/actions/system'

const initialState = {
  title: 'Dashboard',
  dashboardTabHeight: 250,
  dashboardView: '',
  filters: {
    dashboard: {
      opened: false,
      value: 'all'
    },
    tasks: {
      opened: false,
      value: 'all'
    }
  },
  currentTab: 'tasks',
  layoutCols: 3,
  isGridView: true,
  sidebar: {
    isExpanded: false
  },
  menu: {
    isOpen: false
  },
  currentLayout: undefined
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.DASHBOARD_TAB_LOADED: {
      const container = document.getElementById('tabs-container')

      if (container && container.offsetHeight > 0) {
        return { ...state,
          dashboardTabHeight: container.offsetHeight
        }
      }

      return state
    }
    case types.SET_TITLE: {
      return { ...state, title: action.title }
    }
    case types.TOGGLE_FILTER: {
      const updatedFilter = { ...state.filters[action.area] }
      updatedFilter.opened = !updatedFilter.opened

      return {
        ...state,
        filters: { ...state.filters, [action.area]: updatedFilter }
      }
    }

    case types.DASHBOARD_VIEW: {
      return {
        ...state,
        dashboardView: action.area
      }
    }
    case types.SET_FILTER: {
      const updatedFilter = { ...state.filters[action.area] }
      updatedFilter.value = action.value

      return {
        ...state,
        filters: { ...state.filters, [action.area]: updatedFilter }
      }
    }
    case types.TOGGLE_EXPAND: {
      const { isExpanded } = { ...state.sidebar }
      const { openFlag } = action

      let newExpanded
      switch (openFlag) {
        case 'open':
          newExpanded = true
          break
        case 'close':
          newExpanded = false
          break
        default:
          newExpanded = !isExpanded
      }

      return {
        ...state,
        sidebar: { isExpanded: newExpanded }
      }
    }
    case types.DASHBOARD_SWITCH_LAYOUT: {
      let { layoutCols } = state
      const { direction } = action
      layoutCols = direction
      return {
        ...state,
        layoutCols
      }
    }
    case types.SET_VIEW_TYPE: {
      let { isGridView } = state
      const { viewType } = action

      if (viewType === 'gridView' && !isGridView) {
        isGridView = true
      } else if (viewType === 'listView' && isGridView) {
        isGridView = false
      }

      return {
        ...state,
        isGridView
      }
    }
    case types.SET_TAB: {
      return {
        ...state,
        currentTab: action.value
      }
    }
    case types.TOGGLE_MENU: {
      const { isOpen } = action

      return {
        ...state,
        menu: { isOpen }
      }
    }
    case types.SWITCH_LAYOUT: {
      const { layout } = action

      return {
        ...state,
        currentLayout: layout
      }
    }
    default:
      return state
  }
}
