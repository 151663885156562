import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@material-ui/core'
import SaveHeader from 'components/SaveHeader'
import Trans from 'components/Trans'
import { Button } from 'reactstrap'
import ConfirmModal from 'components/Modal/ConfirmModal'
import StepContainer from './Step'
import StepList from './StepList'
import NewStepContainer from './NewStep'

const StepTab = ({
    workflowId,
    workflowName,
    steps,
    loadStepsForWorkflow,
    saveHandler,
    isModalOpened,
    handleCloseModal,
    handleDiscardChanges,
    handleSaveInModal,
    saveStep
  }) => {
  const initialState = () => (
    {
      stepsLoaded: [],
      currentStep: null,
      currentTab: 0,
      stepsHasChanges: false,
      isNewModalOpen: false
    }
  )

  const filteredSteps = steps.filter(step => !step.status || step.status !== 'not_created')

  const [state, setState] = useState(initialState())

  useEffect(() => {
    setState({ ...state, currentStep: steps[0] })
  }, steps)

  const handleGroupChange = (e, groups) => {
    const { value } = e.target
    const group = groups.find(g => g.id === value)

    setState({
      ...state,
      stepsHasChanges: true,
      currentStep: {
        ...state.currentStep,
        group: {
          id: group && group.id,
          name: group && group.name
        }
      }
    })
    saveHandler(false)
  }

  const handleEndpointChange = (e, endpoints) => {
      const { value } = e.target
      const endpoint = endpoints.find(item => item.id === value)

      setState({
        ...state,
        stepsHasChanges: true,
        currentStep: {
          ...state.currentStep,
          endpoint
        },
      })
      saveHandler(false)
  }

  const handleChange = (e) => {
    const { name, value, checked } = e.target
    setState({
      ...state,
      stepsHasChanges: true,
      currentStep: { ...state.currentStep, [name]: checked || value }
    })
    saveHandler(false)
  }

  const saveCurrentStep = () => {
    const promise = saveStep(state.currentStep.id, state.currentStep)
    promise.then(() => {
      setState({ ...state, stepsHasChanges: false })
    })

    return promise
  }

  const saveWithModal = () => {
    saveCurrentStep().then(() => handleSaveInModal())
  }

  const setCurrentStep = (step) => {
    setState({ ...state, currentStep: step })
  }

  const createStep = () => {
    setState({ ...state, isNewModalOpen: true })
  }

  const closeNewModal = () => {
    setState({ ...state, isNewModalOpen: false })
  }

  const createHandler = () => {
    loadStepsForWorkflow(workflowId)
  }

  return (
    <Fragment>
      <NewStepContainer
        isOpen={state.isNewModalOpen}
        handleCloseModal={closeNewModal}
        workflowId={workflowId}
        createHandler={createHandler}
      />
      <Modal
        open={isModalOpened}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ConfirmModal
          handleSaveClick={saveWithModal}
          workflowId={workflowId}
          handleDiscardChanges={handleDiscardChanges}
        />
      </Modal>
      <SaveHeader disabled={!state.stepsHasChanges} onClick={saveCurrentStep}>
        <Button color="primary" className="create-step" onClick={createStep}>
          <Trans id="app.steps.create.button" />
        </Button>
      </SaveHeader>
      { state.currentStep && (
        <StepList
          steps={steps}
          workflowName={workflowName}
          setCurrentStep={setCurrentStep}
        >
          <StepContainer
            step={state.currentStep}
            handleChange={handleChange}
            steps={filteredSteps}
            handleGroupChange={handleGroupChange}
            handleEndpointChange={handleEndpointChange}
          />
        </StepList>
      )}
    </Fragment>
  )
}

StepTab.propTypes = {
  steps: PropTypes.array.isRequired,
  loadStepsForWorkflow: PropTypes.func.isRequired,
  workflowId: PropTypes.string.isRequired,
  workflowName: PropTypes.string.isRequired,
  saveHandler: PropTypes.func.isRequired,
  saveStep: PropTypes.func.isRequired,
  isModalOpened: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleDiscardChanges: PropTypes.func.isRequired,
  handleSaveInModal: PropTypes.func.isRequired
}

export default StepTab
