import React from 'react'
import PropTypes from 'prop-types'

const GroupIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width="35.139" height="24.493" viewBox="0 0 35.139 24.493">
    <g id="Group_8326" data-name="Group 8326" transform="translate(10217.194 -9143.001)">
      <path id="ic_group_24px" d="M13.167,15.428a5.214,5.214,0,1,0-5.214-5.214A5.192,5.192,0,0,0,13.167,15.428Zm0,3.476C9.117,18.9,1,20.938,1,24.988v4.345H25.333V24.988C25.333,20.938,17.216,18.9,13.167,18.9Z" transform="translate(-10218.194 9138.001)" />
      <path id="ic_group_24px-2" data-name="ic_group_24px" d="M17.594,14.189A4.594,4.594,0,1,0,13,9.594,4.575,4.575,0,0,0,17.594,14.189Zm0,3.063c-.444,0,.536-.046,0,0,1.777,1.286,1.531,3.094,1.531,5.36v2.372h9.189V22.612C28.315,19.044,21.163,17.252,17.594,17.252Z" transform="translate(-10210.37 9142.51)" />
    </g>
  </svg>
)

GroupIcon.defaultProps = {
  className: 'groups-icon'
}

GroupIcon.propTypes = {
  className: PropTypes.string
}

export default GroupIcon
