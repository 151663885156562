export const types = {
  LOAD_CATEGORIES_START: 'workflow_categories/LOAD_CATEGORIES_START',
  LOAD_CATEGORIES_SUCCESS: 'workflow_categories/LOAD_CATEGORIES_SUCCESS',
  LOAD_CATEGORIES_FAIL: 'workflow_categories/LOAD_CATEGORIES_FAIL',
}

export const loadWorkflowCategoriesList = () => ({
  types: [types.LOAD_CATEGORIES_START, types.LOAD_CATEGORIES_SUCCESS, types.LOAD_CATEGORIES_FAIL],
  promise: client => client.get('/workflow_categories/', { params: { show_as_magazine: true } })
})
