import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import Menu from '@material-ui/core/Menu'

import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import More from '@material-ui/icons/MoreVert'
import ReplyIcon from '@material-ui/icons/Reply'
import IconButton from '@material-ui/core/Button'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import DialogComponent from 'components/Dialog/MenuDialog'
import GroupMembershipForm from 'components/GroupDetails/GroupMembershipForm'

import GroupAvatar from 'components/Groups/GroupAvatar'
import truncateString from 'utils/strings'
import { t } from 'utils/i18n/translate'

const GroupDetailsTableRow = ({
  row,
  columns,
  userEmail,
  groupId,
  tableTitle,
  handleRowOptionsClick,
  showPagination,
  deleteRow,
  isUserGroupAdmin
  }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openDialogName, setOpenDialog] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (action = undefined) => {
    if (typeof action === 'function') {
      action(row)
    }
    setAnchorEl(null)
  }

  const closeDialog = () => {
    setOpenDialog(null)
  }

  const isGroupList = (tableTitle === t('app.groups.all_groups'))


  const renderAvatar = (color, name) => (
    <GroupAvatar color={pickColor(color)} name={name} float="right" />
  )
  const pickColor = color => (color === ' ' ? '246ee3' : color)

  const renderAction = () => (
    showPagination ? (
      <Link to={`${document.location.pathname}/${row.id}`} className="table-show-link">
        <ReplyIcon />
      </Link>
      )
    : ((tableTitle !== 'Admins'
        && (
          <IconButton>
            <More />
          </IconButton>
      ))))

  const RenderMenu = () => (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      className="group_details_table_row_menu"
    >
      <DialogComponent
        open={openDialogName === row.id && anchorEl === null}
        setOpen={setOpenDialog}
        buttonLabel={t('app.groups.modal.edit_group_membership.title')}
        buttonVariant="text"
        buttonColor="primary"
        modalTitle={t('app.groups.modal.edit_group_membership.title')}
        actionLabel={t('app.groups.actions.edit')}
        formComponent={GroupMembershipForm}
        actionFunction={undefined}
        closeMenu={handleClose}
        parentId={groupId}
        isNewObject={false}
        userEmail={userEmail}
        successToast={t('app.groups.notifications.update_success')}
        errorToast={t('app.groups.notifications.update_failure')}
        fullScreen={isMobile}
        onClose={closeDialog}
        rowId={row.id}
      />
      { (deleteRow !== undefined) && (
        <MenuItem onClick={() => handleClose(handleRowOptionsClick)}>
          <ListItemText
            primary={t(`app.table.delete_${tableTitle.toLowerCase()}`)}
          />
        </MenuItem>
      )}
    </Menu>
  )

  const pendingApproval = () => (
    <span style={{ fontSize: '80%', fontStyle: 'italic', display: 'block' }}>
      ({t('app.table.waiting_for_approval')})
    </span>
  )

  const cellClass = (row.status && row.status === 'waiting_for_approval') ? 'waiting_for_approval' : 'not'
  const avatarClass = column => (column.field === 'avatar' ? 'avatarColumn' : '')

  const renderRows = () => (
    <TableRow key={row.name} className="group_details_table_row">
      {columns.map(column => (
        <TableCell
          key={row[column.field]}
          align={column.field === 'avatar' ? 'right' : 'left'}
          className={`${cellClass} ${avatarClass(column)}`}

        >
          <span title={column.field === 'avatar' ? '' : `${row[column.field]}`}>
            {column.field === 'avatar' ? renderAvatar(row[column.field][1], row[column.field][0]) : `${truncateString(row[column.field], 28)}`}
          </span>
          {(row.status && row.status === 'waiting_for_approval') ? pendingApproval() : ''}

        </TableCell>
      ))}


      <TableCell
        scope="row"
        align="right"
        className={isGroupList ? 'actionColumn' : ''}
      >
        { isUserGroupAdmin && !isGroupList && (userEmail !== row.name) && (
          <Fragment>

            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
              <More />
            </IconButton>
            <RenderMenu />
          </Fragment>
        )}
        { (deleteRow === undefined) && renderAction(row) }
      </TableCell>

    </TableRow>
  )


  return (
    renderRows()
  )
}
GroupDetailsTableRow.defaultProps = {
  handleRowMakeAdminClick: undefined,
  makeGroupUserAdmin: undefined,
  deleteRow: undefined,
}

GroupDetailsTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  noActions: PropTypes.bool.isRequired,
  userEmail: PropTypes.string.isRequired,
  tableTitle: PropTypes.string.isRequired,
  handleRowOptionsClick: PropTypes.func.isRequired,
  showPagination: PropTypes.bool.isRequired,
  deleteRow: PropTypes.func,
  isUserGroupAdmin: PropTypes.bool.isRequired,
  groupId: PropTypes.string.isRequired
}

export default GroupDetailsTableRow
