import React from 'react'

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" id="prefix__dashboard-icon-gridViewM" width="24" height="24" viewBox="0 0 24 24">
    <g id="Rectangle_6819" className="cls-2" data-name="Rectangle 6819" transform="translate(0 17)">
      <rect width="7" height="7" className="cls-3" rx="1" />
      <rect width="5.5" height="5.5" x=".75" y=".75" className="cls-1" rx=".25" />
    </g>
    <g id="Rectangle_6820" className="cls-2" data-name="Rectangle 6820" transform="translate(8.5)">
      <rect width="7" height="8" className="cls-3" rx="1" />
      <rect width="5.5" height="6.5" x=".75" y=".75" className="cls-1" rx=".25" />
    </g>
    <g id="Rectangle_6821" className="cls-2" data-name="Rectangle 6821" transform="translate(17)">
      <rect width="7" height="13" className="cls-3" rx="1" />
      <rect width="5.5" height="11.5" x=".75" y=".75" className="cls-1" rx=".25" />
    </g>
    <g id="Rectangle_6822" className="cls-2" data-name="Rectangle 6822" transform="translate(8.5 9)">
      <rect width="7" height="15" className="cls-3" rx="1" />
      <rect width="5.5" height="13.5" x=".75" y=".75" className="cls-1" rx=".25" />
    </g>
    <g id="Rectangle_6825" className="cls-2" data-name="Rectangle 6825" transform="translate(17 14)">
      <rect width="7" height="10" className="cls-3" rx="1" />
      <rect width="5.5" height="8.5" x=".75" y=".75" className="cls-1" rx=".25" />
    </g>
    <g id="Rectangle_6830" className="cls-2" data-name="Rectangle 6830">
      <rect width="7" height="16" className="cls-3" rx="1" />
      <rect width="5.5" height="14.5" x=".75" y=".75" className="cls-1" rx=".25" />
    </g>
  </svg>
)
