import { connect } from 'react-redux'
import { toggleFilter } from 'redux/actions/system'
import ListViewTabs from './ListViewTabs'

const mapStateToProps = state => (
  {
    isFilterOpened: state.system.filters[state.system.currentTab].opened,
    filterValue: state.system.filters[state.system.currentTab].value,
    currentTab: state.system.currentTab,
    location: state.router.location.pathname,
    tasks: state.tasks.data
  }
)

const mapDispatchToProps = dispatch => ({
  toggleFilter: area => dispatch(toggleFilter(area))
})

export default connect(mapStateToProps, mapDispatchToProps)(ListViewTabs)
