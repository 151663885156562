import React from 'react'
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts'
import PropTypes from 'prop-types'


export const SimpleLineChart = ({ data, kFormatter, formatter }) => (
  <ResponsiveContainer width="99%" height={400}>
    <LineChart
      width={600}
      height={300}
      data={data}
      margin={{
            top: 5, right: 30, left: 20, bottom: 5,
        }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="day" />
      <YAxis tickFormatter={kFormatter} />
      <Tooltip formatter={value => formatter.format(value).slice(0, -3)} />
      <Legend />
      <Line type="monotone" dataKey="revenue" name="Revenue" stroke="#8884d8" activeDot={{ r: 8 }} />
    </LineChart>
  </ResponsiveContainer>
)
SimpleLineChart.propTypes = {
    data: PropTypes.object.isRequired,
    kFormatter: PropTypes.func.isRequired,
    formatter: PropTypes.func.isRequired
}

export default SimpleLineChart
