import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  NavItem,
} from 'reactstrap'

const UtilityNavItem = (props) => {
  const {
    id,
    children,
    classes,
    titleTag,
    ariaLabel
  } = props

  // TODO: can be removed after removing the UncontrolledTooltip
  const linkId = `link${id}`

  return (
    <NavItem>
      <Link
        to="/groups"
        id={linkId}
        type="button"
        title={titleTag}
        aria-label={ariaLabel}
        data-open="brandnav"
        aria-haspopup="true"
        aria-expanded="false"
        className={`navbar-icon nav-link ${linkId} ${classes}`}
        tabIndex="0"
      >
        {children}
      </Link>
    </NavItem>
  )
}

UtilityNavItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  children: PropTypes.node,
  classes: PropTypes.string,
  titleTag: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired
}

UtilityNavItem.defaultProps = {
  children: null,
  classes: '',
}

export default UtilityNavItem
