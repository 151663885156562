import { types } from 'redux/actions/workflows'
import * as loadActions from '../middleware/loadActions'

const initialState = {
  ...loadActions.arrayInitialState,
  templates: loadActions.arrayInitialState
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.LOAD_START:
      return loadActions.onLoadStart(state)
    case types.LOAD_SUCCESS:
      return loadActions.onLoadSuccess(state, action)
    case types.LOAD_FAIL:
      return loadActions.onLoadFailure(state, action)
    case types.LOAD_TEMPLATES_START:
      return { ...state, templates: loadActions.onLoadStart(state) }
    case types.LOAD_TEMPLATES_SUCCESS: {
      return { ...state, templates: loadActions.onLoadSuccess(state, action) }
    }
    case types.LOAD_TEMPLATES_FAIL:
      return { ...state, templates: loadActions.onLoadFailure(state, action) }
    case types.INCREMENT_TASKS_COUNT: {
      const workflows = state.data.map((workflow) => {
        if (workflow.id === action.id) {
          return { ...workflow, tasksCount: workflow.tasksCount + 1 }
        }

        return { ...workflow }
      })

      return { ...state, data: workflows }
    }
    case types.DECREASE_TASKS_COUNT: {
      const workflows = state.data.map((workflow) => {
        if (workflow.id === action.id) {
          return { ...workflow, tasksCount: workflow.tasksCount - 1 }
        }

        return { ...workflow }
      })

      return { ...state, data: workflows }
    }
    default:
      return state
  }
}
