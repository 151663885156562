import React from 'react'
import Trans from 'components/Trans'

const ToolsHeader = () => (
  <div className="header">
    <span> <Trans id="activerecord.models.tool.other" /> </span>
    <span> <Trans id="app.list_view.titles.open" /> </span>
    <span> <Trans id="app.list_view.titles.details" /> </span>
    <span> <Trans id="app.list_view.titles.favorite" /> </span>
  </div>
)

export default ToolsHeader
