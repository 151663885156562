import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

export const HelmetTags = ({ locale, title }) => (
  <Helmet>
    <html lang={locale} />
    <title> { title } </title>
  </Helmet>
)

HelmetTags.propTypes = {
  locale: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  title: state.system.title
})

export default connect(mapStateToProps)(HelmetTags)
