import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { addTask, updateTask } from 'redux/actions/tasks'
import { addNotificationTask } from 'redux/actions/notifications'
import { incrementTasksCount } from 'redux/actions/workflows'
import { showSnackBar } from 'redux/actions/snackbar'
import { addTaskToCurrentWorkflow } from 'redux/actions/workflowDetails'
import PropTypes from 'prop-types'
import { camel } from 'change-case'
import { convertData } from 'api/rails_middleware'
import BrandBar from 'layouts/BrandBar'
import { ActionCableConsumer } from 'react-actioncable-provider'
import FooterContainer from 'layouts/Footer/container'
import { t } from 'utils/i18n/translate'
import NavBarContainer from 'layouts/NavBar/container'
import ErrorBoundary from 'components/ErrorBoundary/.'
import TaskSnackBar from '../../components/TaskSnackBar'

export const DefaultLayout = (
  {
    children,
    includeNavbar,
    isMenuOpen,
    userEmail,
    userGroupIds,
    userId,
    addTask: addTaskRedux,
    addNotification,
    updateTask: updateTaskRedux,
    incrementTasksCount: incrementTasksCountRedux,
    addTaskToCurrentWorkflow: addTaskToCurrentWorkflowRedux,
    taskSnackBar,
    status,
    cable
  }
) => {
  const handleReceivedTask = (response) => {
    const task = convertData(response, camel)


    if (task.taskType.includes('notification')) {
      addNotification(task)
    } else {
      addTaskRedux({ ...task, justIn: true })
      addTaskToCurrentWorkflowRedux({ ...task, justIn: false })


      if (task.action === 'created') {
        incrementTasksCountRedux(task.workflow.id)
          if (userId === task.processOwner) {
              taskSnackBar({ message: t('app.workflows.new_task'), taskId: task.id, workflowName: task.workflow.name, taskGroupId: task.group.id })
          }
      }
    }

    // Need to have a timeout before updating the new task bcs redux is assynchronous
    setTimeout(() => {
      updateTaskRedux(task.id, { ...task, justIn: false })
    }, 50)
  }

  const showBrandBar = process.env.REACT_APP_SHOW_BRAND_BAR === 'true'
  const showFooter = process.env.REACT_APP_SHOW_FOOTER === 'true'

  return (
    <Fragment>
      { cable && (
        <Fragment>
          <ActionCableConsumer
            channel={{ channel: 'UserTasksChannel', email: userEmail }}
            onReceived={handleReceivedTask}
          />
          { userGroupIds && userGroupIds.map(groupId => (
            <ActionCableConsumer
              channel={{ channel: 'GroupTasksChannel', email: userEmail, group_id: groupId }}
              onReceived={handleReceivedTask}
              key={groupId}
            />
          ))
          }
          <TaskSnackBar />
        </Fragment>
      )}
      { showBrandBar && <BrandBar /> }
      {includeNavbar && <NavBarContainer title={t('app.name')} subtitle={t('app.subtitle')} />}
      <main className={isMenuOpen ? 'open' : ''}>
        <ErrorBoundary status={status}>
          {children}
        </ErrorBoundary>
      </main>
      { showFooter && <FooterContainer /> }
    </Fragment>
  )
}

DefaultLayout.defaultProps = {
  includeNavbar: true,
  isMenuOpen: false,
  userGroupIds: null,
  status: 200,
  cable: true
}

DefaultLayout.propTypes = {
  includeNavbar: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  isMenuOpen: PropTypes.bool,
  userEmail: PropTypes.string.isRequired,
  userGroupIds: PropTypes.array,
  userId: PropTypes.string.isRequired,
  addTask: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  updateTask: PropTypes.func.isRequired,
  incrementTasksCount: PropTypes.func.isRequired,
  taskSnackBar: PropTypes.func.isRequired,
  addTaskToCurrentWorkflow: PropTypes.func.isRequired,
  status: PropTypes.number,
  cable: PropTypes.bool
}

const mapStateToProps = state => ({
  isMenuOpen: state.system.menu.isOpen,
  userEmail: state.auth.data.email,
  userGroupIds: state.auth.data.groupIds,
  userId: state.auth.data.id
})

const mapDispatchToProps = dispatch => ({
  addTask: task => dispatch(addTask(task)),
  addNotification: task => dispatch(addNotificationTask(task)),
  updateTask: (taskId, params) => dispatch(updateTask(taskId, params)),
  incrementTasksCount: workflowId => dispatch(incrementTasksCount(workflowId)),
  taskSnackBar: params => dispatch(showSnackBar(params)),
  addTaskToCurrentWorkflow: task => dispatch(addTaskToCurrentWorkflow(task))
})

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout)
