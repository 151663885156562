import React from 'react'
import Trans from 'components/Trans'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'

const ConfirmModal = ({
  handleSaveClick,
  handleDiscardChanges,
  title,
  message,
  cancelButton,
  confirmButton,
  children,
  confirmEnabled
}) => (

  <div className="save-modal-body">
    <div className="header">
      <h2 id="title">
        <Trans id={title} />
      </h2>
      { message.length > 0 && (
        <p id="description">
          <Trans id={message} />
        </p>
      )}
    </div>

    { children && (
      <div className="content">
        { children }
      </div>
    )}

    <div className="actions d-flex justify-content-between align-items-center">
      <Button className="discard-button" onClick={handleDiscardChanges}>
        <Trans id={cancelButton} />
      </Button>
      <Button className="save-button" color="primary" onClick={handleSaveClick} disabled={!confirmEnabled}>
        <Trans id={confirmButton} />
      </Button>
    </div>
  </div>
)

ConfirmModal.defaultProps = {
  message: 'app.save_modal.subtitle',
  title: 'app.save_modal.title',
  cancelButton: 'app.save_modal.discard_changes',
  confirmButton: 'app.workflows.workflow_chart.buttons.save',
  children: [],
  confirmEnabled: true
}

ConfirmModal.propTypes = {
  handleSaveClick: PropTypes.func.isRequired,
  handleDiscardChanges: PropTypes.func.isRequired,
  message: PropTypes.string,
  title: PropTypes.string,
  cancelButton: PropTypes.string,
  confirmButton: PropTypes.string,
  confirmEnabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node
  ])
}

export default ConfirmModal
