import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Spinner from 'components/Spinner'
import ReactRouterPropTypes from 'react-router-prop-types'
import { loadFavoriteList, makeFavorite, removeFavorite } from 'redux/actions/favorites'
import { loadTaskDetails, updateTaskDetails } from 'redux/actions/taskDetails'
import { setTitle } from 'redux/actions/system'
import DefaultLayout from 'layouts/DefaultLayout'
import { t } from 'utils/i18n/translate'
import TaskDetails from 'components/TaskDetails'

class TaskDetailsPage extends PureComponent {
  componentWillMount() {
    this.setup()
  }

  componentDidMount() {
    const { setPageTitle } = this.props

    setPageTitle(t('app.pages.titles.task_detail_view'))
  }

  componentWillReceiveProps(nextProps) {
    const { match } = this.props
    if (nextProps.match.params.id !== match.params.id) {
      this.setup(nextProps)
    }
  }

  setup = (nextProps) => {
    const props = nextProps || this.props
    const { loadTask, loadFavorites, match } = props
    const { id } = match.params

    loadTask(id)
    loadFavorites()
  }

  render() {
    const {
      task,
      favorite,
      isLoaded,
      isLoading,
      handleMakeFavorite,
      handleRemoveFavorite,
      updateTask,
      role,
      status
    } = this.props

    return (
      <DefaultLayout status={status}>
        { (isLoading || !isLoaded) ? (
          <Spinner size={40} top={0} align="center" />
        ) : (
          <TaskDetails
            task={task}
            favorite={favorite}
            handleMakeFavorite={handleMakeFavorite}
            handleRemoveFavorite={handleRemoveFavorite}
            updateTaskDetails={updateTask}
            role={role}
          />
        )}
      </DefaultLayout>
    )
  }
}

const getFavorite = (favorites, taskId) => (
  favorites.find(favorite => favorite.favoritableType === 'Task' && favorite.favoritableId === taskId)
)

const mapStateToProps = (state, ownProps) => ({
  isLoaded: state.taskDetails.isLoaded,
  isLoading: state.taskDetails.isLoading,
  status: state.taskDetails.status,
  task: state.taskDetails.data,
  role: state.auth.data.role,
  favorite: getFavorite(state.favorites.data, ownProps.match.params.id)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadTask: id => dispatch(loadTaskDetails(id)),
  loadFavorites: () => dispatch(loadFavoriteList()),
  handleMakeFavorite: () => dispatch(makeFavorite('Task', ownProps.match.params.id)),
  handleRemoveFavorite: id => dispatch(removeFavorite(id)),
  updateTask: (taskId, data) => dispatch(updateTaskDetails(taskId, data)),
  setPageTitle: title => (dispatch(setTitle(title)))
})

TaskDetailsPage.defaultProps = {
  isLoaded: false,
  isLoading: false,
  favorite: null,
  updateTask: null,
  role: null
}

TaskDetailsPage.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  loadTask: PropTypes.func.isRequired,
  updateTask: PropTypes.func,
  handleMakeFavorite: PropTypes.func.isRequired,
  handleRemoveFavorite: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  loadFavorites: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  favorite: PropTypes.object,
  isLoaded: PropTypes.bool,
  isLoading: PropTypes.bool,
  role: PropTypes.string,
  task: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  status: PropTypes.number.isRequired,
  setPageTitle: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetailsPage)
