import * as React from 'react'
import PropTypes from 'prop-types'
import { List, Datagrid, TextField, FunctionField } from 'react-admin'
import { ResourceActionField } from 'admin/components/ResourceActionField'

const mapAttributes = attributes => (
  attributes.map(attribute => (
    <FunctionField
      label={attribute}
      key={record => record.id}
      render={record => record.resource_data[attribute.toLowerCase()]}
    />
  ))
)

const mapActions = actions => actions.map(action => (
  <ResourceActionField
    label={action.name}
    workflowId={action.workflow_id}
    key={record => record.id}
  />
))

export const ResourceList = (props) => {
  const { options: { attributes, actions } } = props
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" />
        <FunctionField label="User" render={record => record.user.name || ''} />
        {attributes ? mapAttributes(attributes) : null}
        {actions ? mapActions(actions) : null}
      </Datagrid>
    </List>
  )
}

ResourceList.propTypes = {
  resource: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  attributes: PropTypes.array,
  actions: PropTypes.array
}

ResourceList.defaultProps = {
  attributes: null,
  actions: null
}

export default ResourceList
