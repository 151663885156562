import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import Trans from 'components/Trans'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import PropTypes from 'prop-types'

const Step = ({ step }) => (
  <Accordion className="step-list-accordion">
    <AccordionSummary
      aria-controls={step.name}
    >
      { step.name }
    </AccordionSummary>
    <AccordionDetails>
      <span> <Trans id="activerecord.attributes.step.id" /> <br /> { step.id } </span>
    </AccordionDetails>
  </Accordion>
)

Step.propTypes = {
  step: PropTypes.object.isRequired
}

export const StepList = ({ steps }) => {
  const activeSteps = steps.filter(step => step.status === 'active')
  const inactiveSteps = steps.filter(step => step.status === 'inactive')
  const notCreatedSteps = steps.filter(step => step.status === 'not_created')

  return (
    <Fragment>
      <Accordion>
        <AccordionSummary
          aria-controls="active-steps-content"
        >
          <Trans id="app.steps.bpmn_chart.active.title" />
        </AccordionSummary>
        <AccordionDetails>
          <span className="description">
            <Trans id="app.steps.bpmn_chart.active.description" />
          </span>
          { activeSteps.length === 0 && <Trans id="app.steps.bpmn_chart.active.empty" /> }
          { activeSteps.map(step => (
            <Step step={step} />
          )) }
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          aria-controls="inactive-steps-content"
        >
          <Trans id="app.steps.bpmn_chart.inactive.title" />
        </AccordionSummary>
        <AccordionDetails>
          <span className="description">
            <Trans id="app.steps.bpmn_chart.inactive.description" />
          </span>
          { inactiveSteps.length === 0 && <Trans id="app.steps.bpmn_chart.inactive.empty" /> }
          { inactiveSteps.map(step => (
            <Step step={step} />
          )) }
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          aria-controls="pending-steps-content"
        >
          <Trans id="app.steps.bpmn_chart.not_created.title" />
        </AccordionSummary>
        <AccordionDetails>
          <span className="description">
            <Trans id="app.steps.bpmn_chart.not_created.description" />
          </span>
          { notCreatedSteps.length === 0 && <Trans id="app.steps.bpmn_chart.not_created.empty" /> }
          { notCreatedSteps.map(step => (
            <span className="not-created"> { step.name } </span>
          )) }
        </AccordionDetails>
      </Accordion>
    </Fragment>
  )
}

StepList.defaultTypes = {
  steps: []
}

StepList.propTypes = {
  steps: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  steps: state.workflowDetails.steps.data,
})

export default connect(mapStateToProps)(StepList)
