import React, { PureComponent } from 'react'
import { Admin, Resource } from 'react-admin'
import fakeDataProvider from 'ra-data-fakerest'
import DefaultLayout from 'layouts/DefaultLayout'
import { createBrowserHistory } from 'history'
import ResourceList from 'admin/components/ResourceList'
import Dashboard from './Dashboard'

const history = createBrowserHistory({ basename: '/project_management' })
const tasks = require('./tasks.json')

const options = {
  attributes: ['Title', 'Description', 'Label', 'Workflow', 'Created_at'],
}

class ProjectManagementPage extends PureComponent {
  state = { tasks }

  getDataProvider = () => (
    fakeDataProvider({ tasks: this.state.tasks })
  )

  render() {
    return (
      <DefaultLayout>
        <div className="project-management react-admin-layout">
          <Admin
            dataProvider={this.getDataProvider()}
            history={history}
            dashboard={Dashboard}
          >
            <Resource name="tasks" list={ResourceList} options={options} />
          </Admin>
        </div>
      </DefaultLayout>
    )
  }
}

export default ProjectManagementPage
