import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { setTitle } from 'redux/actions/system'
import { Container } from 'reactstrap'
import { Link } from 'react-router-dom'
import DefaultLayout from 'layouts/DefaultLayout'
import { t } from 'utils/i18n/translate'
import Trans from 'components/Trans'

export class NotFoundPage extends PureComponent {
  componentDidMount() {
    const { setPageTitle } = this.props
    setPageTitle(t('app.pages.titles.not_found'))
  }

  render() {
    return (
      <DefaultLayout>
        <NotFoundContent />
      </DefaultLayout>
    )
  }
}

export const NotFoundContent = () => (
  <Container className="error-page">
    <h1><Trans id="app.errors.404.title" /></h1>
    <p className="mb-4"><Trans id="app.errors.404.text" /></p>
    <Link to="/" className="btn btn-primary"><Trans id="app.globals.back_home" /></Link>
  </Container>
)

NotFoundPage.propTypes = {
  setPageTitle: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
  setPageTitle: title => (dispatch(setTitle(title)))
})

export default connect(null, mapDispatchToProps)(NotFoundPage)
