import shortid from 'shortid'
import { types } from 'redux/actions/flashMessages'
import { removeItemById } from 'utils/arrays'

export default (state = [], action = {}) => {
  switch (action.type) {
    case types.ADD_FLASH_MESSAGE:
      return [
        ...state,
        {
          id: shortid.generate(),
          type: action.message.type,
          text: action.message.text
        }
      ]
    case types.DELETE_FLASH_MESSAGE:
      return removeItemById(state, action.id)
    case types.CLEAR_FLASH_MESSAGES: {
      return []
    }
    default:
      return state
  }
}
